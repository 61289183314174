import React from "react";
import DateRangePicker from "../../common/pickers/DateRangePicker";
import PeriodButtonsGroupFilter from "../../common/buttons/PeriodButtonsGroupFilter";
import {
    CURRENT_MONTH,
    LAST_2_DAYS,
    LAST_30_DAYS, LAST_7_DAYS,
    LAST_MONTH,
    TODAY,
    YESTERDAY
} from "../../../utils/constants";
import FiltersCard from "../../FiltersCard";


export default function ReferralFiltersCard({filterData, handleSelectDate, handleSelectPeriod, buildTable, clearData, showTable, inputStyle}) {


    const filters = () => {
        const selectedPeriod = filterData.period;
        return (
            <>
                    <DateRangePicker fromDate={filterData.fromDate}
                                     toDate={filterData.toDate}
                                     selectFromDateFunction={(fromDate) => handleSelectDate("fromDate", fromDate)}
                                     selectToDateFunction={(toDate) => handleSelectDate("toDate", toDate)}
                                     label="Дата"
                                     inputStyle={inputStyle}
                                     locatedInRightFilterBlock/>
                <div className="nowrap-custom" id="blockInputPeriod">
                    <PeriodButtonsGroupFilter
                        label="Период"
                        buttons={[
                            {isSelected: selectedPeriod === TODAY, title: "Сегодня", iconClassName: "date-icon icon-day", onClick: () => handleSelectPeriod(TODAY)},
                            {isSelected: selectedPeriod === YESTERDAY, title: "Вчера", iconClassName: "date-icon icon-minus-day", onClick: () => handleSelectPeriod(YESTERDAY)},
                            {isSelected: selectedPeriod === LAST_2_DAYS, title: "2 дня", iconClassName: "date-icon icon-2", onClick: () => handleSelectPeriod(LAST_2_DAYS)},
                            {isSelected: selectedPeriod === LAST_7_DAYS, title: "7 дней", iconClassName: "date-icon icon-7", onClick: () => handleSelectPeriod(LAST_7_DAYS)},
                            {isSelected: selectedPeriod === CURRENT_MONTH, title: "Текущий месяц", iconClassName: "date-icon icon-m", onClick: () => handleSelectPeriod(CURRENT_MONTH)},
                            {isSelected: selectedPeriod === LAST_MONTH, title: "Прошедший месяц", iconClassName: "date-icon icon-minus-m", onClick: () => handleSelectPeriod(LAST_MONTH)},
                            {isSelected: selectedPeriod === LAST_30_DAYS, title: "30 дней", iconClassName: "date-icon icon-30", onClick: () => handleSelectPeriod(LAST_30_DAYS)},
                        ]}
                    />
                </div>
            </>
        )
    };

    const buttons = () => {
        return (
            <div className="circle-btn-group">
                <button className={showTable ? "circle-btn selected" : "circle-btn"}
                        title="Сформировать отчет" onClick={buildTable}><i className="ti ti-check stat-icon"/>
                </button>
                <button className="circle-btn" title="Очистить" onClick={clearData}><i className="ti ti-close stat-icon"/>
                </button>
            </div>
        );
    };

    return <FiltersCard filters={filters()} buttons={buttons()}/>
}