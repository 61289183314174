import React, {Component} from "react";
import MaterialUiInput from "../../common/inputs/MaterialUiInput";
import SimpleTable from "../../common/tables/SimpleTable";
import {connect} from "react-redux";
import {
    loadPartnerDomains,
    parkPartnerDomain,
    deletePartnerDomain,
    makePartnerDomainDefault
} from "../../../actions/partnerDomainsActions";
import Spinner from "../../common/Spinner";
import NoContentMessage from "../../common/NoContentMessage";
import {showWarning} from "../../../actions/warningActions";


class PartnerDomainsContent extends Component {

    state = {
        cname: ""
    };

    componentDidMount() {
        this.props.loadPartnerDomains(false);
    }

    handleTextTyping = (value) => {
        this.setState({cname: value})
    };

    parkDomain = () => {
        this.state.cname && this.state.cname !== ""
        && this.props.parkPartnerDomain(this.state.cname, () => this.createDomainSuccessCallback());
    };

    createDomainSuccessCallback = () => {
        this.setState({cname: ""});
        this.props.loadPartnerDomains();
    };

    deleteDomain = (domain) => {
        domain && this.props.showWarning("Удалить припаркованный домен?", () => this.props.deletePartnerDomain(domain, () => this.props.loadPartnerDomains()))

    };

    makeDefault = (domain) => {
        const result = {id: domain.id, defaultDomain: !domain.defaultDomain}
        this.props.makePartnerDomainDefault(result, () => this.props.loadPartnerDomains());
    };


    tableColumns = () => {
        return [
            {
                name: "",
                key: "defaultDomainAction",
                getBodyCellValue: domain => <i className={domain.defaultDomain ? "fa fa-star" : "fa fa-star-o"}
                                               title="Добавить в избранные"
                                               style={{cursor: "pointer"}}
                                               onClick={() => this.makeDefault(domain)}/>,
                headerCellStyle: {minWidth: "30px", width: "30px"}
            },
            {
                name: "Припаркованные домены",
                key: "domain",
                getBodyCellValue: domain => domain.id + " | " + domain.cname,
            },
            {
                name: "Дата",
                key: "createDate",
                getBodyCellValue: domain => domain.createDate ? domain.createDate : "",
            },
            {
                name: "",
                key: "deleteDomainAction",
                getBodyCellValue: domain => <>
                    <i className="ti ti-close" style={{"color": "#414455", cursor: "pointer"}} title="Удалить"
                       onClick={() => this.deleteDomain(domain)}/>
                </>,
                headerCellStyle: {minWidth: "30px", width: "30px"}
            },
        ]
    };


    render() {
        return (
            <div className="tab-pane fade active show padding5" id="custom-nav-domains" role="tabpanel"
                 aria-labelledby="custom-nav-domains-tab">
                <p><b>Информация для парковки домена:</b></p>
                <ul className="ul-num">
                    <li>1. Создайте запись CNAME в разделе редактирования DNS-зоны вашего домена со значением
                        parking.affimob.com
                    </li>
                    <li>2. Дождитесь обновления dns-записей</li>
                    <li>3. Добавьте домен в нашу систему используя форму ниже</li>
                    <li>4. Припаркованный домен может использоваться для направления трафика и будет отображаться в
                        списке
                        доменов
                    </li>
                    <li>5. При необходимости отметьте звёздочкой <i className="fa fa-star"/> домен, который будет
                        подставляться в ваши ссылки по умолчанию
                    </li>
                </ul>
                <div className="domain-input">
                    <div className="jss8">
                        <MaterialUiInput onTextTyping={this.handleTextTyping} value={this.state.cname}/>
                    </div>
                    <div>
                        <button className="circle-btn btn-plus" title="Добавить домен" onClick={this.parkDomain}>
                            <i className="ti ti-plus"/>
                        </button>
                    </div>
                </div>
                <div>
                    <div className="responsive-block" id="fullWidth">
                        {this.props.isLoading
                            ? <Spinner/>
                            : (!this.props.domains || this.props.domains.length === 0)
                                ? <div style={{justifyContent: "center"}} className='row col-12'><NoContentMessage
                                    message="У вас нет припаркованных доменов"/></div>
                                : <SimpleTable columns={this.tableColumns()} data={this.props.domains} bodyRowKey="id"/>
                        }
                    </div>
                </div>


            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        domains: state.partnerDomainsReducer.partnerDomains,
        isLoading: state.partnerDomainsReducer.isLoading,
        error: state.error.data,
    }
};

export default connect(mapStateToProps, {
    showWarning, loadPartnerDomains, parkPartnerDomain, deletePartnerDomain,
    makePartnerDomainDefault
})(PartnerDomainsContent);