import React, {Fragment, useEffect} from "react";
import {connect} from "react-redux";
import {loadMainPageBanners} from "../../../../../actions/bannerActions";
import PartnersBlockItem from "./PartnersBlockItem";

function PartnersBlock({loadMainPageBanners, allMainBanners}) {

    useEffect(() => {
        loadMainPageBanners();
    }, []);

    return (
        allMainBanners && allMainBanners.length > 0
        ? <div className="partners">
                <div className="partners-title">
                    Наши партнеры:
                </div>
                <div className="partners-list">
                    {allMainBanners?.map(banner => <Fragment key={banner.id}><PartnersBlockItem partner={banner}/></Fragment>)}
                </div>
          </div>
            : <></>
    )
}

function mapStateToProps(state) {
    return {
        isLoading: state.bannerReducer.allMainBannersLoading,
        allMainBanners: state.bannerReducer.allMainBanners,
    }
}

export default connect(mapStateToProps, {
    loadMainPageBanners,
})(PartnersBlock);