import React, {useState} from "react";

export default function useResizeObserver(el) {
    const [elementSize, setElementSize] = useState({});

    const observer = React.useRef(
        new ResizeObserver(entries => {
            const { width, height, bottom, top, left, right } = entries[0].contentRect;
            setElementSize({width : width, height : height, bottom: bottom, top: top, fullHeight: (bottom + top), fullWidth: (left + right)});
        })
    );

    React.useEffect(() => {
        if (!el) {
            return;
        }
        observer.current.observe(el);

        return () => {
            observer.current.unobserve(el);
        };
    }, [el, observer]);

    return elementSize;
}