import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {Route, BrowserRouter, Switch} from 'react-router-dom';
import './App.css';
import store from './store/store'
import ProfilePage from "./components/pages/profile/ProfilePage";
import DashboardPage from "./components/pages/dashboard/DashboardPage"
import PartnersPage from "./components/pages/partners/PartnersPage";
import StatisticPage from "./components/pages/statistic/StatisticPage";
import PartnerOffersPage from "./components/pages/offers/PartnerOffersPage";
import ProjectsPage from "./components/pages/projects/ProjectsPage";
import MainPage from "./components/pages/main/MainPage";
import RulesPage from "./components/pages/main/RulesPage";
import PasswordRestorePage from "./components/pages/main/PasswordRestorePage";
import PartnerPaymentsPage from "./components/pages/payments/partner/PartnerPaymentsPage";
import ManagerOffersPage from "./components/pages/offers/ManagerOffersPage";

import {loadUser} from './actions/authActions';
import AdminRoute from './components/common/AdminRoute'
import PartnerRoute from './components/common/PartnerRoute'
import {
    URL_DASHBOARD,
    URL_PROFILE,
    URL_PARTNERS,
    URL_STATISTIC,
    URL_OFFERS,
    URL_PROJECTS,
    URL_RULES,
    URL_PW_RESTORE,
    URL_PAYMENTS,
    URL_FAQ,
    URL_TOOLS,
    URL_OFFERS_MANAGER,
    URL_OFFER_EDIT,
    URL_NEWS_MANAGER,
    URL_INFO,
    URL_ROOT, URL_PAYMENTS_MANAGER, URL_REFERRAL, URL_OFFER_ITEM,
} from "./properties";
import FaqPage from "./components/pages/faq/FaqPage";
import ToolsPage from "./components/pages/tools/ToolsPage";
import LoginRequiredRoute from "./components/common/LoginRequiredRoute";
import NotFoundPage from "./components/pages/NotFoundPage";
import OfferEditingPage from "./components/pages/offers/OfferEditPage";
import ManagerNewsPage from "./components/pages/news/manager/ManagerNewsPage";
import MuiThemeWrapper from "./components/wrappers/MuiThemeWrapper";
import NotificationWrapper from "./components/wrappers/NotificationWrapper";
import ManagerPaymentsPage from "./components/pages/payments/manager/ManagerPaymentsPage";
import ReferralProgramPage from "./components/pages/referral/ReferralProgramPage";
import InfoPage from "./components/pages/main/InfoPage";
import OfferItemPage from "./components/pages/offers/partnerOfferItem/OfferItemPage";


class App extends Component {

    componentDidMount() {
        store.dispatch(loadUser());
    }

    render() {
        return (
            <BrowserRouter>
                <Provider store={store}>
                    <MuiThemeWrapper>
                        <NotificationWrapper>
                            <Switch>
                                <Route path={URL_INFO} component={InfoPage}/>
                                <Route path={URL_RULES} exact component={RulesPage}/>
                                <Route path={URL_PW_RESTORE} component={PasswordRestorePage}/>
                                <LoginRequiredRoute path={URL_PROFILE} component={ProfilePage}/>
                                <PartnerRoute path={URL_DASHBOARD} component={DashboardPage}/>
                                <AdminRoute path={URL_PARTNERS} component={PartnersPage}/>
                                <AdminRoute path={URL_OFFERS_MANAGER} component={ManagerOffersPage}/>
                                <AdminRoute path={URL_NEWS_MANAGER} component={ManagerNewsPage}/>
                                <AdminRoute path={URL_OFFER_EDIT} component={OfferEditingPage}/>
                                <LoginRequiredRoute path={URL_OFFER_ITEM} component={OfferItemPage}/>
                                <AdminRoute path={URL_PAYMENTS_MANAGER} component={ManagerPaymentsPage}/>
                                <LoginRequiredRoute path={URL_STATISTIC} component={StatisticPage}/>
                                <PartnerRoute path={URL_OFFERS} component={PartnerOffersPage}/>
                                <PartnerRoute path={URL_PROJECTS} component={ProjectsPage}/>
                                <PartnerRoute path={URL_PAYMENTS} component={PartnerPaymentsPage}/>
                                <LoginRequiredRoute path={URL_FAQ} component={FaqPage}/>
                                <LoginRequiredRoute path={URL_TOOLS} component={ToolsPage}/>
                                <LoginRequiredRoute path={URL_REFERRAL} component={ReferralProgramPage}/>
                                <Route path={URL_ROOT} component={MainPage}/>
                                <Route component={NotFoundPage}/>
                            </Switch>
                        </NotificationWrapper>
                    </MuiThemeWrapper>
                </Provider>
            </BrowserRouter>
        );
    }
}

export default App;
