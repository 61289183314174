import {
    ALL_BANNERS_LOADED, ALL_BANNERS_LOADING, ALL_MAIN_BANNERS_LOADED, ALL_MAIN_BANNERS_LOADING
} from "../../actions/actionTypes";

const initialState = {
    allBanners: [],
    allBannersLoading: false,
    allMainBanners: [],
    allMainBannersLoading: false
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case ALL_BANNERS_LOADING:
            return {
                ...state,
                allBannersLoading: true
            };
        case ALL_BANNERS_LOADED:
            return {
                ...state,
                allBanners: action.payload,
                allBannersLoading: false
            };
        case ALL_MAIN_BANNERS_LOADING:
            return {
                ...state,
                allMainBannersLoading: true
            };
        case ALL_MAIN_BANNERS_LOADED:
            return {
                ...state,
                allMainBanners: action.payload,
                allMainBannersLoading: false
            };
        default:
            return state;
    }
}