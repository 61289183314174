import {
    MANAGER_PARTNERS_ADD_OR_REMOVE_FROM_FAVORITES,
    MANAGER_PARTNERS_APPROVE_REGISTRATION, MANAGER_PARTNERS_CHANGE_LEVEL,
    MANAGER_PARTNERS_FILTER_DATA_LOADED,
    MANAGER_PARTNERS_LOADED,
    MANAGER_PARTNERS_LOADING, MANAGER_PARTNERS_REJECT_REGISTRATION, MANAGER_PARTNERS_TOGGLE_PAYMENT_AVAILABLE
} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import React from "react";
import {getSuccess} from "./successActions";
import {partnerLevelsDispatcher} from "../utils/partnerLevelUtils";


export const loadPartners = (callbackFunction) => (dispatch, getState) => {
    dispatch({type: MANAGER_PARTNERS_LOADING});
        let parameters = "?sortBy=registrationDate&direction=DESC";
        axios
            .get(process.env.REACT_APP_API_URL + '/users/partners' + parameters, getConfig(getState))
            .then(res =>
                dispatch({
                    type: MANAGER_PARTNERS_LOADED,
                    payload: res.data
                })
            )
            .then(() => { callbackFunction && callbackFunction()})
            .catch(err => {
                dispatch(returnErrors(err));
            });
};

export const loadPartnersFilterData = () =>
    (dispatch, getState) => {
        axios
            .get(process.env.REACT_APP_API_URL + '/users/partners/filterData', getConfig(getState))
            .then(res =>
                dispatch({
                    type: MANAGER_PARTNERS_FILTER_DATA_LOADED,
                    payload: res.data
                })
            )
            .catch(err => {
                dispatch(returnErrors(err));
            });
    };

export const approveRegistration = (partnerId, callbackFunction) => (dispatch, getState) => {
    let partner;
    const success = {success: 'Регистрация подтверждена'};
    axios
        .post(process.env.REACT_APP_API_URL + '/users/partners/approveRegistration?partnerId=' + partnerId, null, getConfig(getState))
        .then(res =>
        {
            partner = res.data;
            dispatch({
            type: MANAGER_PARTNERS_APPROVE_REGISTRATION,
            payload: partner
        })
        }
        )
        .then(() => { callbackFunction && callbackFunction(partner)})
        .then(() => {dispatch(getSuccess(success))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const rejectRegistration = (partnerId, callbackFunction) => (dispatch, getState) => {
    let partner;
    const success = {success: 'Регистрация отклонена'};
    axios
        .post(process.env.REACT_APP_API_URL + '/users/partners/rejectRegistration?partnerId=' + partnerId, null, getConfig(getState))
        .then(res =>
        {
            partner = res.data;
            dispatch({
                type: MANAGER_PARTNERS_REJECT_REGISTRATION,
                payload: res.data
            })
        }
        )
        .then(() => { callbackFunction && callbackFunction(partner)})
        .then(() => {dispatch(getSuccess(success))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const addOrRemoveFromFavorites = (partnerId, callbackFunction) => (dispatch, getState) => {
    let partner;
    let success;
    axios
        .post(process.env.REACT_APP_API_URL + '/users/partners/favorites?partnerId=' + partnerId, null, getConfig(getState))
        .then(res =>
            {
                partner = res.data;
                success = {success: partner.isFavorite
                        ? "Партнер '" + partner.partnerName + "' добавлен в избранные"
                        : "Партнер '" + partner.partnerName + "' исключен из избранных"};
                dispatch({
                    type: MANAGER_PARTNERS_ADD_OR_REMOVE_FROM_FAVORITES,
                    payload: partner
                })
            }
        )
        .then(() => { callbackFunction && callbackFunction(partner)})
        .then(() => {dispatch(getSuccess(success))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const toggleCanOrderPayment = (partnerId, callbackFunction) => (dispatch, getState) => {
    let partner;
    let success;
    axios
        .post(process.env.REACT_APP_API_URL + '/users/partners/toggleCanOrderPayment?partnerId=' + partnerId, null, getConfig(getState))
        .then(res =>
            {
                partner = res.data;
                success = {success: partner.canOrderPayment
                        ? "Партнеру '" + partner.partnerName + "' активирована возможность заказа выплаты"
                        : "Партнеру '"+ partner.partnerName + "' деактивирована возможность заказа выплаты" };
                dispatch({
                    type: MANAGER_PARTNERS_TOGGLE_PAYMENT_AVAILABLE,
                    payload: partner
                })
            }
        )
        .then(() => { callbackFunction && callbackFunction(partner)})
        .then(() => {dispatch(getSuccess(success))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const changePartnerLevel = (partnerId, nextLevel, callbackFunction) => (dispatch, getState) => {
    let partner;
    let success;
    axios
        .post(process.env.REACT_APP_API_URL + '/users/partners/changeLevel?partnerId=' + partnerId + '&level=' + nextLevel, null, getConfig(getState))
        .then(res =>
            {
                partner = {...res.data, levelRus: partnerLevelsDispatcher[res.data.level].value};
                success = {success: "Уровень партнера '" + partner.partnerName + "' изменен на " + partnerLevelsDispatcher[partner.level].value};
                dispatch({
                    type: MANAGER_PARTNERS_CHANGE_LEVEL,
                    payload: partner
                })
            }
        )
        .then(() => { callbackFunction && callbackFunction(partner)})
        .then(() => {dispatch(getSuccess(success))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

