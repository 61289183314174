import React from "react";
import {
    BILLING,
    SUBSCRIBE_1,
} from "../../../../utils/constants";
import {isMobileScreen} from "../../../../utils/displayUtils";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";

const payoutTypes = [
    {label: "Выкуп", value: SUBSCRIBE_1},
    {label: "Ребилы", value: BILLING},
];


export default function PayoutTypes(props) {

    const {selectedPayoutType, handleChangeFilterData, inputStyle} = props;
    const isMobile = isMobileScreen();

    const selectTypeGroup = (value) => {
        handleChangeFilterData("payoutType", value, true);
    };

  return(
        <MaterialUISelect
            styles={inputStyle}
            dropdownData={payoutTypes}
            selectedValue={selectedPayoutType}
            displayedField='label'
            dataField='value'
            label='Тип выплаты'
            placeholder="-"
            onSelectCallbackFunction={selectTypeGroup}
            isSearchable={!isMobile}/>
    );
}
