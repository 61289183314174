import React, {Component} from "react";
import BasePage from "../BasePage";
import ProfileAvatarHeaderCard from "./ProfileAvatarHeaderCard";
import ProfileContactsDataCard from "./ProfileContactsDataCard";
import ProfilePaymentDetailsCard from "./paymentCard/ProfilePaymentDetailsCard";
import ProfilePostbackTrafficbackCard from "./ProfilePostbackTrafficbackCard";
import ProfilePasswordChangeCard from "./ProfilePasswordChangeCard";
import {connect} from "react-redux";
import {loadUserData, saveProfile} from "../../../actions/userAction";
import {NONE, WEBMONEY} from "../../../utils/constants";
import ProfileButton from "./buttons/ProfileButton";
import {showWarning} from "../../../actions/warningActions";
import {clearErrors, returnErrors} from "../../../actions/errorActions";
import {isAdmin, isAdminAsPartner, isPartner} from "../../../utils/authUtils";
import ProfileReferralProgramCard from "./ProfileReferralProgramCard";
import ProfileNotificationCard from "./ProfileNotificationCard";
import ProfileAdNetworkCard from "./adNetworkCard/ProfileAdNetworkCard";
import PageContent from "../../common/page/PageContent";
import {sendTestTelegramNotification} from "../../../actions/notificationActions";
import {notEmptyString} from "../../../utils/stringUtils";

const notificationPeriods = [
    {periodInHours: undefined, label: "Не задана"},
    {periodInHours: 1, label: "1 час"},
    {periodInHours: 2, label: "2 часа"},
    {periodInHours: 4, label: "4 часа"},
    {periodInHours: 6, label: "6 часаов"},
    {periodInHours: 12, label: "12 часаов"},
    {periodInHours: 24, label: "24 часа"},
];
const refTmpl = window.location.origin + "?ref=";


class ProfilePage extends Component {

    state = {
        isPaymentDataDisabled: false,
        profile: {
            name: "",
            login: "",
            email: "",
            telegram: "",
            skype: "",
            phone: "",
            referrerLink: "",
            referrerPercent: "0",
            partnerRef: "",
            defaultProject: {
                trafficbackUrl: "",
                postbackUrl: "",
                postbackEventTypes: [],
            },
            paymentData: {
                paymentType: NONE,
                bankName: "",
                bankAccount: "",
                correspondentAccount: "",
                personalAccount: "",
                inn: "",
                bik: "",
                kpp: "",
                contractDate: "",
                contractNumber: "",
                legalEntity: "",
                webmoneyWallet: "",
                wallet: "",
            },
            password: "",
            confirmedPassword: "",
            telegramChatId: "",
            adNetworkAccount: {
              adNetworkId: null,
              adNetworkEmail: null,
            },
            notificationData: {
                telegramChatId: "",
                isSubscribedToNews: false,
                isSubscribedToCaps: false,
                isSubscribedToStatistic: false,
                statistiсNotificationPeriod: notificationPeriods[0]
            },
            financeFee: "",
            defaultFinanceFee: "8",
        },
        userDataLoaded: false,
        profileChanged: false,
    };

    componentDidMount() {
        this.props.loadUserData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!this.state.userDataLoaded && this.props.profileData) || (this.props.profileData !== prevProps.profileData)) {
            this.setState({
                ...this.state,
                isPaymentDataDisabled: !isAdmin() && (this.props.profileData.paymentData && this.props.profileData.paymentData.paymentType === WEBMONEY
                    && this.props.profileData.paymentData.webmoneyWallet && this.props.profileData.paymentData.webmoneyWallet !== ""),
                profile: this.props.profileData,
                userDataLoaded: true,
                profileChanged: false,
            })
        }
    }

    handleTextTyping = (e) => {
        const profile = this.state.profile;
        if (e.target.name === "referrerLink") {
            const value = e.target.value;
            if (!value.includes(refTmpl) || value.includes('&')) {
                return;
            } else {
                const refArr = value.split(refTmpl);
                profile.partnerRef = refArr[1].trim();
            }
        }
        profile[e.target.name] = e.target.value;
        if (e.target.name === "email") {
            profile.login = e.target.value;
        }
        this.setState(
            {...this.state, profile: profile, profileChanged: true}
        );
    };

    handleChangeNotificationData = (fieldName, value) => {
        const profile = this.state.profile;
        const notificationData = profile.notificationData;
        notificationData[fieldName] = value;
        this.setState(
            {...this.state, profile: {...profile, notificationData: notificationData}, profileChanged: true}
        );
    };

    handleUrlTextTyping = (e) => {
        const fieldName = e.target.name;
        const value = e.target.value;
        const profile = this.state.profile;
        profile.defaultProject[fieldName] = value;
        this.setState(
            {...this.state, profile: profile, profileChanged: true}
        );
    };

    onSelectPostbackEventType = (eventType) => {
        const profile = this.state.profile;
        if (!profile.defaultProject.postbackEventTypes) {
            profile.defaultProject.postbackEventTypes = [];
        }
        const {postbackUrl, postbackEventTypes} = this.state.profile.defaultProject;
        let index = postbackEventTypes.indexOf(eventType);
        if (!postbackUrl || postbackUrl === "" || !eventType || postbackEventTypes.length === 3) {
            profile.defaultProject.postbackEventTypes = [];
        } else {
            if (index === -1) {
                profile.defaultProject.postbackEventTypes.push(eventType);
            } else {
                profile.defaultProject.postbackEventTypes.splice(index, 1);
            }
        }
        this.setState({profile: profile, profileChanged: true});
    };

    onSelectPaymentType = (paymentType) => {
        const profile = this.state.profile;
        profile.paymentData.paymentType = paymentType;
        this.setState(
            {...this.state, profile: profile, profileChanged: true}
        );
    };

    onSaveProfile = () => {
        const {password, confirmedPassword} = this.state.profile;
        if (notEmptyString(password) && password !== confirmedPassword) {
            return;
        }
        this.props.showWarning("Сохранить изменения?", () => {
            this.props.clearErrors();
            this.props.saveProfile(this.state.profile)
        });
    };

    onSendTestNotification = () => {
        if (!this.state.profile.notificationData?.telegramChatId) {
            const error = {response: {data: {details: "Введите ID Telegram чата"}, status: 400}};
            this.props.returnErrors(error)
        }
        this.props.sendTestTelegramNotification( this.state.profile.notificationData?.telegramChatId);
    };


    renderContent = () => {
        const {name, email, telegram, skype, phone, defaultProject, paymentData, password, confirmedPassword, dominicPartnerId,
            referrerLink, referrerPercent, notificationData, canOrderPayment, financeFee, defaultFinanceFee} = this.state.profile;
        const {errors} = this.props;
        const fieldErrors = errors && errors.data && errors.data.fieldErrors;
        const isPartnerProfilePage = isPartner() || isAdminAsPartner();
        return (
            <div className="row">
                <ProfileAvatarHeaderCard name={name} email={email} dominicPartnerId={dominicPartnerId} onTextTyping={this.handleTextTyping} isPartnerProfilePage={isPartnerProfilePage}/>
                {isPartnerProfilePage && <ProfileContactsDataCard onTextTyping={this.handleTextTyping} telegram={telegram}
                                             phone={phone} skype={skype} fieldErrors={fieldErrors}/>}

                {isPartnerProfilePage && <ProfilePostbackTrafficbackCard defaultProject={defaultProject}
                                                    handleTextTyping={this.handleUrlTextTyping}
                                                    onSelectPostbackEventType={this.onSelectPostbackEventType}
                    fieldErrors={fieldErrors}/>}
                {isPartnerProfilePage && <ProfilePaymentDetailsCard paymentData={paymentData} onTextTyping={this.handleTextTyping}
                                        onSelectPaymentType={this.onSelectPaymentType} fieldErrors={fieldErrors}
                                        disabled={this.state.isPaymentDataDisabled} financeFee={financeFee}
                                        defaultFinanceFee={defaultFinanceFee}/>}
                {isPartnerProfilePage && <ProfileAdNetworkCard/>}
                {isPartnerProfilePage && <ProfileReferralProgramCard referrerLink={referrerLink} referrerPercent={referrerPercent} onTextTyping={this.handleTextTyping}/>}
                <ProfilePasswordChangeCard password={password} confirmedPassword={confirmedPassword} onTextTyping={this.handleTextTyping}
                fieldErrors={fieldErrors}/>
                {isPartnerProfilePage && <ProfileNotificationCard notificationData={notificationData}
                                                                                 notificationPeriods={notificationPeriods}
                                                                                 onChangeNotificationData={this.handleChangeNotificationData}
                                                                                 onSendTestNotification={this.onSendTestNotification}/>}
                <ProfileButton label="Сохранить" disabled={!this.state.profileChanged} onClick={this.onSaveProfile}/>
            </div>
        )
    };


    render() {
        return (
            <BasePage
                content={<PageContent contentEl={this.renderContent()}/>}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        profileData: state.userReducer.userData,
        isLoading: state.userReducer.loading,
        errors: state.error
    }
}

export default connect(mapStateToProps, {
    loadUserData,
    saveProfile,
    showWarning,
    clearErrors,
    returnErrors,
    sendTestTelegramNotification
})(ProfilePage);