import React from "react";
import {isMobileScreen} from "../../../../utils/displayUtils";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {useSelector} from "react-redux";

export default function LtvServiceTypes(props) {

    const {selectedServiceType, handleChangeFilterData, inputStyle} = props;
    const isMobile = isMobileScreen();
    const ltvServiceTypes = useSelector(state => state.ltvServiceTypesReducer.ltvServiceTypes);
    const rosLines = useSelector(state => state.rosLinesReducer.rosLines);

    ltvServiceTypes.forEach( item => item.line = rosLines.find( line => line.lineId == item.lineId )?.line);

    const selectServiceType = (value) => {
        handleChangeFilterData( "ltvServiceType", value, true);
    };

    return(
        <MaterialUISelect
            styles={inputStyle}
            dropdownData={ltvServiceTypes}
            selectedValue={selectedServiceType}
            displayedField='name'
            dataField='id'
            label='Тип сервиса'
            placeholder='-'
            onSelectCallbackFunction={selectServiceType}
            isSearchable={!isMobile}/>
    );
}
