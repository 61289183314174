import React, {useRef} from "react";
import {connect} from "react-redux";
import {uploadFile} from "../../../actions/fileActions";

function ImageInput({imgId, setImgId, inputClassName, uploadFile}) {


    const inputFileRef = useRef();

    const onSelectFile = () => {
        inputFileRef.current.click();
    };

    const onChangeFile = (e) => {
        uploadCoverImage(e.target.files[0]);
        e.target.value = "";
    };

    const uploadCoverImage = (file) => {
        uploadFile(file, (savedImage) => {
            setImgId && setImgId(savedImage.id)
        });
    };


    return (
        <>
            { imgId && <img src={process.env.REACT_APP_API_URL + "/files/" + imgId} alt="" onClick={onSelectFile} style={{cursor: "pointer"}}/> }
            <input
                className={inputClassName}
                style={{display: (imgId ? "none" : "block")}}
                type="file"
                value=""
                onChange={onChangeFile} onSelect={onChangeFile}
                ref={inputFileRef}/>
        </>
    )

}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {uploadFile})(ImageInput);