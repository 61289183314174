import {
    CHANGE_PARTNER_LINKS_PROJECT_FILTER, PARTNER_PROJECT_SAVED,
    PARTNER_PROJECTS_LOADED, PARTNER_PROJECTS_LOADED_WITH_NEW,
    PARTNER_PROJECTS_LOADING
} from "../../actions/actionTypes";
import {NEW_PROJECT} from "../../utils/projectUtils";

const initialState = {
    projects: [],
    selectedProject: null,
    loading: false
};

export default function (state = initialState, action) {
    let projects = state.projects;
    switch (action.type) {
        case PARTNER_PROJECTS_LOADING:
            return {
                loading: true
            };
        case PARTNER_PROJECTS_LOADED:
            return {
                projects: sortBySourceId(action.payload),
                selectedProject: action.payload ?  action.payload[0] : null,
                loading: false
            };
        case PARTNER_PROJECTS_LOADED_WITH_NEW:
            return {
                projects: moveDefaultProjectToTop(action.payload),
                selectedProject: action.payload ?  action.payload[0] : null,
                loading: false
            };
        case PARTNER_PROJECT_SAVED:
            return {
                projects: action.payload.isNew ? createNewArrayWithProject(projects, action.payload.project) : [action.payload.project],
                selectedProject: action.payload,
                loading: false
            };
        case CHANGE_PARTNER_LINKS_PROJECT_FILTER:
            return {
                ...state,
                selectedProject: action.payload
            };

        default: return state;
    }

}

function createNewArrayWithProject(projects, newProject) {
    if (!projects || !newProject) {
        return projects;
    }
    projects.unshift(newProject);
    return  projects.slice(0);
}

function moveDefaultProjectToTop(projects) {
    if (!projects) {
        return [];
    }
    const defaultProjectIndex = projects.map(project => project.sourceId).indexOf(0);
    var defaultProject = projects[defaultProjectIndex];
    projects.splice(defaultProjectIndex, 1);
    projects.splice(0, 0, defaultProject);
    return addNewAndReturn(projects);
}


const addNewAndReturn = (projects) => {
    projects.splice(0, 0, NEW_PROJECT);
    return projects;
};

function sortBySourceId(projects) {
    if (!projects) {
        return [];
    }
    return projects.sort((a, b) => a.sourceId - b.sourceId);
}