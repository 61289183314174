import React, {Fragment} from "react";
import {createTheme, MuiThemeProvider} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ruLocale from "date-fns/locale/ru";


const color = "#009EFB";
const colorUnderlineBefore = "#dadde0";
const theme = createTheme({
    overrides: {
        MuiInput: {
            underline: {
                "&:after": {
                    borderBottom: `2px solid ${color}`
                },
                '&:focused::after': {
                    borderBottom: `2px solid ${color}`,
                },
                '&:before': {
                    borderBottom: `1px solid ${colorUnderlineBefore}`,
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: `1px solid ${colorUnderlineBefore}`,
                },

            }
        }
    }
});

export default ({children}) => {

    return (
        <Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                <MuiThemeProvider theme={theme}>
                    {children}
                    </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        </Fragment>)
}