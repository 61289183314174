import axios from "axios";
import {returnErrors} from "./errorActions";
import {getConfig} from './authActions';
import {LTV_SERVICE_TYPES_LOADED, LTV_SERVICE_TYPES_LOADING} from "./actionTypes";

export const loadLtvServiceTypes = () => (dispatch, getState) => {

    dispatch({type: LTV_SERVICE_TYPES_LOADING});

    axios
        .get(process.env.REACT_APP_API_URL + '/ltv/ltvServiceTypes', getConfig(getState))
        .then(res =>
            dispatch({
                type: LTV_SERVICE_TYPES_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};
