import axios from "axios";
import {
    BLOG_ITEM_LOADED,
    BLOG_NEWS_LOADED,
    BLOG_NEWS_LOADING, CLEAR_BLOG_ITEM,
    CLEAR_EDITABLE_NEWS_ITEM, CLEAR_HEADER_NEWS,
    CLEAR_NEWS, HEADER_NEWS_LOADED, HEADER_NEWS_LOADING,
    NEWS_ITEM_CARD_LOADED, NEWS_ITEM_READ,
    NEWS_ITEM_SAVED,
    NEWS_LOADED,
    NEWS_LOADING,
} from "./actionTypes";
import {returnErrors} from "./errorActions";
import {getConfig} from "./authActions";
import {getSuccess} from "./successActions";

export const clearNews = () => (dispatch, getState) => {
    dispatch({
        type: CLEAR_NEWS,
    })
};

export const loadHeaderNews = (maxResults, previousId) => (dispatch, getState) => {
    dispatch({type: HEADER_NEWS_LOADING});
    !previousId && dispatch({type: CLEAR_HEADER_NEWS});
    axios
        .get(process.env.REACT_APP_API_URL + '/news/header?maxResults=' + maxResults
            + (previousId !== null ? '&previousId=' + previousId : '' ), getConfig(getState))
        .then(res =>
            dispatch({
                type: HEADER_NEWS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const getBlogNews = (maxResults, previousId) => (dispatch, getState) => {
    dispatch({type: BLOG_NEWS_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/news/blog?maxResults=' + maxResults
            + (previousId !== null ? '&previousId=' + previousId : '' ), getConfig(getState))
        .then(res =>
            dispatch({
                type: BLOG_NEWS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const getManagerNews = () => (dispatch, getState) => {
    dispatch({type: NEWS_LOADING});
        axios
            .get(process.env.REACT_APP_API_URL + '/news/manager', getConfig(getState))
            .then(res =>
                dispatch({
                    type: NEWS_LOADED,
                    payload: res.data
                })
            )
            .catch(err => {
                dispatch(returnErrors(err));
            });
};

export const saveNewsItem = (newsItem, isPublicationConfirmed, callbackFunction) => (dispatch, getState) => {
    const body = {
        id: newsItem.id,
        plannedPublicationDate: newsItem.plannedPublicationDate,
        category: newsItem.category && newsItem.category.value,
        iconClassName: newsItem.iconClassName,
        title: newsItem.title,
        html: newsItem.html,
        urlName: newsItem.urlName,
        imageIds: newsItem && newsItem.imageIds && newsItem.imageIds
            .filter(imageId => imageId === newsItem.coverImageId || newsItem.html.includes("/api_v2/files/" + imageId)),
        coverImageId: newsItem.coverImageId,
        publicationEventTypes: newsItem.publicationEventTypes,
        isPublicationConfirmed: isPublicationConfirmed
    };
    const success = {success: "Новость сохранена"};
        axios
            .post(process.env.REACT_APP_API_URL + '/news', body, getConfig(getState))
            .then(res =>
                dispatch({
                    type: NEWS_ITEM_SAVED,
                    payload: res.data
                })
            )
            .then(() => {
                dispatch(getSuccess(success))
            })
            .then(() => callbackFunction && callbackFunction())
            .catch(err => {
                dispatch(returnErrors(err));
            });
};

export const getNewsItemCard = (newsItemId) => (dispatch, getState) => {
    axios
        .get(process.env.REACT_APP_API_URL + '/news/' + newsItemId + '/manager', getConfig(getState))
        .then(res =>
            dispatch({
                type: NEWS_ITEM_CARD_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const clearEditableNewsItem = () =>  (dispatch, getState) => {
    dispatch({
        type: CLEAR_EDITABLE_NEWS_ITEM,
    })
};

export const loadBlogItem = (id) =>  (dispatch, getState) => {
    dispatch({
        type: CLEAR_BLOG_ITEM,
    });
    axios
        .get(process.env.REACT_APP_API_URL + '/news/blog/' + id, getConfig(getState))
        .then(res =>
            dispatch({
                type: BLOG_ITEM_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const readNewsItem = (newsItem, callbackFunction) => (dispatch, getState) => {
    if (!newsItem?.id) {
        return;
    }
    axios
        .put(process.env.REACT_APP_API_URL + '/news/' + newsItem.id + '/readItem', null, getConfig(getState))
        .then(res =>
            dispatch({
                type: NEWS_ITEM_READ,
                payload: res.data
            })
        )
        .then(() => callbackFunction && callbackFunction())
        .catch(err => {
            dispatch(returnErrors(err));
        });
};