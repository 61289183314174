import React from "react";
import goImg from "../../../../resources/images/icons/go.png"
import {getBannerName} from "../../../../utils/bannerUtils";


export default function DashboardBannerItem({banner, copiedPromoCodeBannerId, copyPromoCode}) {

    const promoCodeEl = () => {
        return copiedPromoCodeBannerId && copiedPromoCodeBannerId === banner.id
        ? <>Код скопирован</>
        : <>код: <span>{banner.promoCode}</span></>;
    };

    return (
        <div className="re-item">
            <div className="col" style={{position: 'inherit'}}>
                <div className="type">
                    {getBannerName(banner?.type)}
                </div>
                <div className="ad-logo">
                    <img src={process.env.REACT_APP_API_URL + "/files/" + banner.imageId} alt=""/>
                </div>
                <div className="ad-bonus">
                    {banner.bonusPercent ? ("Бонус " + banner.bonusPercent + "%") : ""}
                </div>
            </div>
            <div className="col" style={{position: 'inherit'}}>
                <div className="codecopy" onClick={() => copyPromoCode(banner)}>
                    {promoCodeEl()}
                </div>
                <a href={banner.link} className="go">
                    <span>перейти</span>
                    <span><img src={goImg} alt=""/></span>
                </a>

            </div>
        </div>
    )
}