import React from "react";
import {currentUserId, getPartnerId, isAdminAsPartner} from "../../../utils/authUtils";


export default function ChatMessage({messageObj}) {

    const userId = isAdminAsPartner() ? getPartnerId() : currentUserId();
    let message = messageObj.text.replace(/(https?:\/\/[^(\s|\n)]+)/g, "<a href='$1' style='color: #007bff'>$1</a>");
    message = message.replace(/\n/g, "<br/>");
        const date = messageObj.createDate && new Date(messageObj.createDate);
    const time = date && (("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2));
    return (
        <div className={messageObj.userId == userId ? "message parker" : "message stark"}>
            <div dangerouslySetInnerHTML={{__html: message}}/>
                {messageObj.edited &&
                <div className="live-chat-edited">Изменено</div>}
            <div className="live-chat-time">{time}</div>
        </div>
    )
}