import React, {Fragment} from "react";

export default function NewsIconInput(props) {
        const {iconClassName, onChangeIconClassName, error} = props;
    return (
        <Fragment>
            <label htmlFor="iconsChange">Иконка</label>
            <i className="ti ti-help-alt iconChanger" onClick={() => window.open("https://themify.me/themify-icons", "_blank")}/>
            <input type="text" placeholder="ti-gallery" className="form-control" id="iconsChange" value={iconClassName} onChange={onChangeIconClassName}/>
            {error && <div style={{fontSize: "12px", color: "red"}}>{error.error}</div>}
        </Fragment>
    )

}