import {DELETE_USER_PRESET, SAVE_USER_PRESET, USER_PRESETS_LOADED, USER_PRESETS_LOADING} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";

export const loadUserPresets = (type, accessType, userId, callbackFunction) => (dispatch, getState) => {

    dispatch({type: USER_PRESETS_LOADING});

    userId = userId ? userId : "";
    let result = [];
    axios
        .get(process.env.REACT_APP_API_URL + '/presets?type=' + type + '&accessType=' + accessType + '&userId=' + userId,
            getConfig(getState))
        .then(res => {
                result = res.data.result;
                dispatch({
                    type: USER_PRESETS_LOADED,
                    payload: {type: type, data: result}
                });
            }
        )
        .then(() => { callbackFunction && callbackFunction(result)})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const savePreset = (preset, onSuccessCallback, showSuccessMsg) => (dispatch, state) => {
    let success;
    axios
        .post(process.env.REACT_APP_API_URL + '/presets', preset, getConfig(state))
        .then(res => {
                success = res.data;
                dispatch({
                    type: SAVE_USER_PRESET,
                    payload: res.data
                });
            }

        )
        .then(() => { onSuccessCallback && onSuccessCallback() })
        .then(() => {showSuccessMsg && dispatch(getSuccess(success))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const deleteSelectedPreset = (preset, onSuccessCallback) => (dispatch, state) => {
    let success;
    axios
        .delete(process.env.REACT_APP_API_URL + '/presets/' + preset.id, getConfig(state))
        .then(res => {
            res.data.id = preset.id;
            res.data.type = preset.type;
            success = res.data;
            dispatch({
                type: DELETE_USER_PRESET,
                payload: res.data
            });
        })
        .then(() => { onSuccessCallback && onSuccessCallback() })
        .then(() => dispatch(getSuccess(success)))
        .catch(err => {
            dispatch(returnErrors(err));
        });
};