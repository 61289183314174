import React from "react";

export default function OfferLinkInfo() {

    return (
        <div>
            &nbsp;
            <p className="alert"><i className="ti ti-alert"/> Трафик будет направлен на оффер(ы) только при передаче
                источников и площадок в параметрах traf_src и site_id, обязательно проверьте правильность написания
                макросов или обратитесь за помощью к менеджерам</p>
        </div>
    )
}