import React, {useRef} from "react";
import {connect} from "react-redux";
import {uploadRosLineFile} from "../../../../actions/rosLinesActions";

function RosLinesUploadComponent({uploadRosLineFile}) {
    const inputFileRef = useRef();

    const handleClickUploadFile = () => {
        inputFileRef.current.click();
    };

    const handleUploadFile = (e) => {
        uploadRosLineFile(e.target.files[0]);
        e.target.value = "";
    };

    return <div style={{fontSize: "12px", fontWeight: "600", paddingBottom: "15px", float: "right"}} className='row'>
        <div style={{paddingRight: '10px', paddingTop: "6px"}}>Загрузить файл rosLines</div>
        <button className="circle-btn"
                title="Файл используется в формировании отчетов 'Анализ LTV'"
                onClick={handleClickUploadFile}>
            <i className="ti ti-upload"/>
            <input type="file" style={{display: "none"}} ref={inputFileRef} onChange={handleUploadFile}
                   onSelect={handleUploadFile}/>
        </button>
    </div>
}

export default connect(null, {uploadRosLineFile})(RosLinesUploadComponent);