import {
    OFFER_BUYOUT_RULES_LOADED, OFFER_BUYOUT_RULES_LOADING
} from "../../actions/actionTypes";

const initialState = {
    loading: false,
    offerBuyoutRules: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case OFFER_BUYOUT_RULES_LOADING:
            return {
                ...state,
                loading: true,
            };
        case OFFER_BUYOUT_RULES_LOADED:
            return {
                ...state,
                loading: false,
                offerBuyoutRules: action.payload,
            };
        default:
            return state;
    }
}