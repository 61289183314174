import {REFERRER_STATISTIC_DATA_LOADED, REFERRER_STATISTIC_DATA_LOADING} from "./actionTypes";
import {getIsoLocalString} from "../utils/dateUtils";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";

export const loadReferrerStatistic = (filters, statisticType, callbackFunction) => (dispatch, getState) => {
    dispatch({ type: REFERRER_STATISTIC_DATA_LOADING });

    const fromDate = filters.fromDate ? "?from=" + getIsoLocalString(filters.fromDate) : "";
    const toDate = filters.toDate ? "&to=" + getIsoLocalString(filters.toDate) : "";
    let partnerIds = filters.partnerIds.length > 0 ? ("&partnerIds=" + filters.partnerIds) : "";
    axios
        .get(process.env.REACT_APP_API_URL + '/statistic/referrer' + fromDate + toDate + partnerIds, getConfig(getState))
        .then(res =>
            dispatch({
                type: REFERRER_STATISTIC_DATA_LOADED,
                payload: res.data
            })
        )
        .then(() => callbackFunction && callbackFunction())
        .catch(err => {
            dispatch(returnErrors(err));
        });
};