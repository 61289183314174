import React, {Fragment, useState} from "react";

export default function PopupPasswordInput({placeholder="Пароль", name="password", value, errorMsg, onTextTyping, useAutocomplete}) {

    const [isPasswordHidden, setPasswordHidden] = useState(true);

    const toggleShowPassword = () => {
        setPasswordHidden(!isPasswordHidden);
    };

    return (
        <Fragment>
            <input type={isPasswordHidden ? "password" : "text"} name={name} placeholder={placeholder} onChange={onTextTyping}
                   autoComplete={useAutocomplete ? "current-password" : "new-password"}/>
            <span className="hide-password" style={{cursor: "pointer", width: "90px"}} onClick={toggleShowPassword}>{isPasswordHidden ? "Показать" : "Скрыть"}</span>
            {errorMsg && <span className="login-error is-visible">{errorMsg}</span>}
        </Fragment>
    )
}