import React from "react";

export default function OfferModalInput({className, label, value='', dataType, onTextTyping, name, readOnly}) {

    return (
        <div className={className ? className : "form-group flex-start"}>
            <label className="float-left">{label}</label>
            <input className="form-control" name={name} value={value} onChange={onTextTyping} type={dataType} readOnly={readOnly}/>
        </div>
    )

}