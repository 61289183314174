import React, {Component} from "react";
import {connect} from "react-redux";
import ActionPopover from "../../../common/popovers/ActionPopover";
import {DeleteOutlined, SaveOutlined} from '@material-ui/icons';
import {deleteSelectedPreset} from "../../../../actions/presetActions";
import {STATISTIC_TABLE_FILTERS} from "../../../../utils/constants";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {presetsAccessType} from "../../../../utils/presetsUtils";
import {isAdmin} from "../../../../utils/authUtils";
import {IconButton} from "@material-ui/core";
import {getIsoLocalString} from "../../../../utils/dateUtils";

class TableFilterPresets extends Component {

    savePreset = (value) => {
        let preset = {};
        preset.label = value.inputValue;
        preset.defaultPreset = value.checked;
        preset.type = STATISTIC_TABLE_FILTERS;
        preset.accessType = presetsAccessType();
        const pageFilters = this.props.pageState.filters;
        preset.data = {
            groups: pageFilters.groups ? pageFilters.groups : [],
            countries: pageFilters.countries ? pageFilters.countries : [],
            operators: pageFilters.operators ? pageFilters.operators : [],
            offers: pageFilters.offers ? pageFilters.offers : [],
            period: pageFilters.period,
            fromDate: !pageFilters.period && pageFilters.fromDate && getIsoLocalString(pageFilters.fromDate),
            toDate: !pageFilters.period && pageFilters.toDate && getIsoLocalString(pageFilters.toDate),
        };
        if (isAdmin()) {
            preset.data.partners = pageFilters.partners;
            preset.data.serviceGroups = pageFilters.serviceGroups;
            preset.data.ltvServiceType = pageFilters.ltvServiceType;
            preset.data.buyoutRate = pageFilters.buyoutRate;
            preset.data.financeFee = pageFilters.financeFee;
            preset.data.payoutType = pageFilters.payoutType;
        }
        this.props.saveFiltersPreset(preset);
    };

    render() {
        let {pageState, dropdownValues, inputStyle} = this.props;
        const selectedPreset = pageState && pageState.filtersPreset;

        inputStyle = {...inputStyle, input: {paddingRight: 44}};

        const endButtons =
            <div className="row presets-filter-end-btns">
                <ActionPopover
                    button={
                        <IconButton disableFocusRipple size="small" variant="contained">
                            <SaveOutlined fontSize="small"/>
                        </IconButton>
                    }
                    tooltip='Сохранить текущую конфигурацию фильтров'
                    popoverLocation='left'
                    label='Сохранить текущую конфигурацию фильтров?'
                    hasInput
                    inputLabel='Название пресета'
                    inputDefaultValue={selectedPreset ? selectedPreset.label : ""}
                    hasCheckbox
                    checkboxLabel='Дефолтный пресет'
                    isChecked={selectedPreset ? selectedPreset.defaultPreset : false}
                    handleSubmit={this.savePreset}/>

                <ActionPopover
                    button={
                        <IconButton disableFocusRipple size="small" variant="contained">
                                 <DeleteOutlined fontSize="small"/>
                        </IconButton>
                    }
                    tooltip='Удалить выбранный пресет'
                    popoverLocation='left'
                    label='Удалить выбранный пресет?'
                    handleSubmit={this.props.deletePreset}/>
            </div>;

        return (
            <div className="last-filter">
                <MaterialUISelect
                    styles={inputStyle}
                    dropdownData={dropdownValues}
                    dataField={"id"}
                    displayedField={"label"}
                    selectedValue={selectedPreset}
                    label={'Пресеты фильтров'}
                    placeholder={'-'}
                    onSelectCallbackFunction={this.props.selectPreset}
                    isSearchable={false}
                    isClearable={true}
                    onClear={this.props.clearAllFilters}
                />
                {endButtons}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        dropdownValues: state.preset.statisticFiltersPresets,
    }
}

export default connect(mapStateToProps, {deleteSelectedPreset})(TableFilterPresets);
