import React from "react";
import {
    LAST_2_DAYS,
    LAST_30_DAYS,
    LAST_7_DAYS,
    TODAY,
} from "../../../../utils/constants";
import PeriodButtonsGroupFilter from "../../../common/buttons/PeriodButtonsGroupFilter";

export default function DashboardPeriodsFilter({selectedPeriod, selectPeriod, className}) {

    return (
        <div className={className} id="blockInputPeriod">
            <PeriodButtonsGroupFilter
                buttons={[
                    {isSelected: selectedPeriod === TODAY, title: "Сегодня", iconClassName: "date-icon icon-day", onClick: () => selectPeriod(TODAY)},
                    {isSelected: selectedPeriod === LAST_2_DAYS, title: "2 дня", iconClassName: "date-icon icon-2", onClick: () => selectPeriod(LAST_2_DAYS)},
                    {isSelected: selectedPeriod === LAST_7_DAYS, title: "7 дней", iconClassName: "date-icon icon-7", onClick: () => selectPeriod(LAST_7_DAYS)},
                    {isSelected: selectedPeriod === LAST_30_DAYS, title: "30 дней", iconClassName: "date-icon icon-30", onClick: () => selectPeriod(LAST_30_DAYS)},
                ]}
            />
        </div>
    )
}