import {
    DASHBOARD_CHART_DATA_LOADING,
    DASHBOARD_CHART_DATA_LOADED,
    DASHBOARD_DYNAMIC_LOADING,
    DASHBOARD_DYNAMIC_LOADED
} from "../../actions/actionTypes"

const initialState = {
    chartData: null,
    loading: false,
    dashboardDynamic: [],
    dynamicLoading: false,
};

export default function getDashboardChartData(state = initialState, action = {}) {
    switch (action.type) {
        case DASHBOARD_CHART_DATA_LOADED:
            return {
                ...state,
                chartData: action.payload,
                loading: false};
        case DASHBOARD_CHART_DATA_LOADING:
            return {
                ...state,
                loading: true
            };
        case DASHBOARD_DYNAMIC_LOADING:
            return {
                ...state,
                dynamicLoading: true
            };
        case DASHBOARD_DYNAMIC_LOADED:
            return {
                ...state,
                dashboardDynamic: action.payload,
                dynamicLoading: false,
                mainSwitcherChecked: true
            };
        default:
            return state;

    }
}