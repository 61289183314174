import React from "react";
import {URL_TOOLS} from "../../../properties";
import {isAdminAsPartner} from "../../../utils/authUtils";
import {pathParameter} from "../../../utils/urlUtils";
import {useHistory} from 'react-router-dom';


export default function IpRangesButton() {
    const history = useHistory();

    const handleClick = () => {
        const redirectUrl = URL_TOOLS + "?type=iprange" + (isAdminAsPartner() ? "&partnerId=" + pathParameter("partnerId") : "");
        history.push(redirectUrl);
    };

    return (
        <div className="ip-range-btn" onClick={handleClick} title="Диапазон IP">
            {/*<i className="ti ti-world ip-range-icon"/>*/}
            <svg width="30" height="30" viewBox="0 0 527 681" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M517 263.5C517 297.318 501.532 339.787 477.02 385.394C452.667 430.705 420.077 477.875 387.305 520.75C354.562 563.588 321.794 601.935 297.2 629.584C284.908 643.403 274.669 654.536 267.511 662.207C266.041 663.782 264.701 665.211 263.5 666.488C262.299 665.211 260.959 663.782 259.489 662.207C252.331 654.536 242.092 643.403 229.8 629.584C205.206 601.935 172.438 563.588 139.695 520.75C106.923 477.875 74.3332 430.705 49.9802 385.394C25.4677 339.787 10 297.318 10 263.5C10 123.496 123.496 10 263.5 10C403.504 10 517 123.496 517 263.5Z"
                    stroke="black" strokeWidth="20"/>
                <path
                    d="M217.338 170.273V339H181.665V170.273H217.338ZM246.688 339V170.273H313.256C326.054 170.273 336.956 172.717 345.964 177.605C354.971 182.438 361.837 189.167 366.56 197.79C371.339 206.358 373.728 216.244 373.728 227.449C373.728 238.653 371.311 248.54 366.478 257.108C361.645 265.676 354.642 272.349 345.469 277.128C336.352 281.906 325.312 284.295 312.35 284.295H269.921V255.707H306.583C313.449 255.707 319.106 254.527 323.555 252.165C328.058 249.748 331.409 246.425 333.606 242.196C335.858 237.912 336.984 232.996 336.984 227.449C336.984 221.847 335.858 216.958 333.606 212.784C331.409 208.555 328.058 205.287 323.555 202.98C319.051 200.618 313.339 199.438 306.418 199.438H282.362V339H246.688Z"
                    fill="black"/>
                <circle cx="263.5" cy="253.5" r="185.5" stroke="black" strokeWidth="30"/>
            </svg>
        </div>

    )
}
