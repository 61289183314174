import React from "react";

export default function TableCheckBox({id="cc1", checked=false, onChange, name="che"}) {

    return (
        <>
            <input className="custom-checkbox" type="checkbox" id={id} name={name} checked={checked} onChange={onChange}/>
            <label htmlFor={id}/>
        </>
    )
}