import * as React from 'react';
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ReportProblem from '@material-ui/icons/ReportProblem';



export default function LinearWithValueLabel({value, label, color, variant, error}) {

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ paddingRight: "10px", minWidth: "65px" }}>
                    <Typography variant="body2" color="text.secondary">{label}</Typography>
                </Box>
                <Box sx={{ width: '45%', mr: 1 }}>
                    <LinearProgress value={value} color={color} variant={variant} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        value,
                    )}%`}</Typography>
                </Box>
                {error &&
                <Box>
                    <div title={error}>
                        <ReportProblem color="error" fontSize="small"/>
                    </div>
                </Box>}
            </Box>
        </Box>
    );
}