import React, {useEffect, useState} from "react";
import {TextField} from "@material-ui/core";
import MaterialUiClearIcon from "../../../common/MaterialUiClearIcon";
import {columnsFormatter, sumTableData} from "../../../../utils/statisticUtils";
import {FINANCE_FEE, FINANCE_FEE_AMOUNT, INCOME} from "../../../../utils/constants";

export default function LtvFinanceFeeFilter({pageState, handleChangeFilterData, textFieldStyle}) {

    const [financeFee, setFinanceFee] = useState("");
    const [timeoutState, setTimeoutState] = useState({timeout: 0});

    useEffect(() => {
        setFinanceFee(pageState.filters.financeFee);
    }, [pageState]);

    const handleTypingFinanceFee = (value) => {
        if (timeoutState.timeout) {
            clearTimeout(timeoutState.timeout);
        }
        value = value.replace(/[\sa-zA-Z,;%]/g, "");
        if (value.length && !value.endsWith("%")) value = value + "%";
        setFinanceFee(value);
        setTimeoutState({
            timeout: setTimeout(() => {
                handleChangeFilterData( "financeFee", value);
            }, 400)
        });
    };

    const getCurrentFinanceFee = () => {
        return columnsFormatter[FINANCE_FEE](sumTableData(pageState.filteredTableData,FINANCE_FEE_AMOUNT) / sumTableData(pageState.filteredTableData, INCOME) * 100);
    };
    const clearFinanceFee = () => {
        handleTypingFinanceFee("");
    };


    return (
        <TextField label="Финкомиссия"
                   style={textFieldStyle}
                   InputLabelProps={{shrink: true, focused: false, style: {margin: "11px"}}}
                   onChange={(e) => handleTypingFinanceFee (e.target.value)}
                   placeholder={getCurrentFinanceFee()}
                   value={financeFee}
                   InputProps={{
                       endAdornment:
                           <div className="material-ui-indicator-container">
                               <span className=" css-1okebmr-indicatorSeparator"/>
                               <div aria-hidden="true"
                                    className=" css-tlfecz-indicatorContainer" style={{cursor: "pointer", opacity: "0.2"}}
                                    onClick={clearFinanceFee}>
                                   <MaterialUiClearIcon/>
                               </div>
                           </div>,
                       style: {justifyContent: "end"}
                   }}/>
    );

}