import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Avatar, Icon, InputAdornment, MenuItem, NoSsr, Paper, Typography, TextField} from '@material-ui/core';
import {useDispatch} from "react-redux";
import {List} from "react-virtualized";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
        minWidth: props => props.root && props.root.minWidth ? props.root.minWidth : 220,
        padding: props => props.root && props.root.padding ? props.root.padding : 0,
        margin: 0,
        '& .MuiInput-underline:before': {
            borderBottom: props => props.root && props.root.underlineBorderBottom
        },
        '& .MuiInput-underline:after':  {
            borderBottom: props => props.root && props.root.underlineBorderBottom
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: props => props.root && props.root.underlineBorderBottom
        },
    },
    input: {
        display: 'flex',
        padding: 0,
        margin: 0,
        height:  props => props.input && props.input.height ? props.input.height :'auto',
        minHeight: props => props.input && props.input.minHeight,
        paddingRight: props => props.input && props.input.paddingRight ? props.input.paddingRight : 0,
        backgroundColor: props => props.input && props.input.backgroundColor,
        border: props => props.input && props.input.border,
        borderRadius: props => props.input && props.input.borderRadius,
        boxShadow: props => props.input && props.input.boxShadow,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
        fontSize: 14
    },
    placeholder: {
        position: 'absolute',
        left: "5px !important",
        bottom: 6,
        fontSize: "12px !important",
        color: 'rgba(58,58,58,0.43) !important'
    },
    paper: {
        position: props => props.paper?.position ? props.paper?.position : 'absolute',
        zIndex: 10,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: props => props.paper && props.paper.maxHeight,
    },
    divider: {
        height: theme.spacing(2),
    },
    singleValue: {
        fontSize: props => props.input && props.input.fontSize ? props.input.fontSize : '14px !important',
        padding: props => props.input && props.input.padding,
        color: 'rgba(58,58,58,.87)'
    }
}));

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}>Пусто</Typography>
    );
}

NoOptionsMessage.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * Props to be passed on to the wrapper.
     */
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function inputComponent({inputRef, ...props}) {
    return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
};

function Control(props) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: {classes, TextFieldProps, EndAdornmentProps}
    } = props;
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                endAdornment: EndAdornmentProps.endAdornment ? (<InputAdornment position="end">{EndAdornmentProps.endAdornment}</InputAdornment>) : null,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
                readOnly: true
            }}
            {...TextFieldProps}
        />
    );
}

Control.propTypes = {
    /**
     * Children to render.
     */
    children: PropTypes.node,
    /**
     * The mouse down event and the innerRef to pass down to the controller element.
     */
    innerProps: PropTypes.shape({
        onMouseDown: PropTypes.func.isRequired,
    }).isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]).isRequired,
    selectProps: PropTypes.object.isRequired,
};

function Option(props) {
    const icon = props.data.icon ? <span style={{margin: 5}}>{props.data.icon}</span> : null;
    const img = props.data.img
        ?
        <Icon style={{width: '20px', height: 'auto', margin: 5}}>
            <Avatar style={{width: '20px', height: 'auto'}} src={props.data.img}/>
        </Icon>
        : null;
    const hasBorderBottom = props.data && props.data.hasBorderBottom;
    return (
        <MenuItem
            ref={props.innerRef}
            selected={false}
            component="div"
            style={{
                fontWeight: props.isSelected ? 700 : 400,
                fontSize: 14,
                minHeight: '20px',
                height: '30px',
                padding: hasBorderBottom ? 0 : 5,
                left: hasBorderBottom && 5,
                marginRight: hasBorderBottom && 12,
                borderBottom: hasBorderBottom && "1px dashed rgb(102, 102, 102)",
            }}
            {...props.innerProps}
        >
            {icon}{img}{props.children}
        </MenuItem>
    );
}

Option.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.shape({
        id: PropTypes.string.isRequired,
        key: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        tabIndex: PropTypes.number.isRequired,
    }).isRequired,
    /**
     * Inner ref to DOM Node
     */
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
    /**
     * Whether the option is focused.
     */
    isFocused: PropTypes.bool.isRequired,
    /**
     * Whether the option is selected.
     */
    isSelected: PropTypes.bool.isRequired,
};

function Placeholder(props) {
    const {selectProps, innerProps = {}, children} = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

Placeholder.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
    const icon = props.data.icon ? <span style={{margin: 5}}>{props.data.icon}</span> : null;
    const img = props.data.img
        ?
        <Icon style={{width: '20px', height: 'auto', margin: 5}}>
            <Avatar style={{width: '20px', height: 'auto'}} src={props.data.img}/>
        </Icon>
        : null;
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {img}{icon}{props.children}
        </Typography>
    );
}

SingleValue.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * Props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.any,
    selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

Menu.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.element.isRequired,
    /**
     * Props to be passed to the menu wrapper.
     */
    innerProps: PropTypes.object.isRequired,
    selectProps: PropTypes.object.isRequired,
};
function MenuList(props) {
    const { children, maxHeight } = props;
    const itemSize = 35;
    const totalListHeight = children.length * itemSize;
    const menuHeight = !totalListHeight
        ? 1
        : totalListHeight < maxHeight
            ? totalListHeight
            : maxHeight;
    return (
        <List
            isVisible
            height={menuHeight}
            rowCount={children.length ? children.length : 0}
            rowHeight={itemSize}
            width={2000}
            style={{width: '100%', maxWidth: "100%"}}
            rowRenderer={({ index, style }) => <div key={children[index] && children[index].props && "" + children[index].props.value} style={style}>{children[index]}</div>}
        />
    )
}


const components = {
    Control,
    Menu,
    MenuList,
    SingleValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    ValueContainer,
};

export default function MaterialUISelect(props) {
    const {dataField, displayedField, displayedFields, label, placeholder, reduxActionType, endElements, isLoading,
        onSelectCallbackFunction, isSearchable, isClearable, onClear, isDisabled} = props;
    let {dropdownData, selectedValue} = props;
    const classes = useStyles(props.styles);
    const theme = useTheme();
    const dispatch = useDispatch();
    let maxHeight = props.styles?.paper?.maxHeight ? props.styles.paper.maxHeight : 250;


    function handleChangeSelectedItems(data) {
        let value = null;
        if (data) {
            value = displayedFields ? displayedFields.map(field=>data[field]).join(' | ') : data[displayedField];
        }
        if (!data && isClearable && onClear) {
            onClear();
        } else {
            if (reduxActionType) {
                dispatch({type: reduxActionType, payload: data});
            }
            if (onSelectCallbackFunction) {
                onSelectCallbackFunction(data);
            }
        }
    }

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
                paddingLeft: 0,
                paddingRight: 2,
            cursor: "pointer",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            paddingLeft: 0,
            paddingRight: 2,
            cursor: "pointer",
        }),
    };

    return (
        <div className={classes.root}>
            <NoSsr>
                <Select
                    ignoreAccents={false}
                    classes={classes}
                    styles={selectStyles}
                    // inputId="react-select-single"
                    isLoading={isLoading}
                    EndAdornmentProps={{
                        endAdornment: endElements,
                    }}
                    TextFieldProps={{
                        label: label,
                        InputLabelProps: {
                            htmlFor: 'react-select-single',
                            shrink: true,
                        },

                    }}
                    isSearchable={ isSearchable }
                    isClearable={isClearable}
                    onClear={onClear}
                    placeholder={placeholder}
                    getOptionValue={option => option[dataField]}
                    getOptionLabel={option => displayedFields ? displayedFields.map(field=>option[field]).join(' | ') : option[displayedField]}
                    options={dropdownData}
                    components={components}
                    value={selectedValue ? selectedValue : null}
                    onChange={handleChangeSelectedItems}
                    backspaceRemovesValue={false}
                    isDisabled={isDisabled}
                    maxMenuHeight={maxHeight}
                    isMulti={false}
                />
            </NoSsr>
        </div>
    );
}

MaterialUISelect.propTypes = {
    styles: PropTypes.any,
    dropdownData: PropTypes.array,
    selectedValue: PropTypes.any, /*Выбранное значение*/
    dataField: PropTypes.string, /*Поле объекта из массива data, которое будет исп-ся в качестве значения. Например, id*/
    displayedField: PropTypes.string, /*Поле объекта из массива data, которое будет исп-ся для отображения. Например, name*/
    displayedFields: PropTypes.arrayOf(PropTypes.string), /*Поля объекта из массива data, которые будет исп-ся для отображения. Например, [id, name]*/
    label: PropTypes.string, /*Название инпута*/
    placeholder: PropTypes.string,
    reduxActionType: PropTypes.string,
    endElements: PropTypes.any, /*Элементы в конце инпута. Например, кнопки*/
    isLoading: PropTypes.bool,
    onSelectCallbackFunction: PropTypes.func, /*Метод, который будет вызван при выборе позиции. Принимает на вход выбранный элемент*/
    isSearchable: PropTypes.bool, /*Работает-ли поиск? (На мобильной версии лучше отключить)*/
    isClearable: PropTypes.bool, /*Есть-ли кнопка для очистки (крестик)*/
    onClear: PropTypes.func, /*Метод, вызываемый при нажатии на крестик*/
    isDisabled: PropTypes.bool,
};

MaterialUISelect.defaultProps = {
    dropdownData: [],
    dataField: "value",
    displayedField: "label",
    label: "", /*Название инпута*/
    placeholder: "",
    reduxActionType: null,
    endElements: null, /*Элементы в конце инпута. Например, кнопки*/
    isLoading: false,
    isSearchable: false,
    isClearable: true
};
