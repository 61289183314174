import React, {useEffect, useState} from "react";
import {
    AD_NETWORK,
    AD_NETWORK_CALCULATION,
    AD_NETWORK_ORDER, CALCULATED,
    CALCULATION,
    ORDER,
    REGULAR, TRANSFERRING_ERROR
} from "../../../../../utils/constants";
import MaterialUISelect from "../../../../common/selects/MaterialUISelect";
import {getPaymentDetailsString} from "../../../../../utils/paymentUtils";
import TextField from "@material-ui/core/TextField/TextField";
import {getMoneyString} from "../../../../../utils/formatUtils";
import {isEmptyArr} from "../../../../../utils/arrayUtils";

export default function ManagerPaymentPopoverPaymentDetails({
                                                                editablePayment, partnerPaymentDetailsPresets, partnerAdNetworkPresets,
                                                                onChangeEditablePayment, adNetworks, loadAdNetworkBalance,
                                                                adNetworkBalanceData, usdCurrencyRate
                                                            }) {


    const [selectedPaymentDetails, setSelectedPaymentDetails] = useState(null);
    const [paymentDetailsDropdownData, setPaymentDetailsDropdownData] = useState([]);
    const [adNetworksDropdownData, setAdNetworksDropdownData] = useState([]);
    const [selectedAdNetworkPreset, setSelectedAdNetworkPreset] = useState(null);

    useEffect(() => {
        if (!partnerPaymentDetailsPresets) {
            return;
        }
        const paymentDetailsDropdownData = convertPaymentDetailsPresetsToDropdownData();
        setPaymentDetailsDropdownData(paymentDetailsDropdownData);
        if (editablePayment.id) {
            setSelectedPaymentDetails({
                data: editablePayment.paymentData,
                dataString: getPaymentDetailsString(editablePayment.paymentData)
            })
        }
    }, [partnerPaymentDetailsPresets]);

    useEffect(() => {
        if (!partnerAdNetworkPresets || isEmptyArr(adNetworks)) {
            return;
        }
        const activeAdNetworkIds = adNetworks.map(adNetwork => adNetwork.id);
        const adNetworkDropdown = partnerAdNetworkPresets
            .filter(preset => preset.data && activeAdNetworkIds.includes(preset.data.adNetworkId))
            .map(preset => {
                const adNetwork = adNetworks.find(adNetwork => adNetwork.id === preset.data.adNetworkId);
                return {
                    presetId: preset.id,
                    data: {...preset.data, adNetworkEmail: preset.data.email, adNetworkName: adNetwork.name},
                    adNetworkId: preset.data.adNetworkId,
                    name: adNetwork.name,
                    dataString: adNetwork.name + ": " + preset.data.email
                }
            });


        setAdNetworksDropdownData(adNetworkDropdown);
        if (editablePayment.id) {
            const paymentData = editablePayment.paymentData;
            if (AD_NETWORK !== paymentData.paymentType) {
                return;
            }
            setSelectedAdNetworkPreset({
                data: paymentData,
                adNetworkId: paymentData.adNetworkId,
                dataString: paymentData.adNetworkName + ": " + paymentData.adNetworkEmail
            });
        }

    }, [partnerAdNetworkPresets, adNetworks]);

    const convertPaymentDetailsPresetsToDropdownData = () => {
        return partnerPaymentDetailsPresets.map(item => {
            return {...item, dataString: getPaymentDetailsString(item.data)}
        });
    };


    const handleChangePaymentDetails = (paymentDetails) => {
        setSelectedPaymentDetails(paymentDetails);
        onChangeEditablePayment("paymentData", paymentDetails.data);
    };

    const handleChangePaymentDetailsParam = (paramName, value) => {
        onChangeEditablePayment("paymentData", {...editablePayment.paymentData, [paramName]: value});
    };

    const handleSelectAdNetwork = (adNetworkPreset) => {
        setSelectedAdNetworkPreset(adNetworkPreset);
        loadAdNetworkBalance(adNetworkPreset.adNetworkId);
        onChangeEditablePayment("paymentData", adNetworkPreset.data);
    };

    const inputs = () => {
        if (!editablePayment || !editablePayment.type) {
            return <div/>
        }
        switch (editablePayment.type) {
            case CALCULATION:
            case ORDER:
            case REGULAR:
                return paymentDetailsInputs();
            case AD_NETWORK_CALCULATION:
            case AD_NETWORK_ORDER:
                return adNetworkDetailsInputs();
            default:
                return <div/>
        }
    };

    const paymentDetailsInputs = () => {
        return <>
            <MaterialUISelect label="Реквизиты"
                              dropdownData={paymentDetailsDropdownData && paymentDetailsDropdownData}
                              dataField="id" displayedField="dataString"
                              isSearchable
                              selectedValue={selectedPaymentDetails && selectedPaymentDetails}
                              onSelectCallbackFunction={handleChangePaymentDetails}
                              styles={{
                                  root: {minWidth: "150px !important", padding: "0 0 15px 0"},
                                  input: {height: "30px !important"},
                              }}
            />
        </>;
    };

    const adNetworkDetailsInputs = () => {
        return <>
            <MaterialUISelect label="Рекламная сеть" dropdownData={adNetworksDropdownData} dataField="presetId"
                              displayedField="dataString"
                              selectedValue={selectedAdNetworkPreset} onSelectCallbackFunction={handleSelectAdNetwork}
                              isDisabled={editablePayment.id && !editablePayment?.isLastPayment || ![CALCULATED, TRANSFERRING_ERROR].includes(editablePayment.status)}
                              styles={{root: {padding: "0 0 15px 0 !important"}}}/>
                <TextField label="Баланс РС, руб"
                           value={adNetworkBalanceData && adNetworkBalanceData.balance ? getMoneyString(adNetworkBalanceData.balance, 2) : '0,00'}
                           disabled
                           InputLabelProps={{shrink: true}}
                           style={{width: '50%', paddingBottom: '15px', paddingRight: '10px'}}/>
                <TextField label="Баланс РС, $"
                           value={usdCurrencyRate && adNetworkBalanceData && adNetworkBalanceData.balance ? getMoneyString((adNetworkBalanceData.balance / usdCurrencyRate), 2) : '0,00'}
                           disabled
                           InputLabelProps={{shrink: true}}
                           style={{width: '50%', paddingBottom: '15px'}}/>
        </>;
    };

    return (
        <>
            {inputs()}
        </>
    );
}