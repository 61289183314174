import React, {useEffect, useRef, useState} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {LinkCopyPopup} from "../../../common/linkCopyPopup/LinkCopyPopup";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {replaceDomain} from "../../../../utils/urlUtils";
import OfferLinkInfo from "../OfferLinkInfo";


export default function SmartLinksModal(props) {
    const {isOpened, smartLink, onCloseModal, domains} = props;
    const smartLinkName = smartLink ? smartLink.id + "|" + smartLink.name : '';
    const link = smartLink ? smartLink.link : '';
    const projectName = smartLink ? smartLink.projectName : '';
    const sourceId = smartLink ? smartLink.sourceId : '';
    const [selectedDomain, setSelectedDomain] = useState(null);

    useEffect(() => {
        const defaultDomain = domains && domains.find(domain => domain.defaultDomain);
        setSelectedDomain(defaultDomain);
    }, [domains]);

    function toggle() {
        onCloseModal();
    }

    const childRef = useRef();

    const domainsSelect = domains && domains.length > 0
        &&
        <MaterialUISelect
            styles={{root: {minWidth: 170, padding: 5}}}
            dropdownData={domains}
            displayedFields={['id', 'cname']} dataField='id'
            placeholder="Выбрать домен"
            selectedValue={selectedDomain}
            onSelectCallbackFunction={(domain) => setSelectedDomain(domain)}
            isSearchable={true}/>;

    return (

        <Modal size="lg" isOpen={isOpened} toggle={() => toggle()}>
            <ModalHeader style={{float: "left"}} toggle={() => toggle()}>{smartLinkName}</ModalHeader>
            <ModalBody>
                <p onClick={() => childRef.current.handleClickCopy(replaceDomain(link, selectedDomain && selectedDomain.cname))}>
                <span className="link">
                      <label className="float-left" htmlFor="11">{sourceId + " | " + projectName}</label>
                   <input readOnly={true} value={replaceDomain(link, selectedDomain && selectedDomain.cname)}
                          className="form-control form-control-sm" style={{backgroundColor: "#e9ecef"}}
                          title="Копировать ссылку"/>
                </span>
                </p>
                <OfferLinkInfo/>
            </ModalBody>
            <ModalFooter>
                {domainsSelect}
            </ModalFooter>
            <LinkCopyPopup ref={childRef}/>
        </Modal>
    )
}