import React, {Fragment} from "react";

export default function NewsTitleInput(props) {

    const {title, onChangeTitle, error} = props;
    return (
        <Fragment>
            <label htmlFor="exampleAccount">Название в URL</label>
            <input className="form-control" id="exampleAccount" placeholder="Введите текст" type="text" value={title} onChange={onChangeTitle}/>
            {error && <div style={{fontSize: "12px", color: "red"}}>{error.error}</div>}
        </Fragment>
    )

}