import React from "react";
export default function PartnerTablePartnerTypeRow({columns=[], rowData, toggleRowOpen}) {

    return (
        <tr className={rowData.isOpen ? "grouped_row opened" : "grouped_row"} style={{ cursor: "pointer"}} onClick={() => toggleRowOpen(rowData.partnerType)}>
            <td className={"partners_body_group right_position"}>
                <i className={rowData.isOpen ? "fa fa-chevron-right statistic_table_icon opened" : "fa fa-chevron-right statistic_table_icon"}/>
            </td>
            <td className={"partners_body_group left_position"}/>
            <td className={"partners_body_group left_position"}>{(rowData.partnerType)}</td>
            {columns.map((column, idx) =>
                idx > 2 && <td key={"body_cell_" + rowData.rowId + "_" + (idx)} className={"partners_body_group left_position"}/>)}
        </tr>
    )
}