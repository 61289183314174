import React, {Component} from "react";
import {renderToString} from 'react-dom/server'
import BasePage from "../../BasePage";
import {connect} from "react-redux";
import {getDashboardChartData} from "../../../../actions/dashboardActions";
import {loadOperatorTypes} from "../../../../actions/dictionaryActions";
import {
    getManagerNews,
    saveNewsItem,
    getNewsItemCard,
    clearEditableNewsItem, loadHeaderNews,
} from "../../../../actions/newsActions";
import NewsEditCard from "./NewsEditCard";
import {uploadFile} from "../../../../actions/fileActions";
import {addIfNotExist, addOrRemoveAndReturnNewArray} from "../../../../utils/arrayUtils";
import {convertDataURItoBlob} from "../../../../utils/urlUtils";
import NewsTable from "./NewsTable";
import {searchByString} from "../../../../utils/searchUtils";
import {showWarning} from "../../../../actions/warningActions";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import PageContent from "../../../common/page/PageContent";

const categories = [
    {label: "Общая", value: "COMMON"},
    {label: "Кейс", value: "CASE"},
    {label: "Техническая", value: "TECHNICAL"},
];

class ManagerNewsPage extends Component {

    state = {
        id: null,
        plannedPublicationDate: null,
        category: null,
        iconClassName: "ti-gallery",
        title: "",
        urlName: "",
        html: "",
        initialHtml: "",
        imageIds: [],
        coverImageId: null,
        searchValue: "",
        filteredNewsItems: [],
        fieldErrors: [],
        publicationEventTypes: [],
        hasMoreDashboardNews: true,
        hasChanges: false,
    };

    componentDidMount() {
        this.props.getManagerNews();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {editableNewsItem} = this.props;
        if (editableNewsItem && editableNewsItem !== prevProps.editableNewsItem) {
            this.setState({
                ...this.state,
                id: editableNewsItem.id,
                plannedPublicationDate: editableNewsItem.plannedPublicationDate,
                category: categories.find(category => category.value === editableNewsItem.category),
                iconClassName: editableNewsItem.iconClassName,
                title: editableNewsItem.title,
                urlName: editableNewsItem.urlName,
                html: editableNewsItem.html,
                initialHtml: editableNewsItem.html,
                imageIds: editableNewsItem.imageIds,
                coverImageId: editableNewsItem.coverImageId,
                publicationEventTypes: editableNewsItem.publicationEventTypes,
            })
        }
        if (this.state.searchValue !== prevState.searchValue || this.props.news !== prevProps.news) {
            const newsItems = searchByString(this.props.news, ["title"], this.state.searchValue);
            this.setState({...this.state, filteredNewsItems: newsItems});
        }
        if (this.props.fieldErrors !== prevProps.fieldErrors) {
            this.setState({...this.state, fieldErrors: this.props.fieldErrors});
        }
    }

    changeSearchValue = (value) => {
        this.setState({...this.state, searchValue: value})
    };


    setNewsItemData = (value, fieldName) => {
        const fieldErrors = this.state.fieldErrors.filter(error => error.field !== fieldName);
        this.setState({...this.state, [fieldName]: value, hasChanges: true, fieldErrors: fieldErrors});
    };

    changeTitle = value => {
        const title = value;
        const cyrillicToTranslit = CyrillicToTranslit();
        let urlName = value;
        urlName = cyrillicToTranslit.transform(urlName, "-");
        urlName = urlName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
        const fieldErrors = this.state.fieldErrors.filter(error => error.field !== 'title' && error.field !== 'urlName');
        this.setState({...this.state, title: title, urlName: urlName, hasChanges: true, fieldErrors: fieldErrors});


    };

    changeImage = (targetImgElement, index, action, imageInfo, remainingFilesCount) => {
        switch (action) {
            case "create":
                if (!imageInfo.src.includes("http://") && !imageInfo.src.includes("https://")) {
                    this.uploadFile(imageInfo, targetImgElement);
                }
                if (!targetImgElement.id && imageInfo.src.includes("/api_v2/files/")) {
                    targetImgElement.id = imageInfo.src.replace(/(https?:\/\/)(.+)(\/api_v2\/files\/)(.+)/, "imageId_$4");
                }
                break;
            case "update":
                this.uploadFile(imageInfo, targetImgElement);
                break;
            default:
                break;
        }
    };

    uploadFile = (imageInfo, targetImgElement) => {
        const file = new File([convertDataURItoBlob(imageInfo.src)], imageInfo.name);
        this.props.uploadFile(file, (savedImage) => {
            this.afterUploadImageCallback(savedImage, targetImgElement);
        });
    };

    uploadCoverImage = (file) => {
        this.props.uploadFile(file, (savedImage) => {
            this.selectCover(savedImage.id);
        });
    };


    selectCover = (imageId) => {
        const newCoverUrl = process.env.REACT_APP_API_URL + "/files/" + imageId;
        const prevCoverElement = document.getElementById("coverImage");
        let newCover = "";
        if (prevCoverElement) {
            prevCoverElement.setAttribute("src", newCoverUrl);
        } else {
            newCover = <div className="se-component se-image-container __se__float-center" contentEditable="false">
                <figure style={{margin: "auto"}}><img src={newCoverUrl} alt="" data-rotate=""
                                                      data-proportion="true" data-rotatex="" data-rotatey=""
                                                      data-align="center" data-size="," data-percentage="auto,auto"
                                                      data-origin="," id="coverImage" data-file-name=""
                                                      data-file-size="0" data-index=""/></figure>
            </div>;
            newCover = renderToString(newCover);
        }
        const editorValue = newCover + document.getElementsByClassName("se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable")[0].innerHTML;
        const imageIds = addIfNotExist(this.state.imageIds, imageId);
        this.setState({
            ...this.state,
            coverImageId: imageId,
            imageIds: imageIds,
            html: editorValue,
            initialHtml: editorValue
        })
    };


    afterUploadImageCallback = (savedImage, targetImgElement) => {
        const imageId = savedImage.id;
        const src = process.env.REACT_APP_API_URL + "/files/" + imageId;
        if (targetImgElement) {
            targetImgElement.setAttribute("src", src);
            targetImgElement.setAttribute("id", "imageId_" + imageId);
        }
        this.setState({...this.state, imageIds: addIfNotExist(this.state.imageIds, imageId)})
    };

    checkAndSaveNewsItem = (isPublicationConfirmed) => {
        if (!this.state.hasChanges) {
            return;
        }
        const isNeedToShowWarning = !isPublicationConfirmed && this.state.id && this.props.news.find(item => item.id === this.state.id && item.status === "PUBLISHED");
        if (isNeedToShowWarning) {
            this.props.showWarning("Уверены, что хотите перевести в черновик уже опубликованную новость?", () => this.saveNewsItem(isPublicationConfirmed));
        } else {
            this.saveNewsItem(isPublicationConfirmed);
        }

    };


    saveNewsItem = (isPublicationConfirmed) => {
        this.props.saveNewsItem(this.state, isPublicationConfirmed, () => {
            this.setState({hasMoreDashboardNews: true, hasChanges: false});
            this.props.getManagerNews();
            this.props.headerNews && this.props.headerNews(this.props.headerNews.length, null);
        });
    };

    selectEditableNewsItem = (id) => {
        this.props.getNewsItemCard(id);
    };

    selectPublicationEvent = (eventType) => {
        this.setState({
            ...this.state,
            publicationEventTypes: addOrRemoveAndReturnNewArray(this.state.publicationEventTypes, eventType),
            hasChanges: true,
        })
    };

    clearEditableNewsItem = () => {
        this.props.clearEditableNewsItem();
        this.setState({
            ...this.state,
            id: null,
            plannedPublicationDate: null,
            category: null,
            iconClassName: "ti-gallery",
            title: "",
            urlName: "",
            html: "",
            initialHtml: "",
            imageIds: [],
            coverImageId: null,
            searchValue: "",
            fieldErrors: [],
            publicationEventTypes: [],
            hasMoreDashboardNews: true,
            hasChanges: false,
        })
    };


    renderContent = () => {
        return (
            <PageContent
                displayContentHeader
                displaySearchInput
                onChangeSearchInput={this.changeSearchValue}
                searchInputAmount={this.state.filteredNewsItems?.length}
                beforeSearchInputAmount={this.props.news?.length}
                contentEl={
                    <div className="content">
                        <div className="animated fadeIn">
                            <div className="row">
                                <NewsEditCard editableNewsItem={this.state}
                                              allCategories={categories}
                                              setNewsItemData={this.setNewsItemData}
                                              onChangeTitle={this.changeTitle}
                                              changeImage={this.changeImage}
                                              uploadCoverImage={this.uploadCoverImage}
                                              saveNewsItem={this.checkAndSaveNewsItem}
                                              clearFields={this.clearEditableNewsItem}
                                              selectedPublicationEventTypes={this.state.publicationEventTypes}
                                              onSelectPublicationEvent={this.selectPublicationEvent}
                                />
                                <NewsTable news={this.state.filteredNewsItems}
                                           selectEditableNewsItem={this.selectEditableNewsItem}/>
                            </div>
                        </div>
                    </div>
                }
            />

        )
    };


    render() {
        return (
            <BasePage pageName="Новости" content={this.renderContent()}/>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        news: state.newsReducer.news,
        headerNews: state.newsReducer.headerNews,
        editableNewsItem: state.newsReducer.editableNewsItem,
        fieldErrors: state.error.data && state.error.data.fieldErrors,
    }
};

export default connect(mapStateToProps, {
    getDashboardChartData,
    loadOperatorTypes,
    getManagerNews,
    uploadFile,
    saveNewsItem,
    getNewsItemCard,
    clearEditableNewsItem,
    loadHeaderNews,
    showWarning,
})(ManagerNewsPage);