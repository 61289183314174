import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Select from 'react-select';
import {emphasize, makeStyles, useTheme} from '@material-ui/core/styles';
import {Avatar, InputAdornment, MenuItem, NoSsr, Paper, Chip, Typography, TextField} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import useOutsideClick from "../../../hooks/useOutsideClick";
import {List} from "react-virtualized";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: props => props.root && props.root.flexGrow ? props.root.flexGrow : 0,
        minWidth: props => props.root && props.root.minWidth ? props.root.minWidth : 200,
        width: props => props?.root?.width,
        maxWidth: props => props?.root?.maxWidth,
        padding: props => props.root && props.root.padding ? props.root.padding : 10,
        '& .MuiInput-underline:before': {
            borderBottom: props => props.root && props.root.underlineBorderBottom
        },
        '& .MuiInput-underline:after':  {
            borderBottom: props => props.root && props.root.underlineBorderBottom
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: props => props.root && props.root.underlineBorderBottom
        },
    },
    input: {
        display: 'flex',
        padding: 0,
        height:  props => props.input && props.input.height ? props.input.height :'auto',
        minHeight: props => props.input && props.input.minHeight,
        paddingRight: props => props.input && props.input.paddingRight ? props.input.paddingRight : 0,
        backgroundColor: props => props.input && props.input.backgroundColor,
        border: props => props.input && props.input.border,
        borderRadius: props => props.input && props.input.borderRadius,
        boxShadow: props => props.input && props.input.boxShadow,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
        display: 'flex',
        fontSize: 14,
        maxWidth: props => props.chip && props.chip.maxWidth,
    },
    chipLabel: {
        whiteSpace: props => props.chip && props.chip.maxWidth && 'nowrap !important',
        textOverflow: props => props.chip && props.chip.maxWidth && 'ellipsis',
        overflow: props => props.chip && props.chip.maxWidth && 'hidden',
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
        fontSize: 14
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: props => props.placeholder && props.placeholder.fontSize ? props.placeholder.fontSize : 12,
        padding: props => props.placeholder && props.placeholder.padding ,
    },
    paper: {
        position: props => props.paper?.position ? props.paper?.position : 'absolute',
        zIndex: 100,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: props => props.paper && props.paper.maxHeight,
    },
    divider: {
        height: theme.spacing(2),
    },
}));

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}>Пусто</Typography>
    );
}

NoOptionsMessage.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * Props to be passed on to the wrapper.
     */
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function inputComponent({inputRef, ...props}) {
    return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
};

function Control(props) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: {classes, TextFieldProps, EndAdornmentProps}
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                endAdornment: EndAdornmentProps.endAdornment ? (<InputAdornment position="end">{EndAdornmentProps.endAdornment}</InputAdornment>) : null,
                inputProps: {
                    className: classes.input + ' multiselect',
                    ref: innerRef,

                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}

Control.propTypes = {
    /**
     * Children to render.
     */
    children: PropTypes.node,
    /**
     * The mouse down event and the innerRef to pass down to the controller element.
     */
    innerProps: PropTypes.shape({
        onMouseDown: PropTypes.func.isRequired,
    }).isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]).isRequired,
    selectProps: PropTypes.object.isRequired,
};

function Option(props) {
    delete props.innerProps.onMouseMove;
    delete props.innerProps.onMouseOver;
    return (
        <MenuItem
            ref={props.innerRef}
            selected={false}
            component="div"
            style={{
                fontWeight: props.isSelected ? 700 : 400,
                fontSize: 14,
                minHeight: '20px',
                height: '30px',
                padding: 5
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

Option.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.shape({
        id: PropTypes.string.isRequired,
        key: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        tabIndex: PropTypes.number.isRequired,
    }).isRequired,
    /**
     * Inner ref to DOM Node
     */
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
    /**
     * Whether the option is focused.
     */
    isFocused: PropTypes.bool.isRequired,
    /**
     * Whether the option is selected.
     */
    isSelected: PropTypes.bool.isRequired,
};

function Placeholder(props) {
    const {selectProps, innerProps = {}, children} = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

Placeholder.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
    const img = props.data.img;
    const icon = props.data.icon;
    const number = props.data.number ? props.data.number : null;
    const label = props.selectProps.classes.chipLabel
        ? <div className={props.selectProps.classes.chipLabel}>{props.children}</div>
        : props.children;
    return (
        <Chip
            icon={icon}
            avatar={img ? <Avatar src={img}/> : number ? <Avatar><b>{number}</b></Avatar> : null}
            size={'medium'}
            tabIndex={-1}
            label={label}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            variant="outlined"
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

MultiValue.propTypes = {
    children: PropTypes.node,
    isFocused: PropTypes.bool.isRequired,
    removeProps: PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        onMouseDown: PropTypes.func.isRequired,
        onTouchEnd: PropTypes.func.isRequired,
    }).isRequired,
    selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

Menu.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.element.isRequired,
    /**
     * Props to be passed to the menu wrapper.
     */
    innerProps: PropTypes.object.isRequired,
    selectProps: PropTypes.object.isRequired,
};

function MenuList(props) {
    const { children, maxHeight } = props;
    const itemSize = 35;
    const totalListHeight = children.length * itemSize;
    const menuHeight = !totalListHeight
        ? 1
        : totalListHeight < maxHeight
            ? totalListHeight
            : maxHeight;
    return (
         <List
            height={menuHeight}
            rowCount={children.length ? children.length : 0}
            rowHeight={itemSize}
            width={2000}
            style={{width: '100%', maxWidth: "100%"}}
            rowRenderer={({ index, style }) => <div key={children[index] && children[index].props && "" + children[index].props.value} style={style}>{children[index]}</div>}
        />
    )
}

const components = {
    Control,
    Menu,
    MenuList,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    ValueContainer,
};

export default function MaterialUIMultiSelect(props) {
    const {dropdownData, values, dataField, displayedField, displayedFields, label, placeholder, needShowNumber,
        endElements, isLoading, isSearchable,  onSelect, isDisabled} = props;
    const classes = useStyles(props.styles);
    const theme = useTheme();
    const ref = useRef();
    let dropdownValues = dropdownData;
    const [isOpened, setOpened] = useState(false);
    const [inputValue, setInputValue] = useState("");
    let maxHeight = props.styles?.paper?.maxHeight ? props.styles.paper.maxHeight : 250;


    const openMenu = () => {
        setOpened(true);
    };

    const closeMenu = () => {
        setOpened(false);
        setInputValue("");
    };

    const handleChangeSelectedItems = (data) => {
        if (data && data.length === 0) {
            setInputValue("");
        }
        let values = [];
        data = data === null ? [] : data;
        data.forEach((element, idx) => {
            if (needShowNumber) {
                element.number = idx + 1;
            }
            values.push(element);
        });
        if (onSelect) {
            onSelect(values);
        }
    };

    const handleInputChange = (value, e) => {
        if (e.action === 'input-change') {
            setInputValue(value);
        }
    };

    useOutsideClick(ref, closeMenu);

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            paddingLeft: 0,
            paddingRight: 2,
            cursor: "pointer",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            paddingLeft: 0,
            paddingRight: 2,
            cursor: "pointer",
        })
    };

    return (
        <div className={classes.root} ref={ref}>
            <NoSsr>
                <Select
                    ignoreAccents={false}
                    classes={classes}
                    styles={selectStyles}
                    isLoading={isLoading}
                    EndAdornmentProps={{
                        endAdornment: endElements,
                    }}
                    TextFieldProps={{
                        label: label,
                        InputLabelProps: {
                            htmlFor: 'react-select-multiple',
                            shrink: true,
                        },
                    }}
                    onMenuOpen={openMenu}
                    onMenuClose={closeMenu}
                    menuIsOpen={isOpened}
                    isSearchable={ isSearchable }
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    placeholder={placeholder}
                    getOptionValue={option => option[dataField]}
                    getOptionLabel={option => displayedFields ? displayedFields.map(field=>option[field]).join(' | ') : option[displayedField]}
                    options={dropdownValues}
                    components={components}
                    value={values}
                    onChange={handleChangeSelectedItems}
                    isMulti={true}
                    maxMenuHeight={maxHeight}
                    onInputChange={handleInputChange}
                    inputValue={inputValue}
                    isDisabled={isDisabled}
                />
            </NoSsr>
        </div>
    );
}

MaterialUIMultiSelect.propTypes = {
    styles: PropTypes.any,
    dropdownData: PropTypes.array,
    values: PropTypes.array, /*Выбранные значения*/
    dataField: PropTypes.string, /*Поле объекта из массива data, которое будет исп-ся в качестве значения. Например, id*/
    displayedField: PropTypes.string, /*Поле объекта из массива data, которое будет исп-ся для отображения. Например, name*/
    displayedFields: PropTypes.arrayOf(PropTypes.string), /*Поля объекта из массива data, которые будет исп-ся для отображения. Например, [id, name]*/
    label: PropTypes.string, /*Название инпута*/
    placeholder: PropTypes.string,
    needShowNumber: PropTypes.bool,  /*Нужно-ли показывать порядковый номер выбранного объекта*/
    endElements: PropTypes.any,
    isLoading: PropTypes.bool,
    isSearchable: PropTypes.bool, /*Работает-ли поиск? (На мобильной версии лучше отключить)*/
    onSelect: PropTypes.func
};

MaterialUIMultiSelect.defaultProps = {
    dropdownData: [],
    dataField: "value",
    displayedField: "label",
    label: "", /*Название инпута*/
    placeholder: "",
    needShowNumber: false,
    endElements: null, /*Элементы в конце инпута. Например, кнопки*/
    isLoading: false,
    isSearchable: false
};


