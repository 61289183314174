import React, {Component} from "react";
import {connect} from "react-redux";
import BasePage from "../../BasePage";
import PartnerPaymentsFilters from "./filters/PartnerPaymentsFilters";
import PartnerPaymentsTable from "./table/PartnerPaymentsTable";
import {
    AD_NETWORK,
    AD_NETWORK_ORDER,
    DESC,
    LAST_14_DAYS,
} from "../../../../utils/constants";
import {fromDate, toDate} from "../../../../utils/dateUtils";
import {
    cancelPayment,
    clearPartnerPaymentsData,
    loadPartnerPayments,
    createPayment, updatePayment
} from "../../../../actions/paymentsActions";
import PartnerPaymentsContent from "./PartnerPaymentsContent";
import LineChart from "../../../common/charts/LineChart";
import {loadAvailablePartnerBalance, loadPartnerBalance} from "../../../../actions/partnerBalanceActions";
import {toDouble} from "../../../../utils/formatUtils";
import {getPartnerId} from "../../../../utils/authUtils";
import PageContent from "../../../common/page/PageContent";
import {isEquals} from "../../../../utils/objectUtils";
import PaymentOrderCard from "./PaymentOrderCard";
import {getPartnerPaymentTransferTypes} from "../../../../utils/paymentUtils";
import {loadUserPresets} from "../../../../actions/presetActions";
import {searchByString} from "../../../../utils/searchUtils";


class PartnerPaymentsPage extends Component {

    state = {
        filters: {
            period: LAST_14_DAYS,
            fromDate: fromDate(LAST_14_DAYS),
            toDate: toDate(LAST_14_DAYS),
            hasChanges: true,
        },
        sortableColumn: "period",
        sortableDirection: DESC,
        showTable: false,
        showChart: false,
        paymentTransferType: getPartnerPaymentTransferTypes()[0],
        orderedPaymentValue: "",
        paymentData: null,
        xlsxReportData:null,
        headerSearchValue: "",
        searchItemsAmount: 0,
        adNetworkPaymentData: {
            data: null,
            dataString: null,
            adNetworkName: null,
        },
    };

    componentDidMount() {
        this.props.loadAvailablePartnerBalance();
        this.buildTable();
    }

    filterPaymentsTableData = () => {
        const filteredPayments = searchByString(this.props.payments, ["period", "payDate", "paymentDataValue", "paymentStatus", "paymentTypeValue"], this.state.headerSearchValue);
        if (filteredPayments && filteredPayments?.length !== this.state.searchItemsAmount) {
            this.setState({searchItemsAmount: filteredPayments.length});
        }
        return filteredPayments
    };

    sort = (column, direction) => {
        this.setState({
            sortableColumn: column,
            sortableDirection: direction
        });
    };

    selectPeriod = (period) => {
        const filters = this.state.filters;
        filters.period = period;
        filters.fromDate = fromDate(period);
        filters.toDate = toDate(period);
        filters.hasChanges = true;
        this.setState({filters: filters})
    };

    selectFromDate = (fromDate) => {
        const filters = this.state.filters;
        filters.fromDate = fromDate;
        filters.period = null;
        filters.hasChanges = true;
        this.setState({filters: filters})
    };

    selectToDate = (toDate) => {
        const filters = this.state.filters;
        filters.toDate = toDate;
        filters.period = null;
        filters.hasChanges = true;
        this.setState({filters: filters})
    };

    changeSearchValue = (value) => {
        this.setState({headerSearchValue: value});
    };

    getXlsxData = (data) => {
        if (!data || isEquals(data, this.state.xlsxReportData)) {
            return;
        }
        this.setState({xlsxReportData: data});
    };

    buildTable = () => {
        const {fromDate, toDate, hasChanges} = this.state.filters;
        if (hasChanges) {
            this.props.loadPartnerPayments(fromDate, toDate);
        }
        const filters = this.state.filters;
        filters.hasChanges = false;
        this.setState({filters: filters, showTable: true, showChart: false})
    };

    buildChart = () => {
        const {fromDate, toDate, hasChanges} = this.state.filters;
        if (hasChanges) {
            this.props.loadPartnerPayments(fromDate, toDate);
        }
        const filters = this.state.filters;
        filters.hasChanges = false;
        this.setState({filters: filters, showTable: false, showChart: true})
    };

    getTableData = () => {
        const {sortableColumn, sortableDirection} = this.state;
        const directionNumb = sortableDirection === DESC ? -1 : 1;
        let payments = this.filterPaymentsTableData();
        if (!payments || payments.length === 0) {
            return null;
        }
        return payments && payments.sort((a, b) => {
            if (a[sortableColumn] === b[sortableColumn]) {
                return 0;
            }
            return a[sortableColumn] > b[sortableColumn] ? directionNumb : directionNumb * -1;
        });
    };

    getChartData = () => {
        let payments = this.filterPaymentsTableData();
        if (!payments || payments.length === 0) {
            return null;
        }
        const period = "period";
        payments = payments && payments.sort((a, b) => {
            if (a[period] === b[period]) {
                return 0;
            }
            return a[period] > b[period] ? 1 : -1;
        });
        const chartData = {labels: [], chartData: [{lineName: "incomePaidInRub", lineData: []}]};
        payments.forEach(payment => {
            chartData.labels.push(payment.date);
            chartData.chartData[0].lineData.push(payment.incomePaidInRub);
        });
        return chartData;
    };

    renderTable = () => {
        const tableData = this.getTableData();
        return tableData && tableData.length > 0 &&
            <PartnerPaymentsTable tableData={tableData} sort={this.sort} sortableColumn={this.state.sortableColumn}
                                  sortableDirection={this.state.sortableDirection}
                                  availablePartnerBalance={this.props.availablePartnerBalanceData}
                                  updatePayment={this.updatePayment}
                                  cancelOrderedPayment={this.cancelOrderedPayment}
                                  getXlsxReportData={this.getXlsxData}
            />
    };

    renderChart = () => {
        const chartData = this.getChartData();
        return chartData &&
            <LineChart labels={chartData.labels} displayedLines={["incomePaidInRub"]} chartData={chartData.chartData}/>
    };

    clearData = () => {
        this.setState({
            filters: {
                period: LAST_14_DAYS,
                fromDate: fromDate(LAST_14_DAYS),
                toDate: toDate(LAST_14_DAYS),
                hasChanges: true,
            },
            sortableColumn: "period",
            sortableDirection: DESC,
            showTable: false,
            showChart: false
        });
        this.props.clearPartnerPaymentsData();
    };

    handleChangeValue = (fieldName, value) => {
        this.setState({[fieldName]: value})
    };

    createPayment = () => {
        const {paymentTransferType, orderedPaymentValue, adNetworkPaymentData} = this.state;
        const paymentData = paymentTransferType.value === AD_NETWORK_ORDER
            ? {
                paymentType: AD_NETWORK,
                adNetworkId: adNetworkPaymentData.data.adNetworkId,
                adNetworkName: adNetworkPaymentData.adNetworkName,
                adNetworkEmail: adNetworkPaymentData.data.email,
            }
            : this.state.paymentData.data;
        const body = {
            income: orderedPaymentValue,
            partnerId: getPartnerId(),
            type: paymentTransferType.value,
            paymentData: paymentData,
        };

        this.props.createPayment(body, () => this.afterOrderPaymentCallback(body));
    };

    updatePayment = (paymentId, value) => {
        value = toDouble(value);
        this.props.updatePayment({paymentId: paymentId, income: value}, this.afterOrderPaymentCallback);
    };

    cancelOrderedPayment = (payment) => {
        if (!payment) {
            return;
        }
        this.props.cancelPayment({paymentId: payment.id}, this.afterOrderPaymentCallback);
    };

    afterOrderPaymentCallback = (body) => {
        const {fromDate, toDate} = this.state.filters;
        this.props.loadPartnerPayments(fromDate, toDate);
        this.props.loadAvailablePartnerBalance();
        this.props.loadPartnerBalance();
        this.setState({
            orderedPaymentValue: ""
        })
    };


    render() {
        return <BasePage pageName="Финансы"
                         content={
                             <PageContent
                                 displayContentHeader
                                 displayFiltersBtn
                                 displayXlsxBtn
                                 xlsxData = {this.state.xlsxReportData}
                                 displaySearchInput
                                 onChangeSearchInput = {this.changeSearchValue}
                                 searchInputAmount = {this.state.searchItemsAmount}
                                 beforeSearchInputAmount = {this.props.payments?.length}
                                 headerAdditionalEl={
                                     this.props.availablePartnerBalanceData?.canOrderPayment
                                     && <PaymentOrderCard availablePartnerBalanceData={this.props.availablePartnerBalanceData}
                                                       handleChangeValue={this.handleChangeValue}
                                                       orderedPaymentValue={this.state.orderedPaymentValue}
                                                       createPayment={this.createPayment}
                                                       paymentTransferTypes={getPartnerPaymentTransferTypes()}
                                                       pageState={this.state}
                                     />
                                 }
                                 filtersEl={
                                     <PartnerPaymentsFilters filters={this.state.filters}
                                                             selectPeriod={this.selectPeriod}
                                                             selectFromDate={this.selectFromDate}
                                                             selectToDate={this.selectToDate}
                                                             buildTable={this.buildTable}
                                                             buildChart={this.buildChart}
                                                             showTable={this.state.showTable}
                                                             showChart={this.state.showChart}
                                                             clearData={this.clearData}
                                                             availablePartnerBalanceData={this.props.availablePartnerBalanceData}
                                                             paymentTransferTypes={getPartnerPaymentTransferTypes()}
                                                             pageState={this.state}
                                                             handleChangeValue={this.handleChangeValue}
                                                             orderedPaymentValue={this.state.orderedPaymentValue}
                                                             createPayment={this.createPayment}
                                     />
                                 }
                             contentEl={
                                 <PartnerPaymentsContent isLoading={this.props.isLoading} showTable={this.state.showTable}
                                                         table={this.renderTable()} chart={this.renderChart()}
                                                         showChart={this.state.showChart}
                                 />
                             }
                             />

                         }
        >
        </BasePage>
    }

}

function mapStateToProps(state) {
    return {
        isLoading: state.paymentsReducer.loading,
        payments: state.paymentsReducer.payments,
        availablePartnerBalanceData: state.partnerBalanceReducer.availablePartnerBalanceData,
    }
}

export default connect(mapStateToProps, {
    loadPartnerPayments, clearPartnerPaymentsData, loadAvailablePartnerBalance, loadPartnerBalance,
    createPayment, cancelPayment, updatePayment, loadUserPresets
})(PartnerPaymentsPage);