export const filterByArray = (targetCollection, targetField, array) => {
    if (!targetCollection || !targetField || !array || array.length === 0) {
        return targetCollection;
    }
    return targetCollection.filter(object => array.some(item => object[targetField].includes(item)));
};

export const filterByArrayOfObjects = (thisCollection, thisField, otherCollection, otherField) => {
    if (!thisCollection || thisCollection.length === 0 || !thisField || !otherCollection || otherCollection.length === 0 || !otherField) {
        return thisCollection;
    }
    const searchedItems = otherCollection.map(item => item[otherField]);
    return thisCollection.filter(object => searchedItems.some(item => object[thisField].includes(item)));
};