import React from "react";

import settingsImg from "../../../resources/images/setup.png";

export default function TableSettingsButton({ className, onClick }) {

    return (
        <div className={`setup-btn ${className}`} onClick={onClick} title="Настройки таблицы">
            <img src={settingsImg} alt=""/>
        </div>
    )
}
