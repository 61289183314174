import React, {Component} from 'react';
import BasePage from "../BasePage";
import PartnerPageFilters from "./filters/PartnersPageFilters";
import {connect} from "react-redux";
import PartnersTable from "./table/PartnersTable";
import {ASC, DESC} from "../../../utils/constants";
import {
    addOrRemoveFromFavorites,
    approveRegistration, changePartnerLevel,
    loadPartners,
    rejectRegistration, toggleCanOrderPayment
} from "../../../actions/managerPartnersActions";
import {addOrRemove} from "../../../utils/arrayUtils";
import {searchByString} from "../../../utils/searchUtils";
import {partnerLevelsDispatcher} from "../../../utils/partnerLevelUtils";
import {resendConfirmationEmail} from "../../../actions/authActions";
import PageContent from "../../common/page/PageContent";

const NUMBER_OF_ROWS_TO_RENDER = 100;

class PartnersPage extends Component {

    state = {
        tableData: [],
        closedRowNames: [],
        isOnlyActive: true,
        isOnlyFavorite: false,
        isOnlyCanOrderPayment: false,
        registerStatuses: [],
        sortingColumn: "registrationDate",
        sortingDirection: DESC,
        searchString: "",
        beforeSearchInputAmount: 0,
        timeout: 0,
        filteredPartners: [],
        partnersForRendering: [],
        sortingTrigger: 0,
        filtersTrigger: 0,
        renderTrigger: 0,
        groupIdx: 0,
        partnerIdx: 0,
        xlsxReportData: null,
    };

    componentDidMount() {
        this.props.loadPartners(() => this.filterPartners());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {sortingTrigger, filtersTrigger, renderTrigger, partnersForRendering, groupIdx, partnerIdx} = this.state;
        if (filtersTrigger !== prevState.filtersTrigger) {
            this.filterPartners();
        }
        if (sortingTrigger !== prevState.sortingTrigger) {
            this.sortPartners();
        }
        if (renderTrigger !== prevState.renderTrigger) {
            this.getRenderedPartners(partnersForRendering, groupIdx, partnerIdx, NUMBER_OF_ROWS_TO_RENDER);
        }
    }

    filterPartners = () => {
        const partners = this.props.partners;
        const {isOnlyActive, isOnlyFavorite, isOnlyCanOrderPayment, registerStatuses, searchString, sortingTrigger} = this.state;
        const result = [];
        let beforeSearchInputAmount = 0;
        partners && partners.forEach(group => {
            let filteredPartners = group.partners.filter(partner =>
                (!isOnlyActive || partner.isActive === isOnlyActive)
                && (!isOnlyFavorite || partner.isFavorite === isOnlyFavorite)
                && (!isOnlyCanOrderPayment || partner.canOrderPayment === isOnlyCanOrderPayment)
                && (registerStatuses.length === 0 || registerStatuses.includes(partner.registrationStatus)));
            beforeSearchInputAmount += filteredPartners.length;
            filteredPartners = searchByString(filteredPartners, ["partnerName", "name", "phone", "skype", "telegram", "registrationDate", "levelRus"], searchString);
            if (filteredPartners.length > 0) {
                const clonedGroup = Object.assign({}, group);
                clonedGroup.partners = filteredPartners;
                result.push(clonedGroup);
            }
        });
        this.setState({
            filteredPartners: result,
            sortingTrigger: (sortingTrigger + 1),
            beforeSearchInputAmount: beforeSearchInputAmount,
        });
    };

    sortPartners = () => {
        let partners = this.state.filteredPartners.slice(0);
        const direction = this.state.sortingDirection === DESC ? -1 : 1;
        const columnName = this.state.sortingColumn;
        let result = [];
        partners.forEach(group => {
            result.push(group);
            group.partners = group.partners.sort((a, b) => {
                const first = !a[columnName] || a[columnName] === null ? "" : a[columnName].toLowerCase();
                const second = !b[columnName] || b[columnName] === null ? "" : b[columnName].toLowerCase();
                if (first === second) {
                    return 0;
                }
                return first > second ? direction : direction * -1;
            })
        });
        result = result.sort((a, b) => {
            const first = !a.partners || !a.partners[0] || !a.partners[0][columnName] || a.partners[0][columnName] === null ? "" : a.partners[0][columnName].toLowerCase();
            const second = !b.partners || !b.partners[0] || !b.partners[0][columnName] || b.partners[0][columnName] === null ? "" : b.partners[0][columnName].toLowerCase();
            if (first === second) {
                return 0;
            }
            return first > second ? direction : direction * -1;
        });
        this.setState({
            filteredPartners: result,
            renderTrigger: this.state.renderTrigger + 1,
            partnersForRendering: [],
            groupIdx: 0,
            partnerIdx: 0
        })
    };


    getRenderedPartners = (resultPartners, groupIdx, partnerIdx, rowsAmount) => {
        const filteredPartners = this.state.filteredPartners;
        if (rowsAmount === 0 || !filteredPartners[groupIdx]) {
            this.setState({partnersForRendering: resultPartners, groupIdx: groupIdx, partnerIdx: partnerIdx});
            return;
        }
        if (!resultPartners[groupIdx]) {
            resultPartners.push(Object.assign({}, filteredPartners[groupIdx]));
            resultPartners[groupIdx].partners = [];
            rowsAmount = rowsAmount - 1;
        }
        if (this.state.closedRowNames.includes(resultPartners[groupIdx].partnerType)) {
            groupIdx = groupIdx + 1;
            this.getRenderedPartners(resultPartners, groupIdx, 0, rowsAmount);
            return;
        }
        let partnersForAdding = filteredPartners[groupIdx].partners.slice(partnerIdx);
        if (partnersForAdding.length === rowsAmount) {
            resultPartners[groupIdx].partners.push(...partnersForAdding);
            groupIdx = groupIdx + 1;
            partnerIdx = 0;
            rowsAmount = 0;
        } else if (partnersForAdding.length > rowsAmount) {
            partnersForAdding = partnersForAdding.slice(0, rowsAmount);
            resultPartners[groupIdx].partners.push(...partnersForAdding);
            partnerIdx = partnerIdx + rowsAmount;
            rowsAmount = 0;
        } else {
            resultPartners[groupIdx].partners.push(...partnersForAdding);
            groupIdx = groupIdx + 1;
            partnerIdx = 0;
            rowsAmount = rowsAmount - partnersForAdding.length;
        }
        this.getRenderedPartners(resultPartners, groupIdx, partnerIdx, rowsAmount);
    };


    toggleRowOpen = (groupName) => {
        const closedRowNames = this.state.closedRowNames.slice(0);
        const filteredPartners = this.state.filteredPartners;
        const index = closedRowNames.indexOf(groupName);
        const groupIndex = filteredPartners.findIndex(group => group.partnerType === groupName);
        const partnersForRendering = this.state.partnersForRendering.slice(0, groupIndex + 1);
        partnersForRendering[groupIndex].partners = [];
        if (index === -1) {
            closedRowNames.push(groupName);
        } else {
            closedRowNames.splice(index, 1);
        }
        this.setState({
            closedRowNames: closedRowNames, partnersForRendering: partnersForRendering,
            groupIdx: groupIndex, partnerIdx: 0, renderTrigger: (this.state.renderTrigger + 1)
        });
    };


    loadPartnersWithDelay = (isOnlyActive, isOnlyFavorite, registerStatuses, sortingColumn, sortingDirection) => {
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
        this.setState({
            timeout: setTimeout(() => {
                this.props.loadPartners(isOnlyActive, isOnlyFavorite, registerStatuses, sortingColumn, sortingDirection);
            }, 1000)
        })
    };

    toggleIsOnlyActive = () => {
        this.setState({isOnlyActive: !this.state.isOnlyActive, filtersTrigger: this.state.filtersTrigger + 1})
    };

    toggleIsOnlyFavorite = () => {
        this.setState({isOnlyFavorite: !this.state.isOnlyFavorite, filtersTrigger: this.state.filtersTrigger + 1})
    };

    toggleIsOnlyCanOrderPayment = () => {
        this.setState({
            isOnlyCanOrderPayment: !this.state.isOnlyCanOrderPayment,
            filtersTrigger: this.state.filtersTrigger + 1
        })
    };

    toggleRegisterStatus = (status) => {
        let registerStatuses = this.state.registerStatuses.slice(0);
        registerStatuses = addOrRemove(registerStatuses, status);
        this.setState({registerStatuses: registerStatuses, filtersTrigger: this.state.filtersTrigger + 1});
    };

    changeSearchValue = footerSearchValue => {
        this.setState({searchString: footerSearchValue, filtersTrigger: this.state.filtersTrigger + 1});
    };

    approveRegistration = (partnerId) => {
        this.props.approveRegistration(partnerId, (updatedPartner) => this.afterUpdateCallback(updatedPartner));
    };

    rejectRegistration = (partnerId) => {
        this.props.rejectRegistration(partnerId, (updatedPartner) => this.afterUpdateCallback(updatedPartner));
    };

    addOrRemoveFromFavorites = (partnerId) => {
        this.props.addOrRemoveFromFavorites(partnerId, (updatedPartner) => this.afterUpdateCallback(updatedPartner));
    };

    toggleCanOrderPayment = (partnerId) => {
        this.props.toggleCanOrderPayment(partnerId, (updatedPartner) => this.afterUpdateCallback(updatedPartner));
    };

    changeLevel = (partnerId, nextLevel) => {
        this.props.changePartnerLevel(partnerId, nextLevel, (updatedPartner) => this.afterUpdateCallback(updatedPartner));
    };
    resendConfirmationEmail = (partner) => {
        this.props.resendConfirmationEmail(partner.email);
    };

    afterUpdateCallback = (updatedPartner) => {
        updatedPartner.levelRus = partnerLevelsDispatcher[updatedPartner.level].value;
        this.setState({
            ...this.state,
            partnersForRendering:
                this.state.partnersForRendering.slice(0).map(group => {
                    return {
                        partnerType: group.partnerType,
                        partners: group.partners.slice(0).map(partner => partner.id !== updatedPartner.id ? partner : updatedPartner)
                    }
                }),
            renderTrigger: this.state.renderTrigger + 1
        });
    };

    onChangeSorting = (sortingColumn) => {
        const sortingDirection = this.state.sortingColumn !== sortingColumn
            ? DESC
            : this.state.sortingDirection === ASC
                ? DESC
                : ASC;
        this.setState({
            sortingColumn: sortingColumn,
            sortingDirection: sortingDirection,
            sortingTrigger: this.state.sortingTrigger + 1
        });
    };

    onTableScroll = (e) => {
        const srcElement = e.target;
        if (srcElement.clientHeight + srcElement.scrollTop < (srcElement.scrollHeight - 205)) {
            return;
        }
        this.setState({renderTrigger: this.state.renderTrigger + 1})
    };


    getTableData = (partners) => {
        if (!partners) {
            return [];
        }
        const result = [];
        partners.forEach(partnerTypesData => {
            const partnerType = Object.assign({}, partnerTypesData); //клонируем объект
            if (this.state.closedRowNames && this.state.closedRowNames.includes(partnerType.partnerType)) {
                partnerType.isOpen = false;
                partnerType.partners = null;
            } else {
                partnerType.isOpen = true;
            }
            result.push(partnerType);
        });
        return result;
    };

    getXlsxData = (data) => {
        if (!data || data === this.state.xlsxReportData) {
            return;
        }
        this.setState({xlsxReportData: data});
    };

    renderTable = (tableData) => {
        const {isLoading} = this.props;
        return (
            <>
                <PartnersTable tableData={tableData}
                               reportData={this.state.filteredPartners}
                               toggleRowOpen={this.toggleRowOpen}
                               sort={this.onChangeSorting}
                               sortingColumn={this.state.sortingColumn}
                               sortingDirection={this.state.sortingDirection}
                               isLoading={isLoading}
                               onTableScroll={this.onTableScroll}
                               approveRegistration={this.approveRegistration}
                               rejectRegistration={this.rejectRegistration}
                               addOrRemoveFromFavorites={this.addOrRemoveFromFavorites}
                               toggleCanOrderPayment={this.toggleCanOrderPayment}
                               changeLevel={this.changeLevel}
                               resendConfirmationEmail={this.resendConfirmationEmail}
                               getXlsxReportData={this.getXlsxData}
                />
                < div className="padding-top-50"/>
            </>
        );
    };

    renderFilters = () => {
        const {isOnlyActive, isOnlyFavorite, isOnlyCanOrderPayment, registerStatuses} = this.state;
        return <PartnerPageFilters isOnlyActive={isOnlyActive} toggleIsOnlyActive={this.toggleIsOnlyActive}
                                   isOnlyFavorite={isOnlyFavorite}
                                   toggleIsOnlyFavorite={this.toggleIsOnlyFavorite}
                                   isOnlyCanOrderPayment={isOnlyCanOrderPayment}
                                   toggleIsOnlyCanOrderPayment={this.toggleIsOnlyCanOrderPayment}
                                   registerStatuses={registerStatuses}
                                   toggleRegisterStatus={this.toggleRegisterStatus}/>
    };


    render() {
        const {filteredPartners, partnersForRendering, xlsxReportData} = this.state;
        const tableData = this.getTableData(partnersForRendering);
        const content = () => {
            return (
                <PageContent displayContentHeader
                             displayFiltersBtn
                             displayXlsxBtn
                             xlsxData={xlsxReportData}
                             displaySearchInput
                             onChangeSearchInput={this.changeSearchValue}
                             searchInputAmount={filteredPartners?.map(group => group.partners ? group.partners.length : 0)
                                 .reduce((prev, curr) => (prev + curr), 0)}
                             beforeSearchInputAmount={this.state.beforeSearchInputAmount}
                             contentEl={this.renderTable(tableData)}
                             filtersEl={this.renderFilters()}
                />
            )
        };


        return (
            <BasePage
                pageName="Партнёры"
                content={content()}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.managerPartnersReducer.loading,
        partners: state.managerPartnersReducer.partners,
    }
}

export default connect(mapStateToProps, {
    loadPartners, approveRegistration, rejectRegistration, addOrRemoveFromFavorites,
    toggleCanOrderPayment, changePartnerLevel, resendConfirmationEmail
})(PartnersPage);