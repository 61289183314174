import React, {useEffect, useState} from "react";
import Collapse from "reactstrap/es/Collapse";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import MaterialUiInput from "../../../common/inputs/MaterialUiInput";
import {isMobileScreen} from "../../../../utils/displayUtils";

const networks = [
    {name: "activerevenue", traf_src: "activerevenue", site_id: "source_subid"},
    {name: "adcash.com", traf_src: "adcash", site_id: "[zone]"},
    {name: "adhub.ru", traf_src: "adhub", site_id: "[site_id]"},
    {name: "admachine.co", traf_src: "admachine", site_id: "[SITE_ID]"},
    {name: "ad-maven.com", traf_src: "ad-maven", site_id: "{banner}"},
    {name: "adnium.com", traf_src: "adnium", site_id: "{site}"},
    {name: "adoperator.com", traf_src: "adoperator", site_id: "{subid}"},
    {name: "adskeeper.com", traf_src: "adskeeper", site_id: "{teaser_id}"},
    {name: "adspyglass.com", traf_src: "adspyglass", site_id: "% SPOTID%"},
    {name: "adsterra", traf_src: "adsterra", site_id: "##PLACEMENT_ID##"},
    {name: "adxad", traf_src: "adxad", site_id: "{siteId}"},
    {name: "bigclick", traf_src: "bigclick", site_id: "{SID}"},
    {name: "bodyclick", traf_src: "bodyclick", site_id: "[SID]"},
    {name: "buymedia.biz", traf_src: "buymedia", site_id: "{SITE}"},
    {name: "bymyads.com", traf_src: "bymyads", site_id: "{bma_source_1}"},
    {name: "clickadilla.com", traf_src: "clickadilla", site_id: "{{ site }}"},
    {name: "clickadu.com", traf_src: "clickadu", site_id: "{zoneid}"},
    {name: "clickaine.com", traf_src: "clickaine", site_id: "{siteId}"},
    {name: "comeads.com", traf_src: "comeads", site_id: "{domain}"},
    {name: "dao.ad", traf_src: "dao", site_id: "{creative_id}"},
    {name: "datspush.com", traf_src: "datspush", site_id: "{SITE_ID}"},
    {name: "etarg.network", traf_src: "etarg", site_id: "{{site_id}}"},
    {name: "evadav.com", traf_src: "evadav", site_id: "{SOURCE_ID}"},
    {name: "exoclick.com", traf_src: "exoclick", site_id: "{site_id}"},
    {name: "galaksion.com", traf_src: "galaksion", site_id: "{zoneid}"},
    {name: "globalnetwork.pro", traf_src: "globalnetwork", site_id: "[SOURCE_ID]"},
    {name: "hilltopads.com", traf_src: "hilltopads", site_id: "{{zoneid}}"},
    {name: "juicyads.com", traf_src: "juicyads", site_id: "{dynamicSITE}"},
    {name: "kadam.net", traf_src: "kadam", site_id: "[SID]"},
    {name: "kokos.click", traf_src: "kokos", site_id: "[SITE-ID]"},
    {name: "mgid.com", traf_src: "mgid", site_id: "{widget_id}"},
    {name: "mobiads.ru", traf_src: "mobiads", site_id: "[place_id]"},
    {name: "mobivisits.com", traf_src: "mobivisits", site_id: "{publisher}"},
    {name: "mpay69.biz", traf_src: "mpay69", site_id: "{SITE}"},
    {name: "myadcash.com", traf_src: "myadcash", site_id: "[zone]"},
    {name: "popads.net", traf_src: "popads", site_id: "[WEBSITEID]"},
    {name: "popadup.com", traf_src: "popadup", site_id: "[SITE_ID]"},
    {name: "popcash.net", traf_src: "popcash", site_id: "[siteid]"},
    {name: "popunder.net", traf_src: "popunder", site_id: "{wm_site_id}"},
    {name: "popundertotal.com", traf_src: "popundertotal", site_id: "[SITE_ID]"},
    {name: "propellerads.com", traf_src: "propellerads", site_id: "{zoneid}"},
    {name: "reacheffect.com", traf_src: "reacheffect", site_id: "[pubId]"},
    {name: "roxyfoxy.com", traf_src: "roxyfoxy", site_id: "{referrer_domain}"},
    {name: "royalads.net", traf_src: "royalads", site_id: "[$site_id]"},
    {name: "rtxplatform.com", traf_src: "rtxplatform", site_id: "{creative_id}"},
    {name: "startapp.com", traf_src: "startapp", site_id: "{startapp_pub_id}"},
    {name: "teasernet.com", traf_src: "teasernet", site_id: "[SITE_ID]"},
    {name: "trafficforce.com", traf_src: "trafficforce", site_id: "{site_id}"},
    {name: "trafficjunky.com", traf_src: "trafficjunky", site_id: "{SiteName}"},
    {name: "trafficshop.com", traf_src: "trafficshop", site_id: "{site_id}"},
    {name: "trafficstars.com", traf_src: "trafficstars", site_id: "{site_id}"},
    {name: "trafstock.biz", traf_src: "trafstock", site_id: "{hostname}"},
    {name: "twinred.com", traf_src: "twinred", site_id: "{siteName}"},
    {name: "ungads.com", traf_src: "ungads", site_id: "{site}"},
    {name: "visitweb.com", traf_src: "visitweb", site_id: "{HSITE2}"},
    {name: "wigetmedia.com", traf_src: "wigetmedia", site_id: "{pid}"},
    {name: "zeropark.com", traf_src: "zeropark", site_id: "{source}"},
];

export default function MacrosCollapse({macros, macrosPreset}) {

    const [network, setNetwork] = useState(null);
    const [isExpanded, setIsExpanded] = useState(!isMobileScreen() && JSON.parse(localStorage.getItem("isMacrosCollapseExpanded")) === true);
    const [macrosState, setMacrosState] = useState({});
    const [sortedNetworks, setSortedNetworks] = useState([]);

    useEffect(() => {
        macros && setMacrosState({...macros});
    }, [macros]);

    useEffect(() => {
        let sortedNetworks = [...networks].sort((a, b) => a.name.localeCompare(b.name));
        if (macrosPreset && macrosPreset.data) {
            setMacrosState({...macrosState,
                pt_id: macrosPreset.data.pt_id ? macrosPreset.data.pt_id : "",
                pt_id2: macrosPreset.data.pt_id2 ? macrosPreset.data.pt_id2 : "",
                pt_id3: macrosPreset.data.pt_id3 ? macrosPreset.data.pt_id3 : "",
            });
            macros.pt_id = macrosPreset.data.pt_id ? macrosPreset.data.pt_id : null;
            macros.pt_id2 = macrosPreset.data.pt_id2 ? macrosPreset.data.pt_id2 : null;
            macros.pt_id3 = macrosPreset.data.pt_id3 ? macrosPreset.data.pt_id3 : null;
            if (macrosPreset.data.networks && macrosPreset.data.networks.length > 0) {
                //ищем рекламные сетки по названиям и убираем пустые(если в пресетах есть невалидные)
                const prevUsedNetworks = macrosPreset.data.networks
                    .map(networkName => sortedNetworks.find(network => network.name === networkName))
                    .filter(network => network);
                if (prevUsedNetworks[prevUsedNetworks.length - 1]) {
                    prevUsedNetworks[prevUsedNetworks.length - 1].hasBorderBottom = true;
                }
                sortedNetworks = [...prevUsedNetworks, ...sortedNetworks.filter(network => !macrosPreset.data.networks.includes(network.name))];
            }
        }
        setSortedNetworks(sortedNetworks);
    }, [macrosPreset, macros]);

    const toggleExpanded = () => {
        localStorage.setItem("isMacrosCollapseExpanded", !isExpanded + "");
        setIsExpanded(!isExpanded);
    };

    const handleSelectNetwork = (network) => {
        setNetwork(network);
        if (macros) {
            macros.site_id = network.site_id;
            macros.traf_src = network.traf_src;
            macros.networkName = network.name;
        }
        setMacrosState({...macrosState, site_id: network.site_id, traf_src: network.traf_src})
    };

    const handleMacroTyping = (name, value) => {
        if (macros) {
            macros[name] = value;
        }
        setMacrosState({...macrosState, [name]: value});
        setNetwork(null);
    };

    const handlePtParamTyping = (name, value) => {
        if (macros) {
            macros[name] = value;
        }
        setMacrosState({...macrosState, [name]: value})
    };

    const rotateIconStyle = isOpen => ({
        transform: isOpen ? 'rotate(-90deg)' : 'none',
    });

    return (
        <>
                <div className="openparams" onClick={toggleExpanded}> Расширенные параметры
                    <i style={{display: 'inline-table', transition: 'all ease 200ms', ...rotateIconStyle(isExpanded)}} className="ti ti-angle-down " id="icon-change"
                       onClick={toggleExpanded}/>
                </div>
                <div className="params">
                    <Collapse isOpen={isExpanded} style={{width: "100%"}}>
                        <div className={"row par open"} >

                            <div className="form-row per100">
                                <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                    <MaterialUISelect
                                        label="Рекламная сеть"
                                        styles={{root: {minWidth: "100%", padding: 5}}}
                                        dropdownData={sortedNetworks}
                                        displayedField='name' dataField='name'
                                        placeholder="-"
                                        selectedValue={network}
                                        onSelectCallbackFunction={(network) => handleSelectNetwork(network)}
                                        isSearchable={true}/>
                                </div>
                                <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                    <MaterialUiInput label="traf_src"
                                                     onTextTyping={(value) => handleMacroTyping("traf_src", value)}
                                                     value={macrosState.traf_src} placeholder='{traf_src}'
                                                     styles={{root: {minWidth: 100}}}/>
                                </div>
                                <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                    <MaterialUiInput label="site_id"
                                        onTextTyping={(value) => handleMacroTyping("site_id", value)}
                                                     value={macrosState.site_id} placeholder='{site_id}'
                                                     styles={{root: {minWidth: 100}}}/>
                                </div>
                            </div>
                            <div className="form-row per100">
                                <div className="form-group col-md-4 col-sm-12">
                                    <MaterialUiInput label="Параметр pt_id"
                                                     onTextTyping={(value) => handlePtParamTyping("pt_id", value)}
                                                     value={macrosState.pt_id} placeholder='{pt_id}'
                                                     styles={{root: {minWidth: 100}}}/>
                                </div>
                                <div className="form-group col-md-4 col-sm-12">
                                    <MaterialUiInput label="Параметр pt_id2"
                                                     onTextTyping={(value) => handlePtParamTyping("pt_id2", value)}
                                                     value={macrosState.pt_id2} placeholder='{pt_id2}'
                                                     styles={{root: {minWidth: 100}}}/>
                                </div>
                                <div className="form-group col-md-4 col-sm-12">
                                    <MaterialUiInput label="Параметр pt_id3"
                                                     onTextTyping={(value) => handlePtParamTyping("pt_id3", value)}
                                                     value={macrosState.pt_id3} placeholder='{pt_id3}'
                                                     styles={{root: {minWidth: 100}}}/>
                                </div>
                            </div>

                        </div>
                    </Collapse>
                </div>
        </>
    )
}