import React from 'react';
import {columnsFormatter, ltvAnalysisColumns} from "../../../../utils/statisticUtils";
import {
    ROS,
    LTV75,
    ROI75,
    LTV90,
    LTVX,
    ROI90,
    ROIX,
    SUBSCRIPTIONS, BUYOUTS, BUYOUT_FACTOR, MARGIN75, MARGIN90, MARGINX, PAYOUT_RATE, UNSUBSCRIBES_FACTOR,
} from "../../../../utils/constants";

export default function LtvAnalysisSummary(props) {
    const tableFooterData = props?.data;
    const hiddenColumns = props.hiddenColumns;
    const ltvInterval = props?.handleLtvInterval();

    if (!isFinite(tableFooterData[ROS])) return "";   // ROS is calculated for MANAGER_LTV_ANALYSIS only

    const summaryColumns = [
        SUBSCRIPTIONS,
        UNSUBSCRIBES_FACTOR,
        BUYOUTS,
        BUYOUT_FACTOR,
        PAYOUT_RATE,
        ROS,
        LTV75,
        LTV90,
        LTVX,
        ROI75,
        ROI90,
        ROIX,
        MARGIN75,
        MARGIN90,
        MARGINX,
    ];

    const getColumnLabel = (columnName) => {
        const column = ltvAnalysisColumns.find(item => item.name == columnName);
        return column?.label + (column?.ltvInterval ? ltvInterval : "");
    };

    return (
        <>
                <table className="table table-bordered nowrap statistic_table sticky-header sticky-footer userselecttext">
                    <thead className="thead-light">
                    <tr>
                        {summaryColumns.filter(column => !hiddenColumns.includes(column)).map(column => <th key={column} className="right_position">{getColumnLabel(column)}</th>)}
                    </tr>
                    </thead>
                    <tbody className="statistic_body">
                    <tr>
                        {summaryColumns.filter(column => !hiddenColumns.includes(column)).map(column =>
                            <td key={column} className={"statistic_body_group right_position"}>
                                {columnsFormatter[column]?.(tableFooterData[column])}
                            </td>)}
                    </tr>
                    </tbody>
                </table>
            <div className={"card-body"}></div>
        </>
    );
}
