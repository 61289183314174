import {
   PAGE_CONTENT_LOADED,
    PAGE_CONTENT_LOADING, PAGE_CONTENT_SAVED
} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";

export const loadFaqPageContent = () => (dispatch, getState) => {
    const types = 'COMMON_INFO,BEGINNING_OF_WORK,OPERATOR_RESTRICTIONS,PAYMENTS,CONTACTS';
    loadPageContent(types, dispatch, getState);
};

export const loadToolsPageContent = () => (dispatch, getState) => {
    const types = 'TRAFFIC_FILTERS,TRAFFICBACK,POSTBACK,S2S,BLACK_LIST';
    loadPageContent(types, dispatch, getState);
};

const loadPageContent = (types, dispatch, getState) => {
    dispatch({type: PAGE_CONTENT_LOADING});

    axios
        .get(process.env.REACT_APP_API_URL +
            '/pageContent?types=' + types,
            getConfig(getState))
        .then(res =>
            dispatch({
                type: PAGE_CONTENT_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const savePageContent = (content, callbackFunction) => (dispatch, getState) => {

    axios
        .put(process.env.REACT_APP_API_URL +
            '/pageContent', content, getConfig(getState))
        .then(res =>
            dispatch({
                type: PAGE_CONTENT_SAVED,
                payload: res.data
            })
        )
        .then(() => {
            dispatch(getSuccess({success: "Контент сохранен"}))
        })
        .then(() => callbackFunction && callbackFunction())
        .catch(err => {
            dispatch(returnErrors(err));
        });
};