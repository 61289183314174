import {LOG_TYPES_LOADED, LOGS_LOADED, LOGS_LOADING, LOGS_RESET} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnAllErrorsAsPopup} from "./errorActions";
import {getIsoLocalString} from "../utils/dateUtils";

export const loadLogs = (data) => (dispatch, getState) => {
    const {searchString, logType, outputOrder, searchDate, rowLimit} = data;
    if (!logType) {
        return;
    }
    const searchData = (searchString ? searchString.split("+").map(item => item.trim()).join(",") : "");
    const paramsStr = "?searchData=" + searchData
        + "&date=" + (searchDate ? getIsoLocalString(searchDate) : "")
        + "&type=" + (logType ? logType.value : "")
        + "&outputOrder=" + (outputOrder ? outputOrder.value : "")
        + "&rowLimit=" + (rowLimit ? rowLimit : "");

    dispatch({type: LOGS_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/logs' + paramsStr , getConfig(getState))
        .then(res =>
            dispatch({
                type: LOGS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
            dispatch({type: LOGS_LOADED});
        });
};

export const resetLogs = () => (dispatch, getState) => {
    dispatch({type: LOGS_RESET});
};

export const loadLogTypes = () => (dispatch, getState) => {
    axios
        .get(process.env.REACT_APP_API_URL + '/logs/types', getConfig(getState))
        .then(res =>
            dispatch({
                type: LOG_TYPES_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
        });
}
