import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {isAuthenticated} from "../../utils/authUtils";
import {URL_ROOT} from "../../properties";


function LoginRequiredRoute ({component: Component,...rest}) {
    return (
        <Route
            {...rest}
            render={
                (props) => isAuthenticated()
                ? <Component {...props} />
                : <Redirect to={{pathname: URL_ROOT, state: {from: props.location}}} />}
        />
    )
}

export default LoginRequiredRoute;