import React, {Component} from 'react';
import {connect} from "react-redux";
import {CONNECTED, CONNECTED_OFFERS, COUNTRIES, OFFER_CATEGORIES, OPERATORS} from "../../../utils/constants";
import {createLinks, loadPartnerOffers} from "../../../actions/partnerOfferActions";
import Spinner from "../../common/Spinner";
import ImgPopover from "../../common/popovers/ImgPopover";
import {activityStatuses, getBillingTypeName, getOfferNameWithId} from "../../../utils/offerUtils";
import OfferLinksModal from "./modal/OfferLinksModal";
import {loadPartnerProjectsWithNew, saveProject} from "../../../actions/projectsActions";
import IconPopover from "../../common/popovers/IconPopver";
import NoContentMessage from "../../common/NoContentMessage";
import SimpleTable from "../../common/tables/SimpleTable";
import PartnerOfferFilters from "./filters/offers/PartnerOfferFilters";
import PropTypes from "prop-types";
import PageContent from "../../common/page/PageContent";
import OperatorImage from "../../common/images/OperatorImage";
import {sendPostbackTest} from "../../../actions/postbackTestActions";
import {loadFiltersData} from "../../../actions/filterDataActions";
import CountryImage from "../../common/images/CountryImage";

const RENDERED_ROWS = 20;

class ConnectedOffersContent extends Component {
    static propTypes = {
        tabs: PropTypes.object,
        selectNavTab: PropTypes.func,
        selectedNavTab: PropTypes.string,
        displayedComponent: PropTypes.oneOf([CONNECTED_OFFERS]),
        searchInputValue: PropTypes.string,
        beforeSearchInputAmount: PropTypes.any,
        filteredOffers: PropTypes.array,
        filters: PropTypes.object,
        resetAllFilters: PropTypes.func,
        toggleCheckboxFilter: PropTypes.func,
        onSelectFilterValue: PropTypes.func,
        filterOffers: PropTypes.func,
        needToFilter: PropTypes.bool,
        selectedBillingType: PropTypes.string,
    };

    state = {
        offersForRendering: [],
        isOpenedModal: false,
        selectedOffer: null,
        selectedProject: null,
        selectedBillingType: null,
    };


    componentDidMount() {
        this.props.loadPartnerOffers(CONNECTED);
        this.props.loadFiltersData([COUNTRIES, OFFER_CATEGORIES, OPERATORS]);
        const {projects} = this.props;
        if (!projects || projects.length === 0) {
            this.props.loadPartnerProjectsWithNew();
        }
        window.addEventListener('scroll', this.handleScroll);
        this.setState({
            componentMounted: true,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const allConnectedOffers = this.props.allConnectedOffers;
        if ((allConnectedOffers && allConnectedOffers.length > 0 && this.props.needToFilter)
            || (this.props.searchInputValue !== prevProps.searchInputValue)) {
            this.filterOffers();
        }
    }

    handleScroll = (srcElement) => {
        if (srcElement.clientHeight + srcElement.scrollTop < (srcElement.scrollHeight - 200)) {
            return;
        }
        let endIdx = this.state.offersForRendering.length + RENDERED_ROWS;
        if (!this.props.filteredOffers[endIdx]) {
            endIdx = this.props.filteredOffers.length;
        }
        this.setState({
            offersForRendering: this.props.filteredOffers.slice(0, endIdx)

        });
    };

    filterOffers = () => {
        let filteredOffers = this.props.filterOffers(this.props.allConnectedOffers);
        this.setState({
            offersForRendering: filteredOffers && filteredOffers.length >= RENDERED_ROWS ? filteredOffers.slice(0, RENDERED_ROWS) : filteredOffers,
        })
    };

    onSelectProject = (project) => {
        this.setState({selectedProject: project})
    };

    onSelectBillingType = (billingType) => {
        this.setState({selectedBillingType: billingType})
    };

    createPromotionLink = (offer) => {
        const {selectedProject, selectedBillingType} = this.state;

        if (!selectedProject || selectedProject.id === -1 || !selectedBillingType) {
            return;
        }
        const income = offer?.offerIncomes.find(income => income.billingType === selectedBillingType.type);
        if (!income) {
            return;
        }
        const selectedOffer = {offerId: income.offerId}
        this.props.createLinks([selectedOffer], selectedProject, null, () => this.setState({selectedOffer: offer}));

    };

    handleClickLink = (offer) => {
        this.setState({
            isOpenedModal: true,
            selectedOffer: offer
        })
    };

    handleCloseModal = () => {
        this.setState({
            isOpenedModal: false,
            selectedOffer: null
        })
    };

    onSaveProject = (project) => {
        this.props.saveProject(project, () => null);
    };

    onSendPostback = (postbackTest, handlePostbackTestResult) => {
        this.props.sendPostbackTest(postbackTest, handlePostbackTestResult);
    };

     getIncome = (income, offer) => {
        return (income.incomeType === "PERCENT")
            ? (offer.eup && income.income)
                ? ((offer.eup.replace(",", ".")) * (income.income.replace(",", ".")) / 100).toFixed(2).replace(".", ",")
                : null
            : income.income;
    };


    tableColumns = () => {
        return [
            {
                name: "Название оффера",
                key: "name",
                getBodyCellValue: (offer) => {
                    return getOfferNameWithId(offer.offerId, offer.name)
                },
            },
            {
                name: "Промо",
                key: "promo",
                getBodyCellValue: (offer) => {
                    return <ImgPopover imgUrl={offer.imgUrl} target={"Popover" + offer.offerId}
                                       imgClassName="offers-min" placement="right"/>
                },
            },
            {
                name: "Тематика",
                key: "theme",
                getBodyCellValue: (offer) => {
                    return offer.category.name
                },
            },
            {
                name: "ОСС",
                key: "oss",
                getBodyCellValue: (offer) => {
                    return <OperatorImage fileName={offer.operatorImgFileName} title={offer.operatorName}/>
                },
                bodyCellClassName: "td-operator"
            },
            {
                name: "Страна",
                key: "country",
                getBodyCellValue: (offer) => {
                    return <CountryImage fileName={offer.countryImgFileName} title={offer.countryName}/>
                },
                bodyCellClassName: "td-operator"
            },
            {
                name: "Выплата",
                key: "payment",
                getBodyCellValue: (offer) => {
                    return <>
                        {offer?.offerIncomes.map(income => <>
                                <span key={income.offerIncomeId}>
                                    {this.getIncome(income, offer) + "р.  " + (getBillingTypeName(income.billingType, false))}
                                </span><br/>
                                </>)}
                             </>
                },
            },
            {
                name: "Статус",
                key: "status",
                getBodyCellValue: (offer) => {
                    return <IconPopover
                        displayedContent={offer.activityStatus ? activityStatuses[offer.activityStatus].tooltip : null}
                        placement="left" iconClassName="fa fa-dot-circle-o"
                        iconColor={offer.activityStatus ? activityStatuses[offer.activityStatus].color : null}
                        iconSize={22}
                        target={"PopoverIconStatus" + offer.offerId}/>
                },
            },
            {
                name: "",
                key: "keys",
                getBodyCellValue: (offer) => {
                    return <>
                        <i className="ti ti-link" style={{"color": "#007bff"}}
                                onClick={() => this.handleClickLink(offer)}/>
                        <IconPopover displayedContent={offer.term} placement="left" iconClassName="ti ti-info-alt"
                                     target={"PopoverIconTerm" + offer.offerId}/>
                           </>
                },
                headerCellStyle: {minWidth: "60px", width: "60px"}
            },
        ]
    };

    renderTable = () => {
        return <>
            {
                this.state.offersForRendering && this.state.offersForRendering.length > 0
                    ?
                    <div className="table-responsive" id="hiden-offer-table">
                        <SimpleTable columns={this.tableColumns()}
                                     data={this.state.offersForRendering}
                                     bodyRowKey="offerId"/>
                    </div>
                    : <NoContentMessage/>
            }
        </>
    };

    render() {
        return (
            <PageContent tabs={this.props.tabs}
                         selectedTabName={this.props.selectedNavTab}
                         onSelectTab={this.props.selectNavTab}
                         onScroll={this.handleScroll}
                         displayContentHeader
                         displaySearchInput
                         onChangeSearchInput={this.props.onChangeSearchInput}
                         searchInputAmount={this.props.filteredOffers?.length}
                         searchInputValue={this.props.searchInputValue}
                         beforeSearchInputAmount={this.props.beforeSearchInputAmount}
                         displayFiltersBtn
                         displayIpRangesBtn
                         filtersEl={<PartnerOfferFilters key={"2"}
                                                         offers={this.props.allConnectedOffers}
                                                         displayedComponent={this.props.displayedComponent}
                                                         filters={this.props.filters}
                                                         toggleCheckboxFilter={this.props.toggleCheckboxFilter}
                                                         onSelectFilterValue={this.props.onSelectFilterValue}
                                                         resetAllFilters={this.props.resetAllFilters}/>
                         }
                         contentEl={
                             <div className="custom-tab">
                                 {
                                     this.props.isLoading
                                         ? <Spinner/>
                                         : this.renderTable()
                                 }
                                 <OfferLinksModal isOpened={this.state.isOpenedModal}
                                                  isPostbackTestSending={this.props.isPostbackTestSending}
                                                  offer={this.state.selectedOffer}
                                                  projects={this.props.projects}
                                                  selectedProject={this.state.selectedProject}
                                                  onSelectProject={this.onSelectProject}
                                                  onSaveProject={this.onSaveProject}
                                                  onSendPostback={this.onSendPostback}
                                                  onCloseModal={this.handleCloseModal}
                                                  onCreateLink={this.createPromotionLink}
                                                  domains={(this.props.domains)}
                                                  selectedBillingType={this.state.selectedBillingType}
                                                  onSelectBillingType={this.onSelectBillingType}
                                 />
                             </div>
                         }
            >
            </PageContent>
        );
    }
};


function mapStateToProps(state) {
    return {
        isPostbackTestSending: state.postbackTestReducer.sending,
        allConnectedOffers: state.partnerOffers.connectedOffers,
        isLoading: state.partnerOffers.isConnectedOffersLoading,
        projects: state.projects.projects,
        domains: state.partnerDomainsReducer.partnerDomains,
    }
}

export default connect(mapStateToProps, {
    loadPartnerOffers,
    saveProject,
    loadPartnerProjectsWithNew,
    createLinks,
    sendPostbackTest,
    loadFiltersData,
})(ConnectedOffersContent);