import React from "react";
import {useSelector} from "react-redux";
import MaterialUIMultiSelect from "../../../common/selects/MaterialUIMultiSelect";
import {isMobileScreen} from "../../../../utils/displayUtils";

export default function OperatorFilter({selectedOperators, handleChangeFilterData, selectedCountries, inputStyle}) {
    let {operators} = useSelector(state => state.filterDataReducer.filterData);
    if (selectedCountries?.length > 0 && operators?.length > 0) {
        const availableOperatorIds = selectedCountries.flatMap(country => country.operatorIds);
        operators = operators.filter(operator => availableOperatorIds.includes(operator.id));
    }
    const isMobile = isMobileScreen();

    const onSelect = (value) => {
        handleChangeFilterData("operators", value, true);
    };

    return (
            <MaterialUIMultiSelect
                styles={inputStyle}
                dropdownData={operators}
                values={selectedOperators}
                dataField='id'
                displayedField='name'
                label={'Операторы'}
                placeholder={'-'}
                onSelect={onSelect}
                isSearchable={!isMobile}
            />
    );
}