import React, {Fragment} from "react";

export default function OfferEditInput({label, labelClassName="fs10px", inputClassName="form-control", value='', onChange, hasError, disabled, inputStyle}) {

    return (
        <Fragment>
            {label && <label htmlFor="NameForPartners" className={labelClassName}>{label}</label>}
            <input type="text" className={hasError ? inputClassName + " input-error" : inputClassName}
                   value={value} onChange={onChange} readOnly={disabled} style={inputStyle}/>
        </Fragment>
    )
}