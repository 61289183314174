import React from "react";
import PopupRegistrationForm from "./PopupRegistrationForm";
import PopupLoginForm from "./PopupLoginForm";
import {REGISTRATION_FORM, LOGIN_FORM, RESTORE_PASSWORD_FORM} from "../../../../../utils/constants";
import PopupRestorePasswordForm from "./PopupRestorePasswordForm";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

export default function PopupBlock({openedPopup, openPopup, closePopup, onTextTyping, toggleChecked, values, fieldErrors, register,
                                       signIn, resendConfirmationEmail, sendRestorePasswordLink}) {

    return (
        openedPopup &&
        <div className="darkbg">
            <div className="popupMorda">
                {openedPopup === REGISTRATION_FORM
                && <PopupRegistrationForm onTextTyping={onTextTyping} toggleChecked={toggleChecked}
                                          values={values} fieldErrors={fieldErrors} register={register}/>}
                {openedPopup === LOGIN_FORM
                && <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
                    <PopupLoginForm onTextTyping={onTextTyping} toggleChecked={toggleChecked}
                                   values={values} fieldErrors={fieldErrors} signIn={signIn}
                                   resendConfirmationEmail={resendConfirmationEmail}
                                   openPopup={openPopup}/>
                </GoogleReCaptchaProvider>}
                {openedPopup === RESTORE_PASSWORD_FORM
                && <PopupRestorePasswordForm onTextTyping={onTextTyping} values={values} fieldErrors={fieldErrors}
                                             sendRestorePasswordLink={sendRestorePasswordLink}/>}
            </div>

            <div className="close" onClick={closePopup}>×</div>
        </div>
    )
}