import React from "react";
import Spinner from "../../../common/Spinner";
import NoContentMessage from "../../../common/NoContentMessage";

export default function PartnerPaymentsContent({table, chart, isLoading, showTable, showChart}) {

    return (
        <div>
                    {isLoading && <Spinner/>}
                    {!isLoading && showTable && table}
                    {!isLoading && showChart && chart}
                    {!isLoading && !table && !chart && <NoContentMessage/>}
        </div>
    )
};