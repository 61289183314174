import React, {useState, useRef, useEffect} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import ProjectCreationCollapse from "../../../common/projectCreation/ProjectCreationCollapse";
import {LinkCopyPopup} from "../../../common/linkCopyPopup/LinkCopyPopup";
import {replaceDomain} from "../../../../utils/urlUtils";
import OfferLinkInfo from "../OfferLinkInfo";
import PostbackTestModal from "../../projects/modal/PostbackTestModal";
import {connect} from "react-redux";
import {loadPromotionLinks} from "../../../../actions/partnerOfferActions";
import {billingTypesData, getBillingTypeName} from "../../../../utils/offerUtils";

function OfferLinksModal(props) {
    const {isOpened, isPostbackTestSending, offer, onCloseModal, onCreateLink, projects, selectedProject, onSelectProject,
        onSendPostback, domains, loadPromotionLinks, promotionLinks, selectedBillingType, onSelectBillingType} = props;
    const offerName = offer ? offer.offerId + " | " + offer.name : '';
    const childRef = useRef();
    const [isFormExpanded, setFormExpanded] = useState(false);
    const [isTestPostbackExpanded, setTestPostbackExpanded] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [availableBillingTypes, setAvailableBillingTypes] = useState([]);

    useEffect(() => {
        if (!offer) {
            return;
        }
        isOpened && loadPromotionLinks([offer.offerId]);
        const offerBillingTypes = offer.offerIncomes?.map(income => income.billingType);
        const availableBillingTypes= billingTypesData.filter(dataItem => offerBillingTypes.includes(dataItem.type));
        offer && setAvailableBillingTypes(availableBillingTypes);
        onSelectBillingType(availableBillingTypes[0]);
    }, [offer, isOpened]);

    useEffect(() => {
        const defaultDomain = domains && domains.find(domain => domain.defaultDomain);
        setSelectedDomain(defaultDomain);
    }, [domains]);

    const projectsWithoutCreatedLinks
        = (projects && promotionLinks.length > 0 && selectedBillingType)
        ? projects.filter(project => !promotionLinks.find(link => link.projectId === project.id && link.billingType === selectedBillingType.type)) : [];

    function toggle() {
        onCloseModal();
        setFormExpanded(false);
        selectProject(null);
        onSelectBillingType(null);
        setSelectedDomain(null);
    }

    function createLink() {
        if (selectedProject) {
            onCreateLink(offer);
            selectProject(null);
        }
    }

    function hideForm() {
        setFormExpanded(false);
        setTestPostbackExpanded(false);
        selectProject(null);
    }


    function selectProject(project) {
        const isExpanded = project && project.id === -1;
        setFormExpanded(isExpanded);
        if (onSelectProject) {
            onSelectProject(project);
        }
    }

    function openModal () {
        setTestPostbackExpanded(true);
     };

    function handleCloseModal () {
        setTestPostbackExpanded(false);
    };

    const domainsSelect = domains && domains.length > 0
        &&
            <MaterialUISelect
                styles={{root: {minWidth: 150, padding: 5}}}
                dropdownData={domains}
                displayedField='cname' dataField='id'
                placeholder="Домен"
                selectedValue={selectedDomain}
                onSelectCallbackFunction={(domain) => setSelectedDomain(domain)}
                isSearchable={true}/>;

    const projectsSelect =
           <MaterialUISelect styles={{root: {minWidth: 180, padding: 5}}}
                          dropdownData={projectsWithoutCreatedLinks}
                          selectedValue={selectedProject}
                          displayedFields={['sourceId', 'name']} dataField='id'
                          placeholder="Поток"
                             onSelectCallbackFunction={(project) => selectProject(project)}
                             isSearchable={true}/>;

    const billingTypeSelect =
        <MaterialUISelect styles={{root: {minWidth: 120, padding: 5}}}
                          dropdownData={availableBillingTypes}
                          selectedValue={selectedBillingType}
                          displayedField='fullName' dataField='type'
                          placeholder="Тип выплаты"
                          onSelectCallbackFunction={(billingType) => onSelectBillingType(billingType)}
                          isSearchable={true}/>;

    const createLinkBtn = isFormExpanded
        ? null
        : <button className="btn btn-outline-primary" onClick={() => createLink()}>Получить ссылку</button>;

    const testPostbackBtn = isFormExpanded
        ? null
        : <button className="btn btn-outline-warning btn-margin select-potok"
            onClick={() => openModal()}>Проверить Postback</button>;

    const getLinkName = (promotionLink) => {
        return promotionLink.sourceId + " | " + promotionLink.projectName + " (" + getBillingTypeName(promotionLink.billingType, true) + ")";
    };

    return (

        <Modal size="lg" isOpen={isOpened} toggle={() => toggle()}>
            <ModalHeader style={{float: "left"}} toggle={() => toggle()}>{offerName}</ModalHeader>
            <ModalBody>
                {
                    promotionLinks.map(promotionLink =>
                        <p key={promotionLink.projectName + promotionLink.billingType}
                           onClick={() => childRef.current.handleClickCopy(replaceDomain(promotionLink.link, selectedDomain && selectedDomain.cname))}>
                            <span className="link">
                                <label className="float-left" htmlFor="11">{getLinkName(promotionLink)}</label>
                                <input readOnly={true} type="text" value={replaceDomain(promotionLink.link, selectedDomain && selectedDomain.cname)}
                                       className="linkToCopy copyClick form-control form-control-sm"
                                       style={{backgroundColor: "#e9ecef",  display: "flex"}}
                                       title="Копировать ссылку"/>
                            </span>
                        </p>
                    )
                }
                <OfferLinkInfo/>
            </ModalBody>
            <ModalFooter>
                {domainsSelect}
                {projectsSelect}
                {billingTypeSelect}
                {createLinkBtn}
                {testPostbackBtn}
            </ModalFooter>
            <div style={{padding: "10px"}}>
                <ProjectCreationCollapse isExpanded={isFormExpanded} onHideForm={hideForm} onSelectCreatedProject={selectProject}/>
            </div>
            <PostbackTestModal isOpened={isTestPostbackExpanded}
                               isSending={isPostbackTestSending}
                               offers={[offer]}
                               projects={projects}
                               selectedProject={selectedProject}
                               onCloseModal={handleCloseModal}
                               onSendPostback={onSendPostback}
            />
            <LinkCopyPopup ref={childRef}/>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        promotionLinks: state.partnerOffers.promotionLinks,
        isPromotionLinksLoading: state.partnerOffers.isPromotionLinksLoading,
    }
}

export default connect(mapStateToProps, {
    loadPromotionLinks,
})(OfferLinksModal);
