import {
    PARTNER_OFFERS_LOADED,
    PARTNER_OFFERS_LOADING,
    CREATE_PROMOTION_LINK,
    PARTNER_OFFER_ITEM_LOADING,
    PARTNER_OFFER_ITEM_LOADED,
    PROMOTION_LINKS_LOADING,
    PROMOTION_LINKS_LOADED, PROMOTION_LINK_ENCRYPTING, PROMOTION_LINK_ENCRYPTED,
} from "../../actions/actionTypes";
import {CONNECTED, FOR_ACTIVATION,} from "../../utils/constants";


const initialState = {
    offers: [],
    connectedOffers: [],
    isAllOffersLoading: false,
    isConnectedOffersLoading: false,
    offerItem: null,
    isOfferItemLoading: false,
    isPromotionLinksLoading: false,
    promotionLinks: [],
    encryptedOfferLink: null,
    isEncryptedOfferLinkLoading: false,
};


export default function (state = initialState, action = {}) {

    switch (action.type) {
        case PARTNER_OFFERS_LOADING:
            return {
                ...state,
                isAllOffersLoading: action.payload === FOR_ACTIVATION,
                isConnectedOffersLoading: action.payload === CONNECTED
            };
        case PARTNER_OFFERS_LOADED:
            return {
                ...state,
                offers: action.payload.type === FOR_ACTIVATION ? action.payload.offers : state.offers,
                connectedOffers: action.payload.type === CONNECTED ? action.payload.offers : state.connectedOffers,
                isLinkCreated: false,
                isAllOffersLoading: action.payload.type === FOR_ACTIVATION ? false : state.isAllOffersLoading,
                isConnectedOffersLoading: action.payload.type === CONNECTED ? false : state.isConnectedOffersLoading
            };
        case CREATE_PROMOTION_LINK:
            return {
                ...state,
                offers: [],
                promotionLinks: [...state.promotionLinks, ...action.payload],
                isLinkCreated: true,
                loading: false
            };
        case PARTNER_OFFER_ITEM_LOADING:
            return {
                ...state,
                isOfferItemLoading: true,
            };
        case PARTNER_OFFER_ITEM_LOADED:
            return {
                ...state,
                offerItem: action.payload,
                isOfferItemLoading: false,
            };
        case PROMOTION_LINKS_LOADING:
            return {
                ...state,
                isPromotionLinksLoading: true,
                promotionLinks: [],
            };
        case PROMOTION_LINKS_LOADED:
            return {
                ...state,
                isPromotionLinksLoading: false,
                promotionLinks: action.payload,
            };
        case PROMOTION_LINK_ENCRYPTING:
            return {
                ...state,
                isPromotionLinksLoading: true,
                encryptedOfferLink: null,
            };
        case PROMOTION_LINK_ENCRYPTED:
            return {
                ...state,
                isPromotionLinksLoading: false,
                encryptedOfferLink: action.payload,
            };
        default:
            return state;

    }
}