import React from "react";
import {URL_DASHBOARD, URL_PROFILE} from "../../../../properties";
import ActionPopover from "../../../common/popovers/ActionPopover";


export default function PartnerTablePartnerRow({rowData = {}, approveRegistration, rejectRegistration, addOrRemoveFromFavorites,
                                                   toggleCanOrderPayment, changeLevel, resendConfirmationEmail}) {
    const newLevelData = getNewLevelData(rowData.level);
    return (
        <tr>
            <td className={"partners_body_group right_position"}/>
            <td className={"partners_body_group right_position"}
                style={{display: "flex", color: "#616161", fontSize: "18px"}}>
                <a href={getPartnerHref(rowData.id)}><i className="fa fa-sign-in" title="Войти"/></a>
                <a href={getPartnerProfileHref(rowData.id)}><i className="ti ti-user" style={{fontSize: "16px"}}
                                                               title="Перейти на страницу редактирования профиля"/></a>
                <ActionPopover
                    button={<i className={getPartnerFavoriteClass(rowData.isFavorite)} style={{cursor: "pointer",  color: rowData.isFavorite && "#f15a24"}}/>}
                    tooltip={rowData.isFavorite ? "Добавить в избранные" : "Убрать из избранных"}
                    popoverLocation='right'
                    label={rowData.isFavorite
                        ? "Убрать из избранных партнера '" + rowData.partnerName + "' ?"
                        : "Добавить в избранные партнера '" + rowData.partnerName + "' ?"}
                    handleSubmit={() => addOrRemoveFromFavorites(rowData.id)}/>
                <ActionPopover
                    button={<i className="ti ti-wallet" style={{
                        cursor: "pointer",
                        color: rowData.canOrderPayment && "#f15a24",
                        fontSize: "16px"
                    }}/>}
                    tooltip={rowData.canOrderPayment ? "Деактивировать заказ выплат" : "Активировать заказ выплат"}
                    popoverLocation='right'
                    label={rowData.canOrderPayment
                        ? "Деактивировать возможность заказа выплат партнеру '" + rowData.partnerName + "' ?"
                        : "Активировать возможность заказа выплат партнеру '" + rowData.partnerName + "' ?"}
                    handleSubmit={() => toggleCanOrderPayment(rowData.id)}/>
                {rowData.registrationStatus === "EMAIL_CONFIRMED" &&
                <ActionPopover
                    button={<i className={getApproveRegistrationClass()}
                               style={{cursor: "pointer", fontSize: "18px"}}/>}
                    tooltip="Подтвердить регистрацию"
                    popoverLocation='right'
                    label="Подтвердить регистрацию?"
                    handleSubmit={() => approveRegistration(rowData.id)}/>}
                {rowData.registrationStatus === "EMAIL_CONFIRMED" &&
                <ActionPopover
                    button={<i className={getRejectRegistrationClass()}
                               style={{cursor: "pointer", fontSize: "18px"}}/>}
                    tooltip="Отклонить регистрацию"
                    popoverLocation='right'
                    label="Отклонить регистрацию?"
                    handleSubmit={() => rejectRegistration(rowData.id)}/>}
                {rowData.registrationStatus === "NEW" &&
                <ActionPopover
                    button={<i className={getSendEmailClass()}
                               style={{cursor: "pointer", fontSize: "18px"}}/>}
                    tooltip="Отправить email для подтверждения регистрации"
                    popoverLocation='right'
                    label="Отправить email для подтверждения регистрации?"
                    handleSubmit={() => resendConfirmationEmail(rowData)}/>}
            </td>
            <td className={"partners_body_group left_position"}>{(rowData.partnerName)}</td>
            <td className={"partners_body_group left_position"}>
                <div style={{display: "flex"}}>
                    <ActionPopover
                        button={<i className={newLevelData.iconClassName}
                                   style={{cursor: "pointer", fontSize: "18px"}}/>}
                        tooltip={newLevelData.label}
                        popoverLocation='right'
                        label={newLevelData.label + "?"}
                        handleSubmit={() => changeLevel(rowData.id, newLevelData.nextLevel)}/>
                    <span>
                        {rowData.levelRus}
                    </span>
                </div>
            </td>
            <td className={"partners_body_group left_position"}>{(rowData.name)}</td>
            <td className={"partners_body_group left_position"}>
                <i className={getRegisterStatusClass(rowData.registrationStatus)}
                   style={{fontSize: "14px", display: "inline-table"}}/>
                {(rowData.registrationDate)}
            </td>
            <td className={"partners_body_group left_position"}>{(rowData.skype)}</td>
            <td className={"partners_body_group left_position"}>{(rowData.telegram)}</td>
            <td className={"partners_body_group left_position"}>{(rowData.phone)}</td>
            <td className={"partners_body_group left_position"}>{(rowData.ref)}</td>
        </tr>
    )
};

const getPartnerHref = (partnerId) => {
    return URL_DASHBOARD + "?partnerId=" + partnerId;
};

const getPartnerProfileHref = (partnerId) => {
    return URL_PROFILE + "?partnerId=" + partnerId;
};

const getPartnerFavoriteClass = (isFavorite) => {
    return isFavorite ? "fa fa-star" : "fa fa-star-o"
};


const getRegisterStatusClass = (status) => {
    if (!status) {
        return "";
    }
    switch (status) {
        case "REGISTERED":
            return "fa fa-user";
        case "NEW":
            return "fa fa-user-plus";
        case "EMAIL_CONFIRMED":
            return "fa fa-envelope-open";
        default:
            return "fa fa-user-times";
    }
};

const getApproveRegistrationClass = () => {
    return "fa fa-check";
};

const getRejectRegistrationClass = () => {
    return "fa fa-times";
};

const getSendEmailClass = () => {
    return "fa fa-envelope-o";
};

const getNewLevelData = (currentLevel) => {
    switch (currentLevel) {
        case "NOVICE":
            return {iconClassName: "fa fa-level-up", label: "Повысить до 'Средний'", nextLevel: "MIDDLE"};
        case "MIDDLE":
            return {iconClassName: "fa fa-level-up", label: "Повысить до 'Топ'",  nextLevel: "TOP"};
        default:
            return {iconClassName: "fa fa-level-down", label: "Понизить до 'Средний'",  nextLevel: "MIDDLE"};
    }
};