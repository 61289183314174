import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import LeftSideBarLink from "./LeftSideBarLink";
import orangeLogoImg from "../../../resources/images/orange-logo.svg";
import logoTextImg from "../../../resources/images/invert-affimob.svg";

import {getFromLocalStorage, setToLocalStorage} from "../../../utils/localStorageUtils";
import {
    LOCAL_STORAGE_IS_MENU_OPEN,
} from "../../../utils/constants";
import {isScreenWidthLessThen} from "../../../utils/displayUtils";

const STORAGE_PERIOD_IN_HOURS = 365 * 24;

export default function LeftSideBar({getLinksData}) {

    const isOpen = isScreenWidthLessThen(891)
        ? true
        : getFromLocalStorage(LOCAL_STORAGE_IS_MENU_OPEN)
            ? getFromLocalStorage(LOCAL_STORAGE_IS_MENU_OPEN).value
            : true;

    const [value, setValue] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(isOpen);
    const history = useHistory();
    let location = useLocation();

    useEffect(() => {
        setValue(location.pathname);
        document.addEventListener("click", handleClickNavLink);
        return () => {
            document.removeEventListener("click", handleClickNavLink);
        };
    }, []);


    const handleClickNavLink = e => {
        let href;
        if (e.target.tagName === 'A' && (e.target.id.includes("leftSideNavBarIcon") || e.target.id.includes("leftSideNavBarTxt"))) {
            e.preventDefault();
            href = e.target.getAttribute('href');
            setValue(href);
            history.push(href);
        } else if (e.target.parentElement && e.target.parentElement.tagName === 'A'
            && (e.target.parentElement.id.includes("leftSideNavBarIcon") || e.target.parentElement.id.includes("leftSideNavBarTxt"))) {
            e.preventDefault();
            href = e.target.parentElement.getAttribute('href');
            setValue(href);
            history.push(href);
        }
    };

    const toggleOpenMenu = () => {
        changeOpenMenu(!isMenuOpen)
    };

    const changeOpenMenu = (isOpen) => {
        setIsMenuOpen(isOpen);
        const localStorageData = {value: isOpen};
        setToLocalStorage(LOCAL_STORAGE_IS_MENU_OPEN, localStorageData, STORAGE_PERIOD_IN_HOURS);
    };


return (
    <div className={"main-menu" + (isMenuOpen ? " open" : "")} id="main-menu">
        <div className="button-menu" onClick={toggleOpenMenu}>
            <div className={(isMenuOpen ? "arrow-left" : "arrow-right") + " icon-menu"}/>
        </div>
        <div className={"title" + (isMenuOpen ? " open" : "")}>
            <img src={orangeLogoImg} alt="" className="logo-icon"/>
            <img src={logoTextImg} alt="" className="logo-text"/>
        </div>
        <div className="nav-items" id="nav-items">
            {getLinksData && getLinksData().map((link) =>
                <LeftSideBarLink key={link.key} {...link} currentHref={value} isMenuOpen={isMenuOpen} href={link.href}/>)}
        </div>

    </div>
)
}


