import React, {Component, Fragment} from 'react';
import Spinner from "../../common/Spinner";
import {connect} from "react-redux";
import {deleteSmartLink, loadPartnerSmartLink, loadPartnerSmartLinks} from "../../../actions/partnerSmartLinkActions";
import SmartLinksModal from "./modal/SmartLinksModal";
import NoContentMessage from "../../common/NoContentMessage";
import {showWarning} from "../../../actions/warningActions";
import SimpleTable from "../../common/tables/SimpleTable";
import PropTypes from "prop-types";
import {COUNTRIES, OFFER_CATEGORIES, OPERATORS, SMARTLINKS} from "../../../utils/constants";
import PartnerSmartLinkFilters from "./filters/smartlinks/PartnerSmartLinkFilters";
import PageContent from "../../common/page/PageContent";
import {loadFiltersData} from "../../../actions/filterDataActions";
import OperatorImage from "../../common/images/OperatorImage";
import CountryImage from "../../common/images/CountryImage";


const RENDERED_ROWS = 20;

class SmartLinksContent extends Component {

    static propTypes = {
        tabs: PropTypes.object,
        selectNavTab: PropTypes.func,
        displayedComponent: PropTypes.oneOf([SMARTLINKS]),
        searchInputValue: PropTypes.string,
        filters: PropTypes.object,
        resetAllFilters: PropTypes.func,
        onSelectFilterValue: PropTypes.func,
        filteredSmartLinks: PropTypes.array,
        filterSmartLinks: PropTypes.func,
        needToFilter: PropTypes.bool,
        onShowEditSmartLinkComponent: PropTypes.func,
        searchInputAmount: PropTypes.any,
        onChangeSearchInput: PropTypes.func,
    };

    state = {
        smartLinksForRendering: [],
        isOpenedModal: false,
        smartLink: null
    };

    componentDidMount() {
        this.props.loadPartnerSmartLinks();
        this.props.loadFiltersData([COUNTRIES, OFFER_CATEGORIES, OPERATORS]);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const smartLinks = this.props.smartLinks;
        if ((smartLinks && (smartLinks !== prevProps.smartLinks || (prevProps.smartLinks && prevProps.smartLinks.length !== smartLinks.length) || this.props.needToFilter))
            || (this.props.searchInputValue !== prevProps.searchInputValue)) {
            this.filterSmartLinks();
        }
    }

    filterSmartLinks = () => {
        const filteredSmartLinks = this.props.filterSmartLinks(this.props.smartLinks);
        this.setState({
            smartLinksForRendering: filteredSmartLinks && filteredSmartLinks.length >= RENDERED_ROWS
                ? filteredSmartLinks.slice(0, RENDERED_ROWS)
                : filteredSmartLinks,
        })
    };

    handleScroll = (srcElement) => {
        if (srcElement.clientHeight + srcElement.scrollTop < (srcElement.scrollHeight - 200)) {
            return;
        }
        let endIdx = this.state.smartLinksForRendering.length + RENDERED_ROWS;
        if (!this.props.filteredSmartLinks[endIdx]) {
            endIdx = this.props.filteredSmartLinks.length;
        }
        this.setState({
            smartLinksForRendering: this.props.filteredSmartLinks.slice(0, endIdx)

        });
    };

    deleteSmartLink = (smartLink) => {
        this.props.showWarning("Удалить смартлинк?", () => this.props.deleteSmartLink(smartLink));
    };

    editSmartLink = (smartLink) => {
        this.props.onShowEditSmartLinkComponent(smartLink.id)
    };

    handleClickLink = (smartLink) => {
        this.setState({
            isOpenedModal: true,
            smartLink: smartLink
        })
    };


    handleCloseModal = () => {
        this.setState({
            isOpenedModal: false,
            smartLink: null
        })
    };

    tableColumns = () => {
        return [
            {
                name: "Название смартлинка",
                key: "name",
                getBodyCellValue: smartLink => smartLink.id + ' | ' + smartLink.name,
            },
            {
                name: "Тематика",
                key: "theme",
                getBodyCellValue: smartLink => smartLink.category.name,
            },
            {
                name: "ОСС",
                key: "oss",
                getBodyCellValue: smartLink => smartLink.operatorImgFileNames?.map(fileName =>
                    <Fragment key={fileName}><OperatorImage fileName={fileName}/></Fragment>),
                bodyCellClassName: "td-operator inline"
            },
            {
                name: "Страны",
                key: "countries",
                getBodyCellValue: smartLink => smartLink.countryImgFileNames?.map(fileName =>
                    <Fragment key={fileName}><CountryImage fileName={fileName}/></Fragment>),
                bodyCellClassName: "td-operator inline"
            },
            {
                name: <i className="ti ti-layers-alt" style={{fontSize: '16px'}} title="Количество офферов"/>,
                key: "amount",
                getBodyCellValue: smartLink => smartLink.offerAmount,
            },
            {
                name: "",
                key: "buttons",
                getBodyCellValue: smartLink =>
                    <>
                        <i className="ti ti-link" style={{"color": "#007bff"}}
                           onClick={() => this.handleClickLink(smartLink)}/>
                        <i className="ti ti-pencil" style={{"color": "#414455"}} title="Редактировать"
                           onClick={() => this.editSmartLink(smartLink)}/>
                        {smartLink.status !== 'GLOBAL' &&
                        <i className="ti ti-close" style={{"color": "#414455"}} title="Удалить"
                           onClick={() => this.deleteSmartLink(smartLink)}/>
                        }
                    </>,
                headerCellStyle: {minWidth: "80px", width: "80px"}
            },
        ]
    };


    render() {
        const {isLoading} = this.props;
        const smartLinks = this.state.smartLinksForRendering;

        return (
            <PageContent tabs={this.props.tabs}
                         shouldUseSize={false}
                         selectedTabName={this.props.selectedNavTab}
                         onSelectTab={this.props.selectNavTab}
                         onScroll={this.handleScroll}
                         displayContentHeader
                         displaySearchInput
                         onChangeSearchInput={this.props.onChangeSearchInput}
                         searchInputAmount={this.props.searchInputAmount}
                         searchInputValue = {this.props.searchInputValue}
                         beforeSearchInputAmount={this.props.beforeSearchInputAmount}
                         displayFiltersBtn
                         displayIpRangesBtn
                         filtersEl={
                                 <PartnerSmartLinkFilters
                                     smartLinks={this.props.smartLinks}
                                     filters={this.props.filters}
                                     onSelectFilterValue={this.props.onSelectFilterValue}
                                     resetAllFilters={this.props.resetAllFilters}
                                 />
                             }
                         contentEl={
                                 <div className="custom-tab">
                                     <div className="table-responsive" id="hiden-offer-table">
                                         {
                                             isLoading ? <Spinner/>
                                                 :
                                                 <div className="responsive-block" id="fullWidth"
                                                      style={{overflowX: 'auto', overflowY: 'hidden'}}>
                                                     {smartLinks && smartLinks.length > 0
                                                         ? <SimpleTable tableClassName="table simple-table align-left no-wrap table-sm userselecttext"
                                                                        columns={this.tableColumns()} data={this.state.smartLinksForRendering} bodyRowKey="id"/>
                                                         : <NoContentMessage/>}
                                                 </div>
                                         }
                                         <SmartLinksModal isOpened={this.state.isOpenedModal} smartLink={this.state.smartLink}
                                                          onCloseModal={this.handleCloseModal}
                                                          domains={this.props.domains}/>
                                     </div>
                                 </div>
                             }
            >
            </PageContent>
        );
    }
}


function mapStateToProps(state) {
    return {
        smartLinks: state.partnerSmartLinks.smartLinks,
        isLoading: state.partnerSmartLinks.loading,
        domains: state.partnerDomainsReducer.partnerDomains,
    }
}

export default connect(mapStateToProps, {
    deleteSmartLink,
    loadPartnerSmartLinks,
    loadPartnerSmartLink,
    showWarning,
    loadFiltersData
})(SmartLinksContent);