import React, {Fragment} from "react";

export default function HiddenFromNovicesBtnBlock({selectedValue, onSelect}) {

    const hiddenFromNovicesBtns = [
        {label: "Да", value: true},
        {label: "Нет", value: false},
        {label: "Без изменений"},
    ];

        return (
            <Fragment>
                <label htmlFor="RadioOfferHide" className="float-left" style={{width: "100%"}}>Скрыть от новичков</label>
                <div className="btn-group btn-group-toggle" data-toggle="buttons" id="RadioOfferHide">
                    {hiddenFromNovicesBtns.map(btn =>
                        <label key={btn.label} className={selectedValue.label === btn.label ? "btn btn-secondary active" : "btn btn-secondary"}
                               onClick={() =>onSelect(btn)}>
                            <input type="radio" name="options" id="option3" autoComplete="off"/> {btn.label}
                        </label>)}
                </div>
            </Fragment>
        );


}