import React from "react";

export default function StatisticActionPopup({amount, iconEl, txt = "Построить график", onClick}) {

    return (
        amount && amount > 0
            ? <div className="smart-create">
                <div className="text">
                    {txt}
                    <span>{amount}</span>
                </div>
                <div className="create-link" onClick={onClick && onClick}>
                    {iconEl}
                </div>
            </div>
            : <></>
    )
}