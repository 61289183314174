import {useEffect, useState} from "react";
import {searchByString} from "../utils/searchUtils";


const useDelayedSearch = (searchString, data, searchFields, timeoutInMs) => {

    const [result, setResult] = useState(null);
    const [timeoutState, setTimeoutState] = useState({
        timeout: 0
    });

    useEffect(() => {
        if (timeoutState.timeout) {
            clearTimeout(timeoutState.timeout);
        }
        setTimeoutState({
            timeout: setTimeout(() => {
                setResult(searchByString(data, searchFields, searchString));
            }, timeoutInMs)
        });
    }, [searchString, data]);

    return result;

};

export default useDelayedSearch;