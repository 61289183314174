import {FILE_UPLOADED, ALL_FILES_DATA_LOADED, FILE_DELETED} from "../../actions/actionTypes";

const initialState = {
    savedFile: {},
    allFiles: null,
};

export default function(state = initialState, action) {
    switch(action.type) {
        case FILE_UPLOADED:
            return {
                ...state,
                savedFile: action.payload
            };
        case FILE_DELETED:
            return {
                ...state,
            };
        case ALL_FILES_DATA_LOADED:
            return {
                ...state,
                allFiles: action.payload,
            };
        default:
            return state;
    }
}