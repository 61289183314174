import {combineReducers} from "redux";
import dashboardReducer from './dashboardReducer';
import activeOffers from './offersReducer';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import partnerBalanceReducer from './partnerBalanceReducer';
import statisticReducer from './statisticReducer';
import successReducer from "./successReducer";
import presetReducer from "./presetReducer";
import partnerOfferReducer from "./partnerOfferReducer";
import partnerSmartLinkReducer from "./partnerSmartLinkReducer";
import projectsReducer from "./projectsReducer";
import warningReducer from "./warningReducer";
import userReducer from "./userReducer";
import managerPartnersReducer from "./managerPartnersReducer";
import paymentsReducer from "./paymentsReducer";
import serviceGroupsReducer from "./serviceGroupsReducer";
import partnerDomainsReducer from "./partnerDomainsReducer";
import managerOffersReducer from "./managerOffersReducer";
import subscriptionServiceReducer from "./subscriptionServiceReducer";
import retentionEventsReducer from "./retentionEventsReducer";
import chatReducer from "./chatReducer";
import newsReducer from "./newsReducer";
import fileReducer from "./fileReducer";
import pageContentReducer from "./pageContentReducer";
import ipRangeReducer from "./ipRangeReducer";
import blackListReducer from "./blackListReducer";
import referrerReducer from "./referrerReducer";
import currencyRateReducer from "./currencyRateReducer";
import capNamesReducer from "./capNamesReducer";
import capReducer from "./capReducer";
import advertisingNetworksReducer from "./advertisingNetworksReducer";
import ltvServiceTypesReducer from "./ltvServiceTypesReducer";
import rosLinesReducer from "./rosLinesReducer";
import postbackResendingReducer from "./postbackResendingReducer";
import postbackTestReducer from "./postbackTestReducer";
import offerBuyoutRuleReducer from "./offerBuyoutRuleReducer";
import navigationReducer from "./navigationReducer";
import bannerReducer from "./bannerReducer";
import offerIncomeReducer from "./offerIncomeReducer";
import logsReducer from "./logsReducer";
import appParamsReducer from "./appParamsReducer";
import filterDataReducer from "./filterDataReducer";
import operatorsReducer from "./operatorsReducer";
import countriesReducer from "./countriesReducer";

export default combineReducers(
    {
            activeOffers: activeOffers,
            advertisingNetworksReducer,
            appParamsReducer,
            auth: authReducer,
            bannerReducer,
            blackListReducer,
            capReducer,
            capNamesReducer,
            chatReducer,
            countriesReducer,
            currencyRateReducer,
            dashboardReducer,
            error: errorReducer,
            fileReducer,
            filterDataReducer,
            ipRangeReducer,
            logsReducer,
            managerPartnersReducer,
            managerOffersReducer,
            navigationReducer,
            newsReducer,
            offerBuyoutRuleReducer,
            offerIncomeReducer,
            operatorsReducer,
            pageContentReducer,
            partnerBalanceReducer,
            partnerDomainsReducer,
            partnerOffers: partnerOfferReducer,
            partnerSmartLinks: partnerSmartLinkReducer,
            paymentsReducer,
            preset: presetReducer,
            projects: projectsReducer,
            postbackResendingReducer,
            postbackTestReducer,
            retentionEventsReducer,
            referrerReducer,
            rosLinesReducer,
            statisticReducer,
            success: successReducer,
            serviceGroupsReducer,
            ltvServiceTypesReducer,
            subscriptionServiceReducer,
            userReducer,
            warning: warningReducer
    }
);