import React from "react";
import axios from "axios";
import {getConfig} from "./authActions";
import {getSuccess} from "./successActions";
import {returnErrors} from "./errorActions";
import {
    OFFER_INCOME_DELETED,
    OFFER_INCOMES_SAVED,
    OFFER_INCOMES_LOADED,
    OFFER_INCOMES_LOADING, OFFER_WITH_CHILDREN_INCOMES_LOADED, OFFER_WITH_CHILDREN_INCOMES_LOADING
} from "./actionTypes";

export const saveIncomes = (body, needToNotifyPartners, needToRecalculateStatistic, callbackFunction) => (dispatch, getState) => {
    let resp = {success: "Выплаты сохранены"};
    const url  = process.env.REACT_APP_API_URL + '/incomes?needToNotifyPartners=' + needToNotifyPartners + "&needToRecalculateStatistic=" + needToRecalculateStatistic;
    axios
        .post(url, body, getConfig(getState))
        .then(res => {
                resp = {...resp, success:  <div dangerouslySetInnerHTML={{__html: res.data?.success}} />};
                dispatch({
                    type: OFFER_INCOMES_SAVED,
                    payload: resp
                });
            }
        )
        .then(() => callbackFunction && callbackFunction())
        .then(() => {dispatch(getSuccess(resp))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};


export const deleteIncome = (id, callbackFunction) => (dispatch, getState) => {
    let resp = {success: "Выплата удалена"};
    axios
        .delete(process.env.REACT_APP_API_URL + '/incomes/' + id, getConfig(getState))
        .then(res => {
            resp = {...resp, success:  <div dangerouslySetInnerHTML={{__html: res.data?.success}} />};
                dispatch({
                    type: OFFER_INCOME_DELETED,
                })
            }
        )
        .then(() => callbackFunction && callbackFunction())
        .then(() => {dispatch(getSuccess(resp))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadWithChildrenOfferIncomes = (offerIds, onlyCurrent) => (dispatch, getState) => {
    dispatch({type: OFFER_WITH_CHILDREN_INCOMES_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/incomes?offerIds=' + offerIds + "&fetchChildrenIncomes=true"
            + (onlyCurrent ? "&isOnlyCurrent=true" : ""), getConfig(getState))
        .then(res => {
                dispatch({
                    type: OFFER_WITH_CHILDREN_INCOMES_LOADED,
                    payload: res.data
                })
            }
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadOfferIncomes = (offerIds, onlyCurrent, callbackFunction) => (dispatch, getState) => {
    dispatch({type: OFFER_INCOMES_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/incomes?offerIds=' + offerIds + (onlyCurrent ? "&isOnlyCurrent=true" : ""), getConfig(getState))
        .then(res => {
                dispatch({
                    type: OFFER_INCOMES_LOADED,
                    payload: res.data
                })
            }
        )
        .then(() => callbackFunction && callbackFunction())
        .catch(err => {
            dispatch(returnErrors(err));
        });
};