import React, {useEffect, useState} from "react";
import ExportToXlsx from "../../../common/xlsx/ExportToXlsx";


const columns = [
    {title: "Страна", width: {wch: 25}, height: {wpx: 14}},
    {title: "Оператор", width: {wch: 25}, height: {wpx: 14}},
    {title: "Диапазон", width: {wch: 100}, height: {wpx: 14}},
];

export default function IpRangesXlsxLink({ranges, fileNamePrefix="ip-ranges-all-"}) {

    const [dataSet, setDataSet] = useState([{columns: columns, data: []}]);
    const [fileName, setFileName] = useState("");

    useEffect(() => {
        const date = new Date();
        const xlsxData = ranges && ranges.map((row) => {
            return [
                {value: row.countryName, style: {font: {sz: "11", bold: false}, alignment: {horizontal: "left"}}},
                {value: row.operatorName, style: {font: {sz: "11", bold: false}, alignment: {horizontal: "left"}}},
                {value: row.range, style: {font: {sz: "11", bold: false}, alignment: {horizontal: "left"}}},
            ];
        });
        setDataSet([{columns: columns, data: xlsxData}]);
        const fileName = fileNamePrefix + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + "-" + date.getHours() + date.getMinutes();
        setFileName(fileName);
    }, [ranges, fileNamePrefix]);

    return (
        <ExportToXlsx button={<a href="#" style={{whiteSpace: "nowrap"}}> Скачать</a>}
                      dataSet={dataSet} name={fileName} listName="Диапазоны IP"/>
    )
}