import React from "react";
import ProfileSimpleInput from "./inputs/ProfileSimpleInput";
import IosCheckbox from "../../common/checkboxes/IosCheckbox";
import IconPopover from "../../common/popovers/IconPopver";

export default function ProfileNotificationCard({notificationData, notificationPeriods, onChangeNotificationData, onSendTestNotification}) {

    const sendTestNotification = () => {
        onSendTestNotification();
    };

    return (
        <div className="col-12 col-lg-6 padding5">
            <div className="card">
                <div className="card-header padding10">Уведомления &nbsp;
                    <div style={{position: "absolute", right: "60px"}}>
                    <IconPopover
                        displayedContent={"Для отправки уведомлений необходимо в свой чат добавить бота " + process.env.REACT_APP_PARTNER_NOTIFICATION_BOT_NAME}
                        placement="top" iconClassName="ti ti-help-alt" target={"PopoverHelpIcon1"}/>
                    </div>
                    <button className="btn btn-outline-secondary btn-sm" style={{position: "absolute", right: "10px"}}
                            onClick={sendTestNotification}>
                        Тест
                    </button>

                </div>
                <div className="profile-card-data padding15">
                    <div id="for-user">
                        <ProfileSimpleInput inputName="telegramChatId" label="ID Telegram чата"
                                            value={notificationData ? notificationData.telegramChatId : ""}
                                            placeholder="Введите ID Telegram чата для отправки уведомлений" type="text"
                                            onTextTyping={(e) => onChangeNotificationData("telegramChatId", e.target.value)}/>
                    </div>
                    <div className="checkboxes">
                        <div className="custom-mselect margin5">
                            <IosCheckbox label='Новости' isChecked={notificationData.isSubscribedToNews}
                                         onClickFunction={() => onChangeNotificationData("isSubscribedToNews", !notificationData.isSubscribedToNews)}/>
                        </div>
                        <div className="custom-mselect margin5">
                            <IosCheckbox label='Капа' isChecked={notificationData.isSubscribedToCaps}
                                         onClickFunction={() => onChangeNotificationData("isSubscribedToCaps", !notificationData.isSubscribedToCaps)}/>
                        </div>
                        <div className="custom-mselect margin5">
                            <IosCheckbox label='Статистика' isChecked={notificationData.isSubscribedToStatistic}
                                         onClickFunction={() => onChangeNotificationData("isSubscribedToStatistic", !notificationData.isSubscribedToStatistic)}/>
                        </div>
                    </div>
                    <br/>
                    <div className="form-group">
                        <label className="form-control-label">Периодичность отправки статистики</label>
                        <div className="input-group">
                            <select name="selectSm" id="selectSm" className="form-control-sm form-control" value={notificationData.statistiсNotificationPeriod}
                                    onChange={(e) => onChangeNotificationData("statistiсNotificationPeriod", e.target.value)}>
                                    {
                                        notificationPeriods && notificationPeriods.map((notificationPeriod) =>
                                        <option key={notificationPeriod.label} value={notificationPeriod.periodInHours}>{notificationPeriod.label}</option>
                                        )
                                    }
                            </select>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}