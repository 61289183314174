import {BANNER_AD_NETWORK, BANNER_TRACKER} from "./constants";

export const bannerTypesData = [
    {type: BANNER_AD_NETWORK, name: "Рекламная сеть"},
    {type: BANNER_TRACKER, name: "Трекинг"}
];

export const getBannerName = (type) => {
    if (!type) {
        return null;
    }
    return bannerTypesData.find(item => item.type === type)?.name;
}