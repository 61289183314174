import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Select from 'react-select';
import {emphasize, makeStyles, useTheme} from '@material-ui/core/styles';
import {Avatar, Chip, Icon, InputAdornment, MenuItem, NoSsr, Paper, TextField, Typography} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import useOutsideClick from "../../../hooks/useOutsideClick";
import {addOrRemoveAndReturnNewArray} from "../../../utils/arrayUtils";
import {List} from "react-virtualized";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: props => props.root && props.root.flexGrow ? props.root.flexGrow : 1,
        minWidth: props => props.root && props.root.minWidth ? props.root.minWidth : 200,
        padding: props => props.root && props.root.padding ? props.root.padding : 10,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
        backgroundColor: props => props.input && props.input.backgroundColor,
        border: props => props.input && props.input.border,
        borderRadius: props => props.input && props.input.borderRadius,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
        display: 'flex',
        fontSize: 14,
        maxWidth: props => props.chip && props.chip.maxWidth,
    },
    chipLabel: {
        whiteSpace: props => props.chip && props.chip.maxWidth && 'nowrap !important',
        textOverflow: props => props.chip && props.chip.maxWidth && 'ellipsis',
        overflow: props => props.chip && props.chip.maxWidth && 'hidden',
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    groupChip: {
        backgroundColor: 'rgba(176,176,176,0.23)',
        textTransform: 'uppercase',
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
        fontSize: 14
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 12
    },
    paper: {
        position: 'absolute',
        zIndex: 10,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',

    },
    divider: {
        height: theme.spacing(2),
    },
}));

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}>Пусто</Typography>
    );
}

NoOptionsMessage.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * Props to be passed on to the wrapper.
     */
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function inputComponent({inputRef, ...props}) {
    return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
};

function Control(props) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: {classes, TextFieldProps, EndAdornmentProps}
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                endAdornment: EndAdornmentProps.endAdornment ? (
                    <InputAdornment position="end">{EndAdornmentProps.endAdornment}</InputAdornment>) : null,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,

                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}

Control.propTypes = {
    /**
     * Children to render.
     */
    children: PropTypes.node,
    /**
     * The mouse down event and the innerRef to pass down to the controller element.
     */
    innerProps: PropTypes.shape({
        onMouseDown: PropTypes.func.isRequired,
    }).isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]).isRequired,
    selectProps: PropTypes.object.isRequired,
};

function Option(props) {
    delete props.innerProps.onMouseMove;
    delete props.innerProps.onMouseOver;
    const icon = props.data.icon ? <span style={{margin: 5}}>{props.data.icon}</span> : null;
    const img = props.data.img
        ?
        <Icon style={{width: '20px', height: 'auto', margin: 5}}>
            <Avatar style={{width: '20px', height: 'auto'}} src={props.data.img}/>
        </Icon>
        : null;

    const item = props.data.isGroup ? props.selectProps.GroupProps.groupItem(props.data) : props.children;
    if (props.data.isGroup) {
        delete props.innerProps.onClick;
    }
    return (
        <MenuItem
            ref={props.innerRef}
            selected={false}
            component="div"
            style={{
                fontWeight: props.isSelected ? 700 : 400,
                fontSize: 14,
                minHeight: '20px',
                height: '30px',
                padding: 5
            }}
            {...props.innerProps}
        >
            <span>{icon}{img}{item}</span>
        </MenuItem>
    );
}

Option.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.shape({
        id: PropTypes.string.isRequired,
        key: PropTypes.string,
        onClick: PropTypes.func,
        tabIndex: PropTypes.number.isRequired,
    }).isRequired,
    /**
     * Inner ref to DOM Node
     */
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
    /**
     * Whether the option is focused.
     */
    isFocused: PropTypes.bool.isRequired,
    /**
     * Whether the option is selected.
     */
    isSelected: PropTypes.bool.isRequired,
};

function Placeholder(props) {
    const {selectProps, innerProps = {}, children} = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

Placeholder.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
    const img = props.data.img;
    const icon = props.data.icon;
    const label = props.selectProps.classes.chipLabel
        ? <div className={props.selectProps.classes.chipLabel}>{props.children}</div>
        : props.children;
    return (
        <Chip
            icon={icon}
            avatar={img && <Avatar src={img}/>}
            size={'medium'}
            tabIndex={-1}
            label={label}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
                [props.selectProps.classes.groupChip]: props.data.isGroup,
            })}
            variant="outlined"
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

MultiValue.propTypes = {
    children: PropTypes.node,
    isFocused: PropTypes.bool.isRequired,
    removeProps: PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        onMouseDown: PropTypes.func.isRequired,
        onTouchEnd: PropTypes.func.isRequired,
    }).isRequired,
    selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

Menu.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.element.isRequired,
    /**
     * Props to be passed to the menu wrapper.
     */
    innerProps: PropTypes.object.isRequired,
    selectProps: PropTypes.object.isRequired,
};

function MenuList(props) {
    const {children, maxHeight} = props;
    const itemSize = 35;
    const totalListHeight = children.length * itemSize;
    const menuHeight = !totalListHeight
        ? 1
        : totalListHeight < maxHeight
            ? totalListHeight
            : maxHeight;
    return (
        <List
            height={menuHeight}
            rowCount={children.length ? children.length : 0}
            rowHeight={itemSize}
            width={2000}
            style={{width: '100%', maxWidth: "100%"}}
            rowRenderer={({index, style}) => <div
                key={children[index] && children[index].props && "" + children[index].props.value}
                style={style}>{children[index]}</div>}
        />
    )
}

const components = {
    Control,
    Menu,
    MenuList,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    ValueContainer,
};

export default function MaterialUIGroupedMultiSelect(props) {
    const {
        dropdownData, dataField, displayedField, groupDisplayedField, groupDataField, label, placeholder,
        endElements, isLoading, isSearchable, maxChipTxtWidth, onSelect, isGroupSelectionDisabled
    } = props;
    let {values} = props;
    const classes = useStyles(props.styles);
    const theme = useTheme();
    const ref = useRef();
    const [dropdown, setDropDown] = useState([]);
    const [isOpened, setOpened] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [openedGroups, setOpenedGroups] = useState([]);
    const [selectedValuesMap, setSelectedValuesMap] = useState({});
    const [timeoutState, setTimeoutState] = useState({timeout: 0});
    let maxHeight = props.styles?.paper?.maxHeight ? props.styles.paper.maxHeight : 250;

    useEffect(() => {
        if ((!values || values.length === 0) && Object.keys(selectedValuesMap).length !== 0) {
            handleSelectOrDeleteItem([]);
        }
    }, [values, selectedValuesMap]);

    useEffect(() => {

        const filterDropDownBySearchInput = (dropdownData, searchValue) => {
            const newDropDown = [];
            dropdownData.forEach(group => {
                if (!group.options && group[groupDisplayedField].toLowerCase().includes(searchValue)) {
                    newDropDown.push(group);
                } else {
                    const clonedGroup = Object.assign({}, group);
                    clonedGroup.options = group.options && group.options.filter(item => item[displayedField].toLowerCase().includes(searchValue));
                    const selectedGroupValues = selectedValuesMap[group[groupDataField]];
                    if (clonedGroup.options && clonedGroup.options.length > 0 &&
                        (!selectedGroupValues || (clonedGroup.options.length === 1 && !selectedGroupValues.find(selectedItem => selectedItem[dataField] === clonedGroup.options[0][dataField])))) {
                        newDropDown.push(clonedGroup);
                    }
                }

            });
            return newDropDown;
        };

        const getDropDown = (dropdownData) => {
            dropdownData = dropdownData.filter(group =>
                !selectedValuesMap[group[groupDataField]]
                || selectedValuesMap[group[groupDataField]][0].isGroupWithoutOptions
                || selectedValuesMap[group[groupDataField]].length !== group.options.length);
            if (inputValue && inputValue !== '') {
                const searchValue = inputValue.toLowerCase();
                dropdownData = filterDropDownBySearchInput(dropdownData, searchValue);
            }
            const result = [];
            if (!dropdownData) {
                return result;
            }
            dropdownData.forEach(item => {
                const group = {};
                group[dataField] = item[groupDataField];
                group[groupDisplayedField] = item[groupDisplayedField];
                group[groupDataField] = item[groupDataField];
                if (item.options && item.options.length > 0) {
                    group.isGroup = true;
                    group.nestedItems = item.options;
                } else {
                    group.isGroupWithoutOptions = true;
                }
                result.push(group);
                if (openedGroups.includes(group[dataField])) {
                    result.push(...item.options);
                }
            });
            return result;
        };

        setDropDown(getDropDown(dropdownData));
    }, [dropdownData, values, openedGroups, dataField, groupDataField, groupDisplayedField, selectedValuesMap, inputValue]);

    const rotateIconStyle = isOpen => {
        return {transform: isOpen ? 'rotate(90deg)' : 'none',}
    };

    const groupStyles = isOpen => {
        return isOpen
            ? {fontWeight: '600', color: '#616161'}
            : {};
    };

    const groupMenuItem = data => (
        <div style={{display: 'inline'}}>
            <span onClick={() => openGroup(data)} style={{
                paddingLeft: '5px', paddingRight: '12px', paddingTop: '10px',
                paddingBottom: '10px', cursor: "pointer"
            }}>
            <i className="fa fa-chevron-right statistic_table_icon"
               style={{
                   display: 'inline-table',
                   transition: 'all ease 200ms',
                   ...rotateIconStyle(openedGroups.includes(data[groupDataField])),
                   fontSize: '12px',
                   color: "#616161",
                   zIndex: "2"
               }}/></span>
            <span style={{
                cursor: "pointer", display: 'inline', paddingRight: '500px', fontSize: '14', textTransform: 'uppercase',
                ...groupStyles(openedGroups.includes(data[groupDataField]))
            }}
                  onClick={() => handleChangeSelectedItems(data)}>{data[groupDisplayedField]}</span>
        </div>
    );

    const openMenu = () => {
        setOpened(true);
    };

    const closeMenu = () => {
        setOpened(false);
        setInputValue("");
        setOpenedGroups([]);
    };

    const openGroup = (group) => {
        const opndGroups = addOrRemoveAndReturnNewArray(openedGroups, group[groupDataField]);
        setOpenedGroups(opndGroups);
    };


    const handleChangeSelectedItems = (data) => {
        data = data ? data : [];
        if (data[data.length - 1] && data[data.length - 1].isGroup && data[data.length - 1].nestedItems) {
            data[data.length - 1] = data[data.length - 1].nestedItems.find(item => !values.includes(item) && item[displayedField].toLowerCase().includes(inputValue.toLowerCase()));
        }
        if (data && data.isGroup) {
            handleSelectGroup(data);
        } else {
            handleSelectOrDeleteItem(data);
        }
    };

    const handleSelectGroup = (data) => {
        if (isGroupSelectionDisabled) {
            return;
        }
        values = values.slice(0);
        if (selectedValuesMap[data[groupDataField]]) {
            values = values.filter(value => value[groupDataField] !== data[groupDataField]);
        }
        const groupItem = {isGroup: true};
        groupItem[dataField] = data[groupDataField];
        groupItem[displayedField] = data[groupDisplayedField];
        groupItem[groupDataField] = data[groupDataField];
        groupItem[groupDisplayedField] = data[groupDisplayedField];
        selectedValuesMap[groupItem[groupDataField]] = data.nestedItems;
        values.push(groupItem);
        onSelect(values);
    };

    const handleSelectOrDeleteItem = (data) => {
        if (onSelect) {
            onSelect(data);
        }
        const newData = data.reduce((r, a) => {
            r[a[groupDataField]] = r[a[groupDataField]] || [];
            if (a.isGroup) {
                r[a[groupDataField]] = dropdownData.find(item => item[groupDataField] === a[groupDataField]).options;
            } else {
                r[a[groupDataField]].push(a);
            }
            return r;
        }, Object.create(null));
        setSelectedValuesMap(newData);
    };

    const handleInputChange = (value, e) => {
        if (e.action === 'input-change') {
            if (timeoutState.timeout) {
                clearTimeout(timeoutState.timeout);
            }
            closeAllGroups();
            setInputValue(value);
            setTimeoutState({
                timeout: setTimeout(() => {
                    if (value !== "") {
                        openAllGroups();
                    }
                }, 300)
            });
        }
    };

    const openAllGroups = () => {
        const openedGroups = dropdownData.map(item => item.options && item[groupDataField]);
        setOpenedGroups(openedGroups);
    };

    const closeAllGroups = () => {
        setOpenedGroups([]);
    };

    const onSearch = (option, searchText) => {
        return true;
    };

    useOutsideClick(ref, closeMenu);

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            paddingLeft: 0,
            paddingRight: 2,
            cursor: "pointer",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            paddingLeft: 0,
            paddingRight: 2,
            cursor: "pointer",
        }),
    };

    return (
        <div className={classes.root} ref={ref}>
            <NoSsr>
                <Select
                    ignoreAccents={false}
                    classes={classes}
                    styles={selectStyles}
                    isLoading={isLoading}
                    GroupProps={{
                        groupItem: groupMenuItem,
                    }}
                    EndAdornmentProps={{
                        endAdornment: endElements,
                    }}
                    TextFieldProps={{
                        label: label,
                        InputLabelProps: {
                            htmlFor: 'react-select-multiple',
                            shrink: true,
                        },
                    }}
                    onMenuOpen={openMenu}
                    onMenuClose={closeMenu}
                    menuIsOpen={isOpened}
                    isSearchable={isSearchable}
                    filterOption={onSearch}
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    placeholder={placeholder}
                    getOptionValue={option => option[dataField]}
                    getOptionLabel={option => option[displayedField]}
                    options={dropdown}
                    components={components}
                    value={values}
                    onChange={handleChangeSelectedItems}
                    isMulti={true}
                    maxMenuHeight={maxHeight}
                    onInputChange={handleInputChange}
                    inputValue={inputValue}
                />
            </NoSsr>
        </div>
    );
}

MaterialUIGroupedMultiSelect.propTypes = {
    styles: PropTypes.any,
    nonSelectedValue: PropTypes.string,
    dropdownData: PropTypes.array,
    values: PropTypes.array, /*Выбранные значения*/
    dataField: PropTypes.string, /*Поле объекта из массива dropdownData, которое будет исп-ся в качестве значения. Например, id. Должно присутствовать и в группах, и в значениях групп*/
    displayedField: PropTypes.string, /*Поле объекта из массива dropdownData, которое будет исп-ся для отображения. Например, name*/
    groupDisplayedField: PropTypes.string, /*Поле объекта из массива dropdownData, которое будет исп-ся для отображения названия группы. Например, name*/
    groupDataField: PropTypes.string, /*Поле объекта из массива dropdownData, которое будет исп-ся в качестве значения группы. Например, groupValue. Должно присутствовать и в группах, и в значениях групп*/
    label: PropTypes.string, /*Название инпута*/
    placeholder: PropTypes.string,
    needShowNumber: PropTypes.bool,  /*Нужно-ли показывать порядковый номер выбранного объекта*/
    endElements: PropTypes.any,
    isLoading: PropTypes.bool,
    isSearchable: PropTypes.bool, /*Работает-ли поиск? (На мобильной версии лучше отключить)*/
    onSelect: PropTypes.func,
    isGroupSelectionDisabled: PropTypes.bool,  /*Запрещен-ли выбор группы*/
};

MaterialUIGroupedMultiSelect.defaultProps = {
    dropdownData: [],
    dataField: "value",
    displayedField: "label",
    label: "", /*Название инпута*/
    placeholder: "",
    needShowNumber: false,
    endElements: null, /*Элементы в конце инпута. Например, кнопки*/
    isLoading: false,
    isSearchable: false,
    isGroupSelectionDisabled: false
};


