import {
    ADD_MESSAGE_TO_CHAT,
    CHAT_CREATED,
    CHAT_LOADED, CHAT_MESSAGE_TYPING,
    CHATS_HISTORY_LOADED,
    CHATS_MESSAGES_READ,
    CHATS_NUMBER_OF_UNREAD_MESSAGES_LOADED, TOGGLE_OPEN_CHAT,
} from "./actionTypes";
import {getPartnerId} from "../utils/authUtils";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";


export const onChatMessageTyping = (msg) => (dispatch, getState) => {
    dispatch({
        type: CHAT_MESSAGE_TYPING,
        payload: msg
    })

};

export const toggleOpenChat = (isOpened) => (dispatch, getState) => {
    dispatch({
        type: TOGGLE_OPEN_CHAT,
        payload: isOpened
    })
};

export const loadChat = () => (dispatch, getState) => {
    let partnerId = getPartnerId();

    axios
        .get(process.env.REACT_APP_API_URL + '/chats/partners/' + partnerId,  getConfig(getState))
        .then(res =>
            dispatch({
                type: CHAT_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const createChat = () => (dispatch, getState) => {

    let partnerId = getPartnerId();

    axios
        .post(process.env.REACT_APP_API_URL + '/chats/partners/' + partnerId, null, getConfig(getState))
        .then(res =>
            dispatch({
                type: CHAT_CREATED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadNumberOfUnreadMessages = () => (dispatch, getState) => {
    let partnerId = getPartnerId();
    axios
        .get(process.env.REACT_APP_API_URL + '/chats/partners/' + partnerId + '/numberOfUnreadMessages',  getConfig(getState))
        .then(res =>
            dispatch({
                type: CHATS_NUMBER_OF_UNREAD_MESSAGES_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadChatHistory = (callbackFunction) => (dispatch, getState) => {
    let partnerId = getPartnerId();

    let result;
    axios
        .get(process.env.REACT_APP_API_URL + '/chats/partners/' + partnerId + '/chatHistory',
            getConfig(getState))
        .then(res => {
                result = res.data;
                dispatch({
                    type: CHATS_HISTORY_LOADED,
                    payload: res.data
                })
        }
        )
        .then(() => callbackFunction && callbackFunction(result))
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const readMessages = (unreadMessages) => (dispatch, getState) => {
    if (!unreadMessages || unreadMessages.length === 0) {
        return;
    }
    let partnerId = getPartnerId();
    axios
        .put(process.env.REACT_APP_API_URL + '/chats/partners/' + partnerId + '/readMessages', null, getConfig(getState))
        .then(res => {
                dispatch({
                    type: CHATS_MESSAGES_READ,
                    payload: res.data
                })
            }
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const addMessageChatToChat = (msg) => (dispatch, getState) => {
    const messages = getState().chatReducer.messages.slice(0);
    const existedMsgIndex = messages.map(message => message.id).indexOf(msg.id);
    if (existedMsgIndex === -1) {
        messages.push(msg);
    } else {
        messages[existedMsgIndex] = msg;
    }
    let numberOfUnreadMessages = getState().chatReducer.numberOfUnreadMessages;
    if (msg.status === 'SENT_BY_MANAGER') {
        numberOfUnreadMessages = numberOfUnreadMessages + 1;
    }
    dispatch({
        type: ADD_MESSAGE_TO_CHAT,
        payload: {
            messages: messages,
            numberOfUnreadMessages: numberOfUnreadMessages
        }
    })
};
