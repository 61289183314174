import React from "react";
import {ASC, DESC} from "../../../utils/constants";
import PropTypes from "prop-types";


SimpleSortableTableHeader.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.any,
        headerStyle: PropTypes.shape,
    })),
    sortableColumn: PropTypes.string,
    sortableDirection: PropTypes.oneOf([ASC, DESC]),
    onSort: PropTypes.func,
};

export default function SimpleSortableTableHeader({columns = [], sortableColumn, sortableDirection, onSort}) {

    const sortableClassName = (columnName) => {
        return sortableColumn && sortableColumn === columnName
            ? sortableDirection === ASC
                ? "sorting_asc"
                : "sorting_desc"
            : "sorting";
    };

    const sort = (column) => {
        if (sortableColumn !== column || !sortableDirection) {
            return onSort(column, ASC);
        }
        if (sortableDirection === ASC) {
            return onSort(column, DESC);
        }
        return onSort(null, null);

    };

    return (
        <thead className="thead-light">
        <tr>
            {columns.map((column) => <th key={column.name} style={column.headerStyle}
                                         className={"statistic_header left_position "
                                         + (column.isSortingDisabled ? "" : sortableClassName(column.name))}
                                         onClick={() => (column.isSortingDisabled ? {} : sort(column.name))}>{column.label}</th>)}
        </tr>
        </thead>
    );
}