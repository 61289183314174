import React, {Component} from 'react'
import BaseMainPage from "./BaseMainPage";

import {connect} from "react-redux";
import {
    confirmRegistration,
    login,
    register,
    resendConfirmationEmail,
    sendRestorePasswordLink
} from "../../../actions/authActions";
import {clearErrors} from "../../../actions/errorActions";
import {URL_ROOT} from "../../../properties";
import {pathParameter} from "../../../utils/urlUtils";
import {clearNews, getBlogNews} from "../../../actions/newsActions";
import ActionMenu from "./components/menu/ActionMenu";
import AboutUsBlock from "./components/AboutUsBlock";
import CentralBlock from "./components/central/CentralBlock";
import MainPageLogo from "./components/logo/MainPageLogo";
import SocialIconsBlock from "./components/social/SocialIconsBlock";
import PopupBlock from "./components/popup/PopupBlock";
import {LOGIN_FORM, REGISTRATION_FORM, RESTORE_PASSWORD_FORM} from "../../../utils/constants";
import ScrollBlock from "./components/SrollBlock";
import {isMobileScreen} from "../../../utils/displayUtils";
import ym from 'react-yandex-metrika';
import PartnersBlock from "./components/partners/PartnersBlock";


class MainPage extends Component {

    state = {
        openedPopup: null,
        popupData: {
            login: "",
            name: "",
            telegram: "",
            skype: "",
            email: "",
            password: "",
            confirmPassword: "",
            rememberMe: true,
            isTermsConfirmed: false,
        },
        popupDataChanged: false,
        fieldErrors: [],
        pageContentType: "",
        isScrollIconVisible: false
    };


    componentDidMount() {
        document.body.classList.remove('rules-body');
        const confirmationCode = pathParameter("code");
        if (confirmationCode) {
            this.props.confirmRegistration(confirmationCode);
            window.history.pushState({urlPath: URL_ROOT}, "", URL_ROOT);
        }
        window.addEventListener("keydown", this.handleKeyDown);
        if (isMobileScreen()) {
            this.setState({...this.state, isScrollIconVisible: true})
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {error} = this.props;
        if (error !== prevProps.error) {
            const fieldErrors = error.data.fieldErrors;
            if (fieldErrors) {
                this.setState({fieldErrors: fieldErrors});
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKeyDown);
    }


    openPopup = (type) => {
        this.setState({...this.state, openedPopup: type,})
    };

    closePopup = () => {
        this.setState({
            ...this.state,
            openedPopup: null,
            popupData: {
                login: "",
                name: "",
                telegram: "",
                email: "",
                password: "",
                confirmPassword: "",
                rememberMe: false,
            },
            fieldErrors: []
        })
    };


    handleTextTyping = (e) => {
        const popupData = this.state.popupData;
        popupData[e.target.name] = e.target.value;
        this.setState(
            {...this.state, popupData: popupData, popupDataChanged: true}
        );
        this.clearFieldError(e.target.name)
    };

    toggleChecked = (e) => {
        const popupData = this.state.popupData;
        popupData[e.target.name] = !popupData[e.target.name];
        const fieldErrors = this.state.fieldErrors.filter(fieldError => fieldError.field !== e.target.name);
        this.setState(
            {...this.state, popupData: popupData, popupDataChanged: true, fieldErrors: fieldErrors}
        );
    };

    clearFieldError = (fieldName) => {
        let fieldErrors = this.state.fieldErrors;
        if (!fieldErrors || fieldErrors.length === 0) {
            return;
        }
        fieldErrors = fieldErrors.filter(fieldError => fieldError.field !== fieldName);
        this.setState({fieldErrors: fieldErrors})
    };

    hideScrollIcon = (e) => {
        this.setState({...this.state, isScrollIconVisible: false})
    };



    signIn = (reCaptchaToken) => {
        if (!this.state.popupDataChanged) {
            return;
        }
        this.setState({popupDataChanged: false});
        const user = {
            login: this.state.popupData.login && this.state.popupData.login.trim(),
            password: this.state.popupData.password,
            rememberMe: this.state.popupData.rememberMe,
        };

        // Attempt to login
        this.props.login(user, reCaptchaToken, () => this.closePopup());
    };

    register = () => {
        if (!this.state.popupDataChanged) {
            return;
        }
        if (!this.state.popupData.isTermsConfirmed) {
            const fieldErrors = this.state.fieldErrors.slice(0);
            fieldErrors.push({field: "isTermsConfirmed", error: "Подтвердите своё согласие с условиями"});
            this.setState({...this.state, fieldErrors: fieldErrors});
            return;
        }
        this.setState({popupDataChanged: false});
        const user = {
            name: this.state.popupData.name,
            telegram: this.state.popupData.telegram === "" ? null : this.state.popupData.telegram,
            skype: this.state.popupData.skype === "" ? null : this.state.popupData.skype,
            email: this.state.popupData.email && this.state.popupData.email.trim(),
            password: this.state.popupData.password,
        };

        // Attempt to register
        this.props.register(user, () => this.afterRegisterCallback());
    };

    afterRegisterCallback = () => {
        this.closePopup();
        ym('reachGoal','reg_submit');
    };

    sendRestorePasswordLink = () => {
        if (!this.state.popupDataChanged) {
            return;
        }
        this.setState({popupDataChanged: false});
        this.props.sendRestorePasswordLink(this.state.popupData.email, () => this.closePopup())
    };

    resendConfirmationEmail = (login) => {
        if (!login || login === "") {
            return;
        }
        this.props.resendConfirmationEmail(login, () => {
            this.clearFieldError(login);
            this.closePopup();
        })
    };


    handleKeyDown = (e) => {
        const openedPopup = this.state.openedPopup;
        const keyCode = e.keyCode;
        if (!openedPopup || keyCode !== 13) {
            return;
        }
        switch (openedPopup) {
            case LOGIN_FORM:
                this.signIn();
                return;
            case REGISTRATION_FORM:
                this.register();
                return;
            case RESTORE_PASSWORD_FORM:
                this.sendRestorePasswordLink();
                return;
            default:
                return;
        }
    };


    render() {
        const {openedPopup, popupData, fieldErrors} = this.state;
        return (
            <>
                <BaseMainPage>
                    <div className="mobile-scroll">
                        <div className="horizontal-line"/>
                        <ActionMenu openPopup={this.openPopup}/>
                        <AboutUsBlock/>
                        <CentralBlock hideScrollIcons={this.hideScrollIcon}/>
                        <PartnersBlock/>
                        <MainPageLogo/>
                        <SocialIconsBlock/>
                        <PopupBlock openedPopup={openedPopup} openPopup={this.openPopup} closePopup={this.closePopup}
                                    onTextTyping={this.handleTextTyping} toggleChecked={this.toggleChecked}
                                    values={popupData} fieldErrors={fieldErrors} register={this.register}
                                    signIn={this.signIn}
                                    resendConfirmationEmail={this.resendConfirmationEmail}
                                    sendRestorePasswordLink={this.sendRestorePasswordLink}/>
                        <ScrollBlock isVisible={this.state.isScrollIconVisible}/>
                    </div>
                </BaseMainPage>
            </>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error,
    news: state.newsReducer.news,
    allNewsLoaded: state.newsReducer.allNewsLoaded,
    isNewsLoading: state.newsReducer.loading,
});

export default connect(
    mapStateToProps,
    {
        login,
        register,
        sendRestorePasswordLink,
        clearErrors,
        confirmRegistration,
        getBlogNews,
        clearNews,
        resendConfirmationEmail
    }
)(MainPage);