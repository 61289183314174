import React, {Component} from "react";
import BaseMainPage from "./BaseMainPage";
import {connect} from "react-redux";
import {loadBlogItem} from "../../../actions/newsActions";
import {URL_INFO, URL_MAIN} from "../../../properties";
import MainPageLogo from "./components/logo/MainPageLogo";
import {isMobileScreen} from "../../../utils/displayUtils";

class InfoPage extends Component {

    componentDidMount() {
        document.body.classList.add('rules-body');
        let blogItemIdId = window.location.pathname;
        blogItemIdId = blogItemIdId.split(URL_INFO + "/");
        blogItemIdId = blogItemIdId && blogItemIdId.length > 0 && blogItemIdId[1];
        this.props.loadBlogItem(blogItemIdId);
    }


    render() {
        return (
            <BaseMainPage>
                {!isMobileScreen() &&
                <div style={{paddingBottom: '30px'}}>
                    <MainPageLogo href={URL_MAIN}/>
                </div>
                }
                <div className="rules-container">
                    {isMobileScreen() &&  <MainPageLogo href={URL_MAIN} className="rules-page logo"/> }
                    {<div className="news-card-item" dangerouslySetInnerHTML={{__html: this.props.blogItem?.html}}/>}
                </div>
            </BaseMainPage>
        )
    }


}

const mapStateToProps = (state) => {
    return {
        blogItem: state.newsReducer.blogItem,
    }
};

export default connect(mapStateToProps, {loadBlogItem})(InfoPage);