import React from "react";

export default function CentralBlockItem({mainClassName, imgClassName, textBlockClassName, text, additionalEl}) {

    return (
        <div className={mainClassName}>
            {additionalEl}
            <div className={imgClassName}/>
            <div className={textBlockClassName}>{text}</div>
        </div>
    )
};