import React from "react";
import PropTypes from 'prop-types';
import {ASC, DESC} from "../../../utils/constants";

SimpleTable.propTypes = {
    data: PropTypes.array, //Данные таблицы. Например, список офферов.
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.any, //Отображаемое название колонки.
        key: PropTypes.string, //Ключ
        getBodyCellValue: PropTypes.func, //Метод, для получения данных ячейки тела таблицы. Принимает на вход объект из массива data.
        bodyCellStyle: PropTypes.obj, //Стиль ячейки тела таблицы
        bodyCellClassName: PropTypes.string, //Класс ячейки тела таблицы
        headerCellClassName: PropTypes.string, //Класс ячейки хедера таблицы
        headerCellStyle: PropTypes.obj, //Стиль ячейки хедера таблицы,
        isSortingDisabled: PropTypes.bool //Сортировка отключена
    })),
    bodyRowKey: PropTypes.string, //Имя ключа для строки тела таблицы. Должен соответствовать какому-нибудь полю объекта массива data. Например, id.
    tableClassName: PropTypes.string,
    isSortable: PropTypes.bool,
    sortableColumn: PropTypes.string,
    sortableDirection: PropTypes.oneOf([ASC, DESC]),
    onSort: PropTypes.func,
};

export default function SimpleTable(props) {
    const {columns, data, actionRow, bodyRowKey, tableClassName, theadClassName, actionRowClassName, isSortable, sortableColumn, sortableDirection, onSort} = props;

    const sortableClassName = (columnName) => {
        return sortableColumn && sortableColumn === columnName
            ? sortableDirection === ASC
                ? " sorting_asc"
                : " sorting_desc"
            : " sorting";
    };

    const sort = (column) => {
        if (sortableColumn !== column || !sortableDirection) {
            return onSort(column, DESC);
        }
        if (sortableDirection === DESC) {
            return onSort(column, ASC);
        }
        return onSort(null, null);

    };

    if (!data) {
        return null;
    }

    return (
        <table className={tableClassName ? tableClassName : "table simple-table align-left no-wrap table-sm userselecttext"}
               id="table-offers">
            <thead className={theadClassName ? tableClassName: "thead-light"}>
            <tr>
                {columns.map(column =>
                    <th key={column.key} onClick={() => !column.isSortingDisabled && onSort && sort(column.key)}
                        className={column.headerCellClassName + (isSortable && !column.isSortingDisabled ? sortableClassName(column.key) : "")}
                        style={column.headerCellStyle}>{column.name}</th>)}
            </tr>
            </thead>
            <tbody>
            <tr id="actionRow" key="actionRow" className={actionRowClassName ? actionRowClassName : "grayTR"}>
                {actionRow && actionRow.map(col => <td
                    key={"actionRow" + "-|-" + col.key}>{col.getActionCellValue()}</td>)}
            </tr>
            {data.map(bodyRow =>
                <tr id={bodyRow.id} key={bodyRow[bodyRowKey]} className={bodyRow.className} style={bodyRow.style}>
                    {columns.map((col) =>
                        <td key={bodyRow[bodyRowKey] + "-|-" + col.key} className={col.bodyCellClassName}
                            style={col.bodyCellStyle}>{col.getBodyCellValue(bodyRow)}
                        </td>
                    )}
                </tr>)}
            </tbody>
        </table>
    );

}