import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";

IosCheckbox.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    labelLocation: PropTypes.oneOf(['left', 'right']),
    isChecked: PropTypes.any,
    reduxActionType: PropTypes.string,
    onClickFunction: PropTypes.func,
    tooltip: PropTypes.string,
};

IosCheckbox.defaultProps = {
    labelLocation: 'left'
};

export default function IosCheckbox(props) {

    const {label, labelLocation, labelClassName, labelStyle, isChecked, reduxActionType, onClickFunction, tooltip} = props;
    const dispatch = useDispatch();
    function handleClick() {
        if (reduxActionType) {
            dispatch({type: reduxActionType, payload: !isChecked});
        }
        if (onClickFunction) {
            onClickFunction();
        }
    }

    const labelElement = label ? <label className={labelClassName ? labelClassName : "name-check"} style={labelStyle}
                                        onClick={() => handleClick()} title={tooltip}>{label}</label> : null;

    return (
        <>
            {(labelElement && labelLocation === 'left') ? labelElement : null}
            <div className={"ios-ui-select" + (isChecked ? " checked" : "")} onClick={() => handleClick()} title={tooltip}>
                <div className="inner"/>
            </div>
            {(labelElement && labelLocation === 'right') ? labelElement : null}
        </>
    );

};