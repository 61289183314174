import {COUNTRIES_LOADED, COUNTRIES_LOADING, OPERATORS_LOADED, OPERATORS_LOADING} from "../../actions/actionTypes";

const initialState = {
    countries: [],
    loading: false
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case COUNTRIES_LOADING:
            return {
                ...state,
                loading: true
            };
        case COUNTRIES_LOADED:
            return {
                ...state,
                countries: action.payload,
                loading: true
            };
        default:
            return state;

    }
}