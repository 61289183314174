import {
    OPERATORS_LOADED,
    OPERATORS_LOADING,
    OPERATORS_SYNC_COMPLETED,
    OPERATORS_SYNC_IN_PROGRESS,
} from "../../actions/actionTypes";

const initialState = {
    operators: [],
    loading: false,
    operatorsSyncInProgress: false
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case OPERATORS_LOADING:
            return {
                ...state,
                loading: true
            };
        case OPERATORS_LOADED:
            return {
                ...state,
                operators: action.payload,
                loading: true
            };
        case OPERATORS_SYNC_IN_PROGRESS:
            return {
                ...state,
                operatorsSyncInProgress: true,
            };
        case OPERATORS_SYNC_COMPLETED:
            return {
                ...state,
                operatorsSyncInProgress: false,
            };
        default:
            return state;

    }
}