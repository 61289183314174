import React, {useEffect, useState} from "react";
import SimpleTable from "../../../common/tables/SimpleTable";
import ProfileAdNetworkPopover from "./ProfileAdNetworkPopover";
import ActionPopover from "../../../common/popovers/ActionPopover";
import {connect} from "react-redux";
import {loadAllActiveNetworks} from "../../../../actions/advertisingNetworkActions";
import IconPopover from "../../../common/popovers/IconPopver";
import {getPartnerId, isAdmin} from "../../../../utils/authUtils";
import {PARTNER_AD_NETWORK_DATA} from "../../../../utils/constants";
import {deleteSelectedPreset, loadUserPresets, savePreset} from "../../../../actions/presetActions";
import {presetsAccessType} from "../../../../utils/presetsUtils";
import ProfileSmallButton from "../buttons/ProfileSmallButton";


function ProfileAdNetworkCard({activeNetworks, partnerAdNetworkPresets, loadAllActiveNetworks, loadUserPresets, savePreset, deleteSelectedPreset}) {

    const [presetData, setPresetData] = useState({
        id: null,
        adNetwork: null,
        adNetworkEmail: "",
        customDetailsName: "",
        customDetailsValue: "",
    });

    const [tableData, setTableData] = useState([]);
    const [isAdNetworksPresetsEditingAvailable, setIsAdNetworksPresetsEditingAvailable] = useState(false);
    const accessType = presetsAccessType();

    useEffect(() => {
        setIsAdNetworksPresetsEditingAvailable(!tableData || tableData.length === 0 || isAdmin());
    }, [tableData]);

    useEffect(() => {
        loadAllActiveNetworks();
        loadUserPresets(PARTNER_AD_NETWORK_DATA, accessType, getPartnerId());
    }, []);

    useEffect(() => {
        if (!partnerAdNetworkPresets || partnerAdNetworkPresets.length === 0 || !activeNetworks || activeNetworks.length === 0) {
            setTableData([]);
            return;
        }
        const tableData = partnerAdNetworkPresets.map(preset => {
            const adNetworkId = preset.data && preset.data.adNetworkId;
            const adNetwork = activeNetworks.find(adNetwork => adNetwork.id === adNetworkId);
            return {
                id: preset.id,
                adNetwork: adNetwork,
                adNetworkName: adNetwork.name,
                adNetworkEmail: preset.data && preset.data.email,
            }
        });
        setTableData(tableData);
    }, [partnerAdNetworkPresets, activeNetworks]);

    const handleChangeData = (fieldName, value) => {
        setPresetData({...presetData, [fieldName]: value})
    };

    const selectItem = (data) => {
        setPresetData({
            id: data.id,
            adNetwork: data.adNetwork,
            adNetworkEmail: data.adNetworkEmail,
        })
    };

    const saveAdNetworkPreset = () => {
        const preset = {};
        const adNetworkId = presetData?.adNetwork?.id;
        preset.id = presetData.id;
        preset.userId = getPartnerId();
        preset.label = presetData?.adNetwork?.name;
        preset.defaultPreset = false;
        preset.type = PARTNER_AD_NETWORK_DATA;
        preset.accessType = "PARTNER";
        preset.data = {
            adNetworkId: adNetworkId,
            email: presetData.adNetworkEmail,
        };
        savePreset(preset, () => loadUserPresets(PARTNER_AD_NETWORK_DATA, accessType, preset.userId), true);
    };

    const deleteAdNetworkPreset = (id) => {
        const preset = {id: id};
        deleteSelectedPreset(preset, () => loadUserPresets(PARTNER_AD_NETWORK_DATA, accessType, getPartnerId()));
    };


    const tableColumns = () => {
        return [
            {
                name: "Рекламная сеть",
                key: "adNetworkName",
                getBodyCellValue: item => item["adNetworkName"],
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "Email партнёра в рекламной сети",
                key: "adNetworkEmail",
                getBodyCellValue: item => item["adNetworkEmail"],
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "",
                key: "actions",
                getBodyCellValue: (data) => <>
                    {isAdNetworksPresetsEditingAvailable &&
                      <ProfileAdNetworkPopover
                        button={<i className="ti ti-pencil" style={{cursor: "pointer", fontSize: "18px"}}/>}
                        label="Редактировать аккаунт" tooltip="Редактировать аккаунт"
                        onOpenCallback={() => selectItem(data)}
                        partnerAdNetworkData={presetData} onChangeData={handleChangeData} onSave={saveAdNetworkPreset}/>}
                    {isAdNetworksPresetsEditingAvailable &&
                       <ActionPopover
                        button={<i className="ti ti-trash" style={{cursor: "pointer", fontSize: "18px"}}/>}
                        tooltip="Удалить"
                        popoverLocation='right'
                        label="Удалить ?"
                        handleSubmit={() => deleteAdNetworkPreset(data.id)}/>}
                </>,
                headerCellStyle: {minWidth: "100px", width: "100px"},
                bodyCellStyle: isAdNetworksPresetsEditingAvailable ? {textAlign: "left", display: "flex"} : {},
                isSortingDisabled: true,
            },
        ]
    };

    return (
        <div className="col-12 padding5">
            <div className="card">
                <div className="card-header padding10" title="">Аккаунты в рекламных сетях &nbsp;
                    <div style={{position: "absolute", right: "90px"}}>
                    <IconPopover
                        displayedContent="Вам открыта возможность вывода средств из личного кабинета партнера в рекламную сеть.
                    Рекомендуется указать данные по имеющимся у вас аккаунтам, на которые будет производиться вывод средств."
                        placement="top" iconClassName="ti ti-help-alt" target={"PopoverHelpIcon2"}/>
                    </div>
                    {isAdNetworksPresetsEditingAvailable &&
                      <span style={{position: "absolute", right: "10px"}}>
                          <ProfileAdNetworkPopover button={<ProfileSmallButton label="Добавить"/>}
                                                 label="Добавить аккаунт" tooltip="Добавить аккаунт"
                                                 adNetworks={activeNetworks}
                                                 onOpenCallback={() => selectItem({
                                                     adNetwork: null,
                                                     adNetworkEmail: ""
                                                 })}
                                                 onChangeData={handleChangeData} partnerAdNetworkData={presetData}
                                                 onSave={saveAdNetworkPreset}/></span>}
                </div>

                <div className="card-body">
                    <div className="profile-card-data padding15">
                        { partnerAdNetworkPresets && partnerAdNetworkPresets.length> 0 && <SimpleTable
                            tableClassName="table table-bordered nowrap statistic_table sticky-header sticky-action sticky-footer userselecttext"
                            columns={tableColumns()}
                            data={tableData} bodyRowKey="id"/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    activeNetworks: state.advertisingNetworksReducer.adNetworksDropdownData,
    partnerAdNetworkPresets: state.preset.partnerAdNetworkPresets,
});

export default connect(mapStateToProps, {
    loadAllActiveNetworks,
    loadUserPresets,
    savePreset,
    deleteSelectedPreset
})(ProfileAdNetworkCard);