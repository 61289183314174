import React, {useEffect, useState} from "react";
import MaterialUiSimpleSelect from "../../../common/selects/MaterialUiSimpleSelect";
import {DESC} from "../../../../utils/constants";
import ImageInput from "../../../common/inputs/ImageInput";
import SimpleTable from "../../../common/tables/SimpleTable";
import {connect} from "react-redux";
import {deleteBanner, loadAllBanners, saveOrUpdateBanner} from "../../../../actions/bannerActions";
import {isIntegerString, isNumberStringWithTwoDecimalPlaces} from "../../../../utils/validationUtils";
import {bannerTypesData, getBannerName} from "../../../../utils/bannerUtils";
import ActionPopover from "../../../common/popovers/ActionPopover";

const emptyBanner = {
    id: null,
    bonusPercent: "",
    link: "",
    name: "",
    imageId: null,
    promoCode: "",
    type: null,
    weight: ""
};

function BannersContent({errorFields=[], allBanners, loadAllBanners, saveOrUpdateBanner, deleteBanner}) {

    const [editableBanner, setEditableBanner] = useState({...emptyBanner});
    const [sortableColumn, setSortableColumn] = useState("name");
    const [sortableDirection, setSortableDirection] = useState(DESC);

    const dropDownData = bannerTypesData;

    useEffect(() => {
        loadAllBanners();
    }, []);

    const handleChangeEditableBanner = (value, fieldName) => {
        setEditableBanner({...editableBanner, [fieldName]: value, hasChanges: true});
    };

    const handleChangeBonus = (value, fieldName) => {
        if (!isNumberStringWithTwoDecimalPlaces(value)) {
            return;
        }
        value = value.replace(",", ".");
        handleChangeEditableBanner(value, "bonusPercent");
    };

    const handleChangeWeight = (value) => {
        if (!isIntegerString(value)) {
            return;
        }
        handleChangeEditableBanner(value, "weight");
    };

    const clearEditableBanner = () => {
        setEditableBanner({...emptyBanner});
    };

    const saveOrUpdate = () => {
        saveOrUpdateBanner(editableBanner, () => afterSaveCallback());
    };

    const afterSaveCallback = () => {
        loadAllBanners();
        clearEditableBanner();
    };

     const deleteSelectedBanner = (banner) => {
         deleteBanner(banner, () => loadAllBanners());
     };

    const sort = (column, direction) => {
        setSortableColumn(column);
        setSortableDirection(direction);
    };


    const tableColumns = () => {
        return [
            {
                name: "Название",
                key: "name",
                getBodyCellValue: banner => banner.name,
                headerCellStyle: {minWidth: "200px"},
                bodyCellClassName: "tac",
            },
            {
                name: "Тип",
                key: "type",
                getBodyCellValue: banner => getBannerName(banner.type),
                headerCellStyle: {minWidth: "120px", width: "120px"},
                bodyCellClassName: "tac",
            },
            {
                name: "Ссылка",
                key: "link",
                getBodyCellValue: banner => banner.link,
                headerCellStyle: {minWidth: "300px"},
                bodyCellClassName: "tac",
            },
            {
                name: "Промокод",
                key: "promoCode",
                getBodyCellValue: banner => banner.promoCode,
                headerCellStyle: {minWidth: "100px", width: "100px"},
                bodyCellClassName: "tac",
            },
            {
                name: "Бонус, %",
                key: "bonusPercent",
                getBodyCellValue: banner => banner.bonusPercent,
                headerCellStyle: {minWidth: "100px", width: "100px"},
                bodyCellClassName: "tac",
            },
            {
                name: "Вес",
                key: "weight",
                getBodyCellValue: banner => banner.weight,
                headerCellStyle: {minWidth: "80px", width: "80px"},
                bodyCellClassName: "tac",
            },
            {
                name: "Логотип",
                key: "imageId",
                getBodyCellValue: banner => banner.imageId
                    ? <img src={process.env.REACT_APP_API_URL + "/files/" + banner.imageId} alt=""/>
                    : '',
                headerCellStyle: {minWidth: "215px", width: "215px"},
                bodyCellClassName: "tac tacimg",
            },
            {
                name: "",
                key: "actions",
                getBodyCellValue: banner => <>
                    <i className="ti ti-pencil td-save" title="Редактировать"
                       onClick={() => setEditableBanner(banner)}/>
                    <ActionPopover
                        button={<i
                            className="ti ti-trash td-save"
                            title="Удалить"/>}
                        popoverLocation='left'
                        label='Удалить баннер?'
                        handleSubmit={() => deleteSelectedBanner(banner)}/>
                </>,
                headerCellStyle: {minWidth: "120px", width: "120px"},
                bodyCellStyle: {textAlign: "left", display: "flex"},
                isSortingDisabled: true,
            },
        ];
    };


    const tableActionRow = () => {
        return [
            {
                key: "name",
                getActionCellValue: () =>
                    <input
                        name="name"
                        className={errorFields.includes("name") ? "form-control input-error" : "form-control "}
                        value={editableBanner.name}
                        onChange={(e) => handleChangeEditableBanner(e.target.value, "name")}/>
            },
            {
                key: "type",
                getActionCellValue: () =>
                    <MaterialUiSimpleSelect
                        dropdownData={dropDownData}
                        selectedValue={{
                            type: editableBanner.type,
                            name: getBannerName(editableBanner.type)
                        }}
                        onSelectValue={(value) => handleChangeEditableBanner(value?.type, "type")}
                        dataField='type'
                        displayedField='name'
                        isClearable={true}
                    />
            },
            {
                key: "link",
                getActionCellValue: () =>
                    <input
                        className={errorFields.includes("link") ? "form-control input-error" : "form-control "}
                        value={editableBanner.link}
                        onChange={(e) => handleChangeEditableBanner(e.target.value, "link")}/>
            },
            {
                key: "promoCode",
                getActionCellValue: () =>
                    <input
                        className={errorFields.includes("promoCode") ? "form-control input-error" : "form-control "}
                        value={editableBanner.promoCode}
                        onChange={(e) => handleChangeEditableBanner(e.target.value, "promoCode")}/>
            },
            {
                key: "bonusPercent",
                getActionCellValue: () =>
                    <input
                        className={errorFields.includes("bonusPercent") ? "form-control input-error" : "form-control "}
                        value={editableBanner.bonusPercent}
                        onChange={(e) => handleChangeBonus(e.target.value)}/>
            },
            {
                key: "weight",
                getActionCellValue: () =>
                    <input
                        className={errorFields.includes("weight") ? "form-control input-error" : "form-control "}
                        value={editableBanner.weight}
                        onChange={(e) => handleChangeWeight(e.target.value)}/>
            },
            {
                key: "imageId",
                getActionCellValue: () =>
                    <ImageInput imgId={editableBanner.imageId}
                                setImgId={(id) => handleChangeEditableBanner(id, "imageId")}
                                inputClassName={"form-control"}/>
            },
            {
                key: "action",
                getActionCellValue: () => <>
                    <i className="ti ti-close td-save" onClick={clearEditableBanner} title='Очистить'/>
                    <i className="ti ti-save td-save" onClick={saveOrUpdate}
                       title={editableBanner.id ? 'Сохранить изменения' : 'Добавить баннер'}/>
                </>
            },
        ];
    };

    return (
        <div className="responsive-block statistic_table_wrapper" id="fullWidth" style={{minHeight: '30vh'}}>
            <SimpleTable
                tableClassName="table table-bordered nowrap statistic_table sticky-header sticky-action sticky-footer userselecttext"
                columns={tableColumns()}
                data={allBanners}
                bodyRowKey="id"
                actionRow={tableActionRow()}
                isSortable
                sortableColumn={sortableColumn} sortableDirection={sortableDirection} onSort={sort}/>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        isLoading: state.bannerReducer.allBannersLoading,
        allBanners: state.bannerReducer.allBanners,
    }
}

export default connect(mapStateToProps, {
    loadAllBanners,
    saveOrUpdateBanner,
    deleteBanner,
})(BannersContent);