import React from "react";
import MaterialUISelect from "./MaterialUISelect";
import useThemeChange from "../../../hooks/useThemeChange";
import {LIGHT_THEME} from "../../../utils/constants";

export default function MaterialUiSimpleSelect({dropdownData, selectedValue, onSelectValue,dataField, displayedField, isClearable, placeholder, disabled}) {

    const themeName = useThemeChange();

    const inputStyles = {
        border: themeName === LIGHT_THEME ? "1px solid #ced4da" : "1px solid #000",
        borderRadius: ".25rem",
        fontSize: '12px !important',
        padding: "0 0 0 5px",
    };

    const styles = {
        root: {
            padding: "0 !important",
            minWidth: 150,
            underlineBorderBottom: '0px solid rgba(0,0,0,0) !important',
        },
        input: {
            ...inputStyles,
            backgroundColor: themeName === LIGHT_THEME ? "white" : "#1c1c1e",
            minHeight: "30px",

        },
        placeholder: {
            fontSize: "12px !important",
            padding: "0 0 3px 5px",
        }
    };


    return <MaterialUISelect styles={styles}
                             dropdownData={dropdownData}
                             selectedValue={selectedValue}
                             onSelectCallbackFunction={onSelectValue}
                             dataField={dataField}
                             displayedField={displayedField}
                             isClearable={isClearable}
                             placeholder={placeholder}
                             isDisabled={disabled}/>

}