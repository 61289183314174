import React from "react";
import DateRangePicker from "../../../common/pickers/DateRangePicker";

export default function DateRangeFilter(props) {
    const {className, fromDate, toDate, selectFromDate, selectToDate, inputStyle} = props;
    return (
        <div className={className} id="blockInputDatapicker">
                   <DateRangePicker fromDate={fromDate}
                                    toDate={toDate}
                                    selectFromDateFunction={selectFromDate}
                                    selectToDateFunction={selectToDate}
                                    label="Дата"
                                    inputStyle={inputStyle}
                                    locatedInRightFilterBlock/>
        </div>
    )
}
