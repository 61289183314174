import {POSTBACK_TEST_SEND, POSTBACK_TEST_SENT} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";

export const sendPostbackTest = (postbackTest, callbackFunction) => (dispatch, getState) => {
    const successMsg = {success: "Тестовый Postback " + (postbackTest.eventType || "" ) + " отправлен"};
    let body = postbackTest;
    let postbackTestResult;

    dispatch({type: POSTBACK_TEST_SEND});
    axios
        .post(process.env.REACT_APP_API_URL + '/postback/check', body, getConfig(getState))
        .then(res =>
            dispatch({
                type: POSTBACK_TEST_SENT,
                payload: postbackTestResult = res.data,
            })
        )
        .then(() => {callbackFunction &&
            dispatch(callbackFunction(postbackTestResult))
        })
        .catch(err => {
            dispatch(returnErrors(err));
        });
};
