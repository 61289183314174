import React, {useState} from 'react';
import DashboardOfferItem from "./DashboardOfferItem";
import Spinner from "../../../common/Spinner";
import {useSelector} from "react-redux";
import DashboardOffersHeader from "./DashboardOffersHeader";

export default function DashboardOffersCard(props) {

    const {offers, mainOffersSwitcherChecked, toggleMainOffersCheckbox, toggleOfferCheckbox, disabledOfferIds} = props;
    const loading = useSelector(state => state.dashboardReducer.loading);

    const dynamic = getDynamic(offers);

    const [isOffersOpen, setIsOffersOpen] = useState(true);

    const toggleOffersOpen = () => {
        setIsOffersOpen(!isOffersOpen);
    };

    return (
         <>
             <DashboardOffersHeader isChecked={mainOffersSwitcherChecked}
                                    dynamic={dynamic}
                                    onClickCheckbox={toggleOffersOpen}
                                    onSelectAll={toggleMainOffersCheckbox}/>
             {isOffersOpen && <div className="offers-list">
                 {
                     loading
                         ? <Spinner/>
                         :
                         offers && offers.length > 0
                             ? offers.map(offer =>
                                 <DashboardOfferItem
                                     key={offer.id}
                                     offer={offer}
                                     isChecked={!disabledOfferIds.includes(offer.id)}
                                     onClickCheckbox={() => toggleOfferCheckbox(offer.id)}/>
                             )
                             : <p className="dashboard-empty-offers">Здесь будет список офферов,
                                 по которым есть активность за рассматриваемый период</p>
                 }
             </div>}
         </>
    );
};


const getDynamic = (offers) => {
    const emptyItem = {percent: 0, amount: 0};
    if (!offers || offers.length === 0) {
        return emptyItem;
    }
    var currentDayLeads = 0;
    var prevDayLeads = 0;
    offers.forEach(function (offer) {
        if (offer.checked) {
            currentDayLeads += offer.currentDayLeads;
            prevDayLeads += offer.prevDayLeads;
        }
    });
    if (prevDayLeads === 0 && currentDayLeads === 0) {
        return emptyItem;
    }
    var result = ((prevDayLeads === 0) ?
        (currentDayLeads > 0 ? 100 : -100)
        : ((currentDayLeads - prevDayLeads) / Math.abs(prevDayLeads)) * 100);

    return {percent: result.toPrecision(3), amount: (currentDayLeads - prevDayLeads)};
};
