import React from "react";
import ProfileContactsInput from "./inputs/ProfileContactsInput";
import {fieldErrorValue} from "../../../utils/errorUtils";

export default function ProfileContactsDataCard({onTextTyping, telegram='', phone='', skype='', fieldErrors}) {

    const formatPhoneNumber = (phone) => {
        if (phone) {
            return '+' + (phone.length > 0 ? phone.substr(0, 1) : '')
                + (phone.length > 1 ? ('-' + phone.substr(1, 3)) : '')
                + (phone.length > 4 ? ('-' + phone.substr(4, 3)) : '')
                + (phone.length > 7 ? ('-' + phone.substr(7)) : '');
        }

        return '';
    };

    const onPhoneNumberTyping = (e) => {
        const phoneNumber = e.target.value;
        if (!phoneNumber) {
            return "";
        }
        e.target.value = (phoneNumber).replace(/\D/g, '');
        onTextTyping(e)
    };

    return (
        <div className="col-12 col-lg-6  padding5">
            <div className="card">
                <div className="card-header padding10">
                    Контактные данные
                </div>
                    <div className="profile-card-data padding15">
                        <ProfileContactsInput onTextTyping={onTextTyping} value={telegram} label="Telegram"
                                              iconName="fa fa-telegram" inputName="telegram"
                                              errorMsg={fieldErrorValue(fieldErrors, "telegram")}/>
                        <ProfileContactsInput onTextTyping={onTextTyping} value={skype} label="Skype"
                                              iconName="fa fa-skype" inputName="skype"/>
                        <ProfileContactsInput onTextTyping={onPhoneNumberTyping} value={formatPhoneNumber(phone)}
                                              label="Mobile"
                                              iconName="fa fa-phone" inputName="phone"/>
                    </div>
                </div>
        </div>
    )
}
