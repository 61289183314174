import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {DASHBOARD_CHART_DATA_LOADING, DASHBOARD_CHART_DATA_LOADED} from "./actionTypes";
import {pathParameter} from "../utils/urlUtils";
import {currentUserId} from "../utils/authUtils";
import {getIsoLocalString} from "../utils/dateUtils";

export const getDashboardChartData = (fromDate, toDate) => (dispatch, getState) => {
    dispatch({type: DASHBOARD_CHART_DATA_LOADING});


    let partnerId = pathParameter('partnerId');
    if (partnerId === null) {
        partnerId = currentUserId();
    }
    const from = getIsoLocalString(fromDate);
    const to = getIsoLocalString(toDate);
    const body = JSON.stringify({partnerId:partnerId, from: from, to: to});

    axios
        .post(process.env.REACT_APP_API_URL + '/dashboard/chart/', body, getConfig(getState))
        .then(res =>
            dispatch({
                type: DASHBOARD_CHART_DATA_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};


