import {
    CLEAR_PARTNER_PAYMENTS_DATA,
    CREATE_PAYMENT,
    MANAGER_PAYMENTS_LOADED,
    MANAGER_PAYMENTS_LOADING,
    PARTNER_PAYMENTS_LOADED,
    PARTNER_PAYMENTS_LOADING,
    PAYMENT_APPROVED,
    PAYMENT_CANCELED,
    SELECTED_PAYMENTS_APPROVED
} from "../../actions/actionTypes";


const initialState = {
    payments: null,
    loading: false,
    managerPayments: null,
    managerRequestedPayments: null,
    managerPaymentsLoading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PARTNER_PAYMENTS_LOADING:
            return {
                ...state,
                loading: true
            };
        case PARTNER_PAYMENTS_LOADED:
            return {
                ...state,
                payments: action.payload,
                loading: false
            };
        case CLEAR_PARTNER_PAYMENTS_DATA:
            return {
                ...state,
                payments: null,
                loading: false
            };
        case MANAGER_PAYMENTS_LOADING:
            return {
                ...state,
                managerPaymentsLoading: true
            };
        case MANAGER_PAYMENTS_LOADED:
            return {
                ...state,
                managerPayments: action.payload,
                managerPaymentsLoading: false
            };
        case CREATE_PAYMENT:
        case PAYMENT_APPROVED:
        case PAYMENT_CANCELED:
        case SELECTED_PAYMENTS_APPROVED:
        default:
            return {
                ...state
            }
    }
}