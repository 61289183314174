
/*Урлы*/
export const URL_ROOT = '/';
export const URL_MAIN = '/main';
export const URL_INFO = '/info';
export const URL_RULES = '/rules';
export const URL_PW_RESTORE = '/restorePassword';
export const URL_DASHBOARD = '/dashboard';
export const URL_PROFILE = '/profile';
export const URL_PARTNERS = '/partners';
export const URL_STATISTIC = '/statistic';
export const URL_PROJECTS = '/projects';
export const URL_PAYMENTS = '/payments';
export const URL_PAYMENTS_MANAGER = '/payments/manager';
export const URL_NEWS = '/google.com';
export const URL_NEWS_MANAGER = '/news/manager';
export const URL_FAQ = '/faq';
export const URL_OFFERS = '/offers';
export const URL_OFFERS_MANAGER = '/offers/manager';
export const URL_OFFER_EDIT = '/offers/edit';
export const URL_OFFER_ITEM = '/offers/item';
export const URL_TOOLS = '/tools';
export const URL_REFERRAL = '/referral';

export const BLACK_LIST_LINK = "https://fraud.informpartner.com/v1/public/data/c7ebbc78de4b4084e248dda419571e78906fc60b52e2802ba91a3faac490f6e1c6910da32df8a83f3afc9f70240d1e1bf1a6ef820d2a03c0c2504ddfb98ffbfaff8ccfffa0bad934860df9e9a06ae67446fbda8e109cabdd28d776a05fa6f8b8cbc58e";
