import React, {Component} from 'react'
import {Redirect} from "react-router-dom";
import BaseMainPage from "./BaseMainPage";
import {URL_DASHBOARD, URL_ROOT} from "../../../properties";
import {fieldErrorValue} from "../../../utils/errorUtils";
import {pathParameter} from "../../../utils/urlUtils";
import {connect} from "react-redux";
import {changePassword, checkRestorePasswordCode} from "../../../actions/authActions";
import {clearErrors, returnErrors} from "../../../actions/errorActions";
import PopupPasswordInput from "./components/popup/PopupPasswordInput";
import PopupCheckbox from "./components/popup/PopupCheckbox";

class PasswordRestorePage extends Component {

    state = {
        confirmationCode: null,
        password: "",
        confirmPassword: "",
        rememberMe: false,
        fieldErrors: [],
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {error, isAuthenticated, isRestorePasswordCodeValid} = this.props;
        if (!isRestorePasswordCodeValid) {
            const error = {response: {status: 400, data: {details: 'Ссылка для восстановления пароля недействительна'}}};
            this.props.returnErrors(error);
        }
        if (error !== prevProps.error) {
            const fieldErrors = error.data.fieldErrors;
            if (fieldErrors) {
                this.setState({fieldErrors: fieldErrors});
            }
        }
        if (isAuthenticated) {
            this.props.history.push(URL_DASHBOARD);
        }
    }

    componentDidMount() {
        const confirmationCode = pathParameter("restore_code");
        this.setState({confirmationCode: confirmationCode});
        this.props.checkRestorePasswordCode(confirmationCode);
    }

    handleTextTyping = (e) => {
        this.setState(
            {...this.state, [e.target.name]: e.target.value}
        );
    };

    toggleChecked = (e) => {
        const isChecked = !this.state[e.target.name];
        this.setState(
            {...this.state, [e.target.name]: isChecked}
        );
    };

    changePasswordAndLogin = () => {
        this.setState({fieldErrors: []});
        const {password, confirmPassword, rememberMe} = this.state;
        const fieldErrors = [];
        if (!password || password === "") {
            fieldErrors.push({field: "password", error: "Введите пароль"})
        }
        if (!confirmPassword || confirmPassword === "") {
            fieldErrors.push({field: "confirmPassword", error: "Подтвердите пароль"})
        }
        if (password !== "" && confirmPassword !== "" && password !== confirmPassword) {
            fieldErrors.push({field: "confirmPassword", error: "Пароли не совпадают"})
        }
        if (fieldErrors.length > 0) {
            this.setState({fieldErrors: fieldErrors});
            return;
        }
        const body = {
            password: password,
            confirmationCode: pathParameter("restore_code"),
            rememberMe: rememberMe
        };
        this.props.changePassword(body);
    };

    render() {
        const {password, confirmPassword, rememberMe, fieldErrors} = this.state;

        return (
            <>
                {(!this.props.isRestorePasswordCodeValid || !pathParameter("restore_code"))
                && <Redirect to={{pathname: URL_ROOT, state: {from: window.location.pathname}}}/>}
                <BaseMainPage>
                    <div className="darkbg" style={{zIndex: 0}}>
                        <div className="popupMorda">
                            <form id="login-form" style={{display: "block"}}>
                                <PopupPasswordInput onTextTyping={this.handleTextTyping} name="password" placeholder="Новый пароль"
                                                    value={password} errorMsg={fieldErrorValue(fieldErrors, "password")}
                                                    useAutocomplete={false}/>
                                <PopupPasswordInput onTextTyping={this.handleTextTyping} name="confirmPassword" placeholder="Повторите новый пароль"
                                                    value={confirmPassword} errorMsg={fieldErrorValue(fieldErrors, "confirmPassword")}
                                                     useAutocomplete={false}/>
                                <PopupCheckbox onChange={this.toggleChecked} className="checkLogin" name="rememberMe" checked={rememberMe}
                                               label={<>Запомнить меня</>}
                                               errorMsg={fieldErrorValue(fieldErrors, "rememberMe")}/>
                                <a href="#" className="login-btn" onClick={this.changePasswordAndLogin}>Войти</a>
                            </form>
                        </div>
                    </div>
                </BaseMainPage>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error,
    isRestorePasswordCodeValid: state.auth.isRestorePasswordCodeValid,
});

export default connect(
    mapStateToProps,
    {changePassword, returnErrors, clearErrors, checkRestorePasswordCode}
)(PasswordRestorePage);