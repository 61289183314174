import React from "react";

import xlsxImg from "../../../resources/images/xls.png";
import ExportToXlsx from "../xlsx/ExportToXlsx";

export default function XlsxButton({xlsxData}) {

    const {filename, dataset} = xlsxData
        ? xlsxData
        : {filename: '', dataset:[]};

    return (
        <ExportToXlsx name={filename} dataSet={dataset} button={
            <div className="xls-btn" title="Выгрузить в XLSX">
                <img src={xlsxImg} alt=""/>
            </div>
        }/>

    )
}