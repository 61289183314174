import React, {useEffect, useState} from "react";
import IpRangesXlsxLink from "./IpRangesXlsxLink";

export default function IpRangesDataCard({filteredRanges, isCIDRChecked, selectedCountry, selectedOperator}) {

    const [rangesString, setRangesString] = useState("");
    const [groupedByOperatorRanges, setGroupedByOperatorRanges] = useState({});

    useEffect(() => {
        if (!filteredRanges || filteredRanges.length === 0) {
            setRangesString("");
        } else {
            isCIDRChecked
                ? setRangesString(filteredRanges.map(item => item.cidrList.join("\n")).join("\n"))
                : setRangesString(filteredRanges.map(item => item.range).join("\n"));

        }
        const groupedByOperatorRanges = filteredRanges.reduce((r, a) => {
            r[a.operatorName] = r[a.operatorName] || [];
            r[a.operatorName].push(a);
            return r;
        }, Object.create(null));
        setGroupedByOperatorRanges(groupedByOperatorRanges);

    }, [filteredRanges, isCIDRChecked, selectedCountry, selectedOperator]);

    return (
        <div className="form-row col-lg-12">
            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                {rangesString && rangesString !== "" &&
                <div className="input-group">
                    <div className="api-wrap">
                         <textarea name="ip" id="" rows="10" value={rangesString}
                                   onChange={() => {
                                   }} className="iprange"/>
                    </div>
                </div>
                }
            </div>
            <div className="form-group col-lg-3 col-md-6 col-sm-12 flex-start">
                <div className="api-wrap">
                    <div className="diap">
                        {filteredRanges && filteredRanges.length > 0 && groupedByOperatorRanges &&
                        <>
                            <p><b>Диапазоны IP</b></p>
                            {Object.entries(groupedByOperatorRanges).map(([operatorName, ranges]) =>
                                <p key={operatorName}>
                                    {operatorName + " (" + selectedCountry.name + ") "}
                                    <IpRangesXlsxLink ranges={ranges}
                                                      fileNamePrefix={"ip-ranges-" + operatorName + "-"}/>
                                </p>)}
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}