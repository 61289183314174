import React from "react";
import {isMobileScreen} from "../../../../../utils/displayUtils";
import MaterialUIMultiSelect from "../../../../common/selects/MaterialUIMultiSelect";


export default function ManagerSelectedOffersFilter({offers, selectedOffers, onSelectOffer}) {
    const isMobile = isMobileScreen();

    return (
        <div className="rw-multiselect rw-widget float-left">
            <span className="rw-sr"></span>
            <div className="rw-widget-input rw-widget-picker rw-widget-container">
                <MaterialUIMultiSelect styles={{root: {minWidth: 200}}} dropdownData={offers} values={selectedOffers}
                                       dataField='offerId' displayedFields={["offerId", "name"]} label='Офферы'
                                       placeholder='-'
                                       onSelect={onSelectOffer}
                                       isSearchable={!isMobile}
                                       maxChipTxtWidth={140}/>
            </div>
        </div>
    )
}