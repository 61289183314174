import React, {Component} from "react";
import Collapse from "reactstrap/es/Collapse";
import connect from "react-redux/es/connect/connect";
import {saveProject} from "../../../actions/projectsActions";
import ProjectCreationForm from "./ProjectCreationForm";
import {clearErrors} from "../../../actions/errorActions";

class ProjectCreationCollapse extends Component {

    hideForm = () => {
        this.props.onHideForm();
        this.props.clearErrors();
    };


    createProject = (project) => {
        this.props.clearErrors();
        this.props.saveProject(project, (createdProject) => {
            if (this.props.onSelectCreatedProject) {
                this.props.onSelectCreatedProject(createdProject);
            }
            if (this.props.onCreateProjectCallbackFunction) {
                this.props.onCreateProjectCallbackFunction(createdProject)
            }
        });
    };



    render() {

        const {isExpanded, errors} = this.props;
        const fieldErrors = errors && errors.data && errors.data.fieldErrors;
        return (
            <Collapse isOpen={isExpanded}>

                <div className="row">
                    <div className="col ramka">
                        <hr/>
                        <button className="close" onClick={() => this.hideForm()}><i className="ti ti-close cursor"
                                                                                     style={{fontSize: '12px'}}/>
                        </button>
                        <ProjectCreationForm onSave={this.createProject} fieldErrors={fieldErrors}/>
                        <hr/>
                    </div>
                </div>
            </Collapse>
        );
    }

};


const mapStateToProps = state => ({
    errors: state.error
});

export default connect(mapStateToProps, {saveProject, clearErrors})(ProjectCreationCollapse);