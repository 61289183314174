import React, {useEffect, useState} from "react";
import {isEmpty, isNumberStringWithTwoDecimalPlaces} from "../../../../utils/validationUtils";
import {getPaymentDetailsString} from "../../../../utils/paymentUtils";
import {getMoneyString, toDoubleOrNull} from "../../../../utils/formatUtils";
import {
    AD_NETWORK_ORDER,
    ORDER, PARTNER_AD_NETWORK_DATA,
    PARTNER_PAYMENT_DETAILS
} from "../../../../utils/constants";
import {connect} from "react-redux";
import {loadUserPresets} from "../../../../actions/presetActions";
import {getPartnerId} from "../../../../utils/authUtils";
import {IconButton, Popover} from "@material-ui/core";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import TextField from "@material-ui/core/TextField/TextField";
import Done from '@material-ui/icons/DoneOutlined';
import Cancel from '@material-ui/icons/ClearOutlined';
import paymentOrderImg from "../../../../resources/images/icons/atm.svg";
import {loadAllActiveNetworks} from "../../../../actions/advertisingNetworkActions";
import ActionPopover from "../../../common/popovers/ActionPopover";


const selectStyle  = {
    root: {minWidth: "150px !important", padding: "0 0 15px 0"},
    input: {height: "30px !important"}
};


function PaymentOrderCard({
                              availablePartnerBalanceData, handleChangeValue, createPayment, pageState,
                              paymentTransferTypes, activeNetworks, partnerPaymentDetailsPresets, partnerAdNetworkPresets, loadUserPresets, loadAllActiveNetworks
                          }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [orderedPaymentValue, setOrderedPaymentValue] = useState("");
    const [isOrderOpen, setOrderOpen] = useState(false);
    const [paymentDetailsItems, setPaymentDetailsItems] = useState([]);
    const [adNetworkDropdownData, setAdNetworkDropdownData] = useState([]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        if (open) {
            loadUserPresets(PARTNER_PAYMENT_DETAILS, "PARTNER", getPartnerId());
            loadUserPresets(PARTNER_AD_NETWORK_DATA, "PARTNER", getPartnerId());
            loadAllActiveNetworks();
        }
    }, [open]);

    useEffect(() => {
        if (!partnerPaymentDetailsPresets) {
            return;
        }
        const paymentDetailsItems = partnerPaymentDetailsPresets.map(item => {
            return {...item, dataString: getPaymentDetailsString(item.data)}
        });
        setPaymentDetailsItems(paymentDetailsItems);
        const defaultPaymentData = paymentDetailsItems.find(item => item.defaultPreset);
        handleChangeValue("paymentData", defaultPaymentData);
    }, [partnerPaymentDetailsPresets]);

    useEffect(() => {
        if (!partnerAdNetworkPresets || partnerAdNetworkPresets.length === 0 || !activeNetworks || activeNetworks.length === 0) {
            return;
        }
        const partnerAdNetworkData = partnerAdNetworkPresets.map(preset => {
            const adNetwork = activeNetworks.find(adNetwork => adNetwork.id === preset.data.adNetworkId);
            return {
                dataString : adNetwork.name + " : " + preset.data.email,
                data: preset.data,
                adNetworkName: adNetwork.name
            }
        });
        setAdNetworkDropdownData(partnerAdNetworkData);
    }, [partnerAdNetworkPresets, activeNetworks]);

    const toggleOpen = () => {
        setOrderOpen(!isOrderOpen);
    };

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        // setOrderOpen(false);
    };

    const onOrderPayment = () => {
        const {paymentTransferType, orderedPaymentValue} = pageState;
        if (isEmpty(paymentTransferType) || isEmpty(orderedPaymentValue)) {
            return;
        }
        if (!orderedPaymentValue || orderedPaymentValue === "") {
            return;
        }
        toggleOpen();
        createPayment();
        setOrderedPaymentValue("");
        handleChangeValue("orderedPaymentValue", "");
    };

    const onChangeOrderedPayment = (e) => {
        const value = e.target.value.replace(/\s/g, "");
        if (!isNumberStringWithTwoDecimalPlaces(value) || toDoubleOrNull(value) > availablePartnerBalanceData.availableBalance) {
            return;
        }

        setOrderedPaymentValue(value === "" ? "" : value);
        handleChangeValue("orderedPaymentValue", toDoubleOrNull(value));
    };

    const onClickToBalance = () => {
        setOrderedPaymentValue(availablePartnerBalanceData.availableBalance === "" ? "" : getMoneyString(availablePartnerBalanceData.availableBalance));
        handleChangeValue("orderedPaymentValue", availablePartnerBalanceData.availableBalance);
    };

    const paymentDetailsInputs = () => {
        return <MaterialUISelect
                label="Реквизиты"
                dropdownData={paymentDetailsItems && paymentDetailsItems}
                selectedValue={pageState.paymentData}
                onSelectCallbackFunction={(value) => handleChangeValue("paymentData", value)}
                dataField='id'
                displayedField='dataString'
                isClearable={false}
                placeholder="-"
                styles={selectStyle}
        />

    };

    const adNetworkPaymentInputs = () => {
        return <MaterialUISelect
                label="Рекламная сеть"
                dropdownData={adNetworkDropdownData}
                selectedValue={pageState.adNetworkPaymentData}
                onSelectCallbackFunction={(value) => handleChangeValue("adNetworkPaymentData", value)}
                dataField='dataString'
                displayedField='dataString'
                isClearable={false}
                placeholder="-"
                styles={selectStyle}
        />

    };

    const transferFeeOrBonus = pageState.adNetwork && pageState.adNetwork.transferFeeOrBonus;
    const paymentDataString = pageState.paymentData && pageState.paymentData.dataString;
    const message = pageState.paymentTransferType && pageState.paymentTransferType.value === ORDER
        ? "Заказать выплату в " + orderedPaymentValue + " руб на '" + paymentDataString + "'?"
        : <>
            <div>Убедитесь в корректности введенного email.</div>
            <div>{'Заказать пополнение рекламной сети на ' + orderedPaymentValue + ' руб?'}</div>
        </>;

        const popover = () => {
            return (
                <Popover id={id}
                         open={open}
                         anchorEl={anchorEl}
                         onClose={handleClose}
                         anchorOrigin={{
                             vertical: 'bottom',
                             horizontal: 'right',
                         }}
                         transformOrigin={{
                             vertical: 'top',
                             horizontal: 'right',
                         }}
                >
                    <div style={{padding: "10px", width:"250px", maxWidth: "100%"}}>
                    <div  style={{cursor: 'pointer', maxHeight: "12px", paddingBottom: '40px', fontSize: "14px"}}
                         onClick={onClickToBalance}>
                        <b>Баланс:</b> &ensp;{availablePartnerBalanceData.availableBalance.toLocaleString('ru-RU')} руб.
                    </div>
                    <MaterialUISelect label="Тип выплаты" dropdownData={paymentTransferTypes}
                                      dataField="value"
                                      displayedField="name"
                                      selectedValue={pageState.paymentTransferType}
                                      onSelectCallbackFunction={(value) => handleChangeValue("paymentTransferType", value)}
                                      styles={selectStyle}/>
                    {pageState.paymentTransferType && pageState.paymentTransferType.value === ORDER && paymentDetailsInputs()}
                    {pageState.paymentTransferType && (pageState.paymentTransferType.value === AD_NETWORK_ORDER) && adNetworkPaymentInputs()}
                    <TextField label="Сумма запроса, руб"
                               name="incomePaidApproved"
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={onChangeOrderedPayment}
                               value={orderedPaymentValue}
                               style={{width: '100%', paddingRight: '10px', paddingBottom: '15px'}}
                    />
                    <br/>
                    <br/>
                    <div className="text-right">
                        <ActionPopover
                            button={
                                <>
                                <IconButton disableFocusRipple size="medium"
                                            variant="contained"
                                            title="Заказать выплату">
                                    <Done fontSize="medium"/>
                                </IconButton>
                                <IconButton disableFocusRipple size="medium" onClick={handleClose} variant="contained"
                                title="Отмена">
                                <Cancel fontSize="medium"/>
                                </IconButton>
                                </>
                            }
                            tooltip={"Заказать выплату"}
                            popoverLocation='right'
                            label={message}
                            handleSubmit={onOrderPayment}/>
                    </div>
                    </div>
                </Popover>
            )
        };

    return (
        <>
            <div className="col" onClick={handleClick}>
                <a href="#" className="zakaz float-right" title="Заказать выплату">
                    <img src={paymentOrderImg} alt=""/></a>
            </div>
            {anchorEl && popover()}

        </>
    )
};

const mapStateToProps = state => ({
    partnerPaymentDetailsPresets: state.preset.partnerPaymentDetailsPresets,
    activeNetworks: state.advertisingNetworksReducer.activeNetworks,
    activeNetworksLoading: state.advertisingNetworksReducer.activeNetworksLoading,
    partnerAdNetworkPresets: state.preset.partnerAdNetworkPresets
});

export default connect(mapStateToProps, {loadUserPresets, loadAllActiveNetworks})(PaymentOrderCard);