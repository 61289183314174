import React, {useEffect, useState} from "react";
import {TextField} from "@material-ui/core";
import MaterialUiClearIcon from "../../../common/MaterialUiClearIcon";

export default function LtvBuyoutRateFilter({pageState, handleChangeFilterData, textFieldStyle}) {
    const [buyoutRate, setBuyoutRate] = useState("");
    const [timeoutState, setTimeoutState] = useState({timeout: 0});


    useEffect(() => {
        setBuyoutRate(pageState.filters.buyoutRate);
    }, [pageState]);


    const handleTypingBuyoutRate = (value) => {
        if (timeoutState.timeout) {
            clearTimeout(timeoutState.timeout);
        }
        value = value.replace(/\s/g, "");
        if (!isFinite(value)) {
            return;
        }
        setBuyoutRate(value);
        setTimeoutState({
            timeout: setTimeout(() => {
                handleChangeFilterData("buyoutRate", value);
            }, 400)
        });
    };

    const clearbuyoutRate = () => {
        handleTypingBuyoutRate("");
    };

    return (
        <TextField label="Ставка выкупа"
                   style={textFieldStyle}
                   InputLabelProps={{shrink: true, focused: false, style: {margin: "11px"}}}
                   placeholder="Как вариант"
                   onChange={(e) => handleTypingBuyoutRate(e.target.value)}
                   value={buyoutRate}
                   InputProps={{
                       endAdornment:
                           <div className="material-ui-indicator-container" style={{justifyContent: "end"}}>
                               <span className=" css-1okebmr-indicatorSeparator"/>
                               <div aria-hidden="true"
                                    className=" css-tlfecz-indicatorContainer"
                                    style={{cursor: "pointer", opacity: "0.2"}}
                                    onClick={clearbuyoutRate}>
                                   <MaterialUiClearIcon/>
                               </div>
                           </div>,
                       style: {justifyContent: "end"}
                   }}/>
    );
}