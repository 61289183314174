import React from "react";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {isMobileScreen} from "../../../../utils/displayUtils";
import {getGroupChartDataType} from "../../../../utils/chartUtils";


export default function GroupChartDataSelect({value, onSelect, statisticType, donutChart=false}) {
    return (
    <div className="col" style={{paddingTop: "15px", paddingBottom: "10px", paddingLeft: isMobileScreen() ? "5px" : "0"}}>
        <MaterialUISelect dropdownData={getGroupChartDataType(statisticType).filter(item => !(donutChart && item.hideIfDonut))} label="Тип данных"
                          selectedValue={value} onSelectCallbackFunction={onSelect}/>
    </div>
    );
}