import {emptyString} from "./stringUtils";

export const pathParameters = () => {
    return new URLSearchParams(window.location.search);
};

export const path = () => {
    return window.location.pathname;
};

export const pathParameter = (name) => {
    const parameters = pathParameters();
    if (!name || !parameters) {
        return null;
    }
    return parameters.get(name);
};

export const replaceQueryParam = (param, newVal, search) => {
    let regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
    let query = search.replace(regex, "$1").replace(/&$/, '');

    return (query.length > 2 ? query + "&" : "?") + (newVal ? param + "=" + newVal : '');
};

export const replaceDomain = (link, domainName) => {
    if (!domainName || domainName === '') {
        return link;
    }
    if (link.match(/(https?:\/\/)(.+)(\/cl\/)/)) {
        return link.replace(/(https?:\/\/)(.+)(\/cl\/)/, '$1' + domainName + '$3');
    } else if (link.match(/(https?:\/\/)(.+)(\/click.php\?)/)) {
        return link.replace(/(https?:\/\/)(.+)(\/click.php\?)/, '$1' + domainName + '$3');
    } else if (link.match(/(https?:\/\/)(.+)(\/smart\?)/)) {
        return link.replace(/(https?:\/\/)(.+)(\/smart\?)/, '$1' + domainName + '$3');
    } else if (link.match(/(https?:\/\/)(.+)(\/click\?)/)) {
        return link.replace(/(https?:\/\/)(.+)(\/click\?)/, '$1' + domainName + '$3');
    }
    return link.replace(/(https?:\/\/)(.+)(\?)/, '$1' + domainName + '$3');
};

export const cutHashWithParams = (link) => {
    if (!link || link === '') {
        return '';
    }
    if (link.match(/(https?:\/\/)(.+)(\/cl\/)(.+)/)) {
        return link.replace(/(https?:\/\/)(.+)(\/cl\/)(.+)/, '$4');
    } else if (link.match(/(https?:\/\/)(.+)(\/click.php\?)(.+)/)) {
        return link.replace(/(https?:\/\/)(.+)(\/click.php\?)(.+)/, '$4');
    } else if (link.match(/(https?:\/\/)(.+)(\/smart\?)(.+)/)) {
        return link.replace(/(https?:\/\/)(.+)(\/smart\?)(.+)/, '$4');
    } else if (link.match(/(https?:\/\/)(.+)(\/click\?)(.+)/)) {
        return link.replace(/(https?:\/\/)(.+)(\/click\?)(.+)/, '$4');
    }
    return link.replace(/(https?:\/\/)(.+)(\?)(.+)/, '$4');
};

export const encodeCurlyBrackets = (link) => {
    if (emptyString(link)) {
        return link;
    }
    link = link.replaceAll('{', '%7B');
    return  link.replaceAll('}', '%7D');
};

export const decodeCurlyBrackets = (link) => {
    if (emptyString(link)) {
        return link;
    }
    link = link.replaceAll('%7B', '{');
    return  link.replaceAll('%7D', '}');
};


export const convertDataURItoBlob = (dataURI) => {
    if (!dataURI) {
        return null;
    }
    const byteString = atob(dataURI.split(',')[1]);

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    const ab = new ArrayBuffer(byteString.length);

    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: mimeString});
};

export const isEmptyUrl = (url) => {
    return !url || false || ["", "http://", "https://"].includes(url);
};