import React, {Component} from "react";
import {connect} from "react-redux";
import {showWarning} from "../../../../actions/warningActions";
import {loadIpRanges, loadTemplateData, uploadIpRanges} from "../../../../actions/ipRangeActions";
import {loadOperatorTypes} from "../../../../actions/dictionaryActions";
import IpRangesFiltersCard from "./IpRangesFiltersCard";
import IpRangesDataCard from "./IpRangesDataCard";
import {isAdmin} from "../../../../utils/authUtils";

class IpRangesContent extends Component {

    state = {
        selectedOperator: null,
        selectedCountry: null,
        isCIDRChecked: false
    };

    componentDidMount() {
        this.props.loadIpRanges();
        this.props.loadOperatorTypes();
        if (isAdmin()) {
            this.props.loadTemplateData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.ipRanges && this.props.ipRanges != prevProps.ipRanges && this.props.ipRanges.length > 0) {
            const defaultCountryRange = this.props.ipRanges.find(range => range.countryName === "Россия");
            if (defaultCountryRange) {
                this.setState({
                    selectedCountry: {
                        id: defaultCountryRange.countryId,
                        name: defaultCountryRange.countryName
                    }
                })
            }
        }
    }

    getFilteredRanges = () => {
        const {selectedOperator, selectedCountry} = this.state;
        if (!selectedOperator && !selectedCountry) {
            return [];
        }
        let filteredRanges = !selectedCountry ? this.props.ipRanges : this.props.ipRanges.filter(item => selectedCountry.id === item.countryId);
        return !selectedOperator ? filteredRanges : filteredRanges.filter(item => selectedOperator.id === item.operatorId);
    };

    uploadIpRanges = (file) => {
        this.props.uploadIpRanges(file);
    };


    render() {
        return (

            <div>
                <IpRangesFiltersCard
                    allRanges={this.props.ipRanges}
                    selectedOperator={this.state.selectedOperator}
                    selectedCountry={this.state.selectedCountry}
                    isCIDRChecked={this.state.isCIDRChecked}
                    onSelectOperator={(operator) => this.setState({selectedOperator: operator})}
                    onSelectCountry={(country) => this.setState({selectedCountry: country, selectedOperator: null})}
                    toggleCIDRChecked={() => this.setState({isCIDRChecked: !this.state.isCIDRChecked})}
                    uploadIpRanges={this.uploadIpRanges}
                    templateData={this.props.templateData}
                />
                <IpRangesDataCard allRanges={this.props.ipRanges}
                                  templateData={this.props.templateData}
                                  filteredRanges={this.getFilteredRanges()}
                                  isCIDRChecked={this.state.isCIDRChecked}
                                  uploadIpRanges={this.uploadIpRanges}
                                  selectedCountry={this.state.selectedCountry}
                                  selectedOperator={this.state.selectedOperator}/>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        ipRanges: state.ipRangeReducer.ranges,
        templateData: state.ipRangeReducer.templateData,
    }
};

export default connect(mapStateToProps, {
    showWarning,
    loadOperatorTypes,
    loadIpRanges,
    loadTemplateData,
    uploadIpRanges
})(IpRangesContent);