import React, {Component} from 'react';
import LeftSideBar from "../navigation/left/LeftSideBar";
import {pathParameter} from "../../utils/urlUtils";
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router-dom";
import {
    URL_DASHBOARD, URL_FAQ, URL_NEWS_MANAGER,
    URL_OFFERS,
    URL_OFFERS_MANAGER, URL_PARTNERS,
    URL_PAYMENTS, URL_PAYMENTS_MANAGER,
    URL_PROJECTS,
    URL_REFERRAL, URL_STATISTIC, URL_TOOLS
} from "../../properties";
import {loadUserData} from "../../actions/userAction";
import {
    currentUserId,
    currentUserLogin,
    currentUserRoles, isAdminAsPartner
} from "../../utils/authUtils";
import {YMInitializer} from "react-yandex-metrika";
import {addRetentionEvent, executeRetentionEvent} from "../../actions/retentionEventActions";
import ChatCard from "../common/chat/ChatCard";
import {loginAsPartner, logout} from "../../actions/authActions";
import {CONNECTED_OFFERS, SMARTLINKS} from "../../utils/constants";
import PageHeader from "../navigation/header/PageHeader";
import dashboardImg from "../../resources/images/dashboard.png";
import faqImg from "../../resources/images/faq.png";
import offersImg from "../../resources/images/offers.png";
import projectsImg from "../../resources/images/potok.png";
import walletImg from "../../resources/images/wallet.png";
import referalsImg from "../../resources/images/rabs.png";
import analysisImg from "../../resources/images/anal.png";
import toolsImg from "../../resources/images/instruments.png";
import newsImg from "../../resources/images/news.png";

const userParams = {UserID: currentUserId(), login: currentUserLogin(),
    roles: currentUserRoles()};


class BasePage extends Component {
    constructor(props) {
        super(props);
        this.checkPathParameters();
    }

    state = {
        redirectTo: null,
    };


    checkPathParameters() {
        const {isAdminAsPartner} = this.props.auth;
        const hasPartnerIdParameter = pathParameter('partnerId');
        if (!isAdminAsPartner && hasPartnerIdParameter) {
            this.props.loginAsPartner();
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        document.addEventListener('click', this.beforeClickToLink);
        this.addPartnerEmailToBrowserTabTitle();
        this.handleResize();
        const rootElementClassList = document.getElementById("root").classList;
        rootElementClassList.add("main-page-content");
        const bodyClassList = document.body.classList;
        if (window.location.pathname === URL_OFFERS || window.location.pathname === URL_OFFERS_MANAGER) {
            bodyClassList.add('bodyOffers');
        } else {
            bodyClassList.remove('bodyOffers');
        }
        bodyClassList.remove('no-padding');
        bodyClassList.remove("morda");
        bodyClassList.remove('rules-body');
        this.props.executeRetentionEvent(() => {});
    }

    beforeClickToLink = (e) => {
        let href;
        if (e.target.tagName === 'A') {
            href = e.target.getAttribute('href');
        } else if (e.target.parentElement && e.target.parentElement.tagName === 'A') {
            href = e.target.parentElement.getAttribute('href');
        }
        if (href === '#') {
            e.preventDefault();
        }
/*        if (href && href !== '#' && this.props.retentionEvent) {
            e.preventDefault();
            this.props.executeRetentionEvent(() => this.setState({redirectTo: href}));
        }*/
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        document.removeEventListener('click', this.beforeClickToLink);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.handleResize();
        if (window.location.pathname === URL_OFFERS || window.location.pathname === URL_OFFERS_MANAGER) {
            const bodyClassList = document.body.classList;
            if (window.location.search.includes(CONNECTED_OFFERS) || window.location.search.includes(SMARTLINKS)) {
                bodyClassList.remove('bodyOffers');
            } else {
                bodyClassList.add('bodyOffers');
            }
        }

    }

    addPartnerEmailToBrowserTabTitle = () => {
        if (isAdminAsPartner()) {
            const partnerEmail = localStorage.getItem('user' + pathParameter('partnerId'));
            if (partnerEmail) {
                this.addEmailToTitle(partnerEmail);
            } else {
                this.props.loadUserData(this.addPartnerToLocalStorage);
            }
        } else {
            document.title = "Affimob - Wapclick партнёрская программа, wap подписки.";
        }
    };

    addPartnerToLocalStorage = (partner) => {
        if (!partner) {
            return;
        }
        localStorage.setItem('user' + partner.id, partner.email);
        this.addEmailToTitle(partner.email);
    };

    addEmailToTitle = (email) => {
        if (!email) {
            return;
        }
        document.title = "Affimob | " + email;
    };

    handleResize = (e) => {
        const windowWidth = window.innerWidth;
        const bodyClassList = document.body.classList;
        if (windowWidth<769) {
            bodyClassList.add('small-device');
        } else {
            bodyClassList.remove('small-device');
        }
    };


    redirectTo = () => {
        return this.state.redirectTo && <Redirect to = {this.state.redirectTo}/>
    };

     getLinksData = () => {
        const {isAdminAsPartner, isAuthenticated, isPartner} = this.props.auth;
        let params = "";
        if (isAdminAsPartner) {
            params += "?partnerId=" + pathParameter("partnerId");
        }

        const links = [];
        if (isAuthenticated) {
            if (isPartner || isAdminAsPartner) {
                links.push({key: 1, hideOnMobile: false, name: 'Дашборд', href: URL_DASHBOARD + params, pathName: URL_DASHBOARD, img: dashboardImg});
                links.push({key: 3, hideOnMobile: false, name: 'Офферы', href: URL_OFFERS + params, pathName: URL_OFFERS, img: offersImg});
                links.push({key: 4, hideOnMobile: false, name: 'Потоки', href: URL_PROJECTS + params, pathName: URL_PROJECTS, img: projectsImg});
                links.push({key: 7, hideOnMobile: true, name: 'Финансы', href: URL_PAYMENTS + params, pathName: URL_PAYMENTS, img: walletImg});
                links.push({key: 8, hideOnMobile: true, name: 'Рефералы', href: URL_REFERRAL + params, pathName: URL_REFERRAL, img: referalsImg});
            }
            links.push({key: 2, hideOnMobile: false, name: 'Аналитика', href: URL_STATISTIC + params, pathName: URL_STATISTIC, img: analysisImg});
            links.push({key: 9, hideOnMobile: false, name: 'Инструменты', href: URL_TOOLS + params, pathName: URL_TOOLS, img: toolsImg});
            links.push({key: 10, hideOnMobile: false, name: 'FAQ', href: URL_FAQ + params, pathName: URL_FAQ, img: faqImg});
            if (!isPartner && !isAdminAsPartner) {
                links.push({key: 3, hideOnMobile: false, name: 'Офферы', href: URL_OFFERS_MANAGER, pathName: URL_OFFERS_MANAGER, img: offersImg});
                links.push({key: 5, hideOnMobile: false, name: 'Партнёры', href: URL_PARTNERS, pathName: URL_PARTNERS, img: referalsImg});
                links.push({key: 6, hideOnMobile: true, name: 'Уведомления', href: URL_NEWS_MANAGER, pathName: URL_NEWS_MANAGER, img: newsImg});
                links.push({key: 7, hideOnMobile: true, name: 'Финансы', href: URL_PAYMENTS_MANAGER, pathName: URL_PAYMENTS_MANAGER, img: walletImg});
            }
        }
        links.sort(function (a1, a2) {
            return a1.key - a2.key;
        });
        return links;
    };

    render() {
        return (
            <>
                {this.redirectTo()}
                <YMInitializer accounts={[parseInt(process.env.REACT_APP_YA_METRIKA_COUNTER)]}
                               options={{clickmap: true, trackLinks:true, accurateTrackBounce:true, webvisor: true,
                                   userParams: userParams}}/>
                    <LeftSideBar searchFooter={this.props.footerSearch} getLinksData={this.getLinksData} />
                    <div className="content">
                        <PageHeader getLinksData={this.getLinksData}/>
                        {this.props.content}
                        <ChatCard/>
                    </div>
                </>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    partner: state.userReducer.userData,
    retentionEvent: state.retentionEventsReducer.retentionEvent,
});

export default connect(mapStateToProps, {loadUserData, addRetentionEvent,
    executeRetentionEvent, loginAsPartner, logout})(withRouter(BasePage));