
export const isNumberStringWithSpecifiedNumberOfDecimalPlaces = (value, numberOfDecimalPlaces) => {
    let myReg = new RegExp("^[+-]?\\d+((\\.)|(\\,)?)(\\d{1," + numberOfDecimalPlaces + "})?$");
    return !value || value.match(myReg);
};

export const isNumberStringWithTwoDecimalPlaces = (value) => {
    return !value || value.match(/^[+-]?(\d+)?((\.)|(\,)?)(\d{1,2})?$/);
};

export const isIntegerString = (value) => {
    return !value || value.match(/^[+-]?\d+$/);
};

export const isEmpty = (value) => {
    return !value || value === "";
};