import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {isAuthenticated, isPartner, isAdminAsPartner} from "../../utils/authUtils";
import {URL_ROOT, URL_STATISTIC} from "../../properties";


function PartnerRoute ({component: Component,...rest}) {
    return (
        <Route
            {...rest}
            render={
                (props) => isAuthenticated()
                    ? (isPartner() || isAdminAsPartner() ? <Component {...props} /> : <Redirect to={{pathname: URL_STATISTIC, state: {from: props.location}}} />)
                    : <Redirect to={{pathname: URL_ROOT, state: {from: props.location}}} />}
        />
    )
}

export default PartnerRoute;