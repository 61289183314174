import axios from "axios";
import {returnErrors} from "./errorActions";
import {getConfig, getFormDataConfig} from './authActions';
import {ROS_LINES_FILE_UPLOADED, ROS_LINES_LOADED, ROS_LINES_LOADING} from "./actionTypes";
import {getSuccess} from "./successActions";

export const loadRosLines = () => (dispatch, getState) => {

    dispatch({type: ROS_LINES_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/ltv/rosLines', getConfig(getState))
        .then(res =>
            dispatch({
                type: ROS_LINES_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const uploadRosLineFile = (file) => (dispatch, getState) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    let response;
    axios
        .post(process.env.REACT_APP_API_URL + '/ltv/rosLines', formData , getFormDataConfig(getState))
        .then(res => {
                response = res.data;
                dispatch({
                    type: ROS_LINES_FILE_UPLOADED,
                    payload: response
                })
            }
        )
        .then(() => {dispatch(getSuccess({success: "Файл rosLine.json успешно обновлён"}))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};
