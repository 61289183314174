import React, {Fragment} from "react";

import telegram from "../../../../../resources/images/main/social/telegram.svg";
import gmail from "../../../../../resources/images/main/social/gmail.png";
import skype from "../../../../../resources/images/main/social/skype.svg";
import SocialIconItem from "./SocialIconItem";

export default function SocialIconsBlock() {

    const socialIconsData = [
        {href: "tg://resolve?domain=affimob_support", img: telegram},
        {href: "mailto:support@affimob.com?subject=Сообщение с сайта!&amp;body=Здравствуйте,", img: gmail},
        {href: "skype:live:info_520258?chat", img: skype},
    ];

    return (
        <div className="social">
            {socialIconsData.map(item => <Fragment key={item.href}>
                <SocialIconItem href={item.href} img={item.img}/>
            </Fragment>)}
        </div>
    )
}