import React from "react";
import {columnsFormatter} from "../../../../utils/statisticUtils";
import {connect} from "react-redux";

function StatisticTableSimpleRow(props) {
    const {columns, rowData, showDetails, selectedRows} = props;

    const paddingLeft = rowData.level > 0 && (10 + 10 * rowData.level);

    return (
        <tr row_id={rowData.rowId} row_level={rowData.level} className={selectedRows.includes(rowData.rowId) ? 'selected_row' : ''}>
            <td  key={"body_cell_" + rowData.rowId + " 0"} className={"statistic_body_group left_position"} style={{paddingLeft: paddingLeft}}>{rowData.groupName}</td>
            {columns.map((column, idx) => <td key={"body_cell_" + rowData.rowId + " " + (idx + 1)} className={"statistic_body_group right_position"}>
                {columnsFormatter[column.name]?.(rowData[column.name], rowData, props.isLoggedAsPartner, () => showDetails(rowData.rowId, column.name, rowData[column.name]))}
            </td>)}
        </tr>
    );
}


const mapStateToProps = state => ({
    isLoggedAsPartner: state.auth.isPartner || state.auth.isAdminAsPartner
});

export default connect(mapStateToProps, null)(StatisticTableSimpleRow);
