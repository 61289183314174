import React from "react";
import NewsIconInput from "./NewsIconInput";
import NewsTitleInput from "./NewsTitleInput";
import NewsCategorySelect from "./NewsCategorySelect";
import NewsTimePicker from "./NewsDatePicker";
import NewsEditor from "./NewsEditor";
import NewsCoverPreview from "./NewsCoverPreview";
import NewsPublicationButtons from "./NewsPublicationButtons";
import NewsUrlNameInput from "./NewsUrlNameInput";
import CyrillicToTranslit from "cyrillic-to-translit-js";

export default function NewsEditCard(props) {
        const {editableNewsItem, allCategories, setNewsItemData, onChangeTitle, changeImage, uploadCoverImage, saveNewsItem, clearFields,
            selectedPublicationEventTypes, onSelectPublicationEvent} = props;


        const onChangeUrlName = value => {
            const cyrillicToTranslit = CyrillicToTranslit();
            value = cyrillicToTranslit.transform(value, "-");
            value = value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
            setNewsItemData(value, "urlName");
        };

    return (
        <div className="col-md-6">
            <div className="card card-outline-secondary">
                <div className="card-header">
                    <h4 className="mb-0">Создание/Редактирование новости</h4>
                </div>
                <div className="card-body" id="createNewsForm">
                    <div className="row mt-4">

                        <div className="col-12 col-sm-4 pb-3">
                            <NewsTimePicker plannedPublicationDate={editableNewsItem.plannedPublicationDate} onSelectPlannedPublicationDate={(value) => setNewsItemData(value, "plannedPublicationDate")}/>
                        </div>
                        <div className="col-12 col-sm-4 pb-3">
                            <NewsCategorySelect category={editableNewsItem.category} allCategories={allCategories} onSelectCategory={(value) => setNewsItemData(value, "category")}
                            error={editableNewsItem.fieldErrors.find(error => error.field === "category")}/>
                        </div>
                        <div className="col-12 col-sm-4 pb-3">
                            <NewsIconInput iconClassName={editableNewsItem.iconClassName} onChangeIconClassName={(e) => setNewsItemData(e.target.value, "iconClassName")}
                                           error={editableNewsItem.fieldErrors.find(error => error.field === "iconClassName")}/>
                        </div>
                        <div className="col-12 pb-3">
                            <NewsTitleInput title={editableNewsItem.title} onChangeTitle={(e) => onChangeTitle(e.target.value)}
                                            error={editableNewsItem.fieldErrors.find(error => error.field === "title")}/>
                        </div>
                        <div className="col-12 pb-3">
                            <NewsUrlNameInput title={editableNewsItem.urlName} onChangeTitle={(e) => onChangeUrlName(e.target.value)}
                                            error={editableNewsItem.fieldErrors.find(error => error.field === "urlName")}/>
                        </div>
                        <div className="col-12 pb-3">
                            <NewsEditor initialValue={editableNewsItem.initialHtml} setNewsValue={(value) => setNewsItemData(value, "html")} changeImage={changeImage}
                                        error={editableNewsItem.fieldErrors.find(error => error.field === "html")}/>
                        </div>

                        <div className="col-12 pb-3">
                            <NewsCoverPreview imageIds={editableNewsItem.imageIds} coverImageId={editableNewsItem.coverImageId}
                                              uploadCoverImage={uploadCoverImage}/>
                        </div>
                        <div className="col-12 pb-3">
                            <NewsPublicationButtons publicationEventTypes={selectedPublicationEventTypes}
                                                    selectPublicationEvent={onSelectPublicationEvent}/>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </div>
                        <div className="circle-btn-group" style={{position: "absolute", bottom: "0", right: "0"}}>
                            <button className="circle-btn" title="Опубликовать" onClick={() => saveNewsItem(true)}>
                                <i className="ti ti-check"/>
                            </button>
                            <button className="circle-btn" title="Сохранить черновик" onClick={() => saveNewsItem(false)}><i className="ti ti-notepad"/>
                            </button>
                            <button className="circle-btn" title="Очистить" onClick={clearFields}><i className="ti ti-close"/></button>
                        </div>
                </div>
            </div>
        </div>
    )
}