import {
    ACTIVE_ADVERTISING_NETWORKS_LOADED,
    ACTIVE_ADVERTISING_NETWORKS_LOADING, ADVERTISING_NETWORK_BALANCE_LOADED, ADVERTISING_NETWORK_BALANCE_LOADING,
    ADVERTISING_NETWORK_SAVED,
    ALL_ADVERTISING_NETWORKS_LOADED,
    ALL_ADVERTISING_NETWORKS_LOADING,
} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";

export const loadAllNetworks = () => (dispatch, getState) => {
    dispatch({type: ALL_ADVERTISING_NETWORKS_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/advertisingNetwork/all', getConfig(getState))
        .then(res =>
            dispatch({
                type: ALL_ADVERTISING_NETWORKS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadAllActiveNetworks = () => (dispatch, getState) => {
    dispatch({type: ACTIVE_ADVERTISING_NETWORKS_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/advertisingNetwork/active', getConfig(getState))
        .then(res =>
            dispatch({
                type: ACTIVE_ADVERTISING_NETWORKS_LOADED,
                payload: res.data,
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const saveOrUpdateNetwork = (network, onSuccessCallback) => (dispatch, state) => {
    if (!network.hasChanges) {
        return;
    }

    axios
        .post(process.env.REACT_APP_API_URL + '/advertisingNetwork', network, getConfig(state))
        .then(res => {

                dispatch({
                    type: ADVERTISING_NETWORK_SAVED,
                    payload: res.data
                });
            }
        )
        .then(() => {
            onSuccessCallback && onSuccessCallback()
        })
        .then(() => {
            dispatch(getSuccess({success: (network.id ? "Рекламная сеть сохранена" : "Рекламная сеть добавлена" )}))
        })
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadAdNetworkBalance = (id) => (dispatch, getState) => {
    dispatch({type: ADVERTISING_NETWORK_BALANCE_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/advertisingNetwork/' + id + '/balance', getConfig(getState))
        .then(res =>
            dispatch({
                type: ADVERTISING_NETWORK_BALANCE_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};