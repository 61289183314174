import React from "react";
import TextField from "@material-ui/core/TextField/TextField";

export default function ProfileBankTransferPaymentForm({paymentDetails = {}, onTextTyping}) {

    const style = {paddingRight: '10px', paddingBottom: '10px', width: '100%'};
    return (
        <div style={{maxWidth: '300px'}}>
            <TextField label="Наименование банка" name="bankName" onChange={onTextTyping} InputLabelProps={{shrink: true}}
                       value={paymentDetails.bankName ? paymentDetails.bankName : ""} style={style}/>
            <TextField label="Рассчетный счет" name="bankAccount" onChange={onTextTyping} InputLabelProps={{shrink: true}}
                       value={paymentDetails.bankAccount ? paymentDetails.bankAccount : ""} style={style}/>
            <TextField label="Кор. счет" name="correspondentAccount" onChange={onTextTyping} InputLabelProps={{shrink: true}}
                       value={paymentDetails.correspondentAccount ? paymentDetails.correspondentAccount : ""} style={style}/>
            <TextField label="Лицевой счет" name="personalAccount" onChange={onTextTyping} InputLabelProps={{shrink: true}}
                       value={paymentDetails.personalAccount ? paymentDetails.personalAccount : ""} style={style}/>
            <TextField label="ИНН" name="inn" onChange={onTextTyping} InputLabelProps={{shrink: true}}
                       value={paymentDetails.inn ? paymentDetails.inn : ""} style={style}/>
            <TextField label="БИК" name="bik" onChange={onTextTyping} InputLabelProps={{shrink: true}}
                       value={paymentDetails.bik ? paymentDetails.bik : ""} style={style}/>
            <TextField label="КПП" name="kpp" onChange={onTextTyping} InputLabelProps={{shrink: true}}
                       value={paymentDetails.kpp ? paymentDetails.kpp : ""} style={style}/>
        </div>
    )
}
