import React from "react";
import {isMobileScreen} from "../../../../../utils/displayUtils";
import MaterialUISelect from "../../../../common/selects/MaterialUISelect";

export default function ManagerSelectedBillingTypeFilter({selectedBillingType, onSelectBillingType, dropdownData}) {
    const isMobile = isMobileScreen();

    return (
        <div className="rw-multiselect rw-widget float-left">
            <span className="rw-sr"></span>
            <div className="rw-widget-input rw-widget-picker rw-widget-container">
                <MaterialUISelect styles={{root: {minWidth: 200}}} dropdownData={dropdownData} selectedValue={selectedBillingType}
                                       dataField='type' displayedField='fullName' label='Тип выплаты'
                                       placeholder='-'
                                       onSelectCallbackFunction={onSelectBillingType}
                                       isSearchable={!isMobile}
                                       maxChipTxtWidth={140}/>
            </div>
        </div>
    )
}