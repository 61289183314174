import React from "react";
import {
    CURRENT_MONTH, LAST_14_DAYS,
    LAST_30_DAYS,
    LAST_MONTH,
} from "../../../../../utils/constants";
import PeriodButtonsGroupFilter from "../../../../common/buttons/PeriodButtonsGroupFilter";

export default function PaymentsPeriodsFilter({selectedPeriod, selectPeriod, className}) {

    return (
        <div className="nowrap-custom">
            <PeriodButtonsGroupFilter
                label="Период"
                buttons={[
                    {isSelected: selectedPeriod === LAST_14_DAYS, title: "14 дней", iconClassName: "date-icon icon-14", onClick: () => selectPeriod(LAST_14_DAYS)},
                    {isSelected: selectedPeriod === CURRENT_MONTH, title: "Текущий месяц", iconClassName: "date-icon icon-m", onClick: () => selectPeriod(CURRENT_MONTH)},
                    {isSelected: selectedPeriod === LAST_MONTH, title: "Прошлый месяц", iconClassName: "date-icon icon-minus-m", onClick: () => selectPeriod(LAST_MONTH)},
                    {isSelected: selectedPeriod === LAST_30_DAYS, title: "30 дней", iconClassName: "date-icon icon-30", onClick: () => selectPeriod(LAST_30_DAYS)},
                ]}
            />
        </div>
    )
}