import React, {useEffect, useState} from "react";
import {
    POSTBACK_TYPE_BILLING,
    POSTBACK_TYPE_BUYOUTS,
    POSTBACK_TYPE_SUBSCRIBE,
    POSTBACK_TYPE_UNSUBSCRIBE,
    SUBSCRIBE_1,
} from "../../../utils/constants";
import PostbackEventTypeBtn from "./PostbackEventTypeBtn";
import {fieldErrorValue} from "../../../utils/errorUtils";
import ValidInput from "../inputs/ValidInput";
import MaterialUISelect from "../selects/MaterialUISelect";
import {getPartnerId} from "../../../utils/authUtils";
import {getSemiSuccess, getSuccess} from "../../../actions/successActions";
import Spinner from "../Spinner"

const postbackEventTypes =
    [
        {name: null, label: "Все"},
        {name: POSTBACK_TYPE_SUBSCRIBE, label: "Подписка"},
        {name: POSTBACK_TYPE_BUYOUTS, label: "Выкуп"},
        {name: POSTBACK_TYPE_BILLING, label: "Биллинг"},
        {name: POSTBACK_TYPE_UNSUBSCRIBE, label: "Отписка"},
    ];

export default function PostbackTestForm(props) {
    const {projects, offers, fieldErrors, isSending} = props;
    let selectedProject = props.selectedProject || projects?.find(item => item.sourceId === 0);
    let selectedOffer = offers[0];
    const [state, setState] = useState({
        postbackEventTypes: [],
        selectedOffer: selectedOffer,
        selectedProject: selectedProject,
    });
    const [isProjectloaded, setProjectloaded] = useState(false);

    useEffect(() => {
        const project = selectedProject;
        if (project && project.name && !isProjectloaded) {
            setState({
                postbackEventTypes: project.postbackEventTypes ? project.postbackEventTypes.slice() : [],
                selectedOffer: selectedOffer,
                selectedProject: selectedProject,
            });
            setProjectloaded(true);
        }
    }, [selectedProject, selectedOffer, isProjectloaded]);

    const handleTextTyping = (e) => {
        setState(
            {...state, [e.target.name]: e.target.value}
        );
    };

    const onSelectPostbackEvent = (eventType) => {
        let postbackEventTypes;

        if (!eventType) {
            postbackEventTypes = state.selectedProject.postbackEventTypes || [];
        } else {
            postbackEventTypes = [eventType];
        }
        setState({...state, postbackEventTypes: postbackEventTypes});
    };

    const  sendPostback = () => {
        const {selectedProject, selectedOffer, ptId, subId, postbackEventTypes} = state;
        const postbackTest = {
            partnerId: getPartnerId(),
            sourceId: selectedProject.sourceId,
            offerId: selectedOffer.offerId,
            eventType: postbackEventTypes.length === 1? postbackEventTypes[0] : null, // или одно явно выбранное событие, или все разрешённые для потока
            ptId: ptId,
            subId: subId,
        }
        props.onSendPostback(postbackTest, handlePostbackTestResult);
    }

    const formatMessage = (str) => {
        const messages =  str.split(/\n/).filter(item => item.length);

        return messages.length > 1
            ? (<ul align="left"><br/>{messages.map(item => {if(item.length) return(<li>{item}</li>)})}</ul>)
            : str;
    }

    const  handlePostbackTestResult = (testResult) => {
        return !testResult.success || testResult.numberOfFailed
            ? getSemiSuccess({ success: formatMessage((testResult.successMessage ? testResult.successMessage : "") + testResult.errorMessage)})
            : getSuccess({ success: formatMessage(testResult.successMessage)});
    }

    const selectProject = (project) => {
        setState( {...state,
            selectedProject: project,
            postbackEventTypes: project.postbackEventTypes ? project.postbackEventTypes.slice() : [],
        });
    }

    const selectOffer = (offer) => {
        setState( {...state, selectedOffer: offer});
    }

    const offerSelect = <MaterialUISelect
        styles={{root: {minWidth: 180}}}
        dropdownData={offers}
        displayedFields={['offerId', 'name']} dataField='id'
        placeholder="Выбрать оффер"
        selectedValue={state.selectedOffer}
        onSelectCallbackFunction={(offer) => selectOffer(offer)}
        isSearchable={true}/>;

    selectedProject = state.selectedProject;
    const postbackUrl = selectedProject?.postbackUrl || (selectedProject && projects?.find(item => item.sourceId === 0).postbackUrl);
    const hasPtIdMacros = postbackUrl?.includes("{pt_id}");
    const hasSubIdMacros = postbackUrl?.includes("{sub_id}");
    const macrosLabel = "Тестовое значение для {макроса}";

    return (
        <>
            <div className="form-group">
                <label className="float-left">Оффер</label>
                {offers?.length > 1 ? offerSelect : (offers[0].offerId + " | " + offers[0].name)}
            </div>
            <div className="form-group">
                <label className="float-left">Поток</label>
                <MaterialUISelect
                    styles={{root: {minWidth: 180}}}
                    dropdownData={projects?.filter( item => item.id !== -1)}
                    displayedFields={['sourceId', 'name']} dataField='id'
                    placeholder="Выбрать поток"
                    selectedValue={selectedProject}
                    onSelectCallbackFunction={(project) => selectProject(project)}
                    isSearchable={true}/>
            </div>
            <div className="form-group">
                <label className="float-left">Postback URL</label>
                <ValidInput name="postbackUrl"
                            value={postbackUrl}
                            placeholder=""
                            disabled={true}
                            errorMsg={fieldErrorValue(fieldErrors, "postbackUrl")}/>
            </div>
            <div className="form-group">
                {(hasPtIdMacros || hasSubIdMacros) &&
                    <label className="float-left">{macrosLabel}</label>
                }
                {hasPtIdMacros &&
                    <input name="ptId" value={state.ptId}
                           placeholder="pt_id"
                           className="form-control "
                           onChange={handleTextTyping}
                           style={{ width: hasSubIdMacros ? "49%" : "100%"}}
                    />
                }
                {hasSubIdMacros &&
                    <input name="subId" value={state.subId}
                           placeholder="sub_id"
                           className="form-control "
                           onChange={handleTextTyping}
                           style={{ width: hasPtIdMacros ? "49%" : "100%" }}
                    />
                }
            </div>
            <div className="form-group">
                <label className="float-left">События для отправки</label>
                <br/>
                <div className="btn-group" role="group" aria-label="First group"
                     style={{padding: "0px 15px 0px 0px"}}>
                    {
                        postbackEventTypes.map(eventType =>
                        <PostbackEventTypeBtn key={eventType.label} name={eventType.label}
                                              isDisabled={
                                                  eventType.name
                                                  && !(state.selectedProject?.postbackEventTypes?.includes(eventType.name) || !state.selectedProject?.postbackEventTypes?.length)
                                                  || (state.selectedOffer.billingType !== SUBSCRIBE_1 && eventType.name === POSTBACK_TYPE_BUYOUTS)
                                                  || (state.selectedOffer.billingType === SUBSCRIBE_1 && eventType.name !== POSTBACK_TYPE_BUYOUTS)
                                              }
                                              onSelectEventType={() => onSelectPostbackEvent(eventType.name)}
                                              isSelected={
                                                  state.postbackEventTypes.includes(eventType.name)
                                                  || (state.postbackEventTypes.length === 0 && eventType.label === "Все")
                                                  || (state.selectedOffer.billingType === SUBSCRIBE_1 && eventType.name === POSTBACK_TYPE_BUYOUTS)
                                              }/>)
                    }
                </div>
            </div>
            {isSending
                ? <Spinner/>
                : <div className="text-right">
                    <button className="btn btn-outline-warning btn-margin" onClick={() => sendPostback()}>Отправить
                        Postback
                    </button>
                </div>
            }
        </>

    )
}