import React from "react";
import {
    MANAGER_ANALYSIS,
    MANAGER_LTV_ANALYSIS,
    MANAGER_MAIN, MANAGER_MONITOR,
    PARTNER_ANALYSIS,
    PARTNER_MAIN,
} from "../../../../utils/constants";
import GroupFilter from "./GroupFilter";
import useScreenSize from "../../../../hooks/useScreenSize";
import {
    managerStatisticGroups,
    managerTrafficAnalysisGroups,
    partnerStatisticGroups,
    partnerTrafficAnalysisGroups,
    managerLtvAnalysisGroups, managerMonitorGroups
} from "../../../../utils/statisticUtils";
import {cloneArrayOfObjects} from "../../../../utils/arrayUtils";
import {isAdmin} from "../../../../utils/authUtils";

export default function GroupFilters({selectedGroups, selectGroup, toggleGroupsData, statisticType, inputStyle}) {
    const selectedGroup1 = (selectedGroups && selectedGroups.find(group => group.number === 1)) || null;
    const selectedGroup2 = (selectedGroups && selectedGroups.find(group => group.number === 2)) || null;
    const selectedGroup3 = (selectedGroups && selectedGroups.find(group => group.number === 3)) || null;

    const groups = () => {
        switch (statisticType) {
            case PARTNER_MAIN:
                return partnerStatisticGroups;
            case MANAGER_MAIN:
                return managerStatisticGroups;
            case PARTNER_ANALYSIS:
                return partnerTrafficAnalysisGroups;
            case MANAGER_LTV_ANALYSIS:
                return managerLtvAnalysisGroups;
            case MANAGER_ANALYSIS:
                return managerTrafficAnalysisGroups;
            case MANAGER_MONITOR:
                return managerMonitorGroups;

            default:
                return [];
        }
    };
    const dropdownData = cloneArrayOfObjects(groups());

    useScreenSize();

    if (statisticType === MANAGER_LTV_ANALYSIS) {
        return (
            <div className="filters-group">
                <GroupFilter key={"1"} dropdownData={dropdownData} selectedGroup={selectedGroup1} groupNumber={1}
                             label={"Группы"} onSelectGroup={selectGroup} inputStyle={inputStyle} />
                <div className="changeIconBtn" onClick={() => toggleGroupsData(1, 2)}/>
                <GroupFilter key={"2"} dropdownData={dropdownData} selectedGroup={selectedGroup2} groupNumber={2}
                              onSelectGroup={selectGroup} inputStyle={inputStyle}/>
                <div/>
            </div>
        );
    } else if (statisticType === MANAGER_MONITOR) {
        return (
            <div className="filters-group">
                <GroupFilter key={"1"} dropdownData={dropdownData} selectedGroup={selectedGroup1} groupNumber={1}
                             label={"Группа"} onSelectGroup={selectGroup} inputStyle={inputStyle} />
                <div/>
            </div>
        );
    } else {
        return (
            <div className="filters-group">
                <GroupFilter key={"1"} dropdownData={dropdownData} selectedGroup={selectedGroup1} groupNumber={1}
                             label={"Группы"} onSelectGroup={selectGroup} inputStyle={inputStyle}/>
                {isAdmin() && <div className="changeIconBtn" onClick={() => toggleGroupsData(1, 2)}/>}
                <GroupFilter key={"2"} dropdownData={dropdownData} selectedGroup={selectedGroup2} groupNumber={2}
                              onSelectGroup={selectGroup} inputStyle={inputStyle}/>
                {isAdmin() && <div className="changeIconBtn" onClick={() => toggleGroupsData(2, 3)}/>}
                <GroupFilter key={"3"} dropdownData={dropdownData} selectedGroup={selectedGroup3} groupNumber={3}
                              onSelectGroup={selectGroup} inputStyle={inputStyle}/>
                <div/>
            </div>
        );
    }
}