import React from "react";
import { connect } from 'react-redux';
import {Modal, ModalBody, ModalFooter} from "reactstrap";
import {onChatMessageTyping, toggleOpenChat} from "../../../../actions/chatActions";
import {getRusHourDateString} from "../../../../utils/dateUtils";

function NewsItemModal(props) {

    const {newsItem, onCloseModal} = props;

    const ModalHeader = () => {
        return <div className="modal-header">
            <h4 style={{fontSize: "1.1rem"}} className="modal-title">{newsItem.title}</h4>
            <span className="article__info-date"> {"обновлено: " + getRusHourDateString(new Date(newsItem.publicationDate))}</span>
            <button type="button" className="close" data-dismiss="modal" onClick={onCloseModal}>×</button>
        </div>
    };

    const askQuestion = () => {
        props.toggleOpenChat(true);
        props.onChatMessageTyping("Добрый день. Уточните пожалуйста '" + newsItem.title + "'.")
    };

    const isOpen = newsItem && true;

    return (
        newsItem && <Modal size="lg" isOpen={isOpen} toggle={onCloseModal}>
            {ModalHeader()}
            <ModalBody>
                <div className="entry-content">
                    <div dangerouslySetInnerHTML={{__html: newsItem.html}}/>
                </div>
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <div className="btn btn-success float-right" onClick={askQuestion}>Уточнить</div>
            </ModalFooter>
        </Modal>
    );
}

export default connect(null, {toggleOpenChat, onChatMessageTyping})(NewsItemModal);