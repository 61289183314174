import React from 'react';
import PropTypes from "prop-types";
import {
    ALL_OFFERS,
    CONNECTED_OFFERS,
    SMARTLINK_OFFERS_KIT_CHANGING_CONTENT
} from "../../../../../utils/constants";
import OffersOperatorFilter from "./OffersOperatorFilter";
import OffersCountryFilter from "./OffersCountryFilter";
import OffersCategoryFilter from "./OffersCategoryFilter";
import OffersStatusFilter from "./OffersStatusFilter";
import OffersIsNewFilter from "./OffersIsNewFilter";
import OffersIsRecommendedFilter from "./OffersIsRecommendedFilter";
import OffersIsSelectedFilter from "./OffersIsSelectedFilter";
import CircleButtonsGroup from "../../../../common/buttons/CircleButtonsGroup";

PartnerOfferFilters.propTypes = {
    displayedComponent: PropTypes.oneOf([ALL_OFFERS, CONNECTED_OFFERS, SMARTLINK_OFFERS_KIT_CHANGING_CONTENT])
};

export default function PartnerOfferFilters(props) {
    const {offers, displayedComponent, filters, resetAllFilters, toggleCheckboxFilter, onSelectFilterValue, itemMinWidth, inputStyle} = props;

    return (
            <>

                       <div className={"pb-4 flex row"}>
                           <OffersIsNewFilter isOnlyNewOffers={filters.isOnlyNewOffers} toggleIsNew={() => toggleCheckboxFilter("isOnlyNewOffers")}
                                              minWidth={itemMinWidth}/>
                           <OffersIsRecommendedFilter isOnlyRecommendedOffers={filters.isOnlyRecommendedOffers}
                                                      toggleOnlyRecommended={() => toggleCheckboxFilter("isOnlyRecommendedOffers")}
                                                      minWidth={itemMinWidth}/>
                           {displayedComponent === ALL_OFFERS || displayedComponent === SMARTLINK_OFFERS_KIT_CHANGING_CONTENT
                               ? <OffersIsSelectedFilter isOnlySelectedOffers={filters.isOnlySelectedOffers}
                                                         toggleIsOnlySelected={() => toggleCheckboxFilter("isOnlySelectedOffers")}
                                                         minWidth={itemMinWidth}/> : null}
                       </div>
                    <OffersCountryFilter key="countries" offers={offers} selectedCountries={filters.selectedCountries}
                                         onSelectCountry={(value) => onSelectFilterValue(value, "selectedCountries")}
                                         inputStyle={inputStyle}/>
                    <OffersOperatorFilter key="operators" offers={offers} selectedOperators={filters.selectedOperators}
                                          onSelectOperator={(value) => onSelectFilterValue(value, "selectedOperators")}
                                          selectedCountries={filters.selectedCountries} inputStyle={inputStyle}
                                          />
                    <OffersCategoryFilter key="categories" offers={offers} selectedCategories={filters.selectedCategories}
                                          onSelectCategory={(value) => onSelectFilterValue(value, "selectedCategories")}
                                          inputStyle={inputStyle}/>
                    {displayedComponent === CONNECTED_OFFERS
                        ? <OffersStatusFilter key="statuses" offers={offers} selectedStatuses={filters.selectedStatuses}
                                              onSelectStatus={(value) => onSelectFilterValue(value, "selectedStatuses")}
                                              inputStyle={inputStyle}/>
                                              : null}

                <CircleButtonsGroup buttons={
                    [
                        {
                            isSelected: false,
                            title: "Очистить",
                            iconClassName: "ti ti-close stat-icon",
                            onClick: resetAllFilters,
                        },
                    ]
                }/>
            </>
    );
}


