import React, {Fragment} from 'react';
import PartnerTablePartnerTypeRow from "./PartnerTablePartnerTypeRow";
import PartnerTablePartnerRow from "./PartnerTablePartnerRow";

export default function PartnersTableBody({columns = [], tableData=[], toggleRowOpen, approveRegistration, rejectRegistration,
                                              addOrRemoveFromFavorites, toggleCanOrderPayment, changeLevel, resendConfirmationEmail}) {
    return (
        <tbody className="statistic_body">
        {
            tableData.map(row =>
                <Fragment key={row.partnerType}>
                    <PartnerTablePartnerTypeRow key={row.partnerType} columns={columns} rowData={row} toggleRowOpen={toggleRowOpen}/>
                    {
                        row.partners && row.partners.map(partner =>
                            <PartnerTablePartnerRow key={partner.id} columns={columns} rowData={partner}
                                                    approveRegistration={approveRegistration} rejectRegistration={rejectRegistration}
                                                    addOrRemoveFromFavorites={addOrRemoveFromFavorites} toggleCanOrderPayment={toggleCanOrderPayment} changeLevel={changeLevel}
                                                    resendConfirmationEmail={resendConfirmationEmail}/>
                            )
                    }
                </Fragment>
            )
        }
        </tbody>
    );
}