import React, {Fragment} from "react";
import logo from "../../../../resources/images/new-logo.svg";
import IosCheckbox from "../../../common/checkboxes/IosCheckbox";


export default function NewsPublicationButtons(props) {
    const {publicationEventTypes, selectPublicationEvent} = props;
    return (
        <Fragment>
            <NewsPublicationItem className="item blog active" text="Лента новостей"
                                 icon={<i className="iFlex"><span className="pk">ПК</span></i>}
                                 isChecked={publicationEventTypes && publicationEventTypes.includes("PARTNER_NEWS_LINE")}
                                 selectEvent={() => selectPublicationEvent("PARTNER_NEWS_LINE")}/>
            <NewsPublicationItem className="item blog active" text="Blog"
                                 icon={<i className="iFlex"><img src={logo} alt="" className="icon-social"/></i>}
                                 isChecked={publicationEventTypes && publicationEventTypes.includes("BLOG")}
                                 selectEvent={() => selectPublicationEvent("BLOG")}/>
            <NewsPublicationItem className="item active telegram" text="Telegram"
                                 icon={<i className="fa fa-telegram"/>}
                                 isChecked={publicationEventTypes && publicationEventTypes.includes("TELEGRAM")}
                                 selectEvent={() => selectPublicationEvent("TELEGRAM")}/>
            <NewsPublicationItem className="item active vk" icon={<i className="fa fa-vk"/>}  text="VK"
                                 isChecked={publicationEventTypes && publicationEventTypes.includes("VK")}
                                 selectEvent={() => selectPublicationEvent("VK")}/>
        </Fragment>
    )
}

function NewsPublicationItem(props) {
    const {className, icon, text, isChecked, selectEvent} = props;
    return (
        <div className={className} title={text} onClick={selectEvent} style={{cursor: "pointer"}}>
            <div className="item_left">
                <div className="media_box">
                    {icon}
                </div>
                <div className="media_text"/>
            </div>
            <div className="item_right">
                <IosCheckbox isChecked={isChecked} onClickFunction={selectEvent}/>
            </div>
        </div>
    )
}