import {useEffect, useState} from "react";

const getCoordinates = (el) => {
    const coordinates = el?.getBoundingClientRect();
    return coordinates &&
        {
            x: coordinates.x,
            y: coordinates.y,
            top: coordinates.top,
            bottom: coordinates.bottom,
            left: coordinates.left,
            right: coordinates.right,
        };
};

const useElementCoordinates = (el) => {

    const [elementCoordinates, setElementCoordinates] = useState(getCoordinates(el));

    useEffect(() => {

        const handleEvent = () => {
            setElementCoordinates(getCoordinates(el));
        };

        if (!elementCoordinates) {
            handleEvent();
        }

        window.addEventListener('scroll', handleEvent);
        // window.addEventListener('resize', handleEvent);
        return () => {
            window.removeEventListener('scroll', handleEvent);
            // window.addEventListener('resize', handleEvent);
        }
    }, [elementCoordinates, el]); // Empty array ensures that effect is only run on mount and unmount
    return elementCoordinates;
};

export default useElementCoordinates;