import React, {useEffect, useState} from 'react';
import MaterialUIMultiSelect from "../../../../common/selects/MaterialUIMultiSelect";
import {useSelector} from "react-redux";


export default function OffersCountryFilter({offers, selectedCountries, onSelectCountry, inputStyle}) {

    const {countries} = useSelector(state => state.filterDataReducer.filterData);

    const [dropdownValues, setDropdownValues] = useState([]);


    useEffect(() => {
        const getFilteredCountries = () => {
            let resultCountries = [];
            if (countries && offers) {
                countries.forEach(function (country) {
                    const found = offers.find(function (offer) {
                        return country.operatorIds.includes(offer.operatorId);
                    });
                    if (found) {
                        resultCountries.push(country);
                    }
                })
            }
            return resultCountries;
        };
        setDropdownValues(getFilteredCountries());
    }, [offers, countries, selectedCountries]);

    return (
        <MaterialUIMultiSelect styles={inputStyle} dropdownData={dropdownValues} values={selectedCountries}
                               dataField='id' displayedField='name' label='Страны'
                               placeholder='-'
                               onSelect={onSelectCountry}/>
    )
}


