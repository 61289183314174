import { GET_ERRORS, CLEAR_ERRORS } from './actionTypes';

// RETURN ERRORS
export const returnErrors = (error, id = 'ERROR') => {
    console.log('error', error);
    const errorResponse = error.response ? error.response : {status: 500, data: 'Ошибка сервера'};
    return {
        type: GET_ERRORS,
        payload: { errorResponse, id }
    };
};

export const returnAllErrorsAsPopup = (error, id = 'ERROR', fieldErrorsMsg) => {
    console.log('error', error);
    let errorResponse = error.response ? error.response : {status: 500, data: 'Ошибка сервера'};
    if (errorResponse.data.fieldErrors) {
        errorResponse.data.details = fieldErrorsMsg ? fieldErrorsMsg : errorResponse.data.fieldErrors[0].error;
        errorResponse.data.fieldErrors = null;
    }
    return {
        type: GET_ERRORS,
        payload: { errorResponse, id }
    };
};

// CLEAR ERRORS
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    };
};
