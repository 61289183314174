import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import IosCheckbox from "../../../common/checkboxes/IosCheckbox";
import Collapse from "reactstrap/es/Collapse";
import {TextField} from "@material-ui/core";

export default function TableColumnsSettings({isTableSettingsExpanded, hiddenColumns, emptyColumns,
                                                 showOrHideTableColumns, handleLtvInterval}) {

    const allColumns = useSelector(state => state.statisticReducer.tableColumns);
    const [timeoutState, setTimeoutState] = useState({timeout: 0});
    const [ltvInterval, setLtvInterval] = useState(handleLtvInterval());

    const handleTypingInterval = (value) => {
       if (timeoutState.timeout) {
           clearTimeout(timeoutState.timeout);
       }
       value = value.replace(/\D/g, "");
       if (!isFinite(value) || value > 180) {
           return;
       }
       setLtvInterval(value);
       setTimeoutState({
            timeout: setTimeout(() => {
                handleLtvInterval(value);
            }, 400)
        });
     };

    function clickFunction(checkboxName) {
        showOrHideTableColumns(checkboxName);
    }

    const getLtvIntervalInput = (columnLabel) => {
        return (
        <>
        <TextField
            style={{display: 'flex', paddingRight: '10px', minWidth: '30px', maxWidth: '36px'}}
            onChange={(e) => handleTypingInterval(e.target.value)}
            placeholder={columnLabel.replace(/\D*(\d+)$/, '$1')}
            value={ltvInterval}/>
        </>
        );
    }

    const getLtvColumnLabel = (columnLabel) => {
        return columnLabel.replace(/\d*$/, '');
    }

    return (
        <>
            <Collapse isOpen={isTableSettingsExpanded}>
            <div className="row" id="createPotok">
                <div className="col-lg-12 col-sm-12 ramka">
                    <div className="b-group">
                        <div className="col-sm-12 preference">
                            {allColumns.map(column =>
                                <div key={column.name} className="custom-mselect margin5">
                                    <IosCheckbox label={column?.ltvInterval ? getLtvColumnLabel(column.label) : column.label}
                                                 isChecked={hiddenColumns && !hiddenColumns.includes(column.name)}
                                                 labelLocation='right' labelStyle={emptyColumns[column.name] ? {color: "lightGray"} : null}
                                                 onClickFunction={() => clickFunction(column.name)}/>
                                    {column?.ltvInterval ? getLtvIntervalInput(column.label) : ""}
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
                <br/>
            </Collapse>
        </>

    );
}
