import {toDouble} from "./formatUtils";
import {PERCENT, VALUE, VALUE_RUB} from "./constants";
import {getRusDateString} from "./dateUtils";

export const convertFixedIncomeAfterTypeChange = (oldIncomeType, newIncomeType, fixedIncomeString, percentIncomeString, eup, currencyRate) => {
    const scale = getFixedIncomeScale(newIncomeType, currencyRate);
    let fixedIncome = toDouble(fixedIncomeString, scale);
    switch (oldIncomeType) {
        case VALUE:
            return newIncomeType === VALUE_RUB
                ? (fixedIncome * currencyRate).toFixed(2).toLocaleString("ru-RU")
                : fixedIncomeString;
        case PERCENT:
            return newIncomeType === VALUE_RUB
                ? (fixedIncome * currencyRate).toFixed(2).toLocaleString("ru-RU")
                : fixedIncomeString;
        case VALUE_RUB:
            fixedIncome = (eup && eup !== "" && eup != 0) ? (eup * currencyRate * percentIncomeString * 0.01) : fixedIncome;
            return ((fixedIncome / currencyRate)).toFixed(scale).toLocaleString("ru-RU");
        default:
            return "0.00"
    }
};


export const getFixedIncomeString = (incomeString, incomeType, eup, currencyRate) => {
    const scale = getFixedIncomeScale(incomeType, currencyRate);
    const income = toDouble(incomeString, scale);
    switch (incomeType) {
        case VALUE:
        case VALUE_RUB:
            return income.toLocaleString("ru-RU");
        case PERCENT:
            eup = eup && eup !== "" ? eup : 0;
            return (income * 0.01 * eup).toFixed(scale).toLocaleString("ru-RU");
        default :
            return "0,00";
    }
};

export const getPercentIncomeString = (incomeString, incomeType, eup, currencyRate) => {
    let scale = 2;
    const defaultValue = "0,00";
    const isEupExist = eup && eup !== "" && eup != 0;
    eup = eup && eup !== "" ? eup : 0;
    switch (incomeType) {
        case PERCENT:
            return toDouble(incomeString, scale).toLocaleString("ru-RU");
        case VALUE:
            scale = getFixedIncomeScale(VALUE, currencyRate);
            return isEupExist ? ((toDouble(incomeString, scale) * 100) / eup).toFixed(2).toLocaleString("ru-RU") : defaultValue;
        case VALUE_RUB:
            scale = getFixedIncomeScale(VALUE_RUB, currencyRate);
            return isEupExist ? ((toDouble(incomeString, scale) * 100) / (eup * currencyRate)).toFixed(2).toLocaleString("ru-RU") : defaultValue;
        default :
            return defaultValue;
    }
};

/**
 * Returns the number of digits after the decimal point.
 * @param currencyRate Currency exchange rate against the ruble.
 */
export const getFixedIncomeScale = (incomeType, currencyRate) => {
    return incomeType !== VALUE_RUB && currencyRate > 1 ? 4 : 2;
};

export const paymentTypesMapping = {
    VALUE: "В нац. валюте",
    VALUE_RUB: "В рублях",
    PERCENT: "% от EUP без ндс"
};

export const incomeTableColumnFormatters = {
    rusDateString: value => {
        const date = value && new Date(value);
        return date ? getRusDateString(date) : "";
    },
    partnerName: value => value,
    income: value => value !== "" ? value.replace(",", ".") : "",
    incomeInPercent: value => value !== "" ? value.replace(",", ".") + "%" : "",
    incomeType: value => paymentTypesMapping[value],
    billingType: (value) => value,
};

export const isFixedIncomeType = (incomeType) => {
    return incomeType === VALUE || incomeType === VALUE_RUB;
};

export const incomeSymbolMapping = {
    RUB: '₽',
    KZT: '₸',
    BYN: 'Br',
    USD: '$',
    UZS: 'UZS',
    TJS: 'TJS',
};
