import {BLACK_LIST_LOADED, BLACK_LIST_LOADING} from "./actionTypes";
import axios from "axios";
import {returnErrors} from "./errorActions";
import {getConfig} from "./authActions";


export const loadBlackList = () => (dispatch, getState) => {
    dispatch({type: BLACK_LIST_LOADING});
    let id = 0;
    axios
        .get(process.env.REACT_APP_API_URL + '/blacklist', getConfig(getState))
        .then(res =>
            dispatch({
                type: BLACK_LIST_LOADED,
                payload: res.data && res.data.map(item => {return {
                    ...item,
                    id: id++,
                    date: item["date"] ? formatDateOfAdding(item["date"]) : "",
                    domain: item["domain"] ? item["domain"] : "",
                    siteId: item["siteId"] ? item["siteId"] : "",
                    violation: item["violation"] ? item["violation"] : "",
                    isPreLandAllowed: item["isPreLandAllowed"] === true ? "Да" : "Нет",
                    adNetwork: item["adNetwork"] ? item["adNetwork"] : "",
                }})
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

const formatDateOfAdding = (dateString) => {
    return dateString && dateString.length >= 18 ? dateString.substring(0, 10) + " " + dateString.substring(11, 19) : dateString;
};