import {
    COUNTRIES_LOADED,
    COUNTRIES_LOADING,
    COUNTRY_LOGO_UPLOADED,
} from "./actionTypes";
import axios from "axios";
import {getConfig, getFormDataConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";

export const loadAllCountries = () => (dispatch, getState) => {
    dispatch({type: COUNTRIES_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/countries', getConfig(getState))
        .then(res =>
            dispatch({
                type: COUNTRIES_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const uploadCountryLogo = (file, countryId, callbackFunction) => (dispatch, getState) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    axios
        .post(process.env.REACT_APP_API_URL + '/countries/' + countryId + '/logo', formData, getFormDataConfig(getState))
        .then(res =>
            dispatch({
                type: COUNTRY_LOGO_UPLOADED,
                payload: res.data
            })
        )
        .then(() => {
            dispatch(getSuccess({success: "Логотип загружен"}))
        })
        .then(() => callbackFunction && callbackFunction())
        .catch(err => {
            dispatch(returnErrors(err));
        });
};