import {
    PARTNER_DOMAIN_CREATED,
    PARTNER_DOMAIN_CREATING, PARTNER_DOMAIN_DELETED, PARTNER_DOMAIN_MAKE_DEFAULT,
    PARTNER_DOMAINS_LOADED,
    PARTNER_DOMAINS_LOADING
} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnAllErrorsAsPopup, returnErrors} from "./errorActions";
import {getPartnerId} from "../utils/authUtils";
import {getSuccess} from "./successActions";


export const loadPartnerDomains = (withAffimobDomains) => (dispatch, getState) => {
    dispatch({type: PARTNER_DOMAINS_LOADING});
    const partnerIdParam = '?partnerId=' + getPartnerId();
    const withAffimobDominsParam = withAffimobDomains ? "&withAffimobDomains=true" : "&withAffimobDomains=false";

    axios
        .get(process.env.REACT_APP_API_URL + '/domains' + partnerIdParam + withAffimobDominsParam , getConfig(getState))
        .then(res =>
            dispatch({
                type: PARTNER_DOMAINS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const parkPartnerDomain = (cname, callbackFunction) => (dispatch, getState) => {
    dispatch({type: PARTNER_DOMAIN_CREATING});
    cname = cname.trim();
    const body = {cname: cname, partnerId: getPartnerId()};
    const successObj = {success: "Домен " + cname + " добавлен."};

    axios
        .post(process.env.REACT_APP_API_URL + '/domains', body, getConfig(getState))
        .then(res =>
            dispatch({
                type: PARTNER_DOMAIN_CREATED
            })
        )
        .then(() => {callbackFunction && callbackFunction()})
        .then(() => {dispatch(getSuccess(successObj))})
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
        });
};

export const deletePartnerDomain = (domain, callbackFunction) => (dispatch, getState) => {
    let partnerId = getPartnerId();
    if (!partnerId || !domain || !domain.id) {
        return;
    }
    const partnerIdParam =  "?partnerId=" + partnerId;
    const domainIdParam = "&domainId=" + domain.id;
    const successObj = {success: "Домен " + domain.cname + " удален."};
    axios
        .delete(process.env.REACT_APP_API_URL + '/domains' + partnerIdParam + domainIdParam, getConfig(getState))
        .then(res =>
            dispatch({
                type: PARTNER_DOMAIN_DELETED
            })
        )
        .then(() => {callbackFunction && callbackFunction()})
        .then(() => {dispatch(getSuccess(successObj))})
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
        });
};

export const makePartnerDomainDefault = (domain, callbackFunction) => (dispatch, getState) => {
    let partnerId = getPartnerId();
    if (!partnerId || !domain || !domain.id) {
        return;
    }
    const body = {id: domain.id, defaultDomain: domain.defaultDomain, partnerId: getPartnerId()};
    axios
        .put(process.env.REACT_APP_API_URL + '/domains/makeDefault', body, getConfig(getState))
        .then(res =>
            dispatch({
                type: PARTNER_DOMAIN_MAKE_DEFAULT
            })
        )
        .then(() => {callbackFunction && callbackFunction()})
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
        });
};
