import React from "react";

export default function NotFoundPage() {

    return (

        <div className="page_not_found_outer">
            <span className="page_not_found_inner">
                <div style={{fontSize: "80px", fontWeight: "600", margin: "auto"}}>404</div>
                <div style={{fontSize: "25px", color: "rgba(0,0,0,0.4)", fill: "rgba(0,0,0,0.4)", margin: "auto"}}> Страница не найдена</div>
            </span>
        </div>
    )

}