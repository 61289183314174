import {
    BLOG_ITEM_LOADED,
    BLOG_NEWS_LOADED,
    BLOG_NEWS_LOADING,
    CLEAR_BLOG_ITEM,
    CLEAR_EDITABLE_NEWS_ITEM,
    CLEAR_HEADER_NEWS,
    CLEAR_NEWS,
    HEADER_NEWS_LOADED,
    HEADER_NEWS_LOADING,
    NEWS_ITEM_CARD_LOADED,
    NEWS_ITEM_SAVED,
    NEWS_LOADED,
    NEWS_LOADING
} from "../../actions/actionTypes";

const initialState = {
    loading: false,
    news: [],
    headerNews: [],
    allNewsLoaded: false,
    editableNewsItem: null,
    blogItem: null,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case CLEAR_NEWS:
            return {
                ...state,
                loading: false,
                news: [],
                editableNewsItem: null,
            };
        case NEWS_LOADING:
        case HEADER_NEWS_LOADING:
        case BLOG_NEWS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case CLEAR_HEADER_NEWS:
            return {
                ...state,
                headerNews: [],
            };
        case NEWS_LOADED:
            return {
                ...state,
                loading: false,
                news: action.payload,
            };
        case NEWS_ITEM_SAVED:
        case NEWS_ITEM_CARD_LOADED:
            return {
                ...state,
                editableNewsItem: action.payload,
            };
        case HEADER_NEWS_LOADED:
            return {
                ...state,
                headerNews: [...state.headerNews, ...action.payload],
                loading: false,
            };
        case BLOG_NEWS_LOADED:
            return {
                ...state,
                news: [...state.news, ...action.payload],
                allNewsLoaded: action.payload.length === 0,
                loading: false,
            };
        case CLEAR_EDITABLE_NEWS_ITEM:
            return {
                ...state,
                editableNewsItem: null,
            };
        case CLEAR_BLOG_ITEM:
            return {
                ...state,
                blogItem: null,
            };
        case BLOG_ITEM_LOADED:
            return {
                ...state,
                blogItem: action.payload,
            };
        default:
            return state;
    }
}