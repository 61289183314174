import React, { useState } from "react";
import userImg from "../../../resources/images/user.png";
import NavigationMenuMobileItem from "./NavigationMenuMobileItem";
import orangeLogo from "../../../resources/images/new-logo.svg";
export default function MobileNavigationMenu({ linksData, isOpen, partnerName, isPartnerPage }) {
    const [isActive, setIsActive] = useState(false);

    return (
        <>
            <div className="only-mobile-logo">
                <img src={orangeLogo} alt="" className="logo-icon" />
            </div>
            <div className={"mobile-menu" + (isOpen ? " open" : "")}>
                <div className="shadow"></div>
                <div className={"mobile-menu-content" + (isOpen ? " open" : "")}>
                    {isPartnerPage && (
                        <div className="mobile-menu-header">
                            <div className="mail">
                                <div className="icona">
                                    <img src={userImg} alt="" />
                                </div>
                                <span className="amount">{partnerName}</span>
                            </div>
                        </div>
                    )}

                    <div className="mobile-menu-nav">
                        <div className="nav-items">
                            {linksData && linksData().map((link) => <NavigationMenuMobileItem key={link.key} {...link} />)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
