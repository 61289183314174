import React from "react";
import MaterialUISelect from "../../../../common/selects/MaterialUISelect";

export default function ManagerPaymentPopoverPaymentTypeSelect({dropdownData, selectedValue, onSelect, disabled}) {

    return <MaterialUISelect label="Тип выплаты" dropdownData={dropdownData} dataField="value"
                             displayedField="name"
                             selectedValue={selectedValue}
                             onSelectCallbackFunction={onSelect}
                             isDisabled={disabled}
                             styles={{
                                 root: {minWidth: "150px !important", padding: "0 0 15px 0"},
                                 input: {height: "30px !important"},
                             }}/>
}