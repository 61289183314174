import {
    MANAGER_PARTNERS_ADD_OR_REMOVE_FROM_FAVORITES,
    MANAGER_PARTNERS_APPROVE_REGISTRATION, MANAGER_PARTNERS_CHANGE_LEVEL,
    MANAGER_PARTNERS_FILTER_DATA_LOADED,
    MANAGER_PARTNERS_LOADED,
    MANAGER_PARTNERS_LOADING, MANAGER_PARTNERS_REJECT_REGISTRATION, MANAGER_PARTNERS_TOGGLE_PAYMENT_AVAILABLE,
} from "../../actions/actionTypes";
import {partnerLevelsDispatcher} from "../../utils/partnerLevelUtils";

const initialState = {
    loading: false,
    partners: null,
    partnersFilterData: [],
    partnersList: [],
};

export default function(state = initialState, action) {
    switch (action.type) {
        case MANAGER_PARTNERS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case MANAGER_PARTNERS_LOADED:
            return {
                ...state,
                loading: false,
                partners: action.payload.map(group => {
                    return {...group, partners : group.partners.map(partner => {return {...partner, levelRus : partnerLevelsDispatcher[partner.level].value}})}
                }),
            };
        case MANAGER_PARTNERS_FILTER_DATA_LOADED:
            return {
                ...state,
                loading: false,
                partnersFilterData: formatFilterData(action.payload),
                partnersList: groupedPartnersToPartnersList(action.payload),
            };
        case MANAGER_PARTNERS_APPROVE_REGISTRATION:
        case MANAGER_PARTNERS_REJECT_REGISTRATION:
        case MANAGER_PARTNERS_ADD_OR_REMOVE_FROM_FAVORITES:
        case MANAGER_PARTNERS_TOGGLE_PAYMENT_AVAILABLE:
        case MANAGER_PARTNERS_CHANGE_LEVEL:
            return {
                ...state,
                partners: state.partners.slice(0).map(group => {
                    return {
                        partnerType: group.partnerType,
                        partners: group.partners.slice(0).map(partner => partner.id !== action.payload.id ? partner : action.payload)
                    }
                })
            };
        default:
            return state;
    }
}

const formatFilterData = (partners) => {
    const result = [];
    if (!partners) {
        return result;
    }
    partners.forEach(group => {
        result.push({
            partnerType: group.partnerType,
            partnerTypeId: group.partnerTypeId,
            id: group.partnerType,
            key: group.partnerTypeId + group.partnerType,
            options: group.partners.map(partner => {
                return {...partner, key: partner.id + "_" + partner.partnerTypeId}
            })
        });
    });
    return result;
};

const groupedPartnersToPartnersList = (groupedPartners) => {
    const partnersList = groupedPartners.flatMap(group => group.partners);
    const uniquePartnerNames = new Set();
    const result = [];
    partnersList.forEach(partner => {
        if (!uniquePartnerNames.has(partner.partnerName)) {
            uniquePartnerNames.add(partner.partnerName);
            result.push(partner);
        }
    });
    return result
        .sort((a, b) => a.partnerName > b.partnerName ? 1 : a.partnerName < b.partnerName ? -1 : 0);
};