import axios from "axios";
import {returnErrors} from "./errorActions";
import {getConfig} from './authActions';
import {getPartnerId} from "../utils/authUtils";
import {
    TOGGLE_OFFER_SWITCH,
    TOGGLE_ALL_OFFERS_SWITCH,
    STATISTIC_TABLE_ACTIVE_OFFERS_LOADING,
    STATISTIC_TABLE_ACTIVE_OFFERS_LOADED,
    DASHBOARD_DYNAMIC_LOADING, DASHBOARD_DYNAMIC_LOADED
} from "./actionTypes";


export const loadDashboardDynamic = () => (dispatch, getState) => {
    dispatch({type: DASHBOARD_DYNAMIC_LOADING});

    let partnerId = getPartnerId() ? 'partnerId=' + getPartnerId() : '';

    axios
        .get(process.env.REACT_APP_API_URL + '/dashboard/dynamic?' + partnerId, getConfig(getState))
        .then(res =>
            dispatch({
                type: DASHBOARD_DYNAMIC_LOADED,
                payload: addCheckedField(res.data)
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const activeOffers = () => (dispatch, getState) => {
    dispatch({type: STATISTIC_TABLE_ACTIVE_OFFERS_LOADING});

    let partnerId = getPartnerId() ? 'partnerId=' + getPartnerId() : '';

    axios
        .get(process.env.REACT_APP_API_URL + (partnerId ? ('/offers/active?' + partnerId) : '/offers/parent'), getConfig(getState))
        .then(res =>
            dispatch({
                type: STATISTIC_TABLE_ACTIVE_OFFERS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export function toggleOfferSwitch(offerId) {
    return dispatch => {
        dispatch({type: TOGGLE_OFFER_SWITCH, payload: offerId});
    }
}

export function toggleAllOffersSwitch(isChecked) {
    return dispatch => {
        dispatch({type: TOGGLE_ALL_OFFERS_SWITCH, payload: isChecked});
    }
}

function addCheckedField(activeOffers) {
    activeOffers.forEach(function (offer) {
        offer.checked = true;
    });
    return activeOffers;
}
