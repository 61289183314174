import React, {Component} from "react";
import {connect} from "react-redux";
import CapNameTable from "./CapNameTable";
import {
    deleteCapNameItem,
    loadCapNames,
    reloadCapNames,
    saveOrUpdateCapNameItem
} from "../../../../actions/capNamesActions";


class CapContent extends Component {

    state = {
        renderedData: [],
        editableCapNameItem: null,
        hasChanges: false,
    };

    componentDidMount() {
        this.props.loadCapNames();
    }

    handleTyping = (e) => {

        if (!this.state.editableCapNameItem) {
            this.setState({...this.state, editableCapNameItem: {[e.target.name]: e.target.value}, hasChanges: true});
        } else {
            this.setState({
                ...this.state,
                editableCapNameItem: {...this.state.editableCapNameItem, [e.target.name]: e.target.value},
                hasChanges: true
            })
        }
    };

    setEditableCapNameItem = (capNameItem) => {
        this.setState({...this.state, editableCapNameItem: capNameItem, hasChanges: false});
    };

    clearEditableCapNameItem = () => {
        this.setState({...this.state, editableCapNameItem: null, hasChanges: false});
    };


    saveCapNameItem = () => {
        this.state.hasChanges && this.props.saveOrUpdateCapNameItem(this.state.editableCapNameItem, this.afterSaveOrDeleteCallback)
    };

    deleteCapNameItem = (capNameItem) => {
        this.props.deleteCapNameItem(capNameItem, this.afterSaveOrDeleteCallback)
    };

    afterSaveOrDeleteCallback = () => {
        this.setState({...this.state, hasChanges: false, editableCapNameItem: null});
        this.props.loadCapNames();
    };


    render() {
        return <>
            <CapNameTable capNames={this.props.capNameItems} editableCapNameItem={this.state.editableCapNameItem}
                          setEditableCapNameItem={this.setEditableCapNameItem}
                          handleTyping={this.handleTyping} clearEditableCapNameItem={this.clearEditableCapNameItem}
                          saveCapNameItem={this.saveCapNameItem} deleteCapNameItem={this.deleteCapNameItem}/>
            <div>
                <div className="circle-btn-group" style={{position: "absolute", bottom: "0", backgroundColor: "white"}}>
                    <button className={"circle-btn"}
                            title="Синхронизировать данные с трекером"
                            onClick={() => this.props.reloadCapNames()}><i
                        className="ti ti-reload"/></button>
                </div>
            </div>
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        capNameItems: state.capNamesReducer.capNames,
        isLoading: state.capNamesReducer.loading,
    }
};

export default connect(mapStateToProps, {
    loadCapNames, deleteCapNameItem, saveOrUpdateCapNameItem, reloadCapNames
})(CapContent);