
export const setToLocalStorage = (key, value, storagePeriodInHours) => {
    if (!value || !key) {
        return;
    }
    if (storagePeriodInHours) {
        value.expirationDateInMs = new Date().getTime() + (storagePeriodInHours * 60 * 60 * 1000);
        localStorage.setItem(key, JSON.stringify(value));
    }
};

export const getFromLocalStorage = (key) => {
    const value =  JSON.parse(localStorage.getItem(key));

    if (!value) {
        return null;
    }
    if (value.expirationDateInMs && (new Date().getTime() > value.expirationDateInMs)) {
        localStorage.removeItem(key);
        return null;
    } else {
        value.fromDate = value.fromDate ? new Date(value.fromDate) : null;
        value.toDate = value.toDate ? new Date(value.toDate) : null;
        value.expirationDateInMs = undefined;
    }
    return value;
};