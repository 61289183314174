import {
    SAVE_MANAGER_PROFILE,
    SAVE_PARTNER_PROFILE,
    USER_DATA_LOADED,
    USER_DATA_LOADING, USER_REFERRAL_ID_LOADED
} from "./actionTypes";
import {currentUserId, getPartnerId, isAdminAsPartner, isPartner} from "../utils/authUtils";
import axios from "axios";
import {login, getConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";

export const loadUserData = (callbackFunction) => (dispatch, getState) => {
    dispatch({type: USER_DATA_LOADING});
    const isPartnerProfilePage = isPartner() || isAdminAsPartner();
    const url = isPartnerProfilePage
    ? process.env.REACT_APP_API_URL + '/users/partner/' + getPartnerId()
        : process.env.REACT_APP_API_URL + '/users/manager/' + currentUserId();
    let user;
    axios
        .get(url, getConfig(getState))
        .then(res =>
            dispatch({
                type: USER_DATA_LOADED,
                payload: user = res.data
            })
        )
         .then(() => callbackFunction && callbackFunction(user))
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const saveProfile = (profile) => (dispatch, getState) => {
    if (isPartner() || isAdminAsPartner()) {
        savePartnerProfile(profile, dispatch, getState);
    } else {
        saveManagerProfile(profile, dispatch, getState);
    }
};

const savePartnerProfile = (profile, dispatch, state) => {
    let partnerId = getPartnerId() ? getPartnerId() : '';
    if (profile.defaultProject) {
        profile.defaultProject.trafficbackUrl = profile.defaultProject.trafficbackUrl === "" ? null : profile.defaultProject.trafficbackUrl;
        profile.defaultProject.postbackUrl = profile.defaultProject.postbackUrl === "" ? null : profile.defaultProject.postbackUrl;
        profile.defaultProject.postbackEventTypes = profile.defaultProject.postbackUrl === "" ? null : profile.defaultProject.postbackEventTypes;
    }
    axios
        .put(process.env.REACT_APP_API_URL + '/users/partner/' + partnerId, profile, getConfig(currentUserId))
        .then(res => {
                dispatch({
                    type: SAVE_PARTNER_PROFILE,
                    payload: res.data
                });
            }

        )
        .then(() => {
            if (profile.password) {
                dispatch(login({login: profile.login, password: profile.password}));
            }
        })
        .then(() => {dispatch(getSuccess({success: "Изменения сохранены"}))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

const saveManagerProfile = (profile, dispatch, state) => {
    axios
        .put(process.env.REACT_APP_API_URL + '/users/manager/', profile, getConfig(state))
        .then(res => {
                dispatch({
                    type: SAVE_MANAGER_PROFILE,
                    payload: res.data
                });
            }

        )
        .then(() => {
            if (profile.password) {
                dispatch(login({login: profile.login, password: profile.password}));
            }
        })
        .then(() => {dispatch(getSuccess({success: "Изменения сохранены"}))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
}

export const loadReferralUrl = (callbackFunction) => (dispatch, getState) => {
    let user;
    axios
        .get(process.env.REACT_APP_API_URL + '/users/partner/' + getPartnerId() + '/referralId', getConfig(getState))
        .then(res =>
            dispatch({
                type: USER_REFERRAL_ID_LOADED,
                payload: window.location.host + '?ref=' + res.data
            })
        )
        .then(() => callbackFunction && callbackFunction(user))
        .catch(err => {
            dispatch(returnErrors(err));
        });
};
