import React, {useEffect, useRef, useState} from "react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {Calendar} from 'react-date-range';
import {TextField} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import * as rdrLocales from 'react-date-range/dist/locale';
import useOutsideClick from "../../../hooks/useOutsideClick";
import PropTypes from "prop-types";
import {isMobileScreen} from "../../../utils/displayUtils";
import useScreenSize from "../../../hooks/useScreenSize";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        fontSize: 14
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 122,
        textAlign: 'center'
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        minWidth: 150,
        fontSize: 14
    },
    input: {},
    errorInput: {
        color: "red"
    }
}));

DatePicker.propTypes = {
    date: PropTypes.instanceOf(Date),
    onSelectDate: PropTypes.func,
    onClosePicker: PropTypes.func,
    hidden: PropTypes.bool,
    inputType: PropTypes.oneOf(['materialUi', 'simple']),
    inputLabel: PropTypes.string,
    autoClose: PropTypes.bool,
    disabled: PropTypes.bool,
};


export default function DatePicker(props) {
    const {date, onSelectDate, onClosePicker, hidden, inputType='materialUi', inputLabel='Дата', inputWidth,
        pickerWrapperStyle, autoClose, disabled, minDate, maxDate, hasError} = props;
    const classes = useStyles();

    const [isDisplayed, setDisplayed] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [value, setValue] = useState("");
    const [invalid, setInvalid] = useState(false);
    const ref = useRef();

    useEffect(() => {
        setValue(formatDate(date));
    }, [date]);

    useScreenSize();
    const mobile = isMobileScreen();


    const handleTextTyping = (e) => {
        const value = e.target.value;
        setValue(value);
        if (value === "" || value.match(/(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.([2][0][1-2][0-9])$/)) {
            const parsedDate = parseDate(value);
            onSelectDate && onSelectDate(parsedDate);
            setInvalid(false);
            setHasChanges(true);
        } else {
            setInvalid(true);
        }
    };


    const handleSelect = (date) => {
        setHasChanges(true);
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
        onSelectDate && onSelectDate(date);
        setValue(formatDate(date));
        setInvalid(false);
        autoClose && showOrHide();
    };

    const showOrHide = () => {
        if (disabled) {
            return;
        }
        const displayed = !isDisplayed;
        setDisplayed(displayed);
        if (onClosePicker && hasChanges && date && displayed === false) {
            onClosePicker();
            setHasChanges(false);
        }
    };

    const hide = () => {
        setDisplayed(false);
        if (onClosePicker && hasChanges && date) {
            onClosePicker();
            setHasChanges(false);
        }
    };


    useOutsideClick(ref, hide);

    const onKeyDown = (e) => {
        if (e.keyCode === 13) { //Enter
            hide();
        }
    };

    const simpleInput = () => {
        return <input type="text" placeholder="дд.мм.гггг" className={invalid || hasError ? "form-control input-error" : "form-control"}
                       value={value} onChange={handleTextTyping} disabled={disabled}/>

    };

    const materialUiInput = () => {
        return <TextField
            id={inputLabel}
            label={inputLabel}
            className={classes.textField}
            value={value}
            margin="normal"
            style={{fontSize: '14px', display: 'flex', textAlign: "left", width: inputWidth}}
            InputProps={{
                readOnly: !!isMobileScreen(),
                className: invalid || hasError ? classes.errorInput : classes.input
            }}
            onChange={handleTextTyping}
            disabled={disabled}
        />
    };

    const input = () => {
        switch (inputType) {
            case "simple":
                return simpleInput();
            case "materialUi":
                return materialUiInput();
            default:
                return materialUiInput();

        }
    };


    return (
        <>
            <div ref={ref} onKeyDown={onKeyDown}>
                <div onClick={() => showOrHide()}>
                    {input()}
                </div>
                <div className="datePickerCalendarWrapper" hidden={!isDisplayed || hidden} style={pickerWrapperStyle}>
                    <Calendar
                        locale={rdrLocales.ru}
                        date={date}
                        onChange={handleSelect}
                        direction={mobile ? 'vertical' : 'horizontal'}
                        rangeColors={['#17a2b8']}
                        showDateDisplay={false}
                        months={1}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </div>
            </div>
        </>
    )
}

const formatDate = (date) => {
    if (!date) {
        return "дд.мм.гггг";
    }
    return date
        ? ('0' + date.getDate()).slice(-2)
        + "."
        + ('0' + (date.getMonth() + 1)).slice(-2)
        + "." + date.getFullYear().toString().substr(-4)
        : "";
};

const parseDate = (dateString) => {
    if (!dateString || !dateString.match(/(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.([2][0][1-2][0-9])/)) {
        return;
    }
    return new Date(parseInt(dateString.substring(6, 10)), (parseInt(dateString.substring(3, 5)) - 1), parseInt(dateString.substring(0, 2)));
};
