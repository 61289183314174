import {
    OPERATOR_LOGO_UPLOADED,
    OPERATORS_LOADED,
    OPERATORS_LOADING,
    OPERATORS_SYNC_COMPLETED,
    OPERATORS_SYNC_IN_PROGRESS
} from "./actionTypes";
import axios from "axios";
import {getFormDataConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";

export const loadAllOperators = () => (dispatch, getState) => {
    dispatch({type: OPERATORS_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/operators', getFormDataConfig(getState))
        .then(res =>
            dispatch({
                type: OPERATORS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};


export const uploadOperatorLogo = (file, countryId, callbackFunction) => (dispatch, getState) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    axios
        .post(process.env.REACT_APP_API_URL + '/operators/' + countryId + '/logo', formData, getFormDataConfig(getState))
        .then(res =>
            dispatch({
                type: OPERATOR_LOGO_UPLOADED,
                payload: res.data
            })
        )
        .then(() => {
            dispatch(getSuccess({success: "Логотип загружен"}))
        })
        .then(() => callbackFunction && callbackFunction())
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const syncOperatorsWithGateconfig = (callbackFunction) => (dispatch, getState) => {
    dispatch({type: OPERATORS_SYNC_IN_PROGRESS});
    axios
        .get(process.env.REACT_APP_API_URL + '/operators/sync', getFormDataConfig(getState))
        .then(res =>
            dispatch({
                type: OPERATORS_SYNC_COMPLETED,
                payload: res.data
            })
        )
        .then(() => callbackFunction && callbackFunction())
        .then(() => {
            dispatch(getSuccess({success: "Данные синхронизированы"}))
        })
        .catch(err => {
            dispatch(returnErrors(err));
        });
};