import React, {useRef} from "react";
import {LinkCopyPopup} from "../../common/linkCopyPopup/LinkCopyPopup";
import {isAdmin, isPartner} from "../../../utils/authUtils";

export default function ProfileAvatarHeaderCard({name, onTextTyping, email, dominicPartnerId, isPartnerProfilePage}) {

    const childRef = useRef();

    return (
            <div className="col-lg-12 col-12 no-padding">
                <div className="card">
                    <div className="card-body padding15">
                        {isPartnerProfilePage &&
                        <div className="id" onClick={() => childRef.current.handleClickCopy(dominicPartnerId)}>
                            <span>ID</span><span id="user-id">{dominicPartnerId}</span>
                        </div>}
                        <div className="col-12 avat-bg">

                            <div className="title-block">
                                {isPartnerProfilePage && <input type="name"  name="name" className="edit-name color-gray" placeholder="Имя пользователя"
                                       value={name} maxLength="25" onChange={(e) => onTextTyping(e)}/>}
                                {isAdmin() && <input className="secondary fs22 e-mail form-control padding5" value={email} onChange={onTextTyping} name="email"/>}
                                {isPartner() && <p className="secondary fs22 e-mail padding5">{email}</p>}
                            </div>
                        </div>
                    </div>
                </div>
                <LinkCopyPopup ref={childRef} message="ID скопирован"/>
        </div>
    )
}
