import React from "react";

export default function SelectedOffersPopup({amount, img, txt = "Выбрано офферов: ", onClick, toggleOnlySelected}) {

    return (
        amount && amount > 0
            ? <div className="smart-create">
                <div className="text" onClick={toggleOnlySelected} style={{cursor: "pointer"}}>
                    {txt}
                    <span>{amount}</span>
                </div>
                <div className="create-link" onClick={onClick && onClick}>
                    <img src={img} alt=""/>
                </div>
            </div>
            : <></>
    )
}