import React, {useEffect, useState} from 'react';
import MaterialUIMultiSelect from "../../../../common/selects/MaterialUIMultiSelect";
import {useSelector} from "react-redux";

export default function OffersOperatorFilter({selectedOperators, selectedCountries, onSelectOperator, offers, inputStyle}) {

    const {operators} = useSelector(state => state.filterDataReducer.filterData);

    const [dropdownValues, setDropdownValues] = useState([]);

    useEffect(() => {
        const getFilteredOperators = () => {
            let resultOperators = [];
            if (operators && offers) {
                let availableOperatorIds = selectedCountries.length > 0 && selectedCountries.flatMap(country => country.operatorIds);
                operators.forEach(function (operator) {
                    const found = offers.find(function (offer) {
                        return offer.operatorId === operator.id && (!availableOperatorIds || availableOperatorIds.includes(offer.operatorId));
                    });
                    if (found) {
                        resultOperators.push(operator);
                    }
                })
            }
            return resultOperators;
        };
        setDropdownValues(getFilteredOperators());
    }, [selectedOperators, selectedCountries, offers, operators]);


    return (
        <MaterialUIMultiSelect styles={inputStyle} dropdownData={dropdownValues} values={selectedOperators} dataField='id'
                               displayedField='name' label='Операторы' placeholder='-'
                               onSelect={onSelectOperator}/>
    )
}


