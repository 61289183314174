import React, {Fragment} from "react";
import DateTimePicker from "../../../common/pickers/DateTimePicker";

export default function NewsTimePicker(props) {

    const {plannedPublicationDate, onSelectPlannedPublicationDate} = props;
    return (
        <Fragment>
            <label htmlFor="dateTime">Время</label>
            <DateTimePicker selectedDate={plannedPublicationDate} handleDateChange={onSelectPlannedPublicationDate}/>
        </Fragment>
    )
}