import React, {useEffect, useRef} from "react";

import OperatorImage from "../../../common/images/OperatorImage";
import {connect} from "react-redux";
import {loadAllOperators, uploadOperatorLogo} from "../../../../actions/operatorsActions";
import SimpleTable from "../../../common/tables/SimpleTable";
import RefreshOperatorsComponent from "./RefreshOperatorsComponent";
import CountryImage from "../../../common/images/CountryImage";
import {loadAllCountries, uploadCountryLogo} from "../../../../actions/countriesActions";

const COUNTRY = "country";
const OPERATOR = "operator";


function OperatorsToolsContent({countries, operators, loadAllOperators, loadAllCountries, uploadCountryLogo, uploadOperatorLogo}) {

    useEffect(() => {
        loadAllOperators();
        loadAllCountries();
    }, []);

    const inputFileRef = useRef();

    const onSelectFile = (type, id) => {

        const input = inputFileRef.current;
        input.name = type;
        input.id = id;
        inputFileRef.current.click();

    };

    const onChangeFile = (e) => {
        const inputName = e.target.name;
        const id = e.target.id;
        if (!inputName) {
            return;
        }
        const file = e.target.files[0];
        switch (inputName) {
            case COUNTRY:
                uploadCountryLogo(file, id, () => loadAllCountries());
                break;
            case OPERATOR:
                uploadOperatorLogo(file, id, () => loadAllOperators());
                break;
            default:
                return;

        }
    };


    const countriesTableColumns = () => {
        return [
            {
                name: "ID",
                key: "id",
                getBodyCellValue: item => item["id"],
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "Название",
                key: "name",
                getBodyCellValue: item => item["name"],
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "charCode2",
                key: "charCode2",
                getBodyCellValue: item => item["charCode2"],
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "Логотип",
                key: "logo",
                getBodyCellValue: item => <>
                    <div className="td-operator" title="Загрузить новый логотип" onClick={(e) => onSelectFile(COUNTRY, item.id)}>
                        <CountryImage fileName={item["imgFileName"]} title={item["name"]} className="h-25 w-25"/>
                    </div>
                </>,
                headerCellStyle: {width: "50px"},
                bodyCellStyle: {textAlign: "center", cursor: "pointer"},
            }
        ]
    };

    const operatorsTableColumns = () => {
        return [
            {
                name: "ID",
                key: "id",
                getBodyCellValue: item => item["id"],
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "External ID",
                key: "externalId",
                getBodyCellValue: item => item["externalId"],
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "Название",
                key: "name",
                getBodyCellValue: item => item["name"],
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "Логотип",
                key: "logo",
                getBodyCellValue: item => <div>
                    <div className="td-operator" title="Загрузить новый логотип" onClick={(e) => onSelectFile(OPERATOR, item.id)}>
                        <OperatorImage fileName={item["imgFileName"]} title={item["name"]} className="h-25 w-25"/>
                    </div>
                </div>,
                headerCellStyle: {width: "50px"},
                bodyCellStyle: {textAlign: "center", cursor: "pointer"},
            }
        ]
    };



    return <div className="tab-pane fade active show pl-3" id="custom-nav-home" role="tabpanel"
                aria-labelledby="custom-nav-home-tab">
        <div className="row">
            <RefreshOperatorsComponent/>
        </div>

        <div className="row" style={{marginBottom: "20px"}}>
            <div className="col-lg-6 col-md-12 col-sm-12">
                <label>Операторы</label>
                <SimpleTable
                    tableClassName="table table-bordered nowrap statistic_table sticky-header sticky-action sticky-footer userselecttext"
                    columns={operatorsTableColumns()}
                    data={operators} bodyRowKey="id"/>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                <label>Страны</label>
                <SimpleTable
                    tableClassName="table table-bordered nowrap statistic_table sticky-header sticky-action sticky-footer userselecttext"
                    columns={countriesTableColumns()}
                    data={countries} bodyRowKey="id"/>
            </div>
            <input type="file" accept="image/*" style={{display: "none"}} ref={inputFileRef}
                   onChange={(e) => onChangeFile(e)} onSelect={(e) => onChangeFile(e)}/>
        </div>
    </div>
}

function mapStateToProps(state) {
    return {
        operators: state.operatorsReducer.operators,
        countries: state.countriesReducer.countries,
    }
}

export default connect(mapStateToProps, {
    loadAllOperators,
    loadAllCountries,
    uploadCountryLogo,
    uploadOperatorLogo,
})(OperatorsToolsContent);