import {
    DELETE_USER_PRESET,
    SAVE_USER_PRESET,
    USER_PRESETS_LOADED,
    USER_PRESETS_LOADING
} from "../../actions/actionTypes";
import {
    ASC,
    PARTNER_AD_NETWORK_DATA,
    PARTNER_MACROS, PARTNER_PAYMENT_DETAILS,
    STATISTIC_TABLE_COLUMNS,
    STATISTIC_TABLE_FILTERS
} from "../../utils/constants";
import {sort} from "../../utils/sortUtils";


const initialState = {
    statisticFiltersPresets: null,
    statisticColumnsPreset: null,
    macrosPreset: null,
    partnerAdNetworkPresets: null,
    partnerPaymentDetailsPresets: null,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_PRESETS_LOADING:
            return {
                ...state,
                loading: true
            };
        case USER_PRESETS_LOADED:
            return {
                ...state,
                statisticFiltersPresets: action.payload.type === STATISTIC_TABLE_FILTERS ? action.payload.data : state.statisticFiltersPresets,
                statisticColumnsPreset: action.payload.type === STATISTIC_TABLE_COLUMNS ? (action.payload.data ? action.payload.data[0] : state.statisticColumnsPreset) : state.statisticColumnsPreset,
                macrosPreset: action.payload.type === PARTNER_MACROS ? (action.payload.data ? action.payload.data[0] : state.macrosPreset) : state.macrosPreset,
                partnerAdNetworkPresets: action.payload.type === PARTNER_AD_NETWORK_DATA ? action.payload.data : state.partnerAdNetworkPresets,
                partnerPaymentDetailsPresets: action.payload.type === PARTNER_PAYMENT_DETAILS ? sort(action.payload.data, "label", ASC) : state.partnerPaymentDetailsPresets,
                loading: false
            };
        case SAVE_USER_PRESET:
            const savedPreset = action.payload.result;
            return {
                ...state,
                statisticFiltersPresets: (savedPreset.type === STATISTIC_TABLE_FILTERS && !contains(state.statisticFiltersPresets, savedPreset)) ?
                    ([...state.statisticFiltersPresets, savedPreset]) : state.statisticFiltersPresets,
                statisticColumnsPreset: (savedPreset.type === STATISTIC_TABLE_COLUMNS) ?
                    savedPreset : state.statisticColumnsPreset,
                macrosPreset: (savedPreset.type === PARTNER_MACROS) ? savedPreset : state.macrosPreset,
                partnerAdNetworkPresets: (savedPreset.type === PARTNER_AD_NETWORK_DATA && !contains(state.partnerAdNetworkPresets, savedPreset)) ?
                    ([...state.partnerAdNetworkPresets, savedPreset]) : state.partnerAdNetworkPresets,
                partnerPaymentDetailsPresets: (savedPreset.type === PARTNER_PAYMENT_DETAILS && !contains(state.partnerPaymentDetailsPresets, savedPreset)) ?
                    ([...state.partnerPaymentDetailsPresets, savedPreset]) : state.partnerPaymentDetailsPresets,
            };
        case DELETE_USER_PRESET:
            return {
                ...state,
                statisticFiltersPresets: action.payload && action.payload.type === STATISTIC_TABLE_FILTERS ?
                    excludeDeletedPreset(action.payload.id, state.statisticFiltersPresets) : state.statisticFiltersPresets,
                partnerAdNetworkPresets: action.payload && action.payload.type === PARTNER_AD_NETWORK_DATA ?
                    excludeDeletedPreset(action.payload.id, state.partnerAdNetworkPresets) : state.partnerAdNetworkPresets,
                partnerPaymentDetailsPresets: action.payload && action.payload.type === PARTNER_PAYMENT_DETAILS ?
                    excludeDeletedPreset(action.payload.id, state.partnerPaymentDetailsPresets) : state.partnerPaymentDetailsPresets,
            };
        default:
            return state;

    }
}

function contains(presets, preset) {
    let isContains = false;
    if (presets && preset) {
        presets.forEach(function (pres) {
            if (pres.id === preset.id) {
                isContains = true;
            }
        });
    }
    return isContains;
}

function excludeDeletedPreset(deletedPresetId, presets) {
        presets = presets.filter(function (preset) {
            return deletedPresetId !== preset.id;
        });
    return presets;
}
