import React, {Fragment} from "react";
import PeriodButton from "./PeriodButton";

export default function PeriodButtonsGroupFilter({buttons=[{isSelected: false, title: "", iconClassName: "", onClick: () => {}, hidden: false}]}) {

    return(
            <>
                        {
                            buttons.map(btn =>
                                !btn.hidden &&
                                <Fragment key={btn.title} >
                                        <PeriodButton isSelected={btn.isSelected} title={btn.title} iconClassName={btn.iconClassName}
                                                      onClick={btn.onClick}/>
                                </Fragment>)
                        }
            </>
    )
}