import React, {Fragment, useEffect, useRef} from "react";
import ChatMessage from "./ChatMessage";
import {
    deductTimezoneOffset,
    getRusDateHourString,
} from "../../../utils/dateUtils";

export default function ChatBody({messages, isOpened}) {
    const messagesBodyRef = useRef();

    useEffect(() => {
        const element = messagesBodyRef && messagesBodyRef.current;
        element.scrollTo({top: element.scrollHeight});
    }, [messagesBodyRef, messages, isOpened]);

    const getCreateDate = (message) => {
        return message && message.createDate && new Date(message.createDate);
    };


    return (
        <div className="messages" id="chat" ref={messagesBodyRef}>
            {messages && messages.map((message, index) => {
                const currentMsgCreateDate = getCreateDate(message);
                const serverCurrentDate = deductTimezoneOffset(new Date());
                return (
                    <Fragment key={message.id} >
                        {
                            (index === 0 || new Date(message.createDate).toISOString().substring(0, 10) > new Date(messages[index - 1].createDate).toISOString().substring(0, 10)) &&
                        <div className="time" >
                            {currentMsgCreateDate.getDate() === serverCurrentDate.getDate()
                                ? "Сегодня " + ("0" + currentMsgCreateDate.getHours()).slice(-2) + ":" + ("0" + currentMsgCreateDate.getMinutes()).slice(-2)
                                : getRusDateHourString(currentMsgCreateDate)}
                        </div>
                        }
                        {
                            ((index === 0 && message.status === "SENT_BY_MANAGER") || (index > 0 && message.status === "SENT_BY_MANAGER" && messages[index - 1].status !== "SENT_BY_MANAGER"))
                            &&  <div className="time">Нерочитанные сообщения</div>
                        }
                        <ChatMessage messageObj={message}/>
                    </Fragment>
                )

            })}
        </div>
    )
}