import {
    CHANGE_PARTNER_LINKS_PROJECT_FILTER,  PARTNER_PROJECT_DELETED,
    PARTNER_PROJECT_SAVED,
    PARTNER_PROJECTS_LOADED, PARTNER_PROJECTS_LOADED_WITH_NEW,
    PARTNER_PROJECTS_LOADING
} from "./actionTypes";
import {getPartnerId} from "../utils/authUtils";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnAllErrorsAsPopup, returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";

export const loadPartnerProjects = () => (dispatch, getState) => {

    dispatch({type: PARTNER_PROJECTS_LOADING});

    let partnerId = getPartnerId() ? 'partnerId=' + getPartnerId() : '';

    axios
        .get(process.env.REACT_APP_API_URL + '/projects?' + partnerId + '&sortBy=sourceId&orderDirection=asc', getConfig(getState))
        .then(res =>
            dispatch({
                type: PARTNER_PROJECTS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });

};

export const loadPartnerProjectsWithNew = () => (dispatch, getState) => {

    dispatch({type: PARTNER_PROJECTS_LOADING});

    let partnerId = getPartnerId() ? 'partnerId=' + getPartnerId() : '';

    axios
        .get(process.env.REACT_APP_API_URL + '/projects?' + partnerId + '&sortBy=sourceId&orderDirection=asc', getConfig(getState))
        .then(res =>
            dispatch({
                type: PARTNER_PROJECTS_LOADED_WITH_NEW,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });

};

export const saveProject = (project, onSuccessFunction) => (dispatch, getState) => {

    const success = {success: "Поток '" + project.name + "' сохранён"};
    let body = project;
    body.partnerId = getPartnerId();
    body.trafficbackUrl = body.trafficbackUrl === "" ? null : body.trafficbackUrl;
    body.postbackUrl = body.postbackUrl === "" ? null : body.postbackUrl;
    body.postbackEventTypes = body.postbackUrl === "" ? null : body.postbackEventTypes;
    const isNew = !project.projectId;
    let createdProject;
    axios
        .post(process.env.REACT_APP_API_URL + '/projects', body, getConfig(getState))
        .then(res =>
            dispatch({
                type: PARTNER_PROJECT_SAVED,
                payload: {project: createdProject = res.data, isNew: isNew}
            })
        )
        .then(() => {onSuccessFunction(createdProject);})
        .then(() => {
            if (!isNew) {
                dispatch(loadPartnerProjects())
            }
        })
        .then(() => {
        dispatch(getSuccess(success))
    })
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const deleteProject = (project) => (dispatch, getState) => {
    const success = {success: "Поток '" + project.name + "' удалён"};
    const partnerId = getPartnerId();
    axios
        .delete(process.env.REACT_APP_API_URL + '/projects?projectId=' + project.id+ '&partnerId=' + partnerId, getConfig(getState))
        .then(res => {dispatch({type: PARTNER_PROJECT_DELETED});})
        .then(() => {dispatch(loadPartnerProjects())})
        .then(() => {dispatch(getSuccess(success))})
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
        });
};

export const selectProject = (project) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_PARTNER_LINKS_PROJECT_FILTER,
        payload: project
    })
};