
export const screenWidth = () => {
    return window.innerWidth;
};

export const isMobileScreen = (width) => {
    return  width ? width <= 414 : window.innerWidth <= 414;
};

export const isMobileVerticalScreen = () => {
    return  window.innerWidth <= 414 && window.innerWidth < window.innerHeight;
};

export const isMobileHorizontalScreen = () => {
    return  window.innerHeight<= 500 && window.innerWidth > window.innerHeight && window.innerWidth <= 1000;
};

export const isScreenWidthLessThen = (width) => {
    return  width && window.innerWidth < width;
};

export const isTabletScreen = () => {
    const width = screenWidth();
    return  width > 414 && width <= 1024;
};

export const isDesktop = () => {
    const width = screenWidth();
    return  width > 1024;
};

export const isScreenWidthLessThan1025 = () => {
    return window.innerWidth <= 1024;
};

export const isScreenWidthLessThan769 = () => {
    return window.innerWidth <= 769;
};


export const mobileFullWidthStyles = () => {
    return isDesktop() ? null :
        {width: isTabletScreen() ? document.documentElement.clientWidth - 83 : document.documentElement.clientWidth,
        maxWidth: '100%',
        minWidth: '100%'};
};

export const fullWidthStyles = (width) => {
    return {width: width ? width : 0, maxWidth: '100%', minWidth: '100%'};
};
