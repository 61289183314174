import React, {useState} from "react";
import PropTypes from "prop-types";
import {Popover, PopoverBody} from "reactstrap";
import {isMobileScreen} from "../../../utils/displayUtils";

IconPopover.propTypes = {
    target: PropTypes.string,
    iconClassName: PropTypes.string, /*Имя класса иконки*/
    iconColor: PropTypes.string, /*Цвет иконки*/
    iconSize: PropTypes.number, /*Размер иконки в в пикселях*/
    displayedContent: PropTypes.any, /*Отображаемый контент*/
    placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'center']), /*Расположение*/
    value: PropTypes.number, /*Значение*/

};

export default function IconPopover(props) {
    const {target, iconClassName, iconColor, iconSize, displayedContent, placement, value} = props;
    const [isOpen, setIsOpen] = useState(false);


    let styles = {
        color: (iconColor ? iconColor: null),
        fontSize: (iconSize ? iconSize + "px" : null),
        display: "inline-block"
    };

    function toggle() {
        setIsOpen(!isOpen);
    }

    function close() {
        setIsOpen(false);
    }

    const isMobile = isMobileScreen();

    return (
        <>
            <i id={target} className={iconClassName} color={iconColor}  style={styles}
               onMouseEnter={() => {if (displayedContent && !isMobile) {toggle();}}}
               onClick={() => {if (displayedContent && isMobile) {toggle();}}} onMouseLeave={() => {close();}}>{value}</i>
            <Popover isOpen={isOpen} target={target}  placement={placement} >
                <PopoverBody><>{displayedContent}</></PopoverBody>
            </Popover>
        </>
    );
}