import React, {useEffect, useState} from 'react';
import MaterialUIMultiSelect from "../../../../common/selects/MaterialUIMultiSelect";
import {useSelector} from "react-redux";


export default function SmartLinksCountryFilter({smartLinks, onSelectCountry, selectedCountries, inputStyle}) {
    const {countries} = useSelector(state => state.filterDataReducer.filterData);

    const [dropdownValues, setDropdownValues] = useState([]);

    useEffect(() => {
        const getFilteredCountries = () => {
            let resultCountries = [];
            if (countries && smartLinks) {
                countries.forEach(function (country) {
                    const found = smartLinks.find(function (smartLink) {
                        return smartLink.countryIds.includes(country.id);
                    });
                    if (found) {
                        resultCountries.push(country);
                    }
                })
            }
            return resultCountries;
        };
        setDropdownValues(getFilteredCountries());
    }, [smartLinks, countries, selectedCountries]);


    return (
        <MaterialUIMultiSelect styles={inputStyle} dropdownData={dropdownValues} values={selectedCountries}
                               dataField='operatorType' displayedField='name' label='Страны' placeholder='-'
                               onSelect={onSelectCountry}/>
    )
}


