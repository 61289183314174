import {ADD_RETENTION_EVENT, REMOVE_RETENTION_EVENT} from "../../actions/actionTypes";


const initialState = {
    retentionEvent: null
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case ADD_RETENTION_EVENT:
            return {
                ...state,
                retentionEvent: action.payload
            };
        case REMOVE_RETENTION_EVENT:
            return {
                ...state,
                retentionEvent: null
            };
        default:
            return state;

    }
}