import React from "react";
import DateRangePicker from "../../../../common/pickers/DateRangePicker";

export default function PaymentsDateRangePicker({fromDate, toDate, selectFromDate, selectToDate, onCloseDatePicker, hidden, inputStyle}) {

    return (
            <DateRangePicker fromDate={fromDate}
                             toDate={toDate}
                             selectFromDateFunction={selectFromDate}
                             selectToDateFunction={selectToDate}
                             onClosePicker={onCloseDatePicker}
                             hidden={hidden}
                             label="Дата"
                             inputStyle={inputStyle}
                             locatedInRightFilterBlock
            />
    )
}
