
/*xlsx*/
import {isNumberStringWithTwoDecimalPlaces} from "./validationUtils";

export const moneyCellFormat = (value) => {
    return value > 999 ? "0,000.00" : "0.00";
};
export const numLocalStringCellFormat = (value) => {
    return value > 999 ? "0,000" : "0";
};
export const percentageCellFormat = () => {
    return "0.00%";
};

export const getMoneyString = (number, scale) => {
    number = number ? number : 0;
    scale = scale ? scale : 2;
    return number.toLocaleString("ru-RU", {minimumFractionDigits: scale, maximumFractionDigits: scale});
};

export const getRubUsdMoneyString = (rubValue, usdValue) => {
    let value = getMoneyString(rubValue);
    if (usdValue) {
        value += " (" + getMoneyString(usdValue) + ")"
    }
    return value;
};

export const sumFieldsAndReturnMoneyString = (data, fieldName) => {
    if (!data) {
        return "";
    }
    const result = data.reduce((a, b) => a + b[fieldName], 0);
    return getMoneyString(result);
};

export const toDoubleOrNull = (stringNumber) => {
    if (!isNumberStringWithTwoDecimalPlaces(stringNumber)) {
        return null;
    }
    stringNumber = stringNumber.replace(/\,/g, ".");
    return parseFloat(stringNumber);
};

export const toDouble = (stringNumber, scale) => {
    if (isNaN(stringNumber)) {
        return Math.round(stringNumber * 100) / 100;
    }
    stringNumber = !stringNumber || "" ? "0" : stringNumber;
    scale = scale ? scale : 2;
    return parseFloat(stringNumber.replace(",", ".")).toFixed(scale);
};

export const toNumber = (stringNumber) => {
    stringNumber = stringNumber.replace(/\,/g, ".");
    if (isNaN(stringNumber)) {
        return stringNumber;
    }
    stringNumber = !stringNumber || "" ? "0" : stringNumber;
    return parseFloat(stringNumber.replace(",", "."));
};