import {CAP_LOADED, CAP_LOADING} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {getPartnerId} from "../utils/authUtils";

export const loadPartnerCaps = () => (dispatch, getState) => {
    const partnerId = getPartnerId();
    if (!partnerId) {
        return;
    }
    dispatch({type: CAP_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/caps/' + partnerId, getConfig(getState))
        .then(res =>
            dispatch({
                type: CAP_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};