import React from "react";
import SimpleSortableTableHeader from "./SimpleSortableTableHeader";
import SimpleSortableTableFooter from "./SimpleSortableTableFooter";
import SimpleSortableTableBody from "./SimpleSortableTableBody";


export default function SimpleSortableTable({columns, renderedData, allData, sortableColumn, sortableDirection, onSort, tableClassName="table table-sm statistic_table userselecttext", handleEditCell,  bodyRowKey}) {

    return (
        <table className={tableClassName}>
            <SimpleSortableTableHeader columns={columns} sortableColumn={sortableColumn} sortableDirection={sortableDirection}
                                       onSort={onSort}/>
            <SimpleSortableTableBody columns={columns} data={renderedData} handleEditCell={handleEditCell} bodyRowKey={bodyRowKey}/>
            <SimpleSortableTableFooter columns={columns} data={allData}/>
        </table>
    )
}