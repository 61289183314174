import React from "react";
import ProfileSimpleInput from "./inputs/ProfileSimpleInput";
import {fieldErrorValue} from "../../../utils/errorUtils";
import {connect} from "react-redux";
import {clearErrors, returnErrors} from "../../../actions/errorActions";

function ProfilePasswordChangeCard({password, confirmedPassword, onTextTyping, fieldErrors, returnErrors}) {

    const passwordError = fieldErrorValue(fieldErrors, "password");
    const confirmedPasswordError = fieldErrorValue(fieldErrors, "confirmedPassword");
    const handlePasswordTyping = (e) => {

        const fieldName = e.target.name;
        const value = e.target.value;
        let pw = fieldName === 'password' ? value : password;
        let confirmedPw = fieldName === 'confirmedPassword' ? value : confirmedPassword;

        if (pw === confirmedPw && (passwordError || confirmedPasswordError)) {
            fieldErrors = fieldErrors.filter(error => !['password', 'confirmedPassword'].includes(error.field));
            const error = {response: {data: {fieldErrors: fieldErrors}, status: 402}};
            returnErrors(error);
        } else if (pw !== confirmedPw) {
            fieldErrors = fieldErrors ? fieldErrors.filter(error => !['password', 'confirmedPassword'].includes(error.field)) : [];
            fieldErrors.push({field: 'password', error: 'Пароли не совпадают'});
            fieldErrors.push({field: 'confirmedPassword', error: 'Пароли не совпадают'});
            const error = {response: {data: {fieldErrors: fieldErrors}, status: 402}};
            returnErrors(error);
        }
        onTextTyping(e);
    };


    return (
        <div className="col-12 col-lg-6 padding5">
            <div className="card">
                <div className="card-header padding10">Смена пароля</div>
                <div className="profile-card-data padding15">
                    <div id="for-user">
                        <ProfileSimpleInput inputName="password" label="Пароль" value={password}
                                            placeholder="Введите новый пароль не менее 8 символов" type="password"
                                            isIncorrect={passwordError} onTextTyping={handlePasswordTyping} errorMsg={passwordError}/>
                        <ProfileSimpleInput inputName="confirmedPassword" label="Подтверждение пароля"
                                            value={confirmedPassword} type="password"
                                            placeholder="Подтвердите новый пароль"
                                            isIncorrect={confirmedPasswordError} onTextTyping={handlePasswordTyping} errorMsg={confirmedPasswordError}/>
                    </div>
                </div>
            </div>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        errors: state.error
    }
}

export default connect(mapStateToProps, {
    clearErrors,
    returnErrors,
})(ProfilePasswordChangeCard);
