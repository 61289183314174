import React from 'react';
import OffersOperatorFilter from "./OffersOperatorFilter";
import OffersCountryFilter from "./OffersCountryFilter";
import OffersCategoryFilter from "./OffersCategoryFilter";
import OffersIsNewFilter from "./OffersIsNewFilter";
import OffersIsRecommendedFilter from "./OffersIsRecommendedFilter";
import OffersIsSelectedFilter from "./OffersIsSelectedFilter";
import CircleButtonsGroup from "../../../../common/buttons/CircleButtonsGroup";

export default function ManagerOfferFilters(props) {
    const {offers, filters, resetAllFilters, toggleCheckboxFilter, onSelectFilterValue, inputStyle} = props;

    return (
            <>
                <div className={"pb-4 flex row"}>
                    <OffersIsNewFilter isOnlyNewOffers={filters.isOnlyNewOffers}
                                       toggleIsNew={() => toggleCheckboxFilter("isOnlyNewOffers")}/>
                    <OffersIsRecommendedFilter isOnlyRecommendedOffers={filters.isOnlyRecommendedOffers}
                                               toggleOnlyRecommended={() => toggleCheckboxFilter("isOnlyRecommendedOffers")}/>
                    <OffersIsSelectedFilter isOnlySelectedOffers={filters.isOnlySelectedOffers}
                                            toggleIsOnlySelected={() => toggleCheckboxFilter("isOnlySelectedOffers")}/>
                </div>

                <OffersCountryFilter key="countries" offers={offers} selectedCountries={filters.selectedCountries}
                                     onSelectCountry={(value) => onSelectFilterValue(value, "selectedCountries")}
                                     inputStyle={inputStyle}/>
                <OffersOperatorFilter key="operators" offers={offers} selectedOperators={filters.selectedOperators}
                                      onSelectOperator={(value) => onSelectFilterValue(value, "selectedOperators")}
                                      selectedCountries={filters.selectedCountries} inputStyle={inputStyle}/>
                <OffersCategoryFilter key="categories" offers={offers} selectedCategories={filters.selectedCategories}
                                      onSelectCategory={(value) => onSelectFilterValue(value, "selectedCategories")}
                                      inputStyle={inputStyle}/>
                <CircleButtonsGroup buttons={
                    [
                        {
                            isSelected: false,
                            title: "Очистить",
                            iconClassName: "ti ti-close stat-icon",
                            onClick: resetAllFilters
                        },
                    ]
                }/>
            </>
    );
}
