import React from "react";
import {columnsFormatter} from "../../../../utils/statisticUtils";
import {connect} from "react-redux";

function StatisticTableTotalFooterRow(props) {
    const {columns, groupName, data} = props;
    return (
        <tfoot className="thead-light">
        <tr>
            <th key={"header" + groupName} className={"center_position"}>Сумма:</th>
            {columns.map((column) => <th key={column.name} className={"right_position"}>
                {columnsFormatter[column.name]?.(data[column.name], data, props.isLoggedAsPartner)}
            </th>)}
        </tr>
        </tfoot>
    );
}


const mapStateToProps = state => ({
    isLoggedAsPartner: state.auth.isPartner || state.auth.isAdminAsPartner
});

export default connect(mapStateToProps, null)(StatisticTableTotalFooterRow);

