export function copyToClipboard(text) {
    if (text) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    console.log("Copied!");
                })
                .catch((error) => {
                    console.log("Copy failed! Error: ", error);
                    alternativeCopy(text);
                });
        } else {
            alternativeCopy(text);
        }
    }
}

const alternativeCopy = (text) => {
    let textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
};