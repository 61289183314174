import React from "react";
import MaterialUIGroupedMultiSelect from "../../../../common/selects/MaterialUIGroupedMultiSelect";


export default function ManagerPartnersFilter({partners, selectedPartners, selectPartners}) {

    const styles = {
        root: {
            minWidth: 200
        }
    };

    const onSelect = (value) => {
        selectPartners(value, true);
    };

    return (
        <div className="rw-multiselect rw-widget float-left">
            <span className="rw-sr"></span>
            <div className="rw-widget-input rw-widget-picker rw-widget-container">
                <MaterialUIGroupedMultiSelect
                    styles={styles}
                    dropdownData={partners}
                    groupDisplayedField='partnerType'
                    groupDataField='partnerTypeId'
                    values={selectedPartners}
                    dataField='key'
                    displayedField='partnerName'
                    label={'Список партнёров'}
                    placeholder={'-'}
                    onSelect={onSelect}
                    isSearchable
                    maxChipTxtWidth={140}
                />
            </div>
        </div>
    );
}