import { GET_ERRORS, CLEAR_ERRORS } from '../../actions/actionTypes';

const initialState = {
  data: {},
  status: null,
  id: null,
  display: false
};

export default function(state = initialState, action) {
  switch(action.type) {
    case GET_ERRORS:
      return {
        data: action.payload.errorResponse ? action.payload.errorResponse.data : state.data,
        status:  action.payload.errorResponse ? action.payload.errorResponse.status : state.status,
        id: action.payload.id,
        display: true
      };
    case CLEAR_ERRORS:
      return {
        data: {},
        status: null,
        id: null,
        display: false
      };
    default:
      return state;
  }
}