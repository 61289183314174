import {
    LTV_SERVICE_TYPES_LOADING,
    LTV_SERVICE_TYPES_LOADED,
} from '../../actions/actionTypes';

const initialState = {
    ltvServiceTypes: [],
    loading: false
};

export default function (state = initialState, action = {}) {

    switch (action.type) {
        case LTV_SERVICE_TYPES_LOADING:
            return {
                ...state,
                loading: true
            };
        case LTV_SERVICE_TYPES_LOADED:
            return {
                ...state,
                ltvServiceTypes: action.payload,
                loading: true
            };
        default:
            return state;

    }
}
