import React, {useEffect, useState} from 'react';
import {Doughnut} from 'react-chartjs-2';
import PropTypes from "prop-types";
import Spinner from "../Spinner";

DoughnutChart.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    chartData: PropTypes.arrayOf(PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.number),
        borderColor:  PropTypes.arrayOf(PropTypes.string),
        backgroundColor:  PropTypes.arrayOf(PropTypes.string),
    })),
    dataType: PropTypes.string,
    measure: PropTypes.string,
    isLoading: PropTypes.bool
};

export default function DoughnutChart(props) {
    let {labels, chartData, dataType, measure='', isLoading} = props;

    const [prevChartData, setPrevChartData] = useState(null);

    useEffect(() => {
            setPrevChartData(chartData);
    }, [chartData, prevChartData]);

    const data = {
        datasets: [chartData],
        labels: labels,
        dataType: dataType
    };
    const chartOptions = {
        legend: {
            display: false
        },
        tooltips: {
            backgroundColor: '#6c757d',
            titleFontFamily: '"Rubik", sans-serif',
            titleFontSize: 16,
            bodySpacing: 10,
            bodyFontFamily: '"Rubik", sans-serif',
            bodyFontSize: 14,
            displayColors: true,
            callbacks: {
                title: (items, data) => data.labels[items[0].index],
                label: (item, data) => " " + dataType + ": " + data.datasets[item.datasetIndex].data[item.index].toLocaleString("ru-RU") + " " + measure
                    + " " + (data.datasets[item.datasetIndex].data[item.index]/total*100).toFixed(2).toLocaleString("ru-RU") + "%",
            }
        },
        responsive: true,
        maintainAspectRatio: false
    };
    const total = chartData.data.reduce((sum, item) => sum + item, 0);
    const height = window.screen.height * 0.20 + 250;
    return (
        isLoading ? <Spinner/> :
      <Doughnut data={data} height={height} options={chartOptions}/>
    );
};