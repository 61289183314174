import {
    DICTIONARY_ACTIVE_OPERATORS_LOADED,
    DICTIONARY_ACTIVE_OPERATORS_LOADING,
    DICTIONARY_CATEGORIES_LOADED,
    DICTIONARY_CATEGORIES_LOADING, DICTIONARY_GROUPED_OPERATORS_LOADED, DICTIONARY_GROUPED_OPERATORS_LOADING,
    DICTIONARY_OPERATOR_TYPES_LOADED, DICTIONARY_OPERATOR_TYPES_LOADING
} from "./actionTypes";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import axios from "axios";


export const loadOperatorTypes = () => (dispatch, getState) => {

    dispatch({type: DICTIONARY_OPERATOR_TYPES_LOADING});

    axios
        .get(process.env.REACT_APP_API_URL + '/operators/types', getConfig(getState))
        .then(res =>
            dispatch({
                type: DICTIONARY_OPERATOR_TYPES_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadGroupedOperators = () => (dispatch, getState) => {
    dispatch({type: DICTIONARY_GROUPED_OPERATORS_LOADING});

    axios
        .get(process.env.REACT_APP_API_URL + '/operators/grouped', getConfig(getState))
        .then(res =>
            dispatch({
                type: DICTIONARY_GROUPED_OPERATORS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadActiveOperators = () => (dispatch, getState) => {
    dispatch({type: DICTIONARY_ACTIVE_OPERATORS_LOADING});

    axios
        .get(process.env.REACT_APP_API_URL + '/operators/active', getConfig(getState))
        .then(res =>
            dispatch({
                type: DICTIONARY_ACTIVE_OPERATORS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadCategories = () => (dispatch, getState) => {

    dispatch({type: DICTIONARY_CATEGORIES_LOADING});

    axios
        .get(process.env.REACT_APP_API_URL + '/offerCategories', getConfig(getState))
        .then(res =>
            dispatch({
                type: DICTIONARY_CATEGORIES_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};
