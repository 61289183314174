import {
    PARTNER_DOMAIN_CREATED,
    PARTNER_DOMAIN_CREATING,
    PARTNER_DOMAIN_DELETED, PARTNER_DOMAIN_MAKE_DEFAULT,
    PARTNER_DOMAINS_LOADED,
    PARTNER_DOMAINS_LOADING
} from "../../actions/actionTypes";


const initialState = {
    partnerDomains: [],
    loading: false
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case PARTNER_DOMAINS_LOADING:
        case PARTNER_DOMAIN_CREATING:
            return {
                ...state,
                loading: true
            };
        case PARTNER_DOMAINS_LOADED:
            return {
                ...state,
                partnerDomains: action.payload,
                loading: false
            };
        case PARTNER_DOMAIN_DELETED:
        case PARTNER_DOMAIN_CREATED:
        case PARTNER_DOMAIN_MAKE_DEFAULT:
            return {
                ...state,
                loading: false
            } ;
        default:
            return state;

    }
}