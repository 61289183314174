import {
    CHANGE_SMARTLINK_NAME,
    CHANGE_SMARTLINK_OFFER_PROBABILITY,
    CREATE_SMARTLINKS,
    DELETE_SMARTLINK,
    EXCLUDE_OFFER_FROM_SMARTLINK,
    PARTNER_SMARTLINK_LOADED,
    PARTNER_SMARTLINKS_LOADED,
    PARTNER_SMARTLINKS_LOADING,
    SAVE_SMARTLINKS, SMARTLINK_URL_LOADED
} from "../../actions/actionTypes";


const initialState = {
    editableSmartLink: null,
    editableSmartLinkId: null,
    editableSmartLinkUrl: null,
    smartLinks: [],
    createdSmartLinks: [],
    loading: false
};

export default function (state = initialState, action = {}) {
    let editableSmartLink = state.editableSmartLink;
    switch (action.type) {
        case PARTNER_SMARTLINKS_LOADING:
            return {
                ...state,
                editableSmartLink: null,
                editableSmartLinkUrl: null,
                loading: true
            };
        case PARTNER_SMARTLINKS_LOADED:
            return {
                ...state,
                smartLinks: action.payload,
                loading: false
            };
        case PARTNER_SMARTLINK_LOADED:
            return {
                ...state,
                editableSmartLink: action.payload,
                editableSmartLinkUrl: action.payload && action.payload.link,
            };
        case SMARTLINK_URL_LOADED:
            return {
                ...state,
                editableSmartLinkUrl: action.payload,
            };
        case CHANGE_SMARTLINK_NAME:
            editableSmartLink.name = action.payload.value;
            editableSmartLink.hasChanges = true;
            return {
                ...state,
                editableSmartLink: editableSmartLink
            };
        case SAVE_SMARTLINKS:
            return {
                ...state,
                editableSmartLink: action.payload ? action.payload[0] : editableSmartLink,
                editableSmartLinkID: action.payload ? action.payload[0].id : state.editableSmartLinkId
            };
        case CREATE_SMARTLINKS:
            return {
                ...state,
                createdSmartLinks: action.payload,
                smartLinks: [],
            };
        case DELETE_SMARTLINK:
            return {
                ...state,
                createdSmartLinks: state.createdSmartLinks.filter(createdSmartLink => createdSmartLink.id !== action.payload.id)
            };
        case EXCLUDE_OFFER_FROM_SMARTLINK:
            return {
                ...state,
                editableSmartLink: excludeOfferFromSmartLink(editableSmartLink, action.payload.offerId),
                editableSmartLinkId: state.editableSmartLinkId
            };
        case CHANGE_SMARTLINK_OFFER_PROBABILITY:
            return {
                ...state,
                editableSmartLink: changeSmartLinkOfferProbability(editableSmartLink, action.payload),
                editableSmartLinkId: action.payload.id,
            };
        default:
            return state;
    }
}

function changeSmartLinkOfferProbability(editableSmartLink, data) {
    editableSmartLink.smartLinkOffers.forEach(smartLinkOffer => {
        if (smartLinkOffer.offerId === data.offerId) {
            smartLinkOffer.probability = data.probability;
            editableSmartLink.hasChanges = true;
        }
    });

    return editableSmartLink;
}

function excludeOfferFromSmartLink(editableSmartLink, offerId) {
    editableSmartLink.smartLinkOffers =
        editableSmartLink.smartLinkOffers.filter(smartLinkOffer => smartLinkOffer.offerId !== offerId);
    editableSmartLink.hasChanges = true;
    return editableSmartLink;
}