import React from "react";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {isMobileScreen} from "../../../../utils/displayUtils";


export default function GroupFilter({dropdownData, label, selectedGroup, groupNumber, onSelectGroup, inputStyle}) {
    const isMobile = isMobileScreen();

    const selectGroup = (value) => {
        if (value) {
            value.number = groupNumber;
        } else {
            value = {number: groupNumber};
        }
        onSelectGroup(value, true);
    };
    return (
        <MaterialUISelect
            styles={inputStyle}
            dropdownData={dropdownData}
            selectedValue={selectedGroup}
            displayedField='label'
            dataField='value'
            label={label}
            placeholder='-'
            onSelectCallbackFunction={selectGroup}
            isSearchable={!isMobile}/>
    );
}