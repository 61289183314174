import React, {Fragment, useRef, useState} from "react";
import HeaderNewsItem from "./HeaderNewsItem";
import NewsItemModal from "./NewsItemModal";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import {isMobileScreen} from "../../../../utils/displayUtils";
import useScreenSize from "../../../../hooks/useScreenSize";

export default function HeaderNewsItemsPopup({news, readNewsItem, notReadItemsAmount, loadMoreNews}) {

    const [openedNewsItem, setOpenedNewsItem] = useState(null);

    const openNewsItem = (newsItem) => {
        setOpenedNewsItem(newsItem);
        readNewsItem(newsItem);
    };

    const closeNewsItem = () => {
        setOpenedNewsItem(null);
    };

    const screenSize = useScreenSize();

    const isMobile = screenSize.width <= 870;

    return (
        <div className={"notification-block" + (isMobile ? " mobile" : "")} id="notificatios">
            <div className="notification-header">
                <div className="notification-title">
                    Уведомления
                </div>
                <div className="notification-subtitle">{"Новых событий: " + notReadItemsAmount}</div>
            </div>
            <div className="notification-body">
                <div className="notification-line"/>
                <div className="block notification-events" >
                {news && news.map(newsItem => <Fragment key={newsItem.id}><HeaderNewsItem newsItem={newsItem} openNewsItem={openNewsItem}/></Fragment>)}
                </div>
                <div className="notification-line"/>
                <div className="notification-more" onClick={loadMoreNews}>Показать больше новостей</div>

            </div>
            <NewsItemModal newsItem={openedNewsItem} onCloseModal={closeNewsItem}/>
        </div>
    )

}