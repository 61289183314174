import React from 'react';
import IosCheckbox from "../../../../common/checkboxes/IosCheckbox";

const onlySelectedLabel = <><i className="ti ti-check-box"/><i className="ti ti-check-box"/><i
    className="ti ti-check-box"/></>;

export default function OffersIsSelectedFilter({toggleIsOnlySelected, isOnlySelectedOffers, minWidth}) {

    return (
        <div className="custom-mselect" style={{minWidth: minWidth && minWidth + "px"}}>
            <IosCheckbox label={onlySelectedLabel} isChecked={isOnlySelectedOffers}
            onClickFunction={toggleIsOnlySelected} tooltip="Только выделенные офферы"/>
        </div>
    )
}


