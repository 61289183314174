import React, {Component} from "react";
import BasePage from "../BasePage";
import {connect} from "react-redux";
import {deleteProject, loadPartnerProjects, saveProject} from "../../../actions/projectsActions";
import ProjectsTable from "./table/ProjectsTable";
import Spinner from "../../common/Spinner";
import NoContentMessage from "../../common/NoContentMessage";
import ProjectModal from "./modal/ProjectModal";
import {showWarning} from "../../../actions/warningActions";
import {clearErrors} from "../../../actions/errorActions";
import PageContent from "../../common/page/PageContent";

const warningMsg = <div>ВНИМАНИЕ!<br/>После удаления данного потока для привязанных к нему офферов и смарт-линков будут
    применяться дефолтные значения Postback URL и Trafficback URL, прописанные в разделе Мой кабинет <br/>Удалить поток?
</div>;

class ProjectsPage extends Component {

    state = {
        isOpenedModal: false,
        editableProject: null
    };

    componentDidMount() {
        this.props.loadPartnerProjects();
    }

    deleteProject = (project) => {
        this.props.showWarning(warningMsg, () => this.props.deleteProject(project));
    };

    onOpenModal = (project) => {
        this.setState({
            isOpenedModal: true,
            editableProject: project
        })
    };

    onCloseModal = () => {
        this.setState({isOpenedModal: false});
        this.props.clearErrors();
    };

    onSaveProject = (project) => {
        this.props.saveProject(project, () => this.onCloseModal());
    };

    renderContent = () => {
        const {isLoading, projects, errors, defaultProject} = this.props;
        const {editableProject, isOpenedModal} = this.state;
        const fieldErrors = errors && errors.data && errors.data.fieldErrors;
        return <PageContent
            contentEl={
                        <div className="row" style={{width: "inherit"}}>
                            <div className="card col-12 mb-1 no_left_right_padding" style={{borderRadius: "0", padding: "10px"}}>
                                <button type="button" className="btn btn-outline-secondary margin-left5" data-toggle="modal"
                                        data-target="#mediumModal" onClick={this.onOpenModal} >
                                    Создать поток
                                </button>
                                <ProjectModal project={editableProject ? editableProject : null}
                                              isOpened={isOpenedModal}
                                              onCloseModal={this.onCloseModal} onSaveProject={this.onSaveProject}
                                              defaultProject={defaultProject}
                                              fieldErrors={fieldErrors}/>
                            </div>
                            <div className="col-12 no_left_right_padding">

                                <div className="responsive-block" id="fullWidth"
                                     style={{overflowX: 'auto', overflowY: 'hidden'}}>
                                    {isLoading ? <Spinner/> :
                                        projects && projects.length > 0
                                            ?
                                            <div className="table-responsive" id="hiden-offer-table">
                                                <ProjectsTable
                                                    projects={projects}
                                                    defaultProject={defaultProject}
                                                    onDeleteProject={this.deleteProject}
                                                    onEditProject={this.onOpenModal}/>
                                            </div>
                                            : <NoContentMessage/>
                                    }
                                </div>
                            </div>
                </div>
            }
        />
    };


    render() {
        return <BasePage pageName="Потоки" content={this.renderContent()}/>
    }
}

function mapStateToProps(state) {
    return {
        projects: state.projects.projects,
        defaultProject: state.projects.projects ? state.projects.projects.find(project => project.sourceId === 0) : null,
        isLoading: state.projects.loading,
        errors: state.error
    }
}

export default connect(mapStateToProps, {
    loadPartnerProjects,
    saveProject,
    deleteProject,
    showWarning,
    clearErrors
})(ProjectsPage);