import React from "react";
import FiltersCard from "../../../../FiltersCard";
import PaymentsDateRangePicker from "./PaymentsDateRangePicker";
import PaymentsPeriodsFilter from "./PaymentsPeriodsFilter";
import CircleButtonsGroup from "../../../../common/buttons/CircleButtonsGroup";
import {useSelector} from "react-redux";

export default function PartnerPaymentsFilters(props) {

    const isPaymentsLoading = useSelector(state => state.paymentsReducer.loading);

    const filters = () => {
        const {period, fromDate, toDate} = props.filters;
        return (
            <div className="row">
                <PaymentsDateRangePicker fromDate={fromDate} toDate={toDate} selectFromDate={props.selectFromDate}
                                         selectToDate={props.selectToDate}
                                         inputStyle={props.inputStyle}
                />
                <PaymentsPeriodsFilter selectPeriod={props.selectPeriod} selectedPeriod={period}/>
            </div>
        );
    };

    const buttons = () => {

        return <CircleButtonsGroup buttons={
            [
                {
                    isSelected: false,
                    title: "Сформировать отчет",
                    iconClassName: "ti ti-check stat-icon",
                    onClick: props.buildTable,
                    isLoading: isPaymentsLoading,
                },
                {
                    isSelected: false,
                    title: "Очистить",
                    iconClassName: "ti ti-close stat-icon",
                    onClick: props.clearData,
                },
            ]
        }/>
    };

    return <FiltersCard filters={filters()} buttons={buttons()}/>
}
