import {useEffect, useState} from "react";

const getSize = (ref) => {
    return {
        width: ref && ref.current && ref.current.offsetWidth,
        height: ref && ref.current && ref.current.offsetHeight
    };
};

const useElementSize = (ref) => {

    const [elementSize, setElementSize] = useState(getSize(ref));

    useEffect(() => {

        const handleResize = () => {
            setElementSize(getSize(ref));
        };

        if (!elementSize.width) {
            handleResize();
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [elementSize, ref]); // Empty array ensures that effect is only run on mount and unmount

    return elementSize;
};

export default useElementSize;