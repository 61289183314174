import React from "react";
import SimpleTable from "../../../common/tables/SimpleTable";
import {getRusDateHourString} from "../../../../utils/dateUtils";

const categoryMapper = {
    COMMON: "Общая",
    CASE: "Кейс",
    TECHNICAL: "Техническая",
};

const statusMapper = {
    DRAFT: <i className="ti ti-notepad"/>,
    PENDING: <i className="ti ti-time"/>,
    PUBLISHED: <i className="ti ti-check"/>,
};

export default function NewsTable(props) {

    const {news=[], selectEditableNewsItem} = props;

    const tableColumns = () => {
        return [
            {
                name: "#",
                key: "ID",
                getBodyCellValue: newsItem => newsItem.id,
                headerCellStyle: {minWidth: "40px", width: "40px"}
            },
            {
                name: <><i className="ti ti-calendar"/> / <i className="ti ti-time"/><br/> создания</>,
                key: "createDate",
                getBodyCellValue: newsItem => newsItem.createDate ? getRusDateHourString(new Date(newsItem.createDate)) : "",
                headerCellStyle: {minWidth: "90px", width: "90px"}
            },
            {
                name: "Заголовок",
                key: "title",
                getBodyCellValue: newsItem => newsItem.title,
                bodyCellClassName: "max40elips"
            },
            {
                name: "Тематика",
                key: "category",
                getBodyCellValue: newsItem => categoryMapper[newsItem.category],
                // headerCellStyle: {minWidth: "150px", width: "150px"},
                // bodyCellClassName: "td-operator"
            },
            {
                name: <><i className="ti ti-calendar"/> / <i className="ti ti-time"/><br/> публикации</>,
                key: "publicationDate",
                getBodyCellValue: newsItem => newsItem.publicationDate ? getRusDateHourString(new Date(newsItem.publicationDate)) : "",
                headerCellStyle: {minWidth: "90px", width: "90px"},
            },
            {
                name: "Статус",
                key: "status",
                getBodyCellValue: newsItem => {return <>{statusMapper[newsItem.status]} {newsItem.errors && <i className="ti ti-alert" style={{color: "red"}} title={newsItem.errors}/>}</>},
            },
            {
                name: "ред.",
                key: "editAction",
                getBodyCellValue: newsItem => <i className="ti ti-pencil-alt" style={{cursor: "pointer"}} onClick={() => selectEditableNewsItem(newsItem.id)}/>,
                headerCellStyle: {minWidth: "60px", width: "60px"}
            },
        ]
    };

    return (
        <div className="col-md-6" >
            <div className="card">
                <div style={{overflow: 'auto hidden'}}>
                <div className="card-header responsive-block statistic_table_wrapper">
                    <h4 className="mb-0">Пул новостей</h4>
                </div>
                <div className="card-body " id="pulTableBody">
                    <div style={{overflowX: 'auto', overflowY: 'auto', maxHeight: '79vh'}} id="fullWidth">
                    <SimpleTable columns={tableColumns()} data={news} bodyRowKey="id"
                                 tableClassName="table table-hover table-fixed userselecttext"
                                 theadClassName="thead"/>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}