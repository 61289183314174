import React, {useEffect, useState} from 'react';
import MaterialUIMultiSelect from "../../../../common/selects/MaterialUIMultiSelect";
import {useSelector} from "react-redux";
import {
    SMARTLINK_OFFERS_KIT_CHANGING_CONTENT
} from "../../../../../utils/constants";


export default function OffersCategoryFilter({offers, selectedCategories, onSelectCategory, inputStyle}) {
    const displayedComponent = useSelector(state => state.partnerOffers.displayedComponent);

    const {categories} = useSelector(state => state.filterDataReducer.filterData);

    const [dropdownValues, setDropdownValues] = useState([]);

    const defaultCategory = (displayedComponent === SMARTLINK_OFFERS_KIT_CHANGING_CONTENT
        && dropdownValues && dropdownValues.length > 0) ? dropdownValues[0] : null;

    useEffect(() => {
        const getFilteredCategories = () => {
            let resultCategories = [];
            if (categories && offers) {
                categories.forEach(function (category) {
                    const found = offers.find(function (offer) {
                        return offer.category.id === category.id;
                    });
                    if (found) {
                        resultCategories.push(category);
                    }
                })
            }
            return resultCategories;
        };
        setDropdownValues(getFilteredCategories());
    }, [offers, categories, selectedCategories]);

    return (
        <MaterialUIMultiSelect styles={inputStyle} dropdownData={dropdownValues} values={selectedCategories}
                               dataField='id' displayedField='name' label='Тематика'
                               placeholder='-'
                               defaultValue={defaultCategory}
                               onSelect={onSelectCategory}/>
    )
}


