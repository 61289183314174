import {
    PARTNER_BALANCE_LOADING,
    PARTNER_BALANCE_LOADED,
    AVAILABLE_PARTNER_BALANCE_LOADING,
    AVAILABLE_PARTNER_BALANCE_LOADED,
    MANAGER_PARTNER_BALANCE_DATA_LOADING, MANAGER_PARTNER_BALANCE_DATA_LOADED
} from "../../actions/actionTypes";

const initialState = {
    balance: null,
    availablePartnerBalanceData: null,
    loading: false,
    availablePartnerBalanceDataLoading: false,
    managerPartnerBalanceData: null,
    managerPartnerBalanceDataLoading: false,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case PARTNER_BALANCE_LOADING:
            return {
                ...state,
                loading: true
            };
        case PARTNER_BALANCE_LOADED:
            return {
                ...state,
                balance: action.payload,
                loading: false
            };
        case AVAILABLE_PARTNER_BALANCE_LOADING:
            return {
                ...state,
                availablePartnerBalanceDataLoading: true
            };
        case AVAILABLE_PARTNER_BALANCE_LOADED:
            return {
                ...state,
                availablePartnerBalanceData: action.payload,
                availablePartnerBalanceDataLoading: false
            };
        case MANAGER_PARTNER_BALANCE_DATA_LOADING:
            return {
                ...state,
                managerPartnerBalanceData: null,
                managerPartnerBalanceDataLoading: true
            };
        case MANAGER_PARTNER_BALANCE_DATA_LOADED:
            return {
                ...state,
                managerPartnerBalanceData: action.payload,
                managerPartnerBalanceDataLoading: false
            };
        default:
            return state;

    }
}
