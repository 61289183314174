import React, {Fragment} from "react";
import HtmlEditor from "../../../common/htmlEditor/HtmlEditor";

const options = {
    showPathLabel: true,
    charCounter: true,
    height: "100% !important",
    resizingBar: true,
    buttonList : [
        ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
        ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
        ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView']
        // ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
    ],
};

export default function NewsEditor(props) {

    const {initialValue, setNewsValue, changeImage, error} = props;

    return (
        <Fragment>
            <label htmlFor="exampleMessage">Текст новости</label>
            <HtmlEditor onChange={setNewsValue} initialContent={initialValue} onImageUpload={changeImage} options={options}/>
            {error && <div style={{fontSize: "12px", color: "red"}}>{error.error}</div>}
        </Fragment>
    )
}