import {
    ROS_LINES_LOADING,
    ROS_LINES_LOADED,
} from '../../actions/actionTypes';

const initialState = {
    rosLines: [],
    loading: false,
};

export default function (state = initialState, action = {}) {

    switch (action.type) {
        case ROS_LINES_LOADING:
            return {
                ...state,
                loading: true
            };
        case ROS_LINES_LOADED:
            return {
                ...state,
                rosLines: action.payload,
                loading: true
            };
        default:
            return state;

    }
}
