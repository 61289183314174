import React from "react";

export default function SmallNavTab({label, selected, onSelect, id}) {



    return (
        <a id={id} className={"nav-item nav-link" + (selected ? " active show" : "")} data-toggle="tab" href="#" role="tab"
           aria-controls="custom-nav-home" aria-selected="true" onClick={(tabName) => onSelect(tabName)}>{label}</a>
    )
}