import React from "react";

export default function HeaderDesktopInfoItem({className, img, title, onClick}) {

    return (
        <div className={className} onClick={onClick && onClick}>
            <div className="icona">
                <img src={img} alt=""/>
            </div>
            <span className="amount">{title}</span>
        </div>
    )
}