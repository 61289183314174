import {
    AD_NETWORK,
    AD_NETWORK_CALCULATION, AD_NETWORK_ORDER,
    CALCULATION, BALANCE_CORRECTION,
    BANK_TRANSFER,
    CALCULATED,
    CANCELED, CAPITALIST, CONFIRMATION, CONTRACT,
    NONE,
    ORDER,
    PAID,
    PENDING,
    REGULAR,
    TRANSFERRING_ERROR, USDT,
    WEBMONEY,
    YANDEX_MONEY
} from "./constants";

export const getPaymentValue = (paymentDataDto) => {
    if (!paymentDataDto || !paymentDataDto.paymentType) {
        return "";
    }
    switch (paymentDataDto.paymentType) {
        case NONE:
            return "";
        case WEBMONEY:
            return paymentDataDto.webmoneyWallet;
        case BANK_TRANSFER:
            return paymentDataDto.bankName + " | " + paymentDataDto.bankAccount;
        case CONTRACT:
            return "№" + paymentDataDto.contractNumber + " от " + paymentDataDto.contractDate + " c " + paymentDataDto.legalEntity;
        case AD_NETWORK:
            return paymentDataDto.adNetworkName + " | " + paymentDataDto.adNetworkEmail;
        case CAPITALIST:
        case USDT:
        case YANDEX_MONEY:
            return paymentDataDto.wallet;
        default:
            return "";

    }
};

export const getPaymentType = (paymentDataDto) => {
    if (!paymentDataDto || !paymentDataDto.paymentType) {
        return "";
    }
    switch (paymentDataDto.paymentType) {
        case AD_NETWORK:
        case NONE:
            return "";
        case WEBMONEY:
            return "Webmoney:";
        case YANDEX_MONEY:
            return "Яндекс.Деньги:";
        case BANK_TRANSFER:
            return "Банковский перевод:";
        case CONTRACT:
            return "Договор:";
        case CAPITALIST:
            return "Capitalist:";
        case USDT:
            return "USDT:";
        default:
            return "";
    }
};

export const getPaymentDetailsString = (paymentDataDto) => {
    return getPaymentType(paymentDataDto) + " " + getPaymentValue(paymentDataDto);
};

export const getPaymentTypeValue = (type, isAdvance) => {
    if (!type) {
        return "";
    }
    switch (type) {
        case AD_NETWORK_CALCULATION:
            return isAdvance ? "Аванс в РС" : "Выплата в РС";
        case AD_NETWORK_ORDER:
            return "Заказ в РС";
        case CALCULATION:
            return isAdvance ? "Аванс" : "Выплата";
        case BALANCE_CORRECTION:
            return "Корректировка баланса";
        case REGULAR:
            return "Начисление";
        case ORDER:
            return "Заказ"
    }
};

export const getPartnerPaymentStatusData = (payment) => {
    if (!payment || !payment.status) {
        return {};
    }
    switch (payment.status) {
        case CALCULATED:
        case CONFIRMATION:
        case PENDING:
        case TRANSFERRING_ERROR:
            return {value: "В обработке", color: "orange"};
        case PAID:
            return payment.incomePaidApproved === 0 ? {value: "-"} : {value: "Выплачено", color: "green"};
        case CANCELED:
            return {value: "Отменено", color: "red"};
        default:
            return {value: "-"};
    }
};

export const getManagerPaymentStatusData = (payment) => {
    switch (payment.status) {
        case CALCULATED:
            return {
                value: (payment.type === AD_NETWORK_CALCULATION || payment.type === CALCULATION
                    || payment.type === BALANCE_CORRECTION || payment.type === REGULAR) ? "Начислено" : "Запрошено",
                cellColor: "green"
            };
        case PENDING:
            return {value: "Передано", cellColor: "orange"};
        case CONFIRMATION:
            return {value: "Ожидает нотификации от РС", cellColor: "orange"};
        case PAID:
            return {value: "Оплачено", cellColor: "green"};
        case CANCELED:
            return {value: "Отменено", cellColor: "red"};
        case TRANSFERRING_ERROR:
            return {value: "Ошибка отправки", cellColor: "red"};
        default:
            return {value: payment.status};
    }
};

export const getPaymentTypes = () => [
    {value: CALCULATION, label: "Аванс", isAdvance: true, id: CALCULATION + "_ADVANCE"},
    {value: AD_NETWORK_CALCULATION, label: "Аванс в РС", isAdvance: true, id: AD_NETWORK_CALCULATION + "_ADVANCE"},
    {value: CALCULATION, label: "Выплата", isAdvance: false, id: CALCULATION},
    {value: AD_NETWORK_CALCULATION, label: "Выплата в РС", isAdvance: false, id: AD_NETWORK_CALCULATION},
    {value: ORDER, label: "Заказ", isAdvance: null, id: ORDER},
    {value: AD_NETWORK_ORDER, label: "Заказ в РС", isAdvance: null, id: AD_NETWORK_ORDER},
    {value: BALANCE_CORRECTION, label: "Корректировка баланса", isAdvance: null, id: BALANCE_CORRECTION},
    {value: REGULAR, label: "Регулярное начисление", isAdvance: null, id: REGULAR},
];

export const getPaymentDetailsTypes = () => [
    {value: NONE, label: "Не задан"},
    {value: BANK_TRANSFER, label: "Банковский перевод"},
    {value: CONTRACT, label: "Банковский договор"},
    {value: CAPITALIST, label: "Capitalist"},
    {value: USDT, label: "USDT"},
];

export const getPartnerPaymentTransferTypes = () => [
    {
        name: "Выплата на реквизиты",
        value: ORDER,
        paymentTypes: [BANK_TRANSFER, CAPITALIST, CONTRACT, USDT, WEBMONEY, YANDEX_MONEY]
    },
    {name: "Выплата в РС", value: AD_NETWORK_ORDER, paymentTypes: [AD_NETWORK]},
];

export const getAllPaymentTransferTypes = () => [
    {
        name: "Аванс/выплата",
        value: CALCULATION,
        paymentTypes: [BANK_TRANSFER, CAPITALIST, CONTRACT, USDT, WEBMONEY, YANDEX_MONEY]
    },
    {name: "Аванс/выплата в РС", value: AD_NETWORK_CALCULATION, paymentTypes: [AD_NETWORK]},
    {name: "Корректировка баланса", value: BALANCE_CORRECTION, paymentTypes: [NONE]},
    {
        name: "Начисление",
        value: REGULAR,
        paymentTypes: [BANK_TRANSFER, CAPITALIST, CONTRACT, USDT, WEBMONEY, YANDEX_MONEY]
    },
    {
        name: "Заказ",
        value: ORDER,
        paymentTypes: [BANK_TRANSFER, CAPITALIST, CONTRACT, USDT, WEBMONEY, YANDEX_MONEY]
    },
    {name: "Заказ в РС", value: AD_NETWORK_ORDER, paymentTypes: [AD_NETWORK]}
];

export const getApproveStatus = (oldStatus) => {
    switch (oldStatus) {
        case CALCULATED:
        case TRANSFERRING_ERROR:
            return PENDING;
        case PENDING:
            return PAID;
        default:
            return ""
    }
};

export const onlyWithPaymentDetailsDropdownData = [
    {label: "Да", value: true},
    {label: "Нет", value: false},
];

export const getOnlyWithPaymentDetailsDefaultValue = () => {
    return onlyWithPaymentDetailsDropdownData[1];
};

export const getManagerPaymentStatuses = () => [
    {value: CALCULATED, label: 'Начислено/Запрошено'},
    {value: PENDING, label: 'Ожидает оплаты'},
    {value: PAID, label: 'Оплачено'},
    {value: CANCELED, label: 'Отменено'},
    {value: CONFIRMATION, label: 'Ожидает нотификации от РС'},
    {value: TRANSFERRING_ERROR, label: 'Ошибка отправки'},
];

export const getDefaultManagerPaymentStatus = () => {
    return getManagerPaymentStatuses()[0];
};

const cardDetailsPaymentTypes = [CALCULATION, ORDER, REGULAR];
const adNetworkDetailsPaymentTypes = [AD_NETWORK_CALCULATION, AD_NETWORK_ORDER];
const emptyDetailsPaymentTypes = [BALANCE_CORRECTION];

export const isTypesWithSamePaymentDetails = (thisType, otherType) => {
    return thisType === otherType
        || (cardDetailsPaymentTypes.includes(thisType) && cardDetailsPaymentTypes.includes(otherType))
        || (adNetworkDetailsPaymentTypes.includes(thisType) && adNetworkDetailsPaymentTypes.includes(otherType))
        || (emptyDetailsPaymentTypes.includes(thisType) && emptyDetailsPaymentTypes.includes(otherType));
};

export const statusesForApprove = [CALCULATED, PENDING, TRANSFERRING_ERROR, CONFIRMATION];