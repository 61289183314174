import {
    LOG_TYPES_LOADED,
    LOGS_LOADED, LOGS_LOADING, LOGS_RESET
} from "../../actions/actionTypes";
import {emptyString} from "../../utils/stringUtils";

const initialState = {
    logsData: "",
    loading: false,
    logTypes: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case LOGS_LOADING:
            return {
                ...state,
                loading: true
            };
        case LOGS_LOADED:
            return {
                ...state,
                logsData: (emptyString(action.payload) ? "Данные не найдены" : action.payload),
                loading: false
            };
        case LOGS_RESET:
            return {
                ...state,
                logsData: "",
                loading: false
            };
        case LOG_TYPES_LOADED:
            return {
                ...state,
                logTypes: action.payload,
            };
        default:
            return state;

    }
}