import {SHOW_WARNING_MESSAGE, CLEAR_WARNING_MESSAGE, CONFIRM_ACTION} from '../../actions/actionTypes';

const initialState = {
    message: null,
    actionConfirmed: false,
    callbackFunction: null
};

export default function(state = initialState, action) {
    switch(action.type) {
        case SHOW_WARNING_MESSAGE:
            return {
                ...state,
                message: action.payload.message,
                onSubmit: action.payload.onSubmit,
                onCancel: action.payload.onCancel,
                actionConfirmed: false
            };
        case CLEAR_WARNING_MESSAGE:
            return {
                message: null
            };
        case CONFIRM_ACTION:
            return {
                actionConfirmed: true
            };
        default:
            return state;
    }
}