import React from 'react';
import IosCheckbox from "../../../../common/checkboxes/IosCheckbox";


export default function BuyoutRulesIsOnlyNonStandardFilter({isOnlyNonDefaultRules, toggleIsOnlyNonDefaultRules}) {

    return (
        <div className="custom-mselect margin5">
            <IosCheckbox label={'Скрыть стандартные'} isChecked={isOnlyNonDefaultRules}
                         onClickFunction={toggleIsOnlyNonDefaultRules}
            />
        </div>
    )
}


