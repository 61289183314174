import React from "react";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import PropTypes from "prop-types";


const lang = {
    code: 'ru',
    toolbar: {
        default: 'По умолчанию',
        save: 'Сохранить',
        font: 'Шрифт',
        formats: 'Стиль абзаца',
        fontSize: 'Размер шрифта',
        bold: 'Полужирный',
        underline: 'Подчёркнутый',
        italic: 'Курсив',
        strike: 'Зачеркнутый',
        subscript: 'Нижний индекс',
        superscript: 'Верхний индекс',
        removeFormat: 'Очистить форматирование',
        fontColor: 'Цвет текста',
        hiliteColor: 'Цвет фона',
        indent: 'Увеличить отступ',
        outdent: 'Уменьшить отступ',
        align: 'Выравнивание',
        alignLeft: 'Слева',
        alignRight: 'Справа',
        alignCenter: 'По центру',
        alignJustify: 'По ширине',
        list: 'Списки',
        orderList: 'Нумерованный',
        unorderList: 'Маркированный',
        horizontalRule: 'Горизонтальная линия',
        hr_solid: 'Сплошная',
        hr_dotted: 'Пунктир',
        hr_dashed: 'Штриховая',
        table: 'Таблица',
        link: 'Ссылка',
        math: 'математический',
        image: 'Изображение',
        video: 'Видео',
        audio: 'Аудио',
        fullScreen: 'Полный экран',
        showBlocks: 'Блочный вид',
        codeView: 'Редактировать HTML',
        undo: 'Отменить',
        redo: 'Вернуть',
        preview: 'Предварительный просмотр',
        print: 'Печать',
        tag_p: 'Параграф (p)',
        tag_div: 'Базовый (div)',
        tag_h: 'Заголовок',
        tag_blockquote: 'Цитата',
        tag_pre: 'Код',
        template: 'Шаблон',
        lineHeight: 'Высота линии',
        paragraphStyle: 'Стиль абзаца',
        textStyle: 'Стиль текста',
        imageGallery: 'Галерея'
    },
    dialogBox: {
        linkBox: {
            title: 'Вставить ссылку',
            url: 'Ссылка',
            text: 'Текст',
            newWindowCheck: 'Открывать в новом окне'
        },
        mathBox: {
            title: 'математический',
            inputLabel: 'Математическая запись',
            fontSizeLabel: 'Кегль',
            previewLabel: 'Предварительный просмотр'
        },
        imageBox: {
            title: 'Вставить изображение',
            file: 'Выберите файл',
            url: 'Адрес изображения',
            altText: 'Описание картинки (атрибут alt)'
        },
        videoBox: {
            title: 'Вставить видео',
            file: 'Выберите файл',
            url: 'Ссылка на видео'
        },
        audioBox: {
            title: 'Вставить аудио',
            file: 'Выберите файл',
            url: 'Адрес аудио'
        },
        browser: {
            tags: 'Теги',
            search: 'Поиск',
        },
        caption: 'Добавить подпись',
        close: 'Закрыть',
        submitButton: 'Подтвердить',
        revertButton: 'Сбросить',
        proportion: 'Сохранить пропорции',
        basic: 'Без обтекания',
        left: 'Слева',
        right: 'Справа',
        center: 'По центру',
        width: 'Ширина',
        height: 'Высота',
        size: 'Размер',
        ratio: 'Соотношение'
    },
    controller: {
        edit: 'Изменить',
        unlink: 'Убрать ссылку',
        remove: 'Удалить',
        insertRowAbove: 'Вставить строку выше',
        insertRowBelow: 'Вставить строку ниже',
        deleteRow: 'Удалить строку',
        insertColumnBefore: 'Вставить столбец слева',
        insertColumnAfter: 'Вставить столбец справа',
        deleteColumn: 'Удалить столбец',
        fixedColumnWidth: 'Фиксированная ширина столбца',
        resize100: 'Размер 100%',
        resize75: 'Размер 75%',
        resize50: 'Размер 50%',
        resize25: 'Размер 25%',
        autoSize: 'Авто размер',
        mirrorHorizontal: 'Отразить по горизонтали',
        mirrorVertical: 'Отразить по вертикали',
        rotateLeft: 'Повернуть против часовой стрелки',
        rotateRight: 'Повернуть по часовой стрелке',
        maxSize: 'Ширина по размеру страницы',
        minSize: 'Ширина по содержимому',
        tableHeader: 'Строка заголовков',
        mergeCells: 'Объединить ячейки',
        splitCells: 'Разделить ячейку',
        HorizontalSplit: 'Разделить горизонтально',
        VerticalSplit: 'Разделить вертикально'
    },
    menu: {
        spaced: 'интервал',
        bordered: 'Граничная Линия',
        neon: 'неон',
        translucent: 'полупрозрачный',
        shadow: 'Тень'
    }
};


HtmlEditor.propTypes = {
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    disable: PropTypes.bool,
    hide: PropTypes.bool,
    initialContent: PropTypes.string,
    onChange: PropTypes.func,
    onImageUpload: PropTypes.func,
    options: PropTypes.object
};

export default function HtmlEditor(props) {
    const {
        placeholder = "Введите текст...",
        autoFocus = false,
        disable = false,
        hide = false,
        initialContent,
        onChange,
        onImageUpload,
        options
    } = props;

    const handleChange = value => {
        onChange && onChange(value);
    };

    return (
        <SunEditor
            setOptions={options}
            lang={lang}
            name="am_html_editor"
            placeholder={placeholder}
            autoFocus={autoFocus}
            disable={disable}
            hide={hide}
            setContents={initialContent}
            onChange={handleChange}
            onImageUpload={onImageUpload}
        />
    )
}