import React from "react";

export default function ProfileSimpleInput({label="", value="", placeholder="", inputName, pattern, type="text",
                                               isIncorrect, onTextTyping, errorMsg, disabled, labelClassName="form-control-label"}) {

    return (
        <div className="form-group">
            <label className={labelClassName}>{label}</label>
            <div className="input-group">
                <input autoComplete={type && type === "password" ? "new-password" : "off"} name={inputName}
                       className={"form-control" + (errorMsg || isIncorrect ? "  input-error" : "")} value={value}
                        placeholder={placeholder} title={placeholder} type={type} pattern={pattern} onChange={onTextTyping} readOnly={disabled}/>
        </div>
            <p className="error-msg">{errorMsg}</p>
        </div>
    )
}