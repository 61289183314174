import React from "react";
import TextField from "@material-ui/core/TextField/TextField";

const dateRegex = /(([12]\d{3})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

export default function ProfileBankAgreementPaymentForm({paymentDetails = {}, onTextTyping}) {
    const style = {paddingRight: '10px', paddingBottom: '10px', width: '100%'};
    const isDateIncorrect = paymentDetails.contractDate && !paymentDetails.contractDate.match(dateRegex);
    return (
            <div  style={{maxWidth: '300px'}}>
                <TextField label="Дата договора" name="contractDate" onChange={onTextTyping} InputLabelProps={{shrink: true}}
                           value={paymentDetails.contractDate ? paymentDetails.contractDate : ""} style={style} placeholder="Дата в формате ГГГГ-ММ-ДД"
                           error={isDateIncorrect} helperText={isDateIncorrect && 'Введите дату в формате ГГГГ-ММ-ДД'}/>
                <TextField label="Номер" name="contractNumber" onChange={onTextTyping} InputLabelProps={{shrink: true}}
                           value={paymentDetails.contractNumber ? paymentDetails.contractNumber : ""} style={style}/>
                <TextField label="Юридическое лицо" name="legalEntity" onChange={onTextTyping} InputLabelProps={{shrink: true}}
                           value={paymentDetails.legalEntity ? paymentDetails.legalEntity : ""} style={style}/>
            </div>
    )
}