import React, {Fragment} from "react";

export default function PopupCheckbox({onChange, className, name, label, checked, errorMsg}) {

    return (
        <Fragment>
            <input name={name} type="checkbox" className={className} checked={checked} onChange={onChange}/>
            <label htmlFor="accept-terms" style={{paddingLeft: "25px", minWidth: "0px"}}>{label}
            </label>
            <br/>
            {errorMsg && <span className="login-error is-visible">{errorMsg}</span>}
        </Fragment>
    )
}