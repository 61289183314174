import React from "react";

import partnerkin from "../../../../resources/images/main/aboutUs/partnerkin.png";
import cparip from "../../../../resources/images/main/aboutUs/cparip.png";
import madcpa from "../../../../resources/images/main/aboutUs/madcpa.png";

export default function AboutUsBlock() {

    return (
        <div className="o-nas">
            <div className="o-nas-title">О НАС ПИШУТ:</div>
            <div className="row center">
                <a href="https://partnerkin.com/tribuna/blog_tantal73/kejs_pops_trafik__wap_click__1" target="_blank">
                    <img src={partnerkin} alt=""/>
                </a>
                <a href="https://madcpa.ru/obzor-partnerki-affimob/" target="_blank">
                    <img src={madcpa} alt=""/>
                </a>
                <a href="https://cpa.rip/case/mainstream-wap-click-zeroclick/" target="_blank">
                    <img src={cparip} alt=""/>
                </a>
            </div>

        </div>
    )
}