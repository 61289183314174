import {
    CAP_NAME_ITEM_DELETED,
    CAP_NAME_ITEM_SAVED,
    CAP_NAMES_LOADED,
    CAP_NAMES_LOADING, CAP_NAMES_RELOADED, CAP_NAMES_RELOADING,
} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnAllErrorsAsPopup, returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";

export const loadCapNames = () => (dispatch, getState) => {
    dispatch({type: CAP_NAMES_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/capNames', getConfig(getState))
        .then(res =>
            dispatch({
                type: CAP_NAMES_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const deleteCapNameItem = (capNameItem, onSuccessCallback) => (dispatch, state) => {

    axios
        .delete(process.env.REACT_APP_API_URL + '/capNames/' + capNameItem.id, getConfig(state))
        .then(res => {

                dispatch({
                    type: CAP_NAME_ITEM_DELETED,
                    payload: res.data
                });
            }
        )
        .then(() => {
            onSuccessCallback && onSuccessCallback()
        })
        .then(() => {
            dispatch(getSuccess({success: "Позиция удалена"}))
        })
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
        });
};

export const saveOrUpdateCapNameItem = (capNameItem, onSuccessCallback) => (dispatch, state) => {

    axios
        .post(process.env.REACT_APP_API_URL + '/capNames', capNameItem, getConfig(state))
        .then(res => {

                dispatch({
                    type: CAP_NAME_ITEM_SAVED,
                    payload: res.data
                });
            }
        )
        .then(() => {
            onSuccessCallback && onSuccessCallback()
        })
        .then(() => {
            dispatch(getSuccess({success: "Позиция сохранена"}))
        })
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
        });
};

export const reloadCapNames = () => (dispatch, getState) => {
    dispatch({type: CAP_NAMES_RELOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/capNames/reload', getConfig(getState))
        .then(res =>
            dispatch({
                type: CAP_NAMES_RELOADED,
                payload: res.data
            })
        )
        .then(() => {
            dispatch(getSuccess({success: "Данные синхронизированы с трекером"}))
        })
        .catch(err => {
            dispatch(returnErrors(err));
        });
};