import React from "react";
import PropTypes from 'prop-types';

ValidInput.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    size: PropTypes.string,
    autoComplete: PropTypes.string,
    readOnly: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onTextTyping: PropTypes.func,
    errorMsg: PropTypes.string,
};

ValidInput.defaultPros ={
    name: "",
    placeholder: "",
    autoComplete: "off",
    readOnly: "false",
    value: "",
};

export default function ValidInput(props) {
    const {name, placeholder, size, autoComplete, readOnly, disabled, value, onTextTyping, errorMsg, style} = props;

    return (
        <>
            <input name={name} placeholder={placeholder} size={size} style={style}
                   className={"form-control" + (errorMsg ? " input-error" : "")}
                   autoComplete={autoComplete} aria-disabled="false" readOnly={readOnly}
                   onChange={(e) => onTextTyping(name, e.target.value)} value={value}
                   disabled={disabled}/>
            <p className="error-msg">{errorMsg}</p>
        </>
    )
};