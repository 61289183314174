import {PAGE_CONTENT_LOADED, PAGE_CONTENT_LOADING, PAGE_CONTENT_SAVED} from "../../actions/actionTypes";

const initialState = {
    pageContent: [],
    loading: false
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case PAGE_CONTENT_LOADING:
            return {
                ...state,
                loading: true
            };
        case PAGE_CONTENT_LOADED:
            return {
                ...state,
               pageContent: action.payload,
                loading: false
            };
        case PAGE_CONTENT_SAVED:
            return {
                ...state,
                pageContent: state.pageContent.map(pageContent => pageContent.id === action.payload.id ? action.payload : pageContent),
                loading: false
            };
        default:
            return state;

    }
}
