import React, {Component} from 'react';

import BasePage from "../BasePage";
import {connect} from "react-redux";
import {
    ALL_OFFERS,
    CONNECTED_OFFERS, COUNTRIES,
    FOR_ACTIVATION,
    LINKS_AND_SMARTLINKS_CREATION_CONTENT,
    LOCAL_STORAGE_OFFERS_FILTERS, OFFER_CATEGORIES, OPERATORS, PARTNER_MACROS,
    SMARTLINK_OFFERS_KIT_CHANGING_CONTENT,
    SMARTLINKS,
    SMARTLINKS_EDITING_CONTENT,
} from "../../../utils/constants";
import OffersCardsGridContent from "./OffersCardsGridContent";
import ConnectedOffersContent from "./ConnectedOffersContent";
import SmartLinksContent from "./SmartLinksContent";
import LinkCreationComponent from "./linkCreation/LinkCreationComponent";
import {
    createLinks, filterByBillingType,
    filterByCategories,
    filterByCountries,
    filterByOnlyNew,
    filterByOnlyRecommended,
    filterByOperators,
    filterBySearchInput,
    filterByStatuses,
    loadPartnerOffers,
} from "../../../actions/partnerOfferActions";
import SmartLinkEditingComponent from "./linkEditing/SmartLinkEditingComponent";
import {pathParameter, replaceQueryParam} from "../../../utils/urlUtils";
import {addOrRemoveAndReturnNewArray} from "../../../utils/arrayUtils";
import {
    createSmartLinks,
    deleteSmartLink,
    filterSmartLinksByCategories,
    filterSmartLinksByCountries,
    filterSmartLinksByOperators,
    filterSmartLinksBySearchInput,
    saveSmartLink
} from "../../../actions/partnerSmartLinkActions";
import {showWarning} from "../../../actions/warningActions";
import {URL_OFFERS} from "../../../properties";
import {loadPartnerDomains} from "../../../actions/partnerDomainsActions";
import {getFromLocalStorage, setToLocalStorage} from "../../../utils/localStorageUtils";
import {savePreset} from "../../../actions/presetActions";
import {presetsAccessType} from "../../../utils/presetsUtils";
import {partnerOfferTabs} from "../../../utils/pageTabsUtils";
import createLinkImg from "../../../resources/images/create-link.png";
import checkedImg from "../../../resources/images/icons/checked.svg";
import {loadFiltersData} from "../../../actions/filterDataActions";


const componentsWithFilters = [ALL_OFFERS, CONNECTED_OFFERS, SMARTLINKS, SMARTLINK_OFFERS_KIT_CHANGING_CONTENT];

class PartnerOffersPage extends Component {

    state = {
        selectedNavTab: null,
        displayedComponent: null,
        displayedComponentsQueue: [],
        connectedOffers: [],
        selectedSmartLinkOfferIds:[],
        selectedOfferIds: [],
        selectedOffers: [],
        searchInputValue: "",
        beforeSearchInputAmount: 0,
        needToFilter: true,
        selectedProject: null,
        selectedBillingType: null,
        filters: {
            isOnlyNewOffers: false,
            isOnlyRecommendedOffers: false,
            isOnlySelectedOffers: false,
            selectedOperators: [],
            selectedCountries: [],
            selectedCategories: [],
            selectedStatuses: [],
        },
        createdSmartLinksByProjectIdMap: {},
        smartLinksTemplates: [],
        needToBuildTemplate: true,
        isContactsModalOpened: false,
        filteredOffers: [],
        filteredSmartLinks: [],
        shouldScrollToLink: false,
        shouldScrollToSmartLink: false,
        isMacrosModalOpened: false,
        macros: {},
        macrosModalType: null,
    };


    componentDidMount() {
        this.props.loadPartnerOffers(FOR_ACTIVATION);
        this.props.loadFiltersData([COUNTRIES, OFFER_CATEGORIES, OPERATORS]);
        this.props.loadPartnerDomains(true);
        const pathContentType = pathParameter("type");
        const navTab = pathContentType && Object.keys(partnerOfferTabs).includes(pathContentType) ? pathContentType : ALL_OFFERS;
        const localStorageFilters = getFromLocalStorage(LOCAL_STORAGE_OFFERS_FILTERS);
        this.setState({
            width: this.container && this.container.offsetWidth,
            selectedNavTab: navTab,
            displayedComponent: navTab,
            displayedComponentsQueue: [navTab],
            filters:  localStorageFilters ? localStorageFilters : this.state.filters
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.projects && this.props.projects.length > 0 && this.state.selectedBillingType && this.state.needToBuildTemplate) {
            this.setState({
                smartLinksTemplates: buildSmartLinksTemplates(this.state.selectedOffers, this.state.selectedProject, this.state.createdSmartLinks, this.state.selectedBillingType),
                needToBuildTemplate: false
            })
        }
    }


    handleTabSelection = (tabType) => {
        this.props.history.push({
            pathname: URL_OFFERS,
            search: replaceQueryParam("type", tabType, window.location.search)
        });
        this.setState(
            {
                selectedNavTab: tabType,
                displayedComponent: tabType,
                displayedComponentsQueue: [tabType],
                smartLinkId: null,
                needToFilter: true,
            });
    };

    toggleOffer = (offer) => {
        if (offer.hidden) {
        this.setState({isContactsModalOpened: true})
        } else {
            this.setState({
                selectedOfferIds: addOrRemoveAndReturnNewArray(this.state.selectedOfferIds, offer.offerId),
                selectedOffers: addOrRemoveAndReturnNewArray(this.state.selectedOffers, offer)
            });
        }
    };

    toggleSmartLinkOffer = (offer) => {
        this.setState({
            selectedSmartLinkOfferIds: addOrRemoveAndReturnNewArray(this.state.selectedSmartLinkOfferIds, offer.offerId),
        })
    };

    closeContactsModal = () => {
        this.setState({isContactsModalOpened: false})
    };


    onFooterTextTyping = (searchInputValue) => {
        this.setState({searchInputValue: searchInputValue})
    };

    resetAllFilters = () => {
        const filters  = {
            isOnlyNewOffers: false,
                isOnlyRecommendedOffers: false,
                isOnlySelectedOffers: false,
                selectedOperators: [],
                selectedCountries: [],
                selectedCategories: [],
                selectedStatuses: [],
        };
        setToLocalStorage(LOCAL_STORAGE_OFFERS_FILTERS, filters, 24);
        this.setState({
            filters: filters,
            needToFilter: true
        })
    };

    filterOffers = (offers = []) => {
        const {searchInputValue, selectedBillingType} = this.state;
        const { isOnlyNewOffers, isOnlyRecommendedOffers, selectedStatuses = [], selectedOperators = [],
            selectedCountries = [], selectedCategories = []} = this.state.filters;
        let filteredOffers = offers;
        filteredOffers = filterByOnlyNew(filteredOffers, isOnlyNewOffers);
        filteredOffers = filterByOnlyRecommended(filteredOffers, isOnlyRecommendedOffers);
        filteredOffers = filterByOperators(filteredOffers, selectedOperators);
        filteredOffers = filterByCountries(filteredOffers, selectedCountries);
        filteredOffers = filterByCategories(filteredOffers, selectedCategories);
        filteredOffers = this.state.selectedNavTab === CONNECTED_OFFERS ? filterByStatuses(filteredOffers, selectedStatuses) : filteredOffers;
        filteredOffers = this.state.displayedComponent === SMARTLINK_OFFERS_KIT_CHANGING_CONTENT ? filterByBillingType(filteredOffers, selectedBillingType) : filteredOffers;
        const beforeSearchInputAmount = filteredOffers.length;
        filteredOffers = filterBySearchInput(filteredOffers, searchInputValue);
        this.setState({
            filteredOffers : filteredOffers,
            beforeSearchInputAmount: beforeSearchInputAmount,
            needToFilter: false
        });
        return filteredOffers;
    };

    filterSmartLinks = (smartLinks = []) => {
        const {searchInputValue} = this.state;
        const {selectedOperators = [], selectedCountries = [], selectedCategories = []} = this.state.filters;
        let filteredSmartLinks = smartLinks.slice(0);
        filteredSmartLinks = filterSmartLinksByOperators(filteredSmartLinks, selectedOperators);
        filteredSmartLinks = filterSmartLinksByCountries(filteredSmartLinks, selectedCountries);
        filteredSmartLinks = filterSmartLinksByCategories(filteredSmartLinks, selectedCategories);
        const beforeSearchInputAmount = filteredSmartLinks.length;
        filteredSmartLinks = filterSmartLinksBySearchInput(filteredSmartLinks, searchInputValue);
        this.setState({
            filteredSmartLinks: filteredSmartLinks,
            beforeSearchInputAmount: beforeSearchInputAmount,
            needToFilter: false
        });
        return filteredSmartLinks;
    };

    toggleCheckboxFilter = (fieldName) => {
        const filters = {...this.state.filters, [fieldName]: !this.state.filters[fieldName]};
        setToLocalStorage(LOCAL_STORAGE_OFFERS_FILTERS, filters, 24);
        this.setState({filters: filters, needToFilter: true});
    };

    onSelectFilterValue = (value, fieldName) => {
        let filters = this.state.filters;
        if (fieldName === "selectedCountries") {
            const availableOperatorIds = value && value.flatMap(country => country.operatorIds);
            const selectedOperators = availableOperatorIds && availableOperatorIds.length > 0
                ? this.state.filters.selectedOperators.filter(operator => availableOperatorIds.includes(operator.operatorId))
                : this.state.filters.selectedOperators;
            filters = {...this.state.filters, selectedOperators: selectedOperators, selectedCountries: value};
        } else {
            filters = {...this.state.filters, [fieldName]: value};
        }
        setToLocalStorage(LOCAL_STORAGE_OFFERS_FILTERS, filters, 24);
        this.setState({filters: filters, needToFilter: true});
    };

    displayComponent = (displayedComponent) => {
        const displayedComponentsQueue = this.state.displayedComponentsQueue;
        displayedComponentsQueue.push(displayedComponent);
        this.setState({
            displayedComponent: displayedComponent,
            displayedComponentsQueue: displayedComponentsQueue,
            needToBuildTemplate: displayedComponent === LINKS_AND_SMARTLINKS_CREATION_CONTENT
        });
        if (componentsWithFilters.includes(displayedComponent)) {
            this.setState({
                needToFilter: true
            })
        }
    };

    returnToPrevComponent = () => {
        const displayedComponentsQueue = this.state.displayedComponentsQueue;
        if (displayedComponentsQueue && displayedComponentsQueue.length > 1) {
            displayedComponentsQueue.pop();
            const displayedComponent = displayedComponentsQueue[displayedComponentsQueue.length - 1];
            this.setState({
                displayedComponent: displayedComponent,
                displayedComponentsQueue: displayedComponentsQueue,
            });
            if (componentsWithFilters.includes(displayedComponent)) {
                this.setState({
                    needToFilter: true
                })
            }
            if (displayedComponent === ALL_OFFERS) {
                this.setState({
                    selectedSmartLinkOfferIds:[],
                    selectedOfferIds: [],
                    selectedOffers: [],
                    createdSmartLinksByProjectIdMap: {},
                    shouldScrollToLink: false,
                    shouldScrollToSmartLink: false,
                })
            }
        }
    };

    onSelectProject = (project) => {
        this.setState({selectedProject: project, needToBuildTemplate: true})
    };

    createSimpleLinks = (needToSaveMacros) => {
        const {selectedProject, selectedOffers, macros, selectedBillingType} = this.state;
        if (!selectedProject || selectedProject.id === -1 || !selectedBillingType) {
            return;
        }
        const offers = selectedOffers.flatMap(offer => offer.offerIncomes.filter(income=> income.billingType === selectedBillingType.type)).map(income => {return {offerId: income.offerId}});
        const macrosBody = needToSaveMacros
            ? Object.assign({}, macros)
            : null;
        macrosBody && delete macrosBody.networkName;
        this.props.createLinks(offers, selectedProject, macrosBody, this.afterCreateLinksCallback);
        this.setState({
            shouldScrollToLink: true,
            shouldScrollToSmartLink: false,
        });
    };

    onShowEditSmartLinkComponent = (smartLinkId) => {
        this.setState({
            smartLinkId: smartLinkId,
        });
        this.displayComponent(SMARTLINKS_EDITING_CONTENT);
    };

    onShowLinksAndSmartlinksCreationContent = () => {
        if (this.state.selectedOffers && this.state.selectedOffers.length > 0) {
            this.displayComponent(LINKS_AND_SMARTLINKS_CREATION_CONTENT)}
    };

    createSmartLinks = (needToSaveMacros) => {
        const {selectedOffers, selectedProject, smartLinksTemplates, createdSmartLinksByProjectIdMap, macros} = this.state;
        if (!selectedOffers || selectedOffers.length === 0 || !selectedProject
            || createdSmartLinksByProjectIdMap[selectedProject.id] || !smartLinksTemplates || smartLinksTemplates.length === 0) {
            return;
        }
        const macrosBody = needToSaveMacros
            ? Object.assign({}, macros)
            : null;
        macrosBody && delete macrosBody.networkName;
        if (smartLinksTemplates.length > 1) {
            const suffix = (smartLinksTemplates.length >= 2 && smartLinksTemplates.length <= 4) ? 'а' : 'ов';
            this.props.showWarning("Будет создано " + smartLinksTemplates.length + " смартлинк" + suffix + " различных тематик. Продолжить?",
                () => this.props.createSmartLinks(smartLinksTemplates, macrosBody, this.afterCreateSmartLinksCallback));
        } else {
            this.props.createSmartLinks(smartLinksTemplates, macrosBody, this.afterCreateSmartLinksCallback);
        }
    };

    updateSmartLink = (warnMessage, onCancel) => {
        this.props.showWarning(warnMessage, this.setSelectedProjectAndSaveSmartlink, onCancel);
    };

    setSelectedProjectAndSaveSmartlink = () => {
        const {editableSmartLink} = this.props;
        const oldProjectId = editableSmartLink.projectId;
        const {selectedProject} = this.state;
        if (selectedProject && selectedProject.id !== -1) {
            editableSmartLink.projectId = selectedProject.id;
            editableSmartLink.sourceId = selectedProject.sourceId;
            editableSmartLink.projectName = selectedProject.name;
        }
        this.props.saveSmartLink(editableSmartLink, () => this.addUpdatedSmartLinkToStateAndReturnToPrevComponent(oldProjectId));
    };

    addUpdatedSmartLinkToStateAndReturnToPrevComponent = (oldProjectId) => {
        if (this.state.displayedComponentsQueue[0] === SMARTLINKS) {
            this.returnToPrevComponent();
            return;
        }
        const {editableSmartLink} = this.props;
        const {createdSmartLinksByProjectIdMap} = this.state;

        if (editableSmartLink.projectId === oldProjectId) {
            createdSmartLinksByProjectIdMap[editableSmartLink.projectId] = createdSmartLinksByProjectIdMap[editableSmartLink.projectId]
                .map(smartLink => {
                    return smartLink.id === editableSmartLink.id ? editableSmartLink : smartLink
                });
        } else {
            createdSmartLinksByProjectIdMap[editableSmartLink.projectId] = [editableSmartLink];
            createdSmartLinksByProjectIdMap[oldProjectId] = createdSmartLinksByProjectIdMap[oldProjectId]
                .filter(smartLink => smartLink.id !== editableSmartLink.id);
        }
        this.returnToPrevComponent();
    };

    afterCreateSmartLinksCallback = (createdSmartLinks) => {
        if (!createdSmartLinks) {
            return;
        }
        createdSmartLinks = createdSmartLinks
            .sort((a, b) => a.category.name.localeCompare(b.category.name));
        const selectedProject = this.state.selectedProject;
        const createdSmartLinksByProjectIdMap = Object.assign({}, this.state.createdSmartLinksByProjectIdMap);
        createdSmartLinksByProjectIdMap[selectedProject.id] = createdSmartLinks;
        this.setState({
            createdSmartLinksByProjectIdMap: createdSmartLinksByProjectIdMap,
            shouldScrollToSmartLink: true,
            shouldScrollToLink: false,
        });
        this.saveMacrosPreset();
    };

    deleteSmartLink = (smartLink) => {
        this.props.showWarning("Удалить смартлинк?", () => this.props.deleteSmartLink(smartLink, this.removeDeletedSmartlinkFromState));
    };

    removeDeletedSmartlinkFromState = (deletedSmartLink) => {
        const {selectedProject, createdSmartLinksByProjectIdMap} = this.state;
        const filteredSmartLinks = createdSmartLinksByProjectIdMap[selectedProject.id].filter(createdSmartLink => createdSmartLink.id !== deletedSmartLink.id);
        if (filteredSmartLinks.length === 0) {
            delete createdSmartLinksByProjectIdMap[selectedProject.id];
        } else {
            createdSmartLinksByProjectIdMap[selectedProject.id] = filteredSmartLinks;
        }
        createdSmartLinksByProjectIdMap[selectedProject.id] = filteredSmartLinks.length === 0 ? null : filteredSmartLinks;
        this.setState({
            createdSmartLinksByProjectIdMap: Object.assign({}, createdSmartLinksByProjectIdMap)
        });
    };

    afterCreateLinksCallback = () => {
        this.saveMacrosPreset();
    };

    saveMacrosPreset = () => {
        const macrosPreset = this.props.macrosPreset ? this.props.macrosPreset : {};
        const macros = this.state.macros;
        let data = macrosPreset && macrosPreset.data ? macrosPreset.data : {};
        let needToSave = false;
        if (macros.networkName) {
            const networks = data.networks ? data.networks.filter(network => network !== macros.networkName) : [];
            networks.unshift(macros.networkName);
            data.networks = networks;
            needToSave = true;

        }
        if (macros.pt_id && macros.pt_id !== data.pt_id) {
            data.pt_id = macros.pt_id;
            needToSave = true;
        }
        if (macros.pt_id2 && macros.pt_id2 !== data.pt_id2) {
            data.pt_id2 = macros.pt_id2;
            needToSave = true;
        }
        if (macros.pt_id3 && macros.pt_id2 !== data.pt_id3) {
            data.pt_id3 = macros.pt_id3;
            needToSave = true;
        }
        if (needToSave) {
            macrosPreset.data = data;
            macrosPreset.label = "Макросы партнера";
            macrosPreset.defaultPreset = true;
            macrosPreset.type = PARTNER_MACROS;
            macrosPreset.accessType = presetsAccessType();
            this.props.savePreset(macrosPreset)
        }
    };

    selectBillingType = (billingType) => {
        this.setState({selectedBillingType: billingType, needToBuildTemplate: true})
    };

    returnToSmartLinkEditing = () => {
        const smartLink = this.props.editableSmartLink;
        const allOffers = this.props.allOffers;
        const {selectedSmartLinkOfferIds} = this.state;
        if (!selectedSmartLinkOfferIds || selectedSmartLinkOfferIds.length === 0) {
            return;
        }
        let smartLinkOffers = [];
        if (smartLink && smartLink.smartLinkOffers && allOffers) {
            const selectedSmartLinkOffers = allOffers.filter(offer => selectedSmartLinkOfferIds.includes(offer.offerId));
            selectedSmartLinkOffers.forEach(offer => {
                const existedSmartLinkOffer = smartLink.smartLinkOffers.find(smartLinkOffer => smartLinkOffer.offerId === offer.offerId);
                if (existedSmartLinkOffer) {
                    smartLinkOffers.push(existedSmartLinkOffer);
                } else {
                    const income = offer.offerIncomes?.find(income => income.billingType === this.state.selectedBillingType.type);

                    smartLinkOffers.push(
                        {
                            offerId: income?.offerId,
                            category: offer.category,
                            currentIncome: income?.income,
                            offerName: offer.name,
                            probability: 100,
                            billingType: income?.billingType,
                            operatorType: offer.operatorType
                        }
                    );
                    smartLink.hasChanges = true;
                }
            });
            if (smartLink.smartLinkOffers.length !== smartLinkOffers.length) {
                smartLink.hasChanges = true;
            }
            smartLink.smartLinkOffers = smartLinkOffers;
        }
        this.returnToPrevComponent();
    };

    showSmartLinkOffersChangingContent = (smartLink) => {
        if (!smartLink) {
            return;
        }
        const selectedOfferIds = smartLink.smartLinkOffers
            .filter(smartLinkOffer => smartLinkOffer.billingType === this.state.selectedBillingType.type)
            .map(smartLinkOffer => smartLinkOffer.offerId);
        this.setState({
            selectedSmartLinkOfferIds: selectedOfferIds,
            filters: {...this.state.filters,
                isOnlySelectedOffers: true,
                selectedCategories: [smartLink.category]
            },
            needToFilter: true,
        });
        this.displayComponent(SMARTLINK_OFFERS_KIT_CHANGING_CONTENT);
    };


    renderAllOffersContent = () => {

        return <OffersCardsGridContent
            tabs={partnerOfferTabs}
            selectNavTab={this.handleTabSelection}
            selectedNavTab={this.state.selectedNavTab}
            selectedOfferIds={this.state.selectedOfferIds}
            selectedOffers={this.state.selectedOffers}
            onSelectOfferCard={this.toggleOffer}
            displayedComponent={this.state.displayedComponent}
            searchInputValue={this.state.searchInputValue}
            beforeSearchInputAmount={this.state.beforeSearchInputAmount}
            filters={this.state.filters}
            resetAllFilters={this.resetAllFilters}
            toggleCheckboxFilter={this.toggleCheckboxFilter}
            onSelectFilterValue={this.onSelectFilterValue}
            allOffers={this.props.allOffers}
            isLoading={this.isLoading}
            filteredOffers={this.state.filteredOffers}
            filterOffers={this.filterOffers}
            needToFilter={this.state.needToFilter}
            isContactsModalOpened={this.state.isContactsModalOpened}
            closeContactsModal={this.closeContactsModal}
            onChangeSearchInput={this.onFooterTextTyping}
            searchInputAmount={this.state.filteredOffers ? this.state.filteredOffers.length : 0}
            onClickAdditionalEl={this.onShowLinksAndSmartlinksCreationContent}
            displayIpRangesBtn
            popupImg={createLinkImg}
        />
    };

    renderConnectedOffersContent = () => {
        return (
            <ConnectedOffersContent
                tabs={partnerOfferTabs}
                selectNavTab={this.handleTabSelection}
                selectedNavTab={this.state.selectedNavTab}
                displayedComponent={this.state.displayedComponent}
                searchInputValue={this.state.searchInputValue}
                beforeSearchInputAmount={this.state.beforeSearchInputAmount}
                filters={this.state.filters}
                resetAllFilters={this.resetAllFilters}
                toggleCheckboxFilter={this.toggleCheckboxFilter}
                onSelectFilterValue={this.onSelectFilterValue}
                filteredOffers={this.state.filteredOffers}
                filterOffers={this.filterOffers}
                needToFilter={this.state.needToFilter}
                onChangeSearchInput={this.onFooterTextTyping}
                searchInputAmount={this.state.filteredOffers ? this.state.filteredOffers.length : 0}
                displayIpRangesBtn
            />
        );
    };

    renderSmartLinksContent = () => {
        return <SmartLinksContent
            tabs={partnerOfferTabs}
            selectNavTab={this.handleTabSelection}
            selectedNavTab={this.state.selectedNavTab}
            displayedComponent={this.state.displayedComponent}
            searchInputValue={this.state.searchInputValue}
            beforeSearchInputAmount={this.state.beforeSearchInputAmount}
            filteredSmartLinks={this.state.filteredSmartLinks}
            filterSmartLinks={this.filterSmartLinks}
            filters={this.state.filters}
            resetAllFilters={this.resetAllFilters}
            onSelectFilterValue={this.onSelectFilterValue}
            needToFilter={this.state.needToFilter}
            onShowEditSmartLinkComponent={this.onShowEditSmartLinkComponent}
            showSmartLinkOffersChangingContent={this.showSmartLinkOffersChangingContent}
            onChangeSearchInput={this.onFooterTextTyping}
            searchInputAmount={this.state.filteredSmartLinks ? this.state.filteredSmartLinks.length : 0}
        />;
    };

    renderLinksCreationContent = () => {
        const {selectedOffers, selectedProject, createdSmartLinksByProjectIdMap, smartLinksTemplates, needToBuildTemplate,
            shouldScrollToLink, shouldScrollToSmartLink, macros, selectedBillingType} = this.state;
        return <LinkCreationComponent
            selectedOffers={selectedOffers}
            returnToPrevComponent={this.returnToPrevComponent}
            onSelectProject={this.onSelectProject}
            selectedProject={selectedProject}
            onExcludeOffer={this.toggleOffer}
            onShowEditSmartLinkComponent={this.onShowEditSmartLinkComponent}
            createdSmartLinksByProjectIdMap={createdSmartLinksByProjectIdMap}
            smartLinksTemplates={smartLinksTemplates}
            needToBuildTemplate={needToBuildTemplate}
            createSmartLinks={this.createSmartLinks}
            deleteSmartLink={this.deleteSmartLink}
            addCreatedLinksToSelectedOffers={this.afterCreateLinksCallback}
            createLinks={this.createSimpleLinks}
            shouldScrollToLink={shouldScrollToLink}
            shouldScrollToSmartLink={shouldScrollToSmartLink}
            macros={macros}
            selectedBillingType={selectedBillingType}
            onSelectBillingType={this.selectBillingType}
        />
    };

    renderSmartLinksEditingContent = () => {
        const {displayedComponent, selectedProject, smartLinkId, selectedBillingType} = this.state;
        return <SmartLinkEditingComponent
            smartLinkId={smartLinkId}
            displayedComponent={displayedComponent}
            displayComponent={this.displayComponent}
            returnToPrevComponent={this.returnToPrevComponent}
            onSelectProject={this.onSelectProject}
            selectedProject={selectedProject}
            deleteSmartLink={this.deleteSmartLink}
            showSmartLinkOffersChangingContent={this.showSmartLinkOffersChangingContent}
            saveSmartLink={this.updateSmartLink}
            selectedBillingType={selectedBillingType}
            onSelectBillingType={this.selectBillingType}
        />;
    };

    renderSmartLinkOffersKitChangingContent = () => {
        return (<OffersCardsGridContent
            selectedOfferIds={this.state.selectedSmartLinkOfferIds}
            selectedOffers={this.props.allOffers.filter(offer => this.state.selectedSmartLinkOfferIds.includes(offer.offerId))}
            onSelectOfferCard={this.toggleSmartLinkOffer}
            displayedComponent={this.state.displayedComponent}
            searchInputValue={this.state.searchInputValue}
            beforeSearchInputAmount={this.state.beforeSearchInputAmount}
            filters={this.state.filters}
            resetAllFilters={this.resetAllFilters}
            toggleCheckboxFilter={this.toggleCheckboxFilter}
            onSelectFilterValue={this.onSelectFilterValue}
            allOffers={this.props.allOffers}
            isLoading={this.isLoading}
            filterOffers={this.filterOffers}
            filteredOffers={this.state.filteredOffers}
            needToFilter={this.state.needToFilter}
            isContactsModalOpened={this.state.isContactsModalOpened}
            closeContactsModal={this.closeContactsModal}
            displayIpRanges
            onChangeSearchInput={this.onFooterTextTyping}
            onClickAdditionalEl={this.returnToSmartLinkEditing}
            searchInputAmount={this.state.filteredOffers ? this.state.filteredOffers.length : 0}
            popupImg={checkedImg}
        />);
    };


    render() {
        const {displayedComponent} = this.state;

        const renderPage = () => {
            if (!displayedComponent) {
                return null;
            }
            switch (displayedComponent) {
                case ALL_OFFERS:
                    return <BasePage pageName="Офферы" content={this.renderAllOffersContent()}/>;
                case CONNECTED_OFFERS:
                    return <BasePage pageName="Офферы" content={this.renderConnectedOffersContent()}/>;
                case SMARTLINKS:
                    return <BasePage pageName="Офферы" content={this.renderSmartLinksContent()}/>;
                case LINKS_AND_SMARTLINKS_CREATION_CONTENT:
                    return <BasePage pageName="Офферы" content={this.renderLinksCreationContent()}/>;
                case SMARTLINKS_EDITING_CONTENT:
                    return  <BasePage pageName="Офферы" content={this.renderSmartLinksEditingContent()}/>;
                case SMARTLINK_OFFERS_KIT_CHANGING_CONTENT:
                    return <BasePage pageName="Офферы" content={this.renderSmartLinkOffersKitChangingContent()}/>;
                default :
                    return null;
            }
        };

        return renderPage();
    }

}


function mapStateToProps(state) {
    return {
        allOffers: state.partnerOffers.offers,
        connectedOffers: state.partnerOffers.connectedOffers,
        smartLinks: state.partnerSmartLinks.smartLinks,
        editableSmartLink: state.partnerSmartLinks.editableSmartLink,
        selectedOffers: state.partnerOffers.selectedOffers,
        selectedTab: state.partnerOffers.selectedTab,
        domains: state.partnerDomainsReducer.partnerDomains,
        projects: state.projects.projects,
        macrosPreset: state.preset.macrosPreset,
        isLoading: state.partnerOffers.isAllOffersLoading,
    }
}

export default connect(mapStateToProps, {
    loadPartnerOffers,
    createLinks,
    createSmartLinks,
    saveSmartLink,
    showWarning,
    deleteSmartLink,
    loadPartnerDomains,
    savePreset,
    loadFiltersData,
})(PartnerOffersPage);

const buildSmartLinksTemplates = (selectedOffers, selectedProject, createdSmartLinks, selectedBillingType) => {
    if (!selectedOffers || !selectedProject || !selectedBillingType) {
        return null;
    }
    if (createdSmartLinks && createdSmartLinks.find(smartLink => smartLink.projectId === selectedProject.id)) {
        return null;
    }
    const d = new Date();
    let dateString = d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear() + "_" +
        d.getHours() + ":" + d.getMinutes();
    selectedOffers = selectedOffers.sort((a, b) => a.category.name.localeCompare(b.category.name));
    const smartLinkTemplates = [];
    let smartLinkTemplate = null;
    selectedOffers.forEach(offer => {
        const income = offer.offerIncomes.find(income => income.billingType === selectedBillingType.type);
        if (!income) {
            return;
        }
        const smartLinkOffer = {
            offerId: income.offerId,
            category: offer.category,
            currentIncome: income.income,
            offerName: offer.name,
            probability: 100,
            billingType: income.billingType,
            operatorType: offer.operatorType
        };

        if (!smartLinkTemplate || smartLinkTemplate.category.id !== offer.category.id) {
            const name = dateString + '-' + offer.category.name + '-' + selectedProject.dominicPartnerId + '-' + selectedProject.sourceId;
            smartLinkTemplate = {
                name: name,
                category: offer.category,
                offerCategoryId: offer.category.id,
                countries: offer.countryType ? [offer.countryType] : [],
                operators: offer.operatorType ? [offer.operatorType] : [],
                smartLinkOffers: [smartLinkOffer],
                projectId: selectedProject.id,
                offerAmount: 1
            };
            smartLinkTemplates.push(smartLinkTemplate);
        } else {
            if (smartLinkTemplate.countries.indexOf(offer.countryType) === -1) {
                smartLinkTemplate.countries.push(offer.countryType);
            }
            if (smartLinkTemplate.operators.indexOf(offer.operatorType) === -1) {
                smartLinkTemplate.operators.push(offer.operatorType);
            }
            smartLinkTemplate.smartLinkOffers.push(smartLinkOffer);
            smartLinkTemplate.offerAmount = smartLinkTemplate.smartLinkOffers.length;
        }
    });
    return smartLinkTemplates;
};
