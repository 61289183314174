import React, {useEffect, useRef, useState} from "react";
import {getRusDateHourString} from "../../../../utils/dateUtils";
import SimpleTable from "../../../common/tables/SimpleTable";
import {LinkCopyPopup} from "../../../common/linkCopyPopup/LinkCopyPopup";
import {ASC, DESC} from "../../../../utils/constants";
import {sort} from "../../../../utils/sortUtils";
import {connect} from "react-redux";
import {deleteFile, loadAllFilesData, uploadFile} from "../../../../actions/fileActions";
import ActionPopover from "../../../common/popovers/ActionPopover";
import RosLinesUploadComponent from "./RoslinesUploadComponent";
import {uploadRosLineFile} from "../../../../actions/rosLinesActions";


function FilesContent({loadAllFilesData, deleteFile, uploadFile, allFiles}) {

    const childRef = useRef();
    const inputFileRef = useRef();

    const [sortingData, setSortingData] = useState({
        sortableColumn: "createDate",
        sortableDirection: DESC
    });

    const [renderedData, setRenderedData] = useState(null);

    useEffect(() => {
        loadAllFilesData();
    }, []);

    useEffect(() => {
        if (!allFiles || !sortingData) {
            return;
        }
        const {sortableColumn, sortableDirection} = sortingData;
        const directionNumb = !sortableDirection
            ? 0
            : sortableDirection === ASC
                ? 1
                : -1;
        const renderedData = sort(allFiles, sortableColumn, directionNumb);
        setRenderedData(renderedData);
    }, [sortingData.sortableColumn, sortingData.sortableDirection, allFiles]);


    const changeSortData = (fieldName, direction) => {
        setSortingData({
            sortableColumn: fieldName,
            sortableDirection: direction
        })
    };

    const handleClickUploadFile = () => {
        inputFileRef.current.click();
    };

    const handleUploadFile = (e) => {
        uploadFile(e.target.files[0], () => loadAllFilesData());
        e.target.value = "";
    };

    const tableColumns = () => {
        return [
            {
                name: "Дата",
                key: "createDate",
                getBodyCellValue: item => item["createDate"] && getRusDateHourString(new Date(item["createDate"])),
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "Название",
                key: "name",
                getBodyCellValue: item => item["name"],
                headerCellStyle: {minWidth: "350px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "Размер, кб",
                key: "fileSize",
                getBodyCellValue: item => item["fileSize"] && Math.round((item["fileSize"]/1024)),
                headerCellStyle: {minWidth: "250px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "Ссылка",
                key: "id",
                getBodyCellValue: item => {
                    const link = process.env.REACT_APP_FILES_URL + item.id;
                    return (
                        <div style={{cursor: "pointer"}} onClick={() => childRef.current.handleClickCopy(link)}>{link}</div>
                    )
                },
                headerCellStyle: {minWidth: "350px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: <div title="Загрузить файл" onClick={handleClickUploadFile}>
                            <i className="ti ti-upload td-save"/>
                            <input type="file" style={{display: "none"}} ref={inputFileRef} onChange={handleUploadFile} onSelect={handleUploadFile}/>
                     </div>,
                key: "actions",
                getBodyCellValue: (item) =>
                    <ActionPopover
                        button={<i className="ti ti-trash td-save"/>}
                        tooltip="Удалить"
                        popoverLocation='right'
                        label="Удалить?"
                        handleSubmit={() => deleteFile(item.id, () => loadAllFilesData())}/>,
                headerCellStyle: {minWidth: "160px", width: "160px", textAlign: "right"},
                bodyCellStyle: {textAlign: "right"},
                isSortingDisabled: true,
            },
        ]
    };

    return <div className="tab-pane fade active show pl-3" id="custom-nav-home" role="tabpanel"
                aria-labelledby="custom-nav-home-tab">
        <RosLinesUploadComponent/>
        <div className="responsive-block statistic_table_wrapper" id="fullWidth" onScroll={() =>{}} style={{minHeight: '75vh'}}>
            <SimpleTable tableClassName="table table-bordered nowrap statistic_table sticky-header sticky-action sticky-footer userselecttext"
                         columns={tableColumns()} data={renderedData} bodyRowKey="id" isSortable
                         sortableDirection={sortingData.sortableDirection} sortableColumn={sortingData.sortableColumn} onSort={changeSortData}/>
        </div>
        <LinkCopyPopup ref={childRef}/>
    </div>
}

const mapStateToProps = state => ({
    isAllFilesLoading: state.fileReducer.isAllFilesLoading,
    allFiles: state.fileReducer.allFiles,
});

export default connect(mapStateToProps, {
    loadAllFilesData,
    deleteFile,
    uploadFile,
    uploadRosLineFile
})(FilesContent);