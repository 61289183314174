import {
    PARTNER_RULES_LOADED,
    PARTNER_RULES_LOADING, POSTBACK_ITEMS_LOADED, POSTBACK_ITEMS_LOADING,
} from "../../actions/actionTypes";

const initialState = {
    partnerRules: [],
    loading: false,
    resendingItems: [],
    resendingItemsLoading: false,

};

export default function (state = initialState, action) {
    switch (action.type) {
        case PARTNER_RULES_LOADING:
            return {
                ...state,
                loading: true
            };
        case PARTNER_RULES_LOADED:
            return {
                ...state,
                partnerRules: action.payload,
                loading: false
            };
        case POSTBACK_ITEMS_LOADING:
            return {
                ...state,
                resendingItemsLoading: true
            };
        case POSTBACK_ITEMS_LOADED:
            return {
                ...state,
                resendingItems: action.payload,
                resendingItemsLoading: false
            };
        default:
            return state;
    }

}