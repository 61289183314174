import React from "react";
import ActionPopover from "../popovers/ActionPopover";
import {CircularProgress} from "@material-ui/core";

export default function CircleButton({isSelected=false, title="", confirmMessage=null, iconClassName="", onClick = () => {}, isLoading= false, isHidden= false}) {

    return isHidden
                ? <></>
                : confirmMessage
                    ?  <ActionPopover
                        button={
                            <button className={isSelected ? "circle-btn selected" : "circle-btn"} title={title}>
                                <i className={iconClassName}/>
                            </button>
                        }
                        tooltip={confirmMessage}
                        popoverLocation='top'
                        label={confirmMessage}
                        handleSubmit={onClick}/>
                    : <button className={isSelected ? "circle-btn selected" : "circle-btn"} title={title}
                              onClick={() => !isLoading && onClick()}>
                        {isLoading
                            ? <CircularProgress style={{color: 'rgb(102, 102, 102)', height:'20px', width: '20px'}}/>
                            : <i className={isLoading ? 'fa fa-circle-o-notch fa-spin' : iconClassName}/>}
                    </button>

}