import React, {Component} from "react";
import BasePage from "../BasePage";
import {connect} from "react-redux";
import {showWarning} from "../../../actions/warningActions";
import {loadFaqPageContent, savePageContent} from "../../../actions/pageContentActions";
import EditableTabContent from "../../common/htmlEditor/EditableTabContent";
import {faqPageContentTypeMapping, faqPageTabs, offerEditTabs} from "../../../utils/pageTabsUtils";
import {FAQ_TAB_COMMON_INFO} from "../../../utils/constants";
import PageContent from "../../common/page/PageContent";


class FaqPage extends Component {

    state = {
        initValue: null,
        selectedContent: {},
        selectedTab: FAQ_TAB_COMMON_INFO,
        isNewTabSelected: true,
        isEditorOpened: false,
    };

    componentDidMount() {
        this.props.loadFaqPageContent();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.pageContent !== prevProps.pageContent) {
            const contentType = this.state.selectedTab && faqPageContentTypeMapping[this.state.selectedTab];
            const selectedContent = this.props.pageContent.find(pageContent => pageContent.contentType === contentType);
            if (selectedContent) {
                this.setState({...this.state, selectedContent: selectedContent, initValue: selectedContent.content})
            }
        }
    }

    toggleEditorOpened = () => {
        this.setState({...this.state, isEditorOpened: !this.state.isEditorOpened})
    };


    selectTab = (tabName) => {
        const contentType = tabName && faqPageContentTypeMapping[tabName];
        const newEditableContent = this.props.pageContent.find(pageContent => pageContent.contentType === contentType);
        if (this.state.hasChanges) {
            this.props.showWarning("Имеются несохраненные изменения. Сохранить?",
                () => {
                    this.props.savePageContent(this.state.selectedContent, () => this.setOnSelectTabState(tabName, newEditableContent));
                },
                () => this.setOnSelectTabState(tabName, newEditableContent)
            );
        } else {
            this.setOnSelectTabState(tabName, newEditableContent);
        }
    };

    setOnSelectTabState = (tabName, newEditableContent) => {
        this.setState({
            ...this.state,
            selectedTab: tabName,
            hasChanges: false,
            selectedContent: newEditableContent,
            initValue: newEditableContent.content,
            isNewTabSelected: true,
            isEditorOpened: false
        });
    };

    saveContent = () => {
        this.props.savePageContent(this.state.selectedContent, () => this.setState({
            ...this.state,
            hasChanges: false,
            isEditorOpened: false
        }));
    };


    handleChangeContent = (value) => {
        if (!this.state.isNewTabSelected && this.state.selectedContent.content !== value) {
            this.setState({
                ...this.state,
                hasChanges: true,
                selectedContent: {...this.state.selectedContent, content: value},
                isNewTabSelected: false
            });
        } else {
            this.setState({...this.state, isNewTabSelected: false})
        }
    };

    renderContent = () => {
        return <div className="background-white">
            <EditableTabContent
            value={this.state.selectedContent && this.state.selectedContent.content}
            handleChangeContent={this.handleChangeContent}
            handleSave={this.state.hasChanges && this.saveContent}
            toggleEditorOpened={this.toggleEditorOpened}
            isEditorOpened={this.state.isEditorOpened}
        />
        </div>;
    }

    render() {
        return (
            <BasePage pageName="FAQ"
                      content={
                          <PageContent
                              displayContentHeader
                              tabs={faqPageTabs}
                              selectedTabName={this.state.selectedTab}
                              onSelectTab={this.selectTab}
                              contentEl={this.renderContent()}
                          />
                      }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        pageContent: state.pageContentReducer.pageContent,
        error: state.error.data,
    }
};

export default connect(mapStateToProps, {showWarning, loadFaqPageContent, savePageContent})(FaqPage);