import React from 'react';
import {CHANGE_PARTNER_OFFERS_IS_NEW_FILTER,} from "../../../../../actions/actionTypes";
import IosCheckbox from "../../../../common/checkboxes/IosCheckbox";


export default function OffersIsNewFilter({isOnlyNewOffers, toggleIsNew, minWidth}) {

    return (
        <div className="custom-mselect" style={{minWidth: minWidth && (minWidth + "px")}}>
            <IosCheckbox label={'NEW!'} isChecked={isOnlyNewOffers}
                         reduxActionType={CHANGE_PARTNER_OFFERS_IS_NEW_FILTER}
                         onClickFunction={toggleIsNew}
            />
        </div>
    )
}


