import React from "react";
import PropTypes from "prop-types";
import operator_unknown from "../../../resources/images/operators/UNKNOWN.png";


OperatorImage.propTypes = {
    fileName: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.string,
};

export default function OperatorImage({fileName, title="", className, width}) {

    const getImage = () => {
        return fileName
            ? process.env.REACT_APP_OPERATOR_IMG_URL + fileName
                : operator_unknown;
    };


    return <img src={getImage()} alt="" className={className} width={width} title={title}/>
}