import React, {Component, Fragment} from 'react';
import "../../../resources/css/animate.min.css";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {YMInitializer} from "react-yandex-metrika";
import {pathParameter} from "../../../utils/urlUtils";
import {isAdmin, isAuthenticated} from "../../../utils/authUtils";
import {URL_DASHBOARD, URL_INFO, URL_PROJECTS, URL_ROOT} from "../../../properties";
import { Redirect } from "react-router-dom";
import {notEmptyString} from "../../../utils/stringUtils";

class BaseMainPage extends Component{

    componentDidMount() {
        let utm_source = pathParameter("utm_source");
        let ref = pathParameter("ref");

        if (notEmptyString(ref)) {
            localStorage.setItem("ref", ref);
        } else if (notEmptyString(utm_source)) {
            localStorage.setItem("ref", utm_source);
        }

        const body = document.body;
        body.classList.add("morda");
        body.classList.remove("small-device");
        body.classList.remove("theme-dark");
    }


    render() {
        let pathName = window.location.pathname;
        let isInfoPage =  pathName.match("^(" + URL_INFO + ")(/.*)");
        return (
            <Fragment>
                <YMInitializer accounts={[parseInt(process.env.REACT_APP_YA_METRIKA_COUNTER_MAIN_PAGE)]}
                               options={{clickmap: true, trackLinks:true, accurateTrackBounce:true, webvisor: true}}/>
                {isAuthenticated() && !isInfoPage && <Redirect from={URL_ROOT} to={isAdmin() ? URL_PROJECTS : URL_DASHBOARD}/>}
                {this.props.children}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(withRouter(BaseMainPage));