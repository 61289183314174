import axios from "axios";
import {getConfig, getFormDataConfig} from "./authActions";
import {FILE_UPLOADED, ALL_FILES_DATA_LOADED, FILE_DELETED} from "./actionTypes";
import {returnErrors} from "./errorActions";

export const uploadFile = (file, callbackFunction) => (dispatch, getState) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    let response;
    axios
        .post(process.env.REACT_APP_API_URL + '/files', formData , getFormDataConfig(getState))
        .then(res => {
             response = res.data;
                dispatch({
                    type: FILE_UPLOADED,
                    payload: response
                })
            }
        )
        .then(() => callbackFunction && callbackFunction(response))
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadAllFilesData = () => (dispatch, getState) => {
    axios
        .get(process.env.REACT_APP_API_URL + '/files', getConfig(getState))
        .then(res => {
                dispatch({
                    type: ALL_FILES_DATA_LOADED,
                    payload: res.data
                })
            }
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const deleteFile = (id, callbackFunction) => (dispatch, getState) => {
    axios
        .delete(process.env.REACT_APP_API_URL + '/files/' + id, getConfig(getState))
        .then(res => {
                dispatch({
                    type: FILE_DELETED,
                    payload: res.data
                })
            }
        )
        .then(() => callbackFunction && callbackFunction())
        .catch(err => {
            dispatch(returnErrors(err));
        });
};
