import React from "react";
import {useHistory} from 'react-router-dom';

export default function NavigationMenuMobileItem({img, name, pathName, href}) {
    const history = useHistory();


    const getActive = () => {
        return (pathName === window.location.pathname) ? " active" : "";
    };

    const handleClick = () => {
        history.push(href);
    };

    return (
        <div className={"nav-item" + getActive()} onClick={handleClick}>
            <div className="nav-icon">
                <img src={img} alt=""/>
            </div>
            <div className="nav-text">{name}</div>
        </div>
    )
}