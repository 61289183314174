import React from "react";
import ToolsTestLinkCard from "./ToolsTestLinkCard";

export default function OtherToolsContent() {

    return <div  id="custom-nav-home" role="tabpanel"
                aria-labelledby="custom-nav-home-tab" style={{ height: '80vh'}}>
        <ToolsTestLinkCard/>
    </div>

}