import React, {Component} from "react";
import BaseMainPage from "./BaseMainPage";
import MainPageLogo from "./components/logo/MainPageLogo";

export default class RulesPage extends Component {

    componentDidMount() {
        document.body.classList.add('rules-body');
    }


    render() {
        return (
            <BaseMainPage>

                    <div className="rules-container">
                        <div className="rules-content">
                            <MainPageLogo className="rules-page logo"/>
                            <h1 className="rules">ПАРТНЕРСКОЕ СОГЛАШЕНИЕ</h1>
                            <h4>1. Определение терминов</h4>
                            <p>В целях настоящего Соглашения нижеприведенные термины используются в следующем
                                значении:</p>
                            <p>Компания ООО «Аффимоб».</p>
                            <p>Партнёр - любое физическое или юридическое лицо, принявшее условия настоящего
                                Соглашения.</p>
                            <p>Партнёрская программа – программа сотрудничества Компании с Партнёрами, целью которо
                                является продвижение и продажа услуг Компании.</p>
                            <p>Проект - любо мобильны или web-сайт, а также любые печатные издания и любые иные
                                способы распространения Услуг Компании.</p>
                            <p>Услуга - информационно-развлекательный продукт (контент, сервис), предоставляемый
                                Компанией абонентам операторов сотовой связи.</p>
                            <p>Аккаунт - учетная запись, создаваемая Партнеру при заключении Партнерского
                                Соглашения.</p>
                            <p>Партнёрское Соглашение обязательно для соблюдения как Партнёрами, так и Компанией.</p>
                            <p>Регистрация любого пользователя на сайте partner.affimob.com означает согласие с
                                правилами и
                                условиями Партнерского Соглашения.</p>
                            <p>В случае нарушения Партнером Партнёрского Соглашения Компания имеет право применять
                                штрафные санкции к Партнёрам, а также удалять Аккаунты.</p>
                            <p>Настоящее соглашение имеет обязательную силу для сторон с момента его заключения и
                                подлежит применению при решении любых спорных вопросов между сторонами. </p>


                            <h4>2. Участие в Партнёрской программе</h4>
                            <p>2.1 Участие в Партнёрской Программе Компании является бесплатным и добровольным.
                                Партнёром
                                может
                                стать любой желающий.</p>
                            <p>2.2 При регистрации Партнёр обязан указать достоверную информацию о себе и своих
                                Проектах.
                                При
                                изменении информации Партнер обязан в течение 3 рабочих дне внести необходимые изменения
                                в
                                регистрационные данные.</p>
                            <p>2.3 Партнёр обязан следить за соблюдением конфиденциальности реквизитов Аккаунта в
                                Партнёрской
                                программе. За последствия, возникшие вследствие получения доступа третьих лиц к
                                реквизитам Аккаунта (кроме получения доступа по вине Компании), в полной мере отвечает
                                сам
                                Партнёр. В случае утери реквизитов и невозможности их восстановления через e-mail,
                                Партнёр
                                лишается доступа к Аккаунту, Компания не несет за это ответственности и не обязана
                                как-либо
                                решать данную проблему.</p>
                            <h4>3. Формы участия в Партнёрской программе</h4>
                            <p>3.1 Мобильный-магазин - Партнёр создаёт\использует имеющийся сайт для размещения
                                информации
                                об
                                Услуге (информация предоставляется Партнёру Компанией в виде Интернет-ссылок), который
                                позволяет
                                пользователям делать покупки непосредственно со своего мобильного телефона.</p>
                            <p>3.2 Offline - Партнёр использует собственные Проекты (средства) и изготавливает тираж
                                печатной
                                продукции, либо размещает информацию об Услуге в СМИ (все offline макеты должны быть
                                согласованы с Компанией ).</p>
                            <h4>4. Предоставление инструментов для участия в Партнёрской программе</h4>
                            <p>4.1 В Аккаунте Компания предоставляет Интернет-ссылки.</p>
                            <p>4.2 По дополнительному запросу Компания может предоставить текстовую информацию о
                                контенте
                                (коды
                                заказов, сервисные номера, информация о совместимости, актуальность контента и др.) для
                                самостоятельного изготовления Партнёром offline макетов. </p>
                            <p>4.3 Компания осуществляет техническую поддержку Партнёра и пользователей Партнёра.</p>
                            <p>4.4 Компания предоставляет доступ к online статистике продаж Услуг Партнёром в
                                соответствующем
                                разделе Аккаунта</p>
                            <p>4.5 Компания осуществляет ежемесячные выплаты вознаграждения Партнёра по итогам продаж за
                                отчётны
                                период (сроки и условия оговариваются в соответствующем разделе Аккаунта). Отчётным
                                периодом
                                является полны календарны месяц, с первого по последнее числа включительно</p>
                            <h4>5.Партнёр</h4>
                            <p>5.1 Партнёр может пользоваться любыми предоставленными инструментами для распространения
                                Услуг
                                Компании, корректно размещая информацию о предоставляемых Услугах.</p>
                            <p>5.2 Партнёр имеет право претендовать на эксклюзивный статус (условия предоставления
                                эксклюзивного
                                статуса Партнёру, а также дополнительные возможности, связанные с получением
                                статуса, описаны отдельно, вне настоящего Соглашения).</p>
                            <p>5.3 При изготовлении тиража печатной продукции, либо размещении информации об Услуге
                                вСМИ,
                                Партнёр обязан согласовать offline макеты с Компанией.</p>
                            <h4>6.Размещение Партнёром информации об Услуге</h4>
                            <p>6.1 Партнёр обязуется корректно использовать предоставляемую Компанией информацию об
                                Услуге.</p>
                            <p>6.2 Примеры некорректного использования информации об Услуге:</p>
                            <p>6.2.1 Несанкционированные (незапрашиваемые получателем) рассылки на электронные почтовые
                                адреса и
                                номера сотовых телефонов (sms-рассылка) потенциальных пользователе(спам).</p>
                            <p>6.2.2 Введение пользователя в заблуждение относительно способов и стоимости получения,
                                атакже
                                порядка использования Услуги, предоставляемо Компание(пользователю должна
                                бытьпредоставлена
                                достоверная информация о стоимости и форме предоставления Услуги)</p>
                            <p>6.2.3 Использование нелегальных форм осуществления заказов с применением
                                Партнёромспециализированного оборудования</p>
                            <p>6.2.4 Размещение текстово информации на Проекте о коде заказа и сервисном номере
                                безуказания
                                стоимости и списка поддерживаемых телефонов</p>
                            <p>6.2.5 Размещение на Интернет-ресурсе программных приложени , при скачивании пользователем
                                которых
                                без предупреждения о стоимости отправляется sms на сервисны номер.</p>
                            <p>6.3 Ответственность Партнёра в случае выявления фактов нарушения пункта 6.1. настоящего
                                Соглашения:</p>
                            <p>6.3.1 Предупреждение письмом на адрес электронно почты Партнёра, коды которого были
                                использованы
                                по факту нарушения.</p>
                            <p>6.3.2 Штраф (в размере до обще суммы вознаграждения Партнёра за последние шесть месяцев
                                и\или
                                в
                                размере понесенных Компание убытков в результате некорректного использования информации
                                об
                                Услуге Партнером) и\или сброс части статистики Партнёра.</p>
                            <p>6.3.3 Блокирование Аккаунта Партнёра со сбросом все статистики продаж Партнёра с
                                последующим
                                удалением Аккаунта.</p>
                            <p>6.3.4 Передача информации о Партнёре в Правоохранительные Органы </p>
                            <h4>7. Порядок действия и изменения Партнёрского Соглашения</h4>
                            <p>7.1. Данное Соглашение вступает в де ствие сразу после принятия Партнёром данно публично
                                оферты.
                                В случае несогласия с правилами и условиями настоящего Соглашения Партнёр обязан
                                отказаться
                                от
                                регистрации.</p>
                            <p>7.2. Компания имеет право изменять и/или дополнять данные правила без предварительного
                                уведомления Партнёров. В случае изменения текста Соглашения новое Соглашение вступает в
                                действие
                                для всех пользователе в момент публикации его на сайте Партнёрской Программы
                                www.affimob.com</p>
                            <p>7.3. Партнёр имеет право расторгнуть настоящее Соглашение путем уведомления Компании по
                                адресу
                                электронной почты info@affimob.com о своем желании. При этом Соглашение считается
                                расторгнутым
                                по истечении 30 рабочих дне с момента получения Компанией данного уведомления</p>
                    </div>
                </div>
            </BaseMainPage>
        )
    }


}