import {
    SAVE_MANAGER_PROFILE,
    SAVE_PARTNER_PROFILE,
    USER_DATA_LOADED,
    USER_DATA_LOADING,
    USER_REFERRAL_ID_LOADED
} from "../../actions/actionTypes";

const initialState = {
    loading: false,
    userData: null,
    referralUrl: "",
};

export default function(state = initialState, action) {
    switch (action.type) {
        case USER_DATA_LOADING:
            return {
                ...state,
                loading: true,
            };
        case USER_DATA_LOADED:
            return {
                ...state,
                loading: false,
                userData: action.payload,
            };

        case SAVE_MANAGER_PROFILE:
        case SAVE_PARTNER_PROFILE:
            return {
                ...state,
                userData: action.payload,
            };
        case USER_REFERRAL_ID_LOADED:
            return {
                ...state,
                referralUrl: action.payload,
            };
        default:
            return state;
    }
}