import {
    ACTUAL_CURRENCY_RATES_LOADED,
    ACTUAL_CURRENCY_RATES_LOADING,
} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";

export const loadActualCurrencyRates = () => (dispatch, getState) => {
    dispatch({type: ACTUAL_CURRENCY_RATES_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/currencyRates/actual' , getConfig(getState))
        .then(res =>
            dispatch({
                type: ACTUAL_CURRENCY_RATES_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};