import React, {useRef} from "react";

export default function ActionMenuIconWrapper({children, className, onClick, text}) {

    const ref = useRef();

    const showText = () => {
        const textEl = ref.current;
        textEl.classList.add("animate__animated");
        textEl.classList.add("fadeInRight");
        textEl.classList.add("animate__faster");
        textEl.style.display = "inline";
    };

    const hideText = () => {
        const textEl = ref.current;
        textEl.classList.remove("animate__animated");
        textEl.classList.remove("fadeInRight");
        textEl.classList.remove("animate__faster");
        textEl.style.display = "none";
    };

    return (
        <div className={className} onClick={onClick}
             onMouseEnter={showText}
             onMouseLeave={hideText}>
                <span className="material-icons">
                    {children}
                </span>
            <span ref={ref} className="menu-text hide" style={{display: "none", fontSize: "16px"}}>{text}</span>
        </div>
    )
}