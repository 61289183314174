import React, {useEffect, useState} from "react";
import {Popover} from "@material-ui/core";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {connect} from "react-redux";
import {
    deleteIncome,
    loadOfferIncomes,
    loadWithChildrenOfferIncomes,
    saveIncomes
} from "../../../../actions/incomesActions";
import {loadAllManagerOffers} from "../../../../actions/managerOfferActions";
import {incomeTableColumnFormatters} from "../../../../utils/incomeUtils";
import SimpleTable from "../../../common/tables/SimpleTable";
import TableCheckbox from "../../../common/checkboxes/TableCheckbox";
import {addOrRemove} from "../../../../utils/arrayUtils";
import Spinner from "../../../common/Spinner";
import {getIsoLocalString, getServerCurrentDay} from "../../../../utils/dateUtils";
import ActionPopover from "../../../common/popovers/ActionPopover";

function OfferEditCloneIncomesPopover({button, tooltip, label, offer, parentOffer, loadAllManagerOffers, allOffers, loadOfferIncomes, offerIncomes, isLoading, loadWithChildrenOfferIncomes, saveIncomes}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [tableData, setTableData] = useState([]);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [filteredOffers, setFilteredOffers] = useState(null);
    const [timeoutState, setTimeoutState] = useState({
        timeout: 0
    });

    const [selectedIncomeIds, setSelectedIncomeIds] = useState([]);
    const [isIncomesLoading, setIsIncomesLoading] = useState(false);

    useEffect(() => {
        if (!allOffers || allOffers.length === 0) {
            loadAllManagerOffers();
        }
    }, []);

    useEffect(() => {
        if (!offerIncomes || offerIncomes.length === 0) {
            return;
        }
        const currentDate = getIsoLocalString(getServerCurrentDay());
        const data = offerIncomes.map(income=> {return {
            ...income,
            fromDate: currentDate,
            partnerName: income.partnerName || "ОБЩАЯ",
        }});
        setTableData(data);
    }, [offerIncomes])

    useEffect(() => {

        if (timeoutState.timeout) {
            clearTimeout(timeoutState.timeout);
        }
        if (!selectedOffer) {
            return;
        }
        setIsIncomesLoading(true);
        setTimeoutState({
            timeout: setTimeout(() => {
                loadOfferIncomes([selectedOffer.id], true);
            }, 1000)
        });
    }, [selectedOffer]);

    useEffect(() => {
        if (!offer || !allOffers || allOffers.length === 0) {
            return;
        }
        const offers = allOffers.filter(offerItem => offerItem.billingType === offer.billingType);
        setFilteredOffers(offers);
    }, [allOffers, offer]);

    useEffect(() => {
        setIsIncomesLoading(isLoading);
    }, [isLoading]);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedIncomeIds([]);
        setTableData([]);
        setSelectedOffer(null);
        setIsIncomesLoading(false);
    };

    const toggleCheckAllIncomes = () => {
        if (selectedIncomeIds.length > 0 && selectedIncomeIds.length === tableData?.length) {
            setSelectedIncomeIds([]);
        } else {
            setSelectedIncomeIds(tableData.map(income => income.offerIncomeId));
        }
    };

    const toggleCheckIncome = (incomeId) => {
        const checkedIncomeIds = addOrRemove(selectedIncomeIds, incomeId);
        setSelectedIncomeIds([...checkedIncomeIds]);
    };

    const cloneIncomes = () => {
        const currentDate = getIsoLocalString(getServerCurrentDay());
        const incomes = tableData.filter(offerIncome => selectedIncomeIds.includes(offerIncome.offerIncomeId)).map(offerIncome => {
            return {
                offerId: offer.id,
                from: currentDate,
                income: offerIncome.income,
                incomeType: offerIncome.incomeType,
                partnerId: offerIncome.partnerId,
            }
        });
        saveIncomes(incomes, false, false, () => loadWithChildrenOfferIncomes([parentOffer.offerId]));
        handleClose();
    };


    const tableColumns = () => {
        return [
            {
                name: <TableCheckbox
                    checked={selectedIncomeIds.length > 0 && selectedIncomeIds.length === tableData?.length}
                    id="chbx_all" onChange={toggleCheckAllIncomes}/>,
                key: "checkbox",
                getBodyCellValue: income => <TableCheckbox id={"chbx_" + income.offerIncomeId}
                                                           checked={selectedIncomeIds.includes(income.offerIncomeId)}
                                                           onChange={() => toggleCheckIncome(income.offerIncomeId)}/>,
                headerCellStyle: {minWidth: "110px", textAlign: "left"},
                bodyCellStyle: {textAlign: "left"},
            },
            {
                name: "Период",
                key: "fromDate",
                getBodyCellValue: income => {
                    const fromDate = incomeTableColumnFormatters["rusDateString"](income.fromDate);
                    const toDate = incomeTableColumnFormatters["rusDateString"](income.toDate);
                    return fromDate + " - " + (toDate || "");
                },
                headerCellStyle: {minWidth: "110px", textAlign: "left"},
                bodyCellStyle: {textAlign: "left"},
            },
            {
                name: "Партнер",
                key: "partnerName",
                getBodyCellValue: income => incomeTableColumnFormatters["partnerName"](income.partnerName),
                headerCellStyle: {minWidth: "350px", textAlign: "left"},
                bodyCellStyle: {textAlign: "left"},
            },
            {
                name: "Ставка",
                key: "income",
                getBodyCellValue: income => {
                    return incomeTableColumnFormatters["income"](income.income);
                },
                headerCellStyle: {minWidth: "90px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "Тип ставки",
                key: "incomeType",
                getBodyCellValue: income => incomeTableColumnFormatters["incomeType"](income.incomeType),
                headerCellStyle: {minWidth: "150px", textAlign: "left"},
                bodyCellStyle: {textAlign: "left"},
            },
        ];
    };

    return (
        <span style={{position: "absolute", top: "33%"}}>
            <div aria-describedby={id} onClick={handleClick} title={tooltip} style={{display: "inline"}}>
                {button}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{style: {minWidth: "90vw"}}}
            >
                <div style={{padding: "10px", textAlign: "center"}}>{label}</div>
                <div style={{padding: "20px"}}>

                <MaterialUISelect
                    label="Оффер"
                    placeholder="Выберите оффер"
                    displayedFields={['id', 'offerName']} dataField="id"
                    dropdownData={filteredOffers}
                    selectedValue={selectedOffer}
                    onSelectCallbackFunction={setSelectedOffer}
                    isClearable={false}
                    isSearchable/>
                    {isIncomesLoading
                        ? <Spinner/>
                        : tableData?.length > 0
                            ? <>
                                <div style={{overflowY: "auto", maxHeight: "70vh"}}>
                                    <SimpleTable
                                        tableClassName="table table-bordered nowrap statistic_table sticky-header sticky-action sticky-footer userselecttext"
                                        columns={tableColumns()}
                                        data={tableData} bodyRowKey="offerIncomeId"/>
                                </div>
                                <div style={{width: "100%"}}>
                                    <div className="circle-btn-group" >
                                        <ActionPopover
                                            button={<button className="circle-btn" title="Сохранить"><i className="ti ti-save"/></button>}
                                            popoverLocation='top'
                                            label={<><div>{"Клонировать выбранные ставки в оффер '" + offer.id + " | " + offer.name + "'?"}</div><div>{" Существующие ставки партнеров будут обновлены."}</div></>}
                                            handleSubmit={cloneIncomes}
                                            disabled={selectedIncomeIds.length === 0}
                                        />

                                        <button className="circle-btn" title="Отменить изменения"><i className="ti ti-close"
                                                                                                     onClick={handleClose}/>
                                        </button>
                                    </div>
                                 </div>
                         </>
                            : null
                    }
              </div>
            </Popover>
        </span>
    )
}

const mapStateToProps = (state) => {
    return {
        partnersFilterData: state.managerPartnersReducer.partnersFilterData,
        allOffers: state.managerOffersReducer.allOffers,
        offerIncomes: state.offerIncomeReducer.incomes,
        isLoading: state.offerIncomeReducer.loading,
    }
};

export default connect(mapStateToProps, {
    deleteIncome,
    loadWithChildrenOfferIncomes,
    loadOfferIncomes,
    loadAllManagerOffers,
    saveIncomes
})(OfferEditCloneIncomesPopover);