import {
    STATISTIC_DATA_LOADING,
    STATISTIC_DATA_LOADED,
    CLEAR_STATISTIC_DATA,
    DETAILED_STATISTIC_LOADING,
    DETAILED_STATISTIC_LOADED,
    DETAILED_STATISTIC_DOWNLOADING,
    DETAILED_STATISTIC_DOWNLOADED,
    MONITOR_CHART_DATA_LOADED,
    MONITOR_CHART_DATA_LOADING,
} from '../../actions/actionTypes';
import {
    OPERATOR_DEDUCTION_KOEFF,
    OPERATOR_KOEFF,
    PARTNER_KOEFF,
    PRICE,
    ROYALTIES_KOEFF, STATISTIC_REPORT_CHART, STATISTIC_REPORT_TABLE
} from "../../utils/constants";


const disabledLineChartColumns = [PRICE, OPERATOR_DEDUCTION_KOEFF, OPERATOR_KOEFF, PARTNER_KOEFF, ROYALTIES_KOEFF];

const initialState = {
    loadedData: [],
    tableColumns: [],
    tableData: [],
    tableResult: null,
    chartData: [],
    selectedFilterPreset: null,
    selectedColumnsPreset: null,
    loading: false,
    detailedStatisticData: [],
    loadingDetailedStatistic: false,
    downloadingDetailedStatistic: false,
    loadingMonitorChartData: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case STATISTIC_DATA_LOADING:
            return {
                ...state,
                tableColumns: [],
                lineChartColumns: [],
                loadedData: [],
                tableData: [],
                chartData: [],
                loading: true
            };
        case STATISTIC_DATA_LOADED:
            const reportType = action.payload.reportType;
            const loadedData = action.payload.statisticData.statistic;
            const columns = action.payload.statisticData.columns;
            return state.loading
                ? {
                ...state,
                tableColumns: reportType === STATISTIC_REPORT_TABLE ? columns : [],
                lineChartColumns: reportType === STATISTIC_REPORT_CHART ? columns.filter(column => !disabledLineChartColumns.includes(column.name)) : [],
                loadedData: loadedData,
                tableData: reportType === STATISTIC_REPORT_TABLE ? loadedData : [],
                chartData: reportType === STATISTIC_REPORT_CHART && loadedData ? [...loadedData] : [],
                loading: false,
            }
            : state;
        case DETAILED_STATISTIC_LOADING:
            return {
                ...state,
                detailedStatisticData: [],
                loadingDetailedStatistic: true,
            };
        case DETAILED_STATISTIC_LOADED:
            return {
                ...state,
                detailedStatisticData: action.payload,
                loadingDetailedStatistic: false,
            };
        case CLEAR_STATISTIC_DATA:
            return {
                loadedData: [],
                tableColumns: [],
                lineChartColumns: [],
                tableData: [],
                tableResult: null,
                chartData: [],
                selectedFilterPreset: null,
                selectedColumnsPreset: null,
                loading: false,
                loadingMonitorChartData: false,
            };
        case DETAILED_STATISTIC_DOWNLOADING:
            return  {
                ...state,
                downloadingDetailedStatistic: true,
            };
        case DETAILED_STATISTIC_DOWNLOADED:
            return  {
                ...state,
                downloadingDetailedStatistic: false,
            };
        case MONITOR_CHART_DATA_LOADING:
            return  {
                ...state,
                loadingMonitorChartData: true,
            };
        case MONITOR_CHART_DATA_LOADED:
            return  {
                ...state,
                loadingMonitorChartData: false,
                chartData: action.payload.statistic,
                lineChartColumns: action.payload.columns.filter(column => !disabledLineChartColumns.includes(column.name)),
            };
        default:
            return state;
    }
}
