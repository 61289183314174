import React from "react";
import PropTypes from 'prop-types';

ProfileContactsInput.propTypes = {
    inputName: PropTypes.string,
    label: PropTypes.string,
    iconName: PropTypes.string,
    iconSize: PropTypes.number,
    value: PropTypes.string,
    onTextTyping: PropTypes.func,
};

export default function ProfileContactsInput({inputName, label, iconName, iconSize, value, onTextTyping, errorMsg, isIncorrect}) {

    return (
        <div className="form-group">
            <label className="form-control-label">{label}</label>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <button className={"btn btn-outline-secondary" + (errorMsg ? " input-error" : "")} type="button" >
                        <span className={iconName}/>
                    </button>
                </div>
                <input type="text" className={"form-control" + (errorMsg || isIncorrect ? "  input-error" : "")}
                       name={inputName} value={value} onChange={onTextTyping}/>
            </div>
            <p className="error-msg" style={{marginTop: "-15px"}}>{errorMsg}</p>
        </div>
    )
}