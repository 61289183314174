import React, {Fragment, useRef} from "react";
import CircleButton from "../../../common/buttons/CircleButton";

export default function IpRangeUploadXlsxButton({uploadIpRanges}) {

    const inputFileRef = useRef();

    const onSelectFile = () => {
        inputFileRef.current.click();
    };

    const onChangeFile = (e) => {
        uploadIpRanges(e.target.files[0]);
        e.target.value = "";
    };

    return (
        <Fragment>
            <CircleButton onClick={onSelectFile} iconClassName='ti ti-upload' title='Загрузить диапазоны'/>
            <input type="file" style={{display: "none"}} ref={inputFileRef} onChange={onChangeFile} onSelect={onChangeFile}/>
        </Fragment>
    )
}