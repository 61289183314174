import React from "react";
import logo from "../../../resources/images/new-logo.svg";
import affimob from "../../../resources/images/invert-affimob.svg";

export default function ChatStartComponent({startDialog}) {

    return (
        <div className="start-window">
            <p>
                <img src={logo} alt="" className="icon-logo start"/>
                    <img src={affimob} alt="" className="text-logo"/></p>
            <p className="h1">Привет, партнер! Появились вопросы?</p>
            <p>Скорей пиши , мы с радостью тебе поможем!</p>
            <p>
                <button className="btn btn-outline-secondary btn-lg" onClick={startDialog}>Начать диалог</button>
            </p>
            <p style={{fontSize: "10px"}}>(скорость ответа на сообщения зависит от загруженности саппорт-отдела, ~20 мин)</p>
        </div>
    )
}