import {
    DELETE_RESENDING_ITEM,
    PARTNER_RULES_LOADED,
    PARTNER_RULES_LOADING, POSTBACK_ITEMS_LOADED, POSTBACK_ITEMS_LOADING, RESEND_POSTBACKS
} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnAllErrorsAsPopup, returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";


export const loadPartnerRules = () => (dispatch, getState) => {

        dispatch({type: PARTNER_RULES_LOADING});
        axios
            .get(process.env.REACT_APP_API_URL + '/postbackResending/rules', getConfig(getState))
            .then(res =>
                dispatch({
                    type: PARTNER_RULES_LOADED,
                    payload: res.data
                })
            )
            .catch(err => {
                dispatch(returnErrors(err));
            });
    };

export const resendPostbacks = (body, callbackFunction) => (dispatch, getState) => {
    axios
        .post(process.env.REACT_APP_API_URL + '/postbackResending/resend', body, getConfig(getState))
        .then(res =>
            dispatch({
                type: RESEND_POSTBACKS,
                payload: res.data
            })
        )
        .then(() => callbackFunction && callbackFunction())
        .then(() => {dispatch(getSuccess({success: "Событие добавлено в очередь для отправки"}))})
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
        });
};


export const deleteResendingItem = (id, callbackFunction) => (dispatch, getState) => {
    axios
        .delete(process.env.REACT_APP_API_URL + '/postbackResending/delete/' + id,  getConfig(getState))
        .then(res =>
            dispatch({
                type: DELETE_RESENDING_ITEM,
                payload: res.data
            })
        )
        .then(() => callbackFunction && callbackFunction())
        .then(() => {dispatch(getSuccess({success: "Событие удалено"}))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};


export const loadPostbackResendingItems = (ids, callbackFunction) => (dispatch, getState) => {
    dispatch({type: POSTBACK_ITEMS_LOADING});
    let url = process.env.REACT_APP_API_URL + '/postbackResending/all?ids=';
    url += ids ? ids.join(",") : "";
    let result = null;
    axios
        .get(url, getConfig(getState))
        .then(res => {
                result = res.data;
                dispatch({
                    type: POSTBACK_ITEMS_LOADED,
                    payload: res.data
                });
            }
        )
        .then(() => callbackFunction && callbackFunction(result))
        .catch(err => {
            dispatch(returnErrors(err));
        });
};