import React, {useState} from 'react';
import PropTypes from "prop-types";
import {isMobileScreen} from "../../../../utils/displayUtils";
import {useHistory} from 'react-router-dom';
import IosCheckbox from "../../../common/checkboxes/IosCheckbox";
import {URL_OFFER_ITEM, URL_OFFER_EDIT} from "../../../../properties";
import {getPartnerId, isAdminAsPartner, isPartner} from "../../../../utils/authUtils";
import image_not_available from "../../../../resources/images/image-not-available.png";
import operator_unknown from "../../../../resources/images/operators/UNKNOWN.png";
import {BILLING, SUBSCRIBE_1} from "../../../../utils/constants";
import {incomeSymbolMapping} from "../../../../utils/incomeUtils";


const OfferCard = ({offer, offerIncomes, onSelect, isChecked, isGray, img, maxWidth="320px", width = "250px"}) => {
    const history = useHistory();
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const isMobile = isMobileScreen();




    const handleOfferNameClick = () => {
        if (isPartner() || isAdminAsPartner()) {
            const href = URL_OFFER_ITEM + '/' + offer.offerId + (isAdminAsPartner() ? ("?partnerId=" + getPartnerId()) : "");
            history.push(href);
        } else {
            const href = URL_OFFER_EDIT + '/' + offer.offerId;
            history.push(href);
        }
    };

    const handleSelect = () => {
        onSelect && onSelect(offer);
    };

    const handlePreviewClick = (e) => {
        e.stopPropagation();
        setIsPreviewOpen(prev => !prev);
    };

    const offerImage = img ? img : offer.imgFileName ? (process.env.REACT_APP_OFFER_PICTURE_URL + offer.imgFileName) : image_not_available;
    const operatorImg = offer.operatorImgFileName ? process.env.REACT_APP_OPERATOR_IMG_URL + offer.operatorImgFileName : operator_unknown;
    const countryImg = offer.countryImgFileName ? process.env.REACT_APP_COUNTRY_IMG_URL + offer.countryImgFileName : operator_unknown;
    const categoryImg = offer.category?.id && process.env.REACT_APP_CATEGORY_IMG_URL + offer?.category?.id + '.png';

    const renderFrontSide = () => {
        return (
            <>
                <div className="flex w-100">
                    <div className="flex-row w-100">
                        <div className="flex row flex-start gap10">
                            <OfferCardId offer={offer}/>
                            <OfferType offer={offer}/>
                        </div>
                        <div className="offer-ui">
                            <OfferCardCheckBox isChecked={isChecked} onClick={handleSelect}/>
                        </div>
                    </div>
                    <div className="offer-info">
                        <div className="flex-row w-100">
                            <OfferCardName offer={offer} onClick={handleOfferNameClick}/>
                        </div>
                        <div className="flex-row w-100 flex-start align-items-start">
                            <OfferCardImage img={offerImage} onClick={handlePreviewClick}/>
                            <div className="offer-info w-100">
                                <OfferCardChip className="country" img={countryImg} name={offer.countryName}/>
                                <OfferCardChip className="operator" img={operatorImg} name={offer.operatorName}/>
                                <OfferCardChip className="category" img={categoryImg} name={offer.category?.name}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="offer-footer">
                    <OfferCardPrice className="price-block row" incomes={offerIncomes}/>
                    <OfferCardButton onClick={handleOfferNameClick}/>
                </div>
            </>
        )
    };


    return (
        <div className={offer.hidden ? "offer-block noBet " : "offer-block"} onClick={handleSelect} style={{maxWidth: !isMobile && maxWidth, width: !isMobile && width}}>
            <label className="label-checkbox">
                <div className={isGray ? "offer-container hvr-reveal oldGray" : "offer-container hvr-reveal"}
                     onClick={(e) => {isMobile && handleSelect(e)}}>
                    <div className="offer-content">
                        {renderFrontSide()}
                    </div>
                </div>
            </label>
            {isPreviewOpen && <OfferCardImgPreview img={offerImage} onClick={handlePreviewClick}/>}
        </div>
    );
};

OfferCard.propTypes = {
    offer: PropTypes.shape({
        offerId: PropTypes.any.isRequired,
        adultContent: PropTypes.bool.isRequired,
        category: PropTypes.shape({name: PropTypes.string}).isRequired,
        name: PropTypes.string.isRequired,
        imgUrl: PropTypes.string,
        term: PropTypes.string,
        operatorType: PropTypes.string,
        countryType: PropTypes.string,
        isGray: PropTypes.bool,
    }), isChecked: PropTypes.bool.isRequired, onSelect: PropTypes.func.isRequired,
};

export default OfferCard;

const OfferCardId = ({offer}) => {
    return (
        <div className="offer-id">
            <span>ID:</span>{offer.offerId}
        </div>
    );
};
const OfferType = ({ offer }) => {

    return (
        <div className="offer-type-chip">
            {offer.recommend && <div className="offer-hot">ТОП</div>}
            {offer.newOffer && <div className="offer-new">НОВЫЙ</div>}
        </div>
    );
};

const OfferCardName = ({offer, onClick}) => {
    return (
        <div className="offer-title" onClick={onClick}>
            {offer.name}
        </div>
    );
};


const OfferCardCheckBox = ({isChecked, onClick}) => {
    return (
        <div className="offer-checkbox">
            <IosCheckbox isChecked={isChecked} onClickFunction={onClick}/>
        </div>
    )
};

const OfferCardImage = ({img, onClick}) => {
    return (
        <div className="offer-image">
            <img src={img}
                 onError={({currentTarget}) => {
                     currentTarget.onerror = null;
                     currentTarget.src = image_not_available;
                 }}
                 alt="" className=""
                 onClick={onClick}/>
        </div>
    );
};

const OfferCardChip = ({img, name, className}) => {
    return (
        <div className="flex-row">
            <div className={className}>
                <span className="icon">
                    <img src={img} alt="" className=""/>
                </span>
                {name}
            </div>
        </div>
    )
};

const OfferCardPrice = ({className, incomes}) => {
    const cpa = incomes?.find(income => income.billingType === SUBSCRIBE_1);
    const cpaIncome = cpa ? cpa.income + incomeSymbolMapping[cpa.currencyCode] : '-';
    const rs = incomes?.find(income => income.billingType === BILLING);
    const rsIncome = rs ? rs.income + incomeSymbolMapping[rs.currencyCode] : '-';

    return (
        <div className={className}>
            <div className="price"><span>CPA:</span> {cpaIncome}</div>
            <div className="price"><span>RS:</span> { rsIncome}</div>
        </div>
    );
};

const OfferCardButton = ({onClick}) => {
    return (
        <div className="but-block">
            <div className="but"
                 onClick={onClick}> {isPartner() || isAdminAsPartner() ? 'Подключить' : 'Редактировать'}</div>
        </div>
    )
};

const OfferCardDescriptionItem = ({label, img, name}) => {
    return (
        <>
            <div className="flex-row description gap10">
                {label}:
                <div className="icon">
                    <img src={img} alt="" className=""/>
                </div> {name}
            </div>
        </>
    )
};

const OfferCardDescriptionLabel = ({name}) => {
    return (
        <div className="flex-row description blue">{name}</div>
    );
};

const OfferCardImgPreview = ({img, onClick}) => {
    return (
        <div className="preview-container" onClick={onClick}>
            <img onError={({currentTarget}) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = image_not_available;
            }}
                 src={img} alt="Preview" className="preview-image"/>
        </div>
    )
};
