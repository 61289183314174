import { GET_SUCCESS, GET_SEMI_SUCCESS, CLEAR_SUCCESS } from '../../actions/actionTypes';

const initialState = {
    successMsg: null
};

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_SUCCESS:
            return {
                successMsg: action.payload,
            };
        case GET_SEMI_SUCCESS:
            return {
                successMsg: action.payload,
                semiSuccess: true,
            };
        case CLEAR_SUCCESS:
            return {
                successMsg: null,
            };
        default:
            return state;
    }
}