import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField/TextField";
import {getMoneyString, toNumber} from "../../../../../utils/formatUtils";
import {AD_NETWORK, CALCULATED, CANCELED, PAID, TRANSFERRING_ERROR} from "../../../../../utils/constants";
import {isNumberStringWithTwoDecimalPlaces} from "../../../../../utils/validationUtils";

export default function ManagerPaymentPopoverIncome({
                                                         isNewPayment, editablePayment, usdCurrencyRate, onChangeEditablePayment,
                                                         balance
                                                     }) {

    const [incomePaidApproved, setIncomePaidApproved] = useState('0,00');
    const [periodIncomeApproved, setPeriodIncomeApproved] = useState('0,00');

    useEffect(() => {
        if (!editablePayment) {
            return;
        }
        if (editablePayment.incomePaidApproved) {
            setIncomePaidApproved(getMoneyString(editablePayment.incomePaidApproved));
        }
        if (editablePayment.periodIncomeApproved) {
            setPeriodIncomeApproved(getMoneyString(editablePayment.periodIncomeApproved));
        }
    }, []);


    const handleIncomeTyping = (e) => {
        const fieldName = e.target.name;
        let value = e.target.value;
        value = value.replace(/\s/g, "");
        if (!isNumberStringWithTwoDecimalPlaces(value)) {
            return;
        }
        if (fieldName === "periodIncomeApproved") {
            setPeriodIncomeApproved(value);
        } else if (fieldName === "incomePaidApproved") {
            setIncomePaidApproved(value);
        }
        onChangeEditablePayment(fieldName, toNumber(value));
    };

    const getUsdIncome = (fieldName) => {
        if (!usdCurrencyRate) {
            return;
        }
        let value = '0,00';
        if (fieldName === "incomePaidApprovedUsd") {
            value = incomePaidApproved;
        } else if (fieldName === "periodIncomeApprovedUsd") {
            value = periodIncomeApproved;
        }
        if (value) {
            value = toNumber(value);
            value = getMoneyString((value / usdCurrencyRate), 2)
        }
        return value;
    };

    const isDisabled = !editablePayment?.isLastPayment || editablePayment.status === PAID || editablePayment.status === CANCELED ||
        (editablePayment.status !== CALCULATED && editablePayment.status !== TRANSFERRING_ERROR
            && editablePayment.paymentData.paymentType === AD_NETWORK);
    return (
        isNewPayment
            ? <>
                <TextField label="К выплате, руб"
                           name="incomePaidApproved"
                           InputLabelProps={{
                               shrink: true,
                           }}
                           onChange={handleIncomeTyping}
                           value={incomePaidApproved}
                           style={{width: '35%', paddingRight: '10px', paddingBottom: '15px'}}
                />
                <TextField label="К выплате, $"
                           name="incomePaidApprovedUsd"
                           InputLabelProps={{
                               shrink: true,
                           }}
                           autoComplete="true"
                           value={getUsdIncome("incomePaidApprovedUsd") ? getUsdIncome("incomePaidApprovedUsd") : ""}
                           style={{width: '35%', paddingRight: '10px', paddingBottom: '15px'}}
                           disabled
                />
                <TextField label="Баланс"
                           name="partnerBalance"
                           value={balance ? balance : ""}
                           disabled
                           InputLabelProps={{
                               shrink: true,
                           }}
                           style={{width: '30%', maxWidth: '160px', paddingBottom: '15px'}}
                />
            </>
            : <>
                <TextField label="Доход за ПП, руб"
                           name="periodIncomeApproved"
                           value={periodIncomeApproved}
                           onChange={handleIncomeTyping}
                           InputLabelProps={{shrink: true}}
                           disabled={isDisabled}
                           style={{width: '50%', paddingBottom: '15px', paddingRight: '10px'}}/>
                <TextField label="Доход за ПП, $"
                           name="periodIncomeApprovedUsd"
                           autoComplete="true"
                           value={getUsdIncome("periodIncomeApprovedUsd") ? getUsdIncome("periodIncomeApprovedUsd") : ""}
                           disabled
                           InputLabelProps={{shrink: true}}
                           style={{width: '50%', paddingBottom: '15px'}}/>
                <TextField label="К выплате, руб"
                           name="incomePaidApproved"
                           value={incomePaidApproved}
                           onChange={handleIncomeTyping}
                           disabled={isDisabled}
                           InputLabelProps={{shrink: true}}
                           style={{width: '50%', paddingBottom: '15px', paddingRight: '10px'}}/>
                <TextField label="К выплате, $"
                           name="incomePaidApprovedUsd"
                           autoComplete={"true"}
                           value={getUsdIncome("incomePaidApprovedUsd") ? getUsdIncome("incomePaidApprovedUsd") : ""}
                           disabled
                           InputLabelProps={{shrink: true}}
                           style={{width: '50%', paddingBottom: '15px'}}/>
            </>
    )
}