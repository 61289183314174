import {
    ALL_BANNERS_LOADED,
    ALL_BANNERS_LOADING, ALL_MAIN_BANNERS_LOADED, ALL_MAIN_BANNERS_LOADING, BANNER_DELETED, BANNER_SAVED
} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";

export const loadAllBanners = () => (dispatch, getState) => {
    dispatch({type: ALL_BANNERS_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/banner/all', getConfig(getState))
        .then(res =>
            dispatch({
                type: ALL_BANNERS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadMainPageBanners = () => (dispatch, getState) => {
    dispatch({type: ALL_MAIN_BANNERS_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/banner/main/all', getConfig(getState))
        .then(res =>
            dispatch({
                type: ALL_MAIN_BANNERS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const saveOrUpdateBanner = (banner, onSuccessCallback) => (dispatch, state) => {
    if (!banner.hasChanges) {
        return;
    }

    axios
        .post(process.env.REACT_APP_API_URL + '/banner', banner, getConfig(state))
        .then(res => {

                dispatch({
                    type: BANNER_SAVED,
                    payload: res.data
                });
            }
        )
        .then(() => {
            onSuccessCallback && onSuccessCallback()
        })
        .then(() => {
            dispatch(getSuccess({success: "Баннер сохранен"}))
        })
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const deleteBanner = (banner, onSuccessCallback) => (dispatch, state) => {
    if (!banner?.id) {
        return;
    }

    axios
        .delete(process.env.REACT_APP_API_URL + '/banner/' + banner.id, getConfig(state))
        .then(res => {

                dispatch({
                    type: BANNER_DELETED,
                    payload: res.data
                });
            }
        )
        .then(() => {
            onSuccessCallback && onSuccessCallback()
        })
        .then(() => {
            dispatch(getSuccess({success: "Баннер удален"}))
        })
        .catch(err => {
            dispatch(returnErrors(err));
        });
};