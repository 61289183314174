/*Если слова указаны через пробел, то ищем по любому из них. Если слова через пробел, но в кавычках, то ищем целиком.
* Если слова указаны с минусом, то исключаем их из поиска*/
export const searchByString = (objects, searchFields, searchString) => {
    if (!objects || !searchFields || !searchString) {
        return objects;
    }
    searchString = searchString.trim();
    const searchArray = getSearchArr(searchString);
    const includeArray = searchArray.filter(item => !item.match(/^-/));
    const excludeArray = searchArray.filter(item => item.match(/^-/)).map(item => item.substring(1));
    let result = includeArray.length === 0 ? objects : objects.filter(object => {
        const searchObjString = getObjectString(object, searchFields);
        return includeArray.some(item => searchObjString.includes(item));
    });
    return result.filter(object => {
        const searchObjString = getObjectString(object, searchFields);
        return !excludeArray.some(item => searchObjString.includes(item));
    });

};

const getObjectString = (object, searchFields) => {
    return searchFields.map(field => object[field]).join(" ").toLowerCase();
};

const getSearchArr = (searchString) => {
    searchString = searchString.toLowerCase();
    if (searchString.match("\"\.\*\"")) {
        return [searchString.split("\"").join("")]; //replaceAll
    }
    return searchString.split(" ");
};