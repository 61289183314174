import React from "react";
import ContentEditable from "react-contenteditable";
import {useSelector} from "react-redux";

export default function ChatFooter({sendMessage, onChatMessageTyping}) {

    const value = useSelector(state => state.chatReducer.text);

    const handleKeyDown = (e) => {
        if ((e.ctrlKey && e.keyCode === 13) || (e.shiftKey && e.keyCode === 13)) {
            return;
        }
        if (e.keyCode === 13) {
            e.preventDefault();
            sendMessage();
        }
    };

    const onTextTyping = (e) => {
        onChatMessageTyping(e.target.value);
    };

    return (
        <div className="input" onKeyDown={handleKeyDown}>
            <ContentEditable html={value} onChange={onTextTyping} className="inputDiv" tagName="div"
                             placeholder="Напишите что нибудь..."/>
                <div className="icon-send" onClick={sendMessage}></div>
        </div>
    )
}
