import {
    REFERRER_STATISTIC_DATA_LOADED, REFERRER_STATISTIC_DATA_LOADING
} from "../../actions/actionTypes";

const initialState = {
    statistic: [],
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REFERRER_STATISTIC_DATA_LOADING:
            return {
                loading: true
            };
        case REFERRER_STATISTIC_DATA_LOADED:
            return {
                statistic: action.payload,
                loading: false
            };
        default:
            return state;
    }

}