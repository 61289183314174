import {
    FILTERS_LOADED,
    FILTERS_LOADING
} from "../../actions/actionTypes";

const initialState = {
    filterData: {},
    loading: false,
};

export default function(state = initialState, action) {
    switch(action.type) {
        case FILTERS_LOADING:
            return {
                ...state,
                loading: true
            };
        case FILTERS_LOADED:
            return {
                ...state,
                loading: false,
                filterData: action.payload,
            };
        default:
            return state;
    }
}