import React, {useEffect, useRef, useState} from "react";
import {emptyString} from "../../../../utils/stringUtils";
import {testPromotionLink} from "../../../../actions/partnerOfferActions";
import connect from "react-redux/es/connect/connect";
import {LinkCopyPopup} from "../../../common/linkCopyPopup/LinkCopyPopup";
import {cutHashWithParams, decodeCurlyBrackets, encodeCurlyBrackets} from "../../../../utils/urlUtils";

function ToolsTestLinkCard({encryptedOfferLink, testPromotionLink}) {

    const [hashedLink, setHashedLink] = useState("");
    const [result, setResult] = useState("");

    const linkCopyRef = useRef();

    const inputStyle = {marginBottom: "10px"};

    useEffect(() => {
        if (emptyString(encryptedOfferLink)) {
            return;
        }
        encryptedOfferLink = decodeURIComponent(encryptedOfferLink);
        encryptedOfferLink = decodeCurlyBrackets(encryptedOfferLink);
        setResult(encryptedOfferLink);
    }, [encryptedOfferLink]);


    const handleTextTyping = (e) => {
        setHashedLink(e.target.value);
        setResult("")
    };

    const testLink = () => {
        let hashedLinkParams = cutHashWithParams(hashedLink);
        if (emptyString(hashedLinkParams)) {
            return;
        }
        hashedLinkParams = encodeCurlyBrackets(hashedLinkParams);
        testPromotionLink(hashedLinkParams);
    };

    return (
        <div className="row" >
            <div className="col-12">
                <label className="form-control-label">Проверка хешированной ссылки на оффер</label>
            </div>
            <div className="col-12">
                <span className="link">
                   <input type="text" placeholder="Хэшированная ссылка" style={inputStyle}
                               className="linkToCopy copyClick form-control form-control-sm"
                               value={hashedLink} onChange={handleTextTyping}/>
                </span>
            </div>
            <div className="col-12">
               <span className="link" onClick={() => linkCopyRef.current.handleClickCopy(result)} >
                   <input type="text" readOnly={true} placeholder="Ссылка с дешифрованными параметрами" style={inputStyle}
                          className="linkToCopy copyClick form-control form-control-sm"
                          value={result} onChange={handleTextTyping}/>
                </span>
            </div>
            <div className="col-1">
                <button className={"btn btn-outline-secondary"} disabled={false} onClick={testLink}
                        style={{marginTop: "2px"}}>Проверить
                </button>
            </div>
            <LinkCopyPopup ref={linkCopyRef}/>
        </div>
    )
}

const mapStateToProps = state => ({
    encryptedOfferLink: state.partnerOffers.encryptedOfferLink,
    isEncryptedOfferLinkLoading: state.partnerOffers.isEncryptedOfferLinkLoading,
});

export default connect(mapStateToProps, {
    testPromotionLink,
})(ToolsTestLinkCard);