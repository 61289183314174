import React from 'react';
import {Popover, Typography, Checkbox, FormControlLabel, TextField} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PropTypes from "prop-types";
import DatePicker from "../pickers/DatePicker";
import {isMobileScreen} from "../../../utils/displayUtils";
import CircleButtonsGroup from "../buttons/CircleButtonsGroup";


ActionPopover.propTypes = {
    button: PropTypes.object.isRequired,
    tooltip: PropTypes.any,
    popoverLocation: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'center']),
    label: PropTypes.any.isRequired,
    hasInput: PropTypes.bool,
    inputLabel: PropTypes.string,
    inputDefaultValue: PropTypes.string,
    hasCheckbox: PropTypes.bool,
    checkboxLabel: PropTypes.string,
    isChecked: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    hasDatePicker: PropTypes.bool,
    datePickerLabel: PropTypes.string,
    disabled: PropTypes.bool,
};

export default function ActionPopover(props) {
    const {
        button, tooltip, popoverLocation, label, hasInput, inputLabel, inputDefaultValue, hasCheckbox, checkboxLabel,
        isChecked, handleSubmit, hasDatePicker, datePickerLabel, disabled, inputValueFormatter, onClickCallback
    } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [state, setState] = React.useState({
        checked: isChecked,
        inputValue: inputDefaultValue,
        dateValue: new Date(),
    });


    function handleClick(event) {
        if (disabled) {
            return;
        }
        onClickCallback && onClickCallback();
        setState({
            checked: isChecked,
            inputValue: inputDefaultValue,
            dateValue: new Date(),
        });
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleChecked({target}) {
        setState({...state, checked: target.checked});
    }

    function handleTextTyping({target}) {
        setState({...state, inputValue: inputValueFormatter ? inputValueFormatter(target.value) : target.value});
    }

    function handleSelectDate(date) {
        setState({...state, dateValue: date});
    }

    function handleOnApply() {
        handleSubmit(state);
        handleClose();
    }

    function getContent() {
        return (
            <div className="card-body" style={{minWidth: "150px"}}>
                <div style={{fontSize: "15px"}}>{label}</div>
                <br/>
                {hasInput
                && <div>
                        <TextField fullWidth label={inputLabel} onChange={(target) => handleTextTyping(target)}
                                   value={state.inputValue}/>
                        <br/><br/>
                    </div>
                }

                {
                    hasDatePicker && <DatePicker date={state.dateValue} inputLabel={datePickerLabel}
                                                 inputWidth={isMobileScreen() ? "250px" : "300px"}
                                                 pickerWrapperStyle={{top: "auto"}}
                                                 onSelectDate={handleSelectDate}
                                                 autoClose/>
                }

                {
                    hasCheckbox
                        && <FormControlLabel
                            control={<Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                checked={state.checked} onChange={(target) => handleChecked(target)}
                                color="default"
                                inputProps={{
                                    'aria-label': 'checkbox with default color',
                                }}/>}
                            label={<Typography style={{fontSize: 14}}>{checkboxLabel}</Typography>}
                        />
                }

                <CircleButtonsGroup buttons={
                    [
                        {
                            isSelected: false,
                            title: "Подтвердить",
                            iconClassName: "ti ti-check stat-icon",
                            onClick: handleOnApply
                        },
                        {
                            isSelected: false,
                            title: "Отмена",
                            iconClassName: "ti ti-close stat-icon",
                            onClick: handleClose
                        },
                    ]
                }/>


            </div>
        );

    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <div aria-describedby={id} onClick={handleClick} title={tooltip}>
                {button}
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: getVerticalAnchorOrigin(popoverLocation),
                    horizontal: getHorisontalAnchorOrigin(popoverLocation),
                }}
                transformOrigin={{
                    vertical: getVerticalTransformOrigin(popoverLocation),
                    horizontal: getHorisontalTransformOrigin(popoverLocation),
                }}
            >
                <div>{getContent()}</div>
            </Popover>
        </div>
    );
}


function getHorisontalAnchorOrigin(location) {
    switch (location) {
        case 'left':
            return 'left';
        case 'right':
            return 'right';
        default:
            return 'center';

    }
}

function getVerticalAnchorOrigin(location) {
    switch (location) {
        case 'top':
            return 'top';
        case 'bottom':
            return 'bottom';
        default:
            return 'center';

    }
}

function getHorisontalTransformOrigin(location) {
    switch (location) {
        case 'left':
            return 'right';
        case 'right':
            return 'left';
        default:
            return 'center';

    }
}

function getVerticalTransformOrigin(location) {
    switch (location) {
        case 'top':
            return 'bottom';
        case 'bottom':
            return 'top';
        default:
            return 'center';

    }
}