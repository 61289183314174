import {
    TOGGLE_OPEN_FILTER_BLOCK_BY_BUTTON,
    TOGGLE_OPEN_FILTER_BLOCK_BY_MOUSE,
} from "../../actions/actionTypes";

const initialState = {
    isFilterBlockOpenByMouse: false,
    isFilterBlockOpenByBtn: false,
};

export default function(state = initialState, action) {

    switch (action.type) {
        case TOGGLE_OPEN_FILTER_BLOCK_BY_MOUSE:
            return {
                ...state,
                isFilterBlockOpenByMouse: action.payload,
                isFilterBlockOpenByBtn: false,
            };
        case TOGGLE_OPEN_FILTER_BLOCK_BY_BUTTON:
            return {
                ...state,
                isFilterBlockOpenByMouse: false,
                isFilterBlockOpenByBtn: action.payload,
            };
        default:
            return state;
    }
}