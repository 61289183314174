import React from "react";

export default function HeaderDesktopActionItem({img, imgClassName, onClick, additionalEl}) {

    return (
        <div className="nav-footer-icon" onClick={onClick && onClick}>
            <img className={imgClassName} src={img} alt=""/>
            {additionalEl}
        </div>
    )
}