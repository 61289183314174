import React from "react";
import {REGISTRATION_FORM, RESTORE_PASSWORD_FORM} from "../../../../../utils/constants";

export default function PopupOtherOptions({openPopup}) {

    return (
        <div className="other-options">
            <div className="option" id="newUser" onClick={() => openPopup(REGISTRATION_FORM)}>
                <p className="option-text">Новый пользователь</p>
            </div>
            <div className="option" id="fPass" onClick={() => openPopup(RESTORE_PASSWORD_FORM)}>
                <p className="option-text"> Восстановить пароль</p>
            </div>
        </div>
    )
}