import React, {useEffect, useState} from "react";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {getPaymentDetailsTypes} from "../../../../utils/paymentUtils";

export default function ProfilePaymentSelect({selectedType, onSelectPaymentType, disabled}) {

    const [paymentType, setPaymentType] = useState(null);

    const dropdownData = getPaymentDetailsTypes();

    useEffect(() => {
        selectedType && setPaymentType(dropdownData.find(item => item.value === selectedType));
    }, [selectedType]);


    return(
        <div className="input-group" style={{paddingBottom: "15px"}}>
            <MaterialUISelect label="Способ оплаты" isDisabled={disabled} dropdownData={dropdownData} selectedValue={paymentType}
                              onSelectCallbackFunction={(paymentType) => onSelectPaymentType('paymentType', paymentType.value)}/>
        </div>
    )
}