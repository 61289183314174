import {BILLING, BILLING_1, ICLICK, SUBSCRIBE_1} from "./constants";
import React from "react";
import {incomeSymbolMapping} from "./incomeUtils";

export const activityStatuses = {
    NEW: {
        color: "#1dd1a1",
        name: "Новый",
        tooltip: "Новый, трафик отсутствует",
    },
    ACTIVE: {
        color: "#10ac84",
        name: "В продвижении",
        tooltip: "Есть трафик за последние 3 дня",
    },
    PAUSED: {
        color: "#ff9f43",
        name: "Пауза",
        tooltip: "Отсутствует трафик за последние 3 дня",
    },
    STOPPED: {
        color: "#ff6b6b",
        name: "Неактивный",
        tooltip: "Оффер устарел",
    },

};

export const billingTypesData = [
    {type: ICLICK, name: 'интернет клик', fullName: 'Интернет клик', shortName: '-'},
    {type: BILLING_1, name: 'первый ребилл', fullName: 'Первый ребилл', shortName: '-'},
    {type: SUBSCRIBE_1, name: 'выкуп', fullName: 'Выкуп', shortName: 'CPA'},
    {type: BILLING, name: 'ребилл', fullName: 'Ребилл', shortName: 'RS'}
    ];

export const availableBillingTypesData = [
    {type: SUBSCRIBE_1, name: 'выкуп', fullName: 'Выкуп', shortName: 'CPA'},
    {type: BILLING, name: 'ребилл', fullName: 'Ребилл', shortName: 'RS'},
];

export const offerNamePostfixMap = {
    [BILLING] : (offerName) => offerName.replace("_Выкуп", ""),
    [SUBSCRIBE_1]: (offerName) => offerName + "_Выкуп"
};

export const getBillingTypeName = (billingType, isFullName) => {
    if (!billingType) {
        return null;
    }
    const fieldName = isFullName ? "fullName" : "name";
    return billingTypesData.find(type => type.type === billingType)[fieldName];
};

export const getOfferNameWithId = (id, name) => {
    return id + ' | ' + name;
};

export const NEW_OFFER_IDS = ['00000', '00001'];

export const newOffer = (billingType) => {
    return {
        id: newOfferId(billingType),
        offerName: "Новый оффер"
    };
};

export const newOfferId = (billingType) => {
    let idx = billingType ? availableBillingTypesData.findIndex(item => item.type === billingType) : 0;
    if (!idx) {
        idx = 0;
    }
    return NEW_OFFER_IDS[idx];
};

export const getIncome = (income, offer) => {
    return !income
        ? " - "
        : (income.incomeType === "PERCENT")
            ? (offer.eup && income.income)
                ? ((offer.eup.replace(",", ".")) * (income.income.replace(",", ".")) / 100).toFixed(2).replace(".", ",") + income.currencyName
                : " - "
            : income.income + (incomeSymbolMapping[income.currencyCode] || '-');
};

export const getIncomes = (incomes, offer) => {
    return <>
        {
            availableBillingTypesData.map(billingTypeItem => {
                const income = incomes.find(income => income.billingType === billingTypeItem.type);
                return <div key={billingTypeItem.type} className="offer-info-item">
                    <span>{getIncome(income, offer)}</span>
                    <span>{billingTypeItem.name}</span>
                </div>
            })
        }
    </>
};
