import React from "react";
import DateRangePicker from "../../../common/pickers/DateRangePicker";

export default function DashboardDateRangePicker({fromDate, toDate, selectFromDate, selectToDate, className, onCloseDatePicker, hidden}) {

    return (
        <div className={className} id="blockInputDatapicker">
            <DateRangePicker fromDate={fromDate}
                             toDate={toDate}
                             selectFromDateFunction={selectFromDate}
                             selectToDateFunction={selectToDate}
                             onClosePicker={onCloseDatePicker}
                             hidden={hidden}/>
        </div>
    )
}
