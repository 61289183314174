import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {isAuthenticated, isAdmin} from "../../utils/authUtils";
import {URL_DASHBOARD, URL_ROOT} from "../../properties";


function AdminRoute ({component: Component,...rest}) {
    return (
        <Route
            {...rest}
            render={
                (props) => isAuthenticated()
                    ? (isAdmin() ? <Component {...props} /> : <Redirect to={{pathname: URL_DASHBOARD, state: {from: props.location}}} />)
                    : <Redirect to={{pathname: URL_ROOT, state: {from: props.location}}} />}
        />
    )
}

export default AdminRoute;