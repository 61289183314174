import {
BLACK_LIST_LOADING, BLACK_LIST_LOADED
} from "../../actions/actionTypes"

const initialState = {
    blackList: null,
    loading: false,
};

export default function getDashboardChartData(state = initialState, action = {}) {
    switch (action.type) {
        case BLACK_LIST_LOADING:
            return {
                ...state,
                loading: true
            };
        case BLACK_LIST_LOADED:
            return {
                ...state,
                blackList: action.payload,
                loading: false
            };
        default:
            return state;

    }
}