import React from "react";
import {
    CURRENT_MONTH, LAST_14_DAYS, LAST_14_5_DAYS, LAST_30_5_DAYS,
    LAST_2_DAYS,
    LAST_30_DAYS,
    LAST_7_DAYS, LAST_MONTH,
    TODAY,
    YESTERDAY,
    MANAGER_LTV_ANALYSIS,
} from "../../../../utils/constants";
import {isAdminAsPartner, isPartner} from "../../../../utils/authUtils";
import {isMobileScreen} from "../../../../utils/displayUtils";
import PeriodButtonsGroupFilter from "../../../common/buttons/PeriodButtonsGroupFilter";


export default function PeriodButtonsFilter(props) {

    const {className, selectedPeriod, setPeriod, statisticType} = props;
    const isLoggedAsPartner = isPartner() || isAdminAsPartner();
    let isLtvAnalysis = statisticType === MANAGER_LTV_ANALYSIS;

    return (
        <div className={className} id="blockInputPeriod">
            <PeriodButtonsGroupFilter
                buttons={[
                    {isSelected: selectedPeriod === TODAY, title: "Сегодня", iconClassName: "date-icon icon-day", onClick: () => setPeriod(TODAY, true), hidden: isLtvAnalysis},
                    {isSelected: selectedPeriod === YESTERDAY, title: "Вчера", iconClassName: "date-icon icon-minus-day", onClick: () => setPeriod(YESTERDAY, true), hidden: isLtvAnalysis},
                    {isSelected: selectedPeriod === LAST_2_DAYS, title: "2 дня", iconClassName: "date-icon icon-2", onClick: () => setPeriod(LAST_2_DAYS, true), hidden: isLtvAnalysis},
                    {isSelected: selectedPeriod === LAST_7_DAYS, title: "7 дней", iconClassName: "date-icon icon-7", onClick: () => setPeriod(LAST_7_DAYS, true), hidden: isLtvAnalysis},
                    {isSelected: selectedPeriod === LAST_14_DAYS, title: "14 дней", iconClassName: "date-icon icon-14", onClick: () => setPeriod(LAST_14_DAYS, true), hidden: isLtvAnalysis || isLoggedAsPartner || isMobileScreen()},
                    {isSelected: selectedPeriod === LAST_14_5_DAYS, title: "14+5 дней", iconClassName: "date-icon icon-14", onClick: () => setPeriod(LAST_14_5_DAYS, true), hidden: !isLtvAnalysis},
                    {isSelected: selectedPeriod === CURRENT_MONTH, title: "Текущий месяц", iconClassName: "date-icon icon-m", onClick: () => setPeriod(CURRENT_MONTH, true), hidden: false},
                    {isSelected: selectedPeriod === LAST_MONTH, title: "Прошедший месяц", iconClassName: "date-icon icon-minus-m", onClick: () => setPeriod(LAST_MONTH, true), hidden: false},
                    {isSelected: selectedPeriod === LAST_30_DAYS, title: "30 дней", iconClassName: "date-icon icon-30", onClick: () => setPeriod(LAST_30_DAYS, true), hidden: isLtvAnalysis},
                    {isSelected: selectedPeriod === LAST_30_5_DAYS, title: "30+5 дней", iconClassName: "date-icon icon-30", onClick: () => setPeriod(LAST_30_5_DAYS, true), hidden: !isLtvAnalysis},
                ]}
            />
        </div>
);
}