import {
    TEST_TELEGRAM_NOTIFICATION_SENT
} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";

export const sendTestTelegramNotification = (chatId) => (dispatch, getState) => {
    axios
        .post(process.env.REACT_APP_API_URL + '/notification/testTelegramNotification', {chatId: chatId},  getConfig(getState))
        .then(res =>
            dispatch({
                type: TEST_TELEGRAM_NOTIFICATION_SENT,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};