import { returnErrors } from './errorActions';
import axios from 'axios';
import {getPartnerId, isAdminAsPartner, isPartner} from "../utils/authUtils";

import {
    PARTNER_BALANCE_LOADING,
    PARTNER_BALANCE_LOADED,
    AVAILABLE_PARTNER_BALANCE_LOADING,
    AVAILABLE_PARTNER_BALANCE_LOADED,
    MANAGER_PARTNER_BALANCE_DATA_LOADING, MANAGER_PARTNER_BALANCE_DATA_LOADED
} from './actionTypes';

import {getConfig} from "./authActions";

export const loadPartnerBalance = () => (dispatch, getState) => {
    dispatch({type: PARTNER_BALANCE_LOADING});

    const partnerId = getPartnerId();
    const hasBalance = isAdminAsPartner() || isPartner();
    if (!partnerId || !hasBalance) {
        return;
    }
    axios
        .get(process.env.REACT_APP_API_URL + '/users/' + partnerId + '/balance', getConfig(getState))
        .then(res =>
            dispatch({
                type: PARTNER_BALANCE_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadAvailablePartnerBalance = () => (dispatch, getState) => {
    dispatch({type: AVAILABLE_PARTNER_BALANCE_LOADING});

    const partnerId = getPartnerId();
    const hasBalance = isAdminAsPartner() || isPartner();
    if (!partnerId || !hasBalance) {
        return;
    }
    axios
        .get(process.env.REACT_APP_API_URL + '/users/' + partnerId + '/availableBalance', getConfig(getState))
        .then(res =>
            dispatch({
                type: AVAILABLE_PARTNER_BALANCE_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadManagerPartnerBalanceData = (partnerId) => (dispatch, getState) => {
    dispatch({type: MANAGER_PARTNER_BALANCE_DATA_LOADING});

    axios
        .get(process.env.REACT_APP_API_URL + '/users/' + partnerId + '/balance', getConfig(getState))
        .then(res =>
            dispatch({
                type: MANAGER_PARTNER_BALANCE_DATA_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};