import React from "react";
import PropTypes from "prop-types";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import PostbackTestForm from "../../../common/projectCreation/PostbackTestForm";

PostbackTestModal.propTypes = {
    isOpened: PropTypes.bool,
    project: PropTypes.object,
    onCloseModal: PropTypes.func,
    onSendPostback: PropTypes.func,
};

export default function PostbackTestModal({offers, isOpened, isSending, onCloseModal, onSendPostback, projects, selectedProject, fieldErrors}) {
    return (
        <Modal size="lg" isOpen={isOpened} toggle={() => onCloseModal()}>
            <ModalHeader style={{float: "left"}} toggle={() => onCloseModal()}>
                Проверка Postback<br/>
            </ModalHeader>
            <ModalBody>
                <PostbackTestForm offers={offers} projects={projects} selectedProject={selectedProject}
                                        isSending={isSending} onSendPostback={onSendPostback} fieldErrors={fieldErrors}/>
            </ModalBody>
        </Modal>
    )
}