import React from "react";
import PropTypes from "prop-types";


SimpleSortableTableFooter.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.any,
        footerStyle: PropTypes.object
    }))
};

export default function SimpleSortableTableFooter({columns = [], data=[]}) {

    return (
        <tfoot className="thead-light statistic_footer">
        <tr>
            {columns.map((column) =>
                <th key={column.name} style={column.footerStyle} className={"statistic_footer right_position"}>{column.footerValue(data)}</th>)}
        </tr>
        </tfoot>
    );
}