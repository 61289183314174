import React from "react";
import {getRusDateHourString} from "../../../../utils/dateUtils";

export default function HeaderNewsItem({newsItem, openNewsItem}) {

    return (
            <div className={"notification-item " + (newsItem.isNew ? "active" : "")} onClick={() => openNewsItem(newsItem)}>
                <div className="notification-item-icon"><i className={"iconNews ti " + newsItem.iconClassName}/></div>
                <div className="notification-item-title">{newsItem.title}</div>
                <div className="notification-item-date">{getRusDateHourString(new Date(newsItem.publicationDate))}</div>
            </div>
    )
}