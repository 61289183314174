import React from "react";

export default function SocialIconItem({href, img}) {

    return (
        <span className="icon-social">
                <a href={href}>
                <img src={img} alt=""/>
                </a>
            </span>
    )

}