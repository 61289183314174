import {
    CLEAR_PARTNER_PAYMENTS_DATA,
    CREATE_PAYMENT,
    MANAGER_PAYMENTS_LOADED,
    MANAGER_PAYMENTS_LOADING,
    PARTNER_PAYMENTS_LOADED,
    PARTNER_PAYMENTS_LOADING,
    PAYMENT_APPROVED,
    PAYMENT_CANCELED,
    SELECTED_PAYMENTS_APPROVED,
    UPDATE_PAYMENT
} from "./actionTypes";
import {getPartnerId, isAdminAsPartner, isPartner} from "../utils/authUtils";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnAllErrorsAsPopup, returnErrors} from "./errorActions";
import {getIsoLocalString} from "../utils/dateUtils";
import {getSuccess} from "./successActions";
import {
    getManagerPaymentStatusData, getPartnerPaymentStatusData, getPaymentDetailsString,
    getPaymentType,
    getPaymentTypeValue,
    getPaymentValue
} from "../utils/paymentUtils";
import {PAID, PENDING} from "../utils/constants";


export const loadPartnerPayments = (from, to) => (dispatch, getState) => {

    dispatch({type: PARTNER_PAYMENTS_LOADING});

    const params = 'partnerId=' + getPartnerId() +
        (from ? ('&from=' + getIsoLocalString(from)) : '') +
        (to ? ('&to=' + getIsoLocalString(to)) : '');

    axios
        .get(process.env.REACT_APP_API_URL + '/payments/partner?' + params, getConfig(getState))
        .then(res =>
            dispatch({
                type: PARTNER_PAYMENTS_LOADED,
                payload: res.data && res.data.map(item => {
                    const orderedPaymentStatusData = getPartnerPaymentStatusData(item);
                    return {
                        ...item,
                        paymentTypeValue: getPaymentTypeValue(item.type),
                        paymentDataValue: (item.paymentData && (getPaymentType(item.paymentData) + " " + getPaymentValue(item.paymentData))),
                        paymentStatus: orderedPaymentStatusData.value,
                        paymentStatusColor: orderedPaymentStatusData.color
                    }
                })
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });

};

export const clearPartnerPaymentsData = () => (dispatch, getState) => {
    dispatch({type: CLEAR_PARTNER_PAYMENTS_DATA});
};

export const createPayment = (body, onSuccessCallback) => (dispatch, state) => {

    axios
        .post(process.env.REACT_APP_API_URL + '/payments/', body, getConfig(state))
        .then(res => {

                dispatch({
                    type: CREATE_PAYMENT,
                });
            }
        )
        .then(() => {
            onSuccessCallback && onSuccessCallback()
        })
        .then(() => {
            dispatch(getSuccess({success: "Выплата создана"}))
        })
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
        });
};

export const loadManagerPartnerPayments = (body) => (dispatch, getState) => {
    dispatch({type: MANAGER_PAYMENTS_LOADING});

    axios
        .post(process.env.REACT_APP_API_URL + '/payments/manager', body, getConfig(getState))
        .then(res =>
            dispatch({
                type: MANAGER_PAYMENTS_LOADED,
                payload: res.data && res.data.map(item => {
                    const orderedPaymentStatusData = getManagerPaymentStatusData(item);
                    return {
                        ...item,
                        paymentDataValue: (item.paymentData && (getPaymentDetailsString(item.paymentData))),
                        paymentStatus: orderedPaymentStatusData,
                        payDate: item.status === PAID ? item.payDate : "-",
                        payDateCellColor: orderedPaymentStatusData.cellColor,
                        payDateTooltip: orderedPaymentStatusData.tooltip,
                        paymentTypeValue: getPaymentTypeValue(item.type, item.isAdvance),
                    }
                })
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });

};


export const cancelPayment = (body, onSuccessCallback) => (dispatch, state) => {
    const url = isPartner() || isAdminAsPartner() ? '/payments/partner/cancel' : '/payments/manager/cancel';
    axios
        .post(process.env.REACT_APP_API_URL + url, body, getConfig(state))
        .then(res => {

                dispatch({
                    type: PAYMENT_CANCELED,
                    payload: res.data
                });
            }
        )
        .then(() => {
            onSuccessCallback && onSuccessCallback()
        })
        .then(() => {
            dispatch(getSuccess({success: "Выплата отменена"}))
        })
        .catch(err => {
            dispatch(returnErrors(err));
        });
};


export const updatePaymentByManager = (body, successMsg, onSuccessCallback) => (dispatch, state) => {
    if (!body) {
        return;
    }

    successMsg = successMsg ? successMsg : "Выплата обновлена";

    axios
        .put(process.env.REACT_APP_API_URL + '/payments/manager/update', body, getConfig(state))
        .then(res => {

                dispatch({
                    type: PAYMENT_APPROVED,
                    payload: res.data
                });
            }
        )
        .then(() => {
            onSuccessCallback && onSuccessCallback()
        })
        .then(() => {
            dispatch(getSuccess({success: successMsg}))
        })
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err, null, 'Проверьте правильность введенных данных'));
        });
};

export const massApprovePayments = (body, onSuccessCallback) => (dispatch, state) => {
    if (!body || body.length === 0) {
        return;
    }
    const successMsg =
        body[0].status === 'PENDING'
            ? 'Выбранные позиции переданы на оплату'
            : 'Оплата выбранных позиций подтверждена';

    axios
        .put(process.env.REACT_APP_API_URL + '/payments/manager/massApprove', body, getConfig(state))
        .then(res => {

                dispatch({
                    type: SELECTED_PAYMENTS_APPROVED,
                    payload: res.data
                });
            }
        )
        .then(() => {
            onSuccessCallback && onSuccessCallback()
        })
        .then(() => {
            dispatch(getSuccess({success: successMsg}))
        })
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err, null, 'Проверьте правильность введенных данных'));
        });
};

export const updatePayment = (body, onSuccessCallback) => (dispatch, state) => {
    axios
        .post(process.env.REACT_APP_API_URL + '/payments/partner/updatePayment', body, getConfig(state))
        .then(res => {

                dispatch({
                    type: UPDATE_PAYMENT,
                    payload: res.data
                });
            }
        )
        .then(() => {
            onSuccessCallback && onSuccessCallback()
        })
        .then(() => {
            dispatch(getSuccess({success: "Выплата обновлена"}))
        })
        .catch(err => {
            dispatch(returnErrors(err));
        });
}