import React from "react";
import PropTypes from "prop-types";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import ProjectCreationForm from "../../../common/projectCreation/ProjectCreationForm";

ProjectModal.propTypes = {
    isOpened: PropTypes.bool,
    project: PropTypes.object,
    onCloseModal: PropTypes.func,
    onSaveProject: PropTypes.func,
};

export default function ProjectModal({isOpened, onCloseModal, project, onSaveProject, fieldErrors}) {
    return (
        <Modal size="lg" isOpen={isOpened} toggle={() => onCloseModal()}>
            <ModalHeader style={{float: "left"}} toggle={() => onCloseModal()}>
                Поток<br/>
            </ModalHeader>
            <ModalBody>
                <ProjectCreationForm project={project} onSave={onSaveProject} fieldErrors={fieldErrors}/>
            </ModalBody>
        </Modal>
    )
}