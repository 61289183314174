import React, {useEffect, useState} from "react";
import DateRangePicker from "../../../../common/pickers/DateRangePicker";
import AdminPartnersFilter from "../../../../common/filters/AdminPartnersFilter";
import CircleButtonsGroup from "../../../../common/buttons/CircleButtonsGroup";
import PeriodButtonsGroupFilter from "../../../../common/buttons/PeriodButtonsGroupFilter";
import {PAYMENT_PERIOD_MONTH, PAYMENT_PERIOD_WEEK} from "../../../../../utils/constants";
import MaterialUISelect from "../../../../common/selects/MaterialUISelect";
import {
    getManagerPaymentStatuses, getOnlyWithPaymentDetailsDefaultValue, getPaymentDetailsTypes,
    getPaymentTypes, onlyWithPaymentDetailsDropdownData
} from "../../../../../utils/paymentUtils";
import {isIntegerString} from "../../../../../utils/validationUtils";
import MaterialUIMultiSelect from "../../../../common/selects/MaterialUIMultiSelect";
import {TextField} from "@material-ui/core";
import {useSelector} from "react-redux";

export default function ManagerPaymentsFiler({
                                                 filters = {period: {from: new Date(), to: new Date()}, partners: []},
                                                 loadTableData, clearAllFilters, selectDate, setFilterData, onTypingMoneyString,
                                                 inputStyle,
                                             }) {

    const [timeoutState, setTimeoutState] = useState({timeout: 300});
    const [periodIncomeApprovedData, setPeriodIncomeApprovedData] = useState({incomeMin: '', incomeMax: ''});


    const isManagerPaymentsLoading = useSelector(state => state.paymentsReducer.managerPaymentsLoading);

    useEffect(() => {
        setPeriodIncomeApprovedData({incomeMin: filters.incomeMin, incomeMax: filters.incomeMax});
    }, []);

    const handleTypingMoneyString = (fieldName, value) => {
        if (timeoutState.timeout) {
            clearTimeout(timeoutState.timeout);
        }
        value = value.replace(/\s/g, "");
        if (!isIntegerString(value)) {
            return;
        }
        setPeriodIncomeApprovedData({...periodIncomeApprovedData, [fieldName]: value === "" ? "" : parseInt(value).toLocaleString('ru-RU')});
        setTimeoutState({
            timeout: setTimeout(() => {
                onTypingMoneyString(fieldName, value);
            }, 300)
        });
    };

    const handleSelectOnlyWithPaymentDetails = (value) => {
        if (!value) {
            setFilterData("onlyWithPaymentDetails", getOnlyWithPaymentDetailsDefaultValue())
        }
        const onlyWithPaymentDetails = onlyWithPaymentDetailsDropdownData.find(item => item === value)?.value;
        setFilterData("onlyWithPaymentDetails", onlyWithPaymentDetails)
    };

    const textFieldStyle = {...inputStyle?.root, minWidth: "210px"};

    return (
        <>
          <DateRangePicker fromDate={filters.period.from}
                                     toDate={filters.period.to}
                                     selectFromDateFunction={(from) => selectDate("from", from)}
                                     selectToDateFunction={(to) => selectDate("to", to)}
                                     label="Дата"
                                     locatedInRightFilterBlock
                                     inputStyle={inputStyle?.root}/>
            <div className="nowrap-custom" id="blockInputPeriod">
                    <PeriodButtonsGroupFilter
                        label="Платёжный период"
                        buttons={[
                            {isSelected: filters.paymentPeriod === PAYMENT_PERIOD_WEEK, title: "Неделя",
                                iconClassName: "date-icon icon-7", onClick: () => setFilterData("paymentPeriod", PAYMENT_PERIOD_WEEK)},
                            {isSelected: filters.paymentPeriod === PAYMENT_PERIOD_MONTH, title: "Месяц",
                                iconClassName: "date-icon icon-m", onClick: () => setFilterData("paymentPeriod", PAYMENT_PERIOD_MONTH)},
                        ]}
                    />
            </div>
            <MaterialUIMultiSelect label="Статусы" placeholder="-"  dropdownData={getManagerPaymentStatuses()} values={filters.statuses}
                                   onSelect={(statuses) => setFilterData("statuses", statuses)} styles={inputStyle}/>
            <MaterialUIMultiSelect label="Типы" placeholder="-"  dropdownData={getPaymentTypes()} values={filters.types} dataField="id"
                                   onSelect={(statuses) => setFilterData("types", statuses)} styles={inputStyle}/>
            <MaterialUISelect label="Только с реквизитами" placeholder="-" dropdownData={onlyWithPaymentDetailsDropdownData}
                              selectedValue={onlyWithPaymentDetailsDropdownData.find(item => item.value === filters.onlyWithPaymentDetails)}
                              onSelectCallbackFunction={handleSelectOnlyWithPaymentDetails} styles={inputStyle}/>
            <TextField label="Сумма к выплате от"
                       className="MuiFormControl-fullWidth"
                           style={textFieldStyle}
                           InputLabelProps={{shrink: true, focused: false, style: {margin: "0px"}}}
                           placeholder="-" onChange={(e) => handleTypingMoneyString("incomeMin", e.target.value)}
                           value={periodIncomeApprovedData.incomeMin}/>
            <TextField label="Сумма к выплате до"
                       className="MuiFormControl-fullWidth"
                           style={textFieldStyle}
                           InputLabelProps={{shrink: true, focused: false, style: {margin: "0px"}}}
                           placeholder="-" onChange={(e) => handleTypingMoneyString("incomeMax", e.target.value)}
                           value={periodIncomeApprovedData.incomeMax}/>
            <AdminPartnersFilter selectedPartners={filters.partners}
                                 handleChangeFilterData={(value) => setFilterData("partners", value)}
                                 inputStyle={inputStyle}/>
            <div className="last-filter">
                <MaterialUIMultiSelect label="Реквизиты" placeholder="-"  dropdownData={getPaymentDetailsTypes()} values={filters.paymentDetailsTypes} dataField="value" displayedField="label"
                                       onSelect={(paymentDetailsTypes) => setFilterData("paymentDetailsTypes", paymentDetailsTypes)} styles={inputStyle}/>
            </div>
            <CircleButtonsGroup buttons={
                [
                    {
                        isSelected: false,
                        title: "Сформировать отчет",
                        iconClassName: "ti ti-check stat-icon",
                        onClick: loadTableData,
                        isLoading: isManagerPaymentsLoading
                    },
                    {
                        isSelected: false,
                        title: "Очистить",
                        iconClassName: "ti ti-close stat-icon",
                        onClick: clearAllFilters
                    },
                ]
            }/>
        </>
    )
}
