import React, {Component} from "react";
import {connect} from "react-redux";
import BasePage from "../BasePage";
import ReferralLinkCard from "./ReferralLinkCard";
import ReferralFiltersCard from "./ReferralFiltersCard";
import PartnerReferralsTable from "./PartnerReferralsTable";
import {loadReferralUrl} from "../../../actions/userAction";
import {loadReferrerStatistic} from "../../../actions/referrerActions";
import {ASC, DESC, LAST_30_DAYS} from "../../../utils/constants";
import {fromDate, toDate} from "../../../utils/dateUtils";
import {getPartnerId} from "../../../utils/authUtils";
import {sort} from "../../../utils/sortUtils";
import PageContent from "../../common/page/PageContent";
class ReferralProgramPage extends Component {


    state = {
        filters: {
            period: LAST_30_DAYS,
            fromDate: fromDate(LAST_30_DAYS),
            toDate: toDate(LAST_30_DAYS),
        },
        sortableColumn: "partnerRegistrationDate",
        sortableDirection: DESC,
    };

    componentDidMount() {
        this.props.loadReferralUrl();
    }

    loadReferrerStatistic = () => {
        this.props.loadReferrerStatistic({...this.state.filters, partnerIds: [getPartnerId()]})
    };

    handleSelectDate = (filterName, value) => {
        const filters = this.state.filters;
        filters[filterName] = value;
        this.setState({filters: filters});
    };

    handleSelectPeriod = (value) => {
        this.setState({filters: {period: value, fromDate: fromDate(value), toDate: toDate(value)}});
    };

    sort = (column, direction) => {
        this.setState({
            ...this.state,
            sortableColumn: column,
            sortableDirection: direction
        });
    };

    getSortedTableData = () => {
        const {referrerStatistic, isLoading} = this.props;
        if (isLoading || !referrerStatistic) {
            return null;
        }
        const {sortableColumn, sortableDirection} = this.state;
        const directionNumb = !sortableDirection
            ? 0
            : sortableDirection === ASC
                ? 1
                : -1;
        return sort(referrerStatistic, sortableColumn, directionNumb);
    };

    renderContentEl = () => {
        return (
            <>
                <ReferralLinkCard referralUrl={this.props.referralUrl}/>

                <PartnerReferralsTable isLoading={this.props.isLoading} tableData={this.getSortedTableData()}
                                       sortableColumn={this.state.sortableColumn} sortableDirection={this.state.sortableDirection}
                                       sort={this.sort}/>
            </>
        )
    };

    renderFiltersEl = () => {
        return <ReferralFiltersCard filterData={this.state.filters} buildTable={this.loadReferrerStatistic}
                                    handleSelectDate={this.handleSelectDate} handleSelectPeriod={this.handleSelectPeriod}/>
    };


    render() {
        return (
            <BasePage pageName="Реферальная программа"
                        content={
                            <PageContent
                                displayContentHeader
                                displayFiltersBtn
                                filtersEl={this.renderFiltersEl()}
                                contentEl={this.renderContentEl()}
                            />

                        }/>
        );
    }

}

function mapStateToProps(state) {
    return {
        referralUrl: state.userReducer.referralUrl,
        referrerStatistic: state.referrerReducer.statistic,
        isLoading: state.referrerReducer.loading,
    }
}

export default connect(mapStateToProps, {
    loadReferralUrl,
    loadReferrerStatistic,
})(ReferralProgramPage);