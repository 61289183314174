import React, {useEffect, useState} from "react";
import BuyoutRulesFilters from "./filters/buyoutRules/BuyoutRulesFilters";
import Spinner from "../../common/Spinner";
import SimpleTable from "../../common/tables/SimpleTable";
import NoContentMessage from "../../common/NoContentMessage";
import {useSelector} from "react-redux";
import {
    ASC,
    DESC,
    LIGHT_THEME,
} from "../../../utils/constants";
import MaterialUIMultiSelect from "../../common/selects/MaterialUIMultiSelect";
import MaterialUISelect from "../../common/selects/MaterialUISelect";
import useThemeChange from "../../../hooks/useThemeChange";
import TableCheckBox from "../../common/checkboxes/TableCheckbox";
import {isIntegerString} from "../../../utils/validationUtils";
import {sort} from "../../../utils/sortUtils";
import IconPopover from "../../common/popovers/IconPopver";
import PageContent from "../../common/page/PageContent";

const helpMsg = 'В таблице все правила сгруппированы по колонкам Партнер, Время выкупа, Повторный выкуп, Активно.\n' +
    'Данные не удаляются.\n' +
    'Для редактирования/добавления нужных офферов достаточно выбрать их в строке редактирования и изменить. \n' +
    'После сохранения данные в таблице перегруппируются. ';

export default function OfferBuyoutRulesContent({tabs, selectedNavTab, selectNavTab, onChangeSearchInput, searchInputAmount, searchInputValue,
                                     beforeSearchInputAmount, filteredBuyoutRules, filters, onSelectFilterValue,
                                     toggleCheckboxFilter, saveOrUpdateBuyoutRule}) {

    const isLoading =  useSelector(state => state.offerBuyoutRuleReducer.loading);
    const {partners, subscriptionServices, buyoutOffers} = useSelector(state => state.filterDataReducer.filterData);

    const [editableBuyoutRule, setEditableBuyoutRule] = useState({isNew: true});
    const [sortingData, setSortingData] = useState({column: 'partnerName', direction: ASC});
    const [sortedBuyoutRules, setSortedBuyoutRules] = useState(null);
    const [offersDropdownData, setOffersDropdownData] = useState(null);
    const [subscriptionServicesDropdownData, setSubscriptionServicesDropdownData] = useState([]);


   useEffect(() => {
       const directionNumb = sortingData.direction === DESC ? -1 : 1;
      const sortedData = sort(filteredBuyoutRules, sortingData.column, directionNumb);
       setSortedBuyoutRules(sortedData);
   }, [sortingData, filteredBuyoutRules]);

    useEffect(() => {
        if (!buyoutOffers || !editableBuyoutRule || !editableBuyoutRule.externalServiceIds || editableBuyoutRule.externalServiceIds.length === 0) {
            setOffersDropdownData(buyoutOffers);
            return;
        }

        const selectedServiceIds = editableBuyoutRule.externalServiceIds;
        const offers = buyoutOffers.filter(offer => selectedServiceIds.includes(offer.externalServiceId));
        setOffersDropdownData(offers);
    }, [buyoutOffers, editableBuyoutRule]);

    useEffect(() => {
        if (buyoutOffers?.length > 0 && subscriptionServices?.length > 0) {
            const buyoutExternalServiceIds = new Set(buyoutOffers.map(offer => offer.externalServiceId));
            const filteredSubscriptionServices = subscriptionServices.filter(service => buyoutExternalServiceIds.has(service.externalId));
            setSubscriptionServicesDropdownData(filteredSubscriptionServices);
        }
    }, [buyoutOffers, subscriptionServices]);

    const setSorting = (column, direction) => {
        setSortingData({column: column, direction: direction})
    };

    const selectPartner = (partner) => {
        setEditableBuyoutRule({...editableBuyoutRule, partnerId: partner?.id});
    };

    const selectServices = (services) => {
        const prevExternalServiceIds = editableBuyoutRule.externalServiceIds;
        const newExternalServiceIds = services ? services.map(service => service.externalId) : [];
        const addedExternalServiceId = newExternalServiceIds.find(id => !prevExternalServiceIds?.includes(id));
        const deletedExternalServiceId = prevExternalServiceIds?.find(id => !newExternalServiceIds.includes(id));
        let offerIds = editableBuyoutRule.offerIds ? editableBuyoutRule.offerIds : [];
        if (addedExternalServiceId) {
            const addedOfferIds = buyoutOffers.filter(offer => addedExternalServiceId === offer.externalServiceId).map(offer => offer.id);
            offerIds.push(...addedOfferIds);
        } else if (deletedExternalServiceId) {
            const deletedOfferIds = buyoutOffers.filter(offer => deletedExternalServiceId === offer.externalServiceId).map(offer => offer.id);
            if (deletedOfferIds) {
                offerIds = offerIds.filter(offerId => !deletedOfferIds.includes(offerId));
            }
        }
        setEditableBuyoutRule({...editableBuyoutRule, externalServiceIds: newExternalServiceIds, offerIds: offerIds});
    };

    const selectOffers = (offers) => {
        const offerIds = offers ? offers.map(offer => offer.id) : [];
        setEditableBuyoutRule({...editableBuyoutRule, offerIds: offerIds});
    };

    const handleChangeBuyoutSeconds = (e) => {
        if (e.target.value && !isIntegerString(e.target.value)) {
            return;
        }
        setEditableBuyoutRule({...editableBuyoutRule, buyoutSeconds: e.target.value});
    };

    const toggleSwitch = (e) => {
        setEditableBuyoutRule({...editableBuyoutRule, [e.target.name]: !editableBuyoutRule[e.target.name]});
    };

    const clearEditableBuyoutRule = () => {
        setEditableBuyoutRule({isNew: true});
    };

    const saveOrUpdate = () => {
        saveOrUpdateBuyoutRule(editableBuyoutRule);
        clearEditableBuyoutRule();
    };

    const tableColumns = [
            {
                name: "Партнёр",
                key: "partnerName",
                getBodyCellValue: buyoutRule => buyoutRule.partnerName,
                headerCellStyle: {minWidth: "200px"},
            },
            {
                name: "Сервисы",
                key: "externalServiceIds",
                getBodyCellValue: buyoutRule => buyoutRule.externalServiceIds?.join(", "),
                headerCellStyle: {minWidth: "250px", maxWidth: "250px"},
                bodyCellStyle: {maxWidth: "250px", whiteSpace: 'wrap'},
            },
            {
                name: "Офферы",
                key: "offerIds",
                getBodyCellValue: buyoutRule => buyoutRule.offerIds?.join(", "),
                bodyCellClassName: "td-operator inline",
                headerCellStyle: {minWidth: "250px", maxWidth: "350px"},
                bodyCellStyle: {maxWidth: "350px", whiteSpace: 'wrap'},
            },
            {
                name: "Время выкупа, сек",
                key: "buyoutSeconds",
                getBodyCellValue: buyoutRule => buyoutRule.buyoutSeconds,
                headerCellStyle: {minWidth: "140px", width: "140px"},
            },
            {
                name: "Повторный выкуп",
                key: "reBuyoutEnabled",
                getBodyCellValue: buyoutRule => buyoutRule.reBuyoutEnabled ? 'Да' : 'Нет',
                headerCellStyle: {minWidth: "130px", width: "130px"},
            },
            {
                name: "Активно",
                key: "active",
                getBodyCellValue: buyoutRule => buyoutRule.active ? 'Да' : 'Нет',
                headerCellStyle: {minWidth: "80px", width: "80px"},
            },
            {
                name: "",
                key: "buttons",
                getBodyCellValue: buyoutRule =>
                    <>
                        <i className="ti ti-pencil" style={{"color": "#414455", cursor: "pointer"}} title="Редактировать"
                           onClick={() => setEditableBuyoutRule(buyoutRule)}/>
                    </>,
                headerCellStyle: {minWidth: "110px", width: "110px"}
            },
        ];

    const themeName = useThemeChange();
    const inputStyles = {
        border: themeName === LIGHT_THEME ? "1px solid #ced4da" : "1px solid #000",
        borderRadius: ".25rem",
        fontSize: '12px !important',
        padding: "0 0 0 5px",
    };

    const actionInputStyles = {
        root: {
            padding: "0",
            minWidth: "350px",
            width: "max-content",
            maxWidth:"800px",
            underlineBorderBottom: '0px solid rgba(0,0,0,0) !important',
        },
        input: {
            ...inputStyles,
            backgroundColor: themeName === LIGHT_THEME ? "white" : "#1c1c1e",
            minHeight: "30px",

        },
        placeholder: {
            fontSize: "12px !important",
            padding: "0 0 3px 5px",
        }
    };

    const tableActionRow =  [
            {
                key: "partnerName",
                getActionCellValue: () =>
                    <MaterialUISelect styles={actionInputStyles}
                                      dropdownData={partners}
                                      selectedValue={editableBuyoutRule && partners?.find(value => value.id === editableBuyoutRule.partnerId)}
                                      onSelectCallbackFunction={selectPartner}
                                      isClearable={false}
                                      dataField="id" displayedField="name"
                                      // isDisabled={!editableBuyoutRule?.isNew}
                                      placeholder="ОБЩЕЕ ПРАВИЛО"
                                      isSearchable

                    />
            },
            {
                key: "externalServiceIds",
                getActionCellValue: () =>
                    <MaterialUIMultiSelect styles={actionInputStyles}
                                           dropdownData={subscriptionServicesDropdownData}
                                           values={editableBuyoutRule?.externalServiceIds
                                           && subscriptionServices?.filter(service => editableBuyoutRule?.externalServiceIds.includes(service.externalId))}
                                           onSelect={selectServices}
                                           dataField='id'
                                           displayedFields={['externalId', 'name']}
                                           isSearchable
                                           isClearable={true}
                                           maxChipTxtWidth={100}
                                           placeholder="-"
                    />
            },
            {
                key: "offerIds",
                getActionCellValue: () =>
                    <MaterialUIMultiSelect styles={actionInputStyles}
                                           dropdownData={offersDropdownData}
                                           values={editableBuyoutRule?.offerIds
                                           && buyoutOffers?.filter(offer => editableBuyoutRule?.offerIds.includes(offer.id))}
                                           onSelect={selectOffers}
                                           dataField='id'
                                           displayedFields={['id', 'name']}
                                           isSearchable
                                           isClearable={true}
                                           maxChipTxtWidth={55}
                                           placeholder="-"
                    />
            },
            {
                key: "buyoutSeconds",
                getActionCellValue: () => <input
                    className="form-control "
                    value={editableBuyoutRule?.buyoutSeconds ? editableBuyoutRule.buyoutSeconds : ""}
                    onChange={handleChangeBuyoutSeconds}/>
            },
            {
                key: "reBuyoutEnabled",
                getActionCellValue: () => <TableCheckBox name={"reBuyoutEnabled"} checked={editableBuyoutRule?.reBuyoutEnabled}
                                                         id="chbx1" onChange={toggleSwitch}/>
            },
            {
                key: "active",
                getActionCellValue: () => <TableCheckBox name={"active"} checked={editableBuyoutRule?.active}
                                                         id="chbx2" onChange={toggleSwitch}/>
            },
            {
                key: "action",
                getActionCellValue: () => <>
                    <i className="ti ti-close td-save" onClick={clearEditableBuyoutRule} title='Очистить'/>
                    {editableBuyoutRule &&
                    <i className="ti ti-save td-save" onClick={saveOrUpdate} title='Сохранить правило'/>}
                    <IconPopover
                        displayedContent={helpMsg}
                        placement="top" iconClassName="ti ti-help-alt" target={"PopoverHelpIcon2"}/>
                </>
            },
        ];

    const renderFiltersEl = () => {
        return <BuyoutRulesFilters
            filters={filters}
            onSelectFilterValue={onSelectFilterValue}
            toggleCheckboxFilter={toggleCheckboxFilter}
            subscriptionServicesDropdownData={subscriptionServicesDropdownData}
        />;
    };


        return (
            <PageContent
                tabs = {tabs}
                selectedTabName = {selectedNavTab}
                onSelectTab = {selectNavTab}
                displaySearchInput
                onChangeSearchInput = {onChangeSearchInput}
                searchInputAmount = {searchInputAmount}
                beforeSearchInputAmount= {beforeSearchInputAmount}
                searchInputLabel = {"Правил:"}
                displayContentHeader
                filtersEl = {renderFiltersEl()}
                displayFiltersBtn
                contentEl = {
                    <div className="custom-tab">
                        <div className="table-responsive" id="hiden-offer-table">
                            {
                                isLoading ? <Spinner/>
                                    :
                                    <div className="responsive-block statistic_table_wrapper" id="fullWidth">
                                        {sortedBuyoutRules
                                            ? <SimpleTable tableClassName="table simple-table align-left table-sm userselecttext statistic_table sticky-header"
                                                           actionRowClassName="grayTR sticky-action-row"
                                                           columns={tableColumns} data={sortedBuyoutRules} bodyRowKey="tableKey" actionRow={tableActionRow} isSortable
                                                           sortableColumn={sortingData.column} sortableDirection={sortingData.direction} onSort={setSorting}/>
                                            : <NoContentMessage/>}
                                    </div>
                            }
                        </div>
                    </div>
                }
            >
            </PageContent>
        )
}