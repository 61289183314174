import React, {useState, useImperativeHandle, forwardRef} from "react";
import {copyToClipboard} from "../../../utils/copyToClipboardUtils";

export const LinkCopyPopup = forwardRef((props, ref) => {

    const [showAlertCopy, setShowAlertCopy] = useState(false);
    const {message} = props;
    useImperativeHandle(ref, () => ({

        handleClickCopy(link) {
            if (link && link !== '') {
                setShowAlertCopy(true);
                copyToClipboard(link);
                setTimeout(() => {
                    setShowAlertCopy(false);
                }, 1000)
            }
        }


    }));

    return (
        <div className="alert-copy" style={{display: showAlertCopy ? "flex" : "none", zIndex: showAlertCopy && 1000}}>
            <i className="fa fa-code"/><span>{message ? message : "Ссылка скопирована"}</span>
        </div>
    )
});