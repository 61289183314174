import React from "react";
import {ASC, DESC} from "../../../../utils/constants";

export default function StatisticTableHeaderRow(props) {
    const {columns, groupName, currentSorting, onSort} = props;
    const currentSortingColumn = currentSorting ? currentSorting.column : null;
    const currentDirection = currentSortingColumn && currentSorting.direction === DESC ? DESC : ASC;

    function sort(columnName) {
        let direction = DESC;
        let column = columnName;
        if (currentSortingColumn === columnName) {
            if (currentDirection === DESC) {
                direction = ASC;
            } else if (currentDirection === ASC) {
                direction = null;
                column = null;
            } else {
                direction = ASC;
            }
        }
        onSort(column, direction);
    }

    return (
        <thead className="thead-light">
        <tr>
            <th key={"header" + groupName} className={"center_position "
            + getSorting("groupName", currentSortingColumn, currentDirection)} onClick={() => sort("groupName")}>{groupName}</th>
            {columns.map((column) => <th key={column.name} className={"center_position "
            + getSorting(column.name, currentSortingColumn, currentDirection)} onClick={() => sort(column.name)}>{column.label}</th>)}
        </tr>
        </thead>
    );
}

function getSorting(column, sortingColumn, direction) {
    if (sortingColumn && sortingColumn === column) {
        return direction === ASC ? "sorting_asc" : "sorting_desc"
    }
    return "sorting";
}
