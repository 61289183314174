import {
    OFFER_INCOMES_LOADED,
    OFFER_INCOMES_LOADING,
    OFFER_WITH_CHILDREN_INCOMES_LOADED,
    OFFER_WITH_CHILDREN_INCOMES_LOADING,
} from "../../actions/actionTypes";

const initialState = {
    incomesWithChildren: [],
    incomes: [],
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case OFFER_WITH_CHILDREN_INCOMES_LOADING:
            return {
                loading: true
            };
        case OFFER_WITH_CHILDREN_INCOMES_LOADED:
            return {
                incomesWithChildren: action.payload,
                loading: false
            };
        case OFFER_INCOMES_LOADING:
            return {
                loading: true
            };
        case OFFER_INCOMES_LOADED:
            return {
                incomes: action.payload,
                loading: false
            };
        default: return state;
    }

}