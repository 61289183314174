import React from 'react';
import IosCheckbox from "../../../../common/checkboxes/IosCheckbox";


export default function OffersIsRecommendedFilter({toggleOnlyRecommended, isOnlyRecommendedOffers, minWidth}) {
    return (
        <div className="custom-mselect" style={{minWidth: minWidth && minWidth + "px"}}>
            <IosCheckbox label={'HOT!'} isChecked={isOnlyRecommendedOffers}
                         onClickFunction={toggleOnlyRecommended}/>
        </div>
    )
}


