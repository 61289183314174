import {
    IP_RANGES_LOADED,
    IP_RANGES_LOADING, IP_RANGES_TEMPLATE_DADA_LOADED, IP_RANGES_TEMPLATE_DADA_LOADING, IP_RANGES_UPLOADED,
} from "../../actions/actionTypes";

const initialState = {
    ranges: [],
    templateData: [],
    templateDadaLoading:false,
    loading: false,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case IP_RANGES_LOADING:
            return {
                ...state,
                loading: true
            };
        case IP_RANGES_LOADED:
            return {
                ...state,
                ranges: action.payload,
                loading: false
            };
        case IP_RANGES_TEMPLATE_DADA_LOADING:
            return {
                ...state,
                templateDadaLoading: true
            };
        case IP_RANGES_TEMPLATE_DADA_LOADED:
            return {
                ...state,
                templateData: action.payload,
                templateDadaLoading: false
            };
        case IP_RANGES_UPLOADED:
            return {
                ...state,
                ranges: action.payload,
            };
        default:
            return state;

    }
}