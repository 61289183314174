import React, {Component} from "react";
import BasePage from "../BasePage";
import {connect} from "react-redux";
import {loadManagerOffers} from "../../../actions/managerOfferActions";
import {addOrRemoveAndReturnNewArray} from "../../../utils/arrayUtils";
import {
    filterByCategories,
    filterByCountries,
    filterByOnlyNew,
    filterByOnlyRecommended, filterByOperators,
    filterBySearchInput
} from "../../../actions/partnerOfferActions";
import OfferMassEditingModal from "./modal/OfferMassEditingModal";
import {URL_OFFER_EDIT} from "../../../properties";
import {getFromLocalStorage, setToLocalStorage} from "../../../utils/localStorageUtils";
import {
    ACTIVE_OFFERS, BUYOUT_OFFERS,
    COUNTRIES,
    DEPRECATED_OFFERS,
    INACTIVE_OFFERS,
    LOCAL_STORAGE_OFFERS_FILTERS,
    OFFER_BUYOUT_RULES,
    OFFER_CATEGORIES,
    OPERATORS,
    PARTNER_GROUPS,
    PARTNERS,
    SUBSCRIPTION_SERVICES,
} from "../../../utils/constants";
import {loadActualCurrencyRates} from "../../../actions/currencyRateActions";
import {managerOfferTabs} from "../../../utils/pageTabsUtils";
import OfferBuyoutRulesContent from "./OfferBuyoutRulesContent";
import {searchByString} from "../../../utils/searchUtils";
import {filterByArrayOfObjects} from "../../../utils/filterUtils";
import {loadAllOfferBuyoutRules, saveOrUpdateBuyoutRules} from "../../../actions/offerBuyoutRuleActions";
import OffersCardsGridContent from "./OffersCardsGridContent";
import editOfferImg from "../../../resources/images/edit.png";
import {loadFiltersData} from "../../../actions/filterDataActions";


class ManagerOffersPage extends Component {

    state = {
        selectedNavTab: ACTIVE_OFFERS,
        selectedOffers: [],
        selectedOfferIds: [],
        searchInputValue: "",
        beforeSearchInputAmount: 0,
        needToFilter: true,
        filteredOffers: [],
        filteredBuyoutRules: [],
        filters: {
            isOnlyNewOffers: false,
            isOnlyRecommendedOffers: false,
            isOnlySelectedOffers: false,
            selectedOperators: [],
            selectedCountries: [],
            selectedCategories: [],
            selectedPartners: [],
            selectedSubscriptionServices: [],
            selectedOffers: [],
            isOnlyNonDefaultRules: true,
        },
        needToClearSearchInput: false,
        isModalOpened: false,
        modalDataClearTrigger: 0,
        modalDataClearSelectedPartnersCounter: 0,
        needRedirectToEditOfferPage: false,
    };

    componentDidMount() {
        this.props.loadManagerOffers(this.state.selectedNavTab, () => this.setState({...this.state, needToFilter: true,}));
        this.props.loadFiltersData([COUNTRIES, OFFER_CATEGORIES, OPERATORS, PARTNER_GROUPS]);
        const localStorageFilters = getFromLocalStorage(LOCAL_STORAGE_OFFERS_FILTERS);
        this.setState({
            filters: localStorageFilters ? localStorageFilters : this.state.filters
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.selectedNavTab !== OFFER_BUYOUT_RULES && !this.props.isLoading && this.state.needToFilter) {
            this.filterOffers();
        }
        if (this.state.selectedNavTab === OFFER_BUYOUT_RULES && !this.props.isBuyoutRulesLoading && this.state.needToFilter) {
            this.filterBuyoutRules();
        }
        if (this.state.needRedirectToEditOfferPage) {
            this.props.history.push(URL_OFFER_EDIT + "/" + this.state.selectedOfferIds[0]);
        }
    }


    selectNavTab = (tabName) => {
        this.setState({
            selectedNavTab: tabName,
            selectedOffers: [],
            selectedOfferIds: [],
            searchInputValue: "",
            needToFilter: true,
            needToClearSearchInput: true
        });
        switch (tabName) {
            case ACTIVE_OFFERS:
            case INACTIVE_OFFERS:
            case DEPRECATED_OFFERS:
                this.props.loadManagerOffers(tabName);
                this.props.loadFiltersData([COUNTRIES, OFFER_CATEGORIES, OPERATORS, PARTNER_GROUPS]);
                break;
            case OFFER_BUYOUT_RULES:
                this.props.loadAllOfferBuyoutRules();
                this.props.loadFiltersData([PARTNERS, SUBSCRIPTION_SERVICES, BUYOUT_OFFERS]);

        }
    };

    toggleOffer = (offer) => {
        this.setState({
            selectedOfferIds: addOrRemoveAndReturnNewArray(this.state.selectedOfferIds, offer.offerId),
            selectedOffers: addOrRemoveAndReturnNewArray(this.state.selectedOffers, offer)
        });
    };

    onFooterTextTyping = (searchInputValue) => {
        this.setState({searchInputValue: searchInputValue, needToFilter: true, needToClearSearchInput: false})
    };

    toggleCheckboxFilter = (fieldName) => {
        const filters = {...this.state.filters, [fieldName]: !this.state.filters[fieldName]};
        setToLocalStorage(LOCAL_STORAGE_OFFERS_FILTERS, filters, 24);
        this.setState({filters: filters, needToFilter: true});
    };

    onSelectFilterValue = (value, fieldName) => {
        let filters = this.state.filters;
        if (fieldName === "selectedCountries") {
            const availableOperatorIds = value && value.flatMap(country => country.operatorIds);
            const selectedOperators = availableOperatorIds && availableOperatorIds.length > 0
                ? this.state.filters.selectedOperators.filter(operator => availableOperatorIds.includes(operator.operatorId))
                : this.state.filters.selectedOperators;
            filters = {...this.state.filters, selectedOperators: selectedOperators, selectedCountries: value};
        } else {
            filters = {...this.state.filters, [fieldName]: value};
        }
        setToLocalStorage(LOCAL_STORAGE_OFFERS_FILTERS, filters, 24);
        this.setState({filters: filters, needToFilter: true});
    };

    editOffers = () => {
        if (!this.state.selectedOffers || this.state.selectedOffers.length === 0) {
            return;
        }
        if (this.state.selectedOffers.length === 1) {
            this.setState({needRedirectToEditOfferPage: true});
        } else {
            this.setState({isModalOpened: !this.state.isModalOpened});
        }
    };

    closeModal = () => {
        this.setState({
            isModalOpened: !this.state.isModalOpened,
            modalDataClearSelectedPartnersCounter: this.state.modalDataClearSelectedPartnersCounter + 1
        });
    };


    onSelectOffers = (offers) => {
        this.setState({
            selectedOfferIds: offers.map(offer => offer.offerId),
            selectedOffers: offers.slice(0)
        })
    };

    onSaveCallback = (closeAfterSave) => {
        this.props.loadManagerOffers(this.state.selectedNavTab, () => this.setSelectedOffers(closeAfterSave));
        this.setState({
            filteredOffers: [],
            needToClearSearchInput: true,
        });
    };

    onSaveBuyoutRule = (rule) => {
        this.props.saveOrUpdateBuyoutRules(rule, () => this.onSaveRuleCallback());
    };

    onSaveRuleCallback = () => {
        this.props.loadAllOfferBuyoutRules();
        this.setState({
            needToFilter: true,
            needToClearSearchInput: true
        });
    };

    setSelectedOffers = (closeAfterSave) => {
        if (closeAfterSave) {
            this.setState({selectedOffers: [], selectedOfferIds: [], isOnlySelectedOffers: false, needToFilter: true});
            this.closeModal();
        } else {
            const selectedOffers = this.getOffers().filter(offer => this.state.selectedOfferIds.includes(offer.offerId));
            this.setState({selectedOffers: selectedOffers, needToFilter: true});
        }
    };

    resetAllFilters = () => {
        const filters  = {
            isOnlyNewOffers: false,
            isOnlyRecommendedOffers: false,
            isOnlySelectedOffers: false,
            selectedOperators: [],
            selectedCountries: [],
            selectedCategories: [],
            selectedStatuses: [],
        };
        setToLocalStorage(LOCAL_STORAGE_OFFERS_FILTERS, filters, 24);
        this.setState({
            filters: filters,
            needToFilter: true
        })
    };

    filterOffers = () => {
        const {searchInputValue, selectedOffers} = this.state;
        const { isOnlyNewOffers, isOnlyRecommendedOffers, isOnlySelectedOffers, selectedOperators = [], selectedCountries = [],
             selectedCategories = [] } = this.state.filters;
        let filteredOffers = isOnlySelectedOffers ? selectedOffers.slice(0) : this.getOffers().slice(0);
        filteredOffers = filterByOnlyNew(filteredOffers, isOnlyNewOffers);
        filteredOffers = filterByOnlyRecommended(filteredOffers, isOnlyRecommendedOffers);
        filteredOffers = filterByOperators(filteredOffers, selectedOperators);
        filteredOffers = filterByCountries(filteredOffers, selectedCountries);
        filteredOffers = filterByCategories(filteredOffers, selectedCategories);
        const beforeSearchInputAmount = filteredOffers.length;
        filteredOffers = filterBySearchInput(filteredOffers, searchInputValue);
        this.setState({needToFilter: false, filteredOffers: filteredOffers, beforeSearchInputAmount: beforeSearchInputAmount});
        return filteredOffers;
    };

    filterBuyoutRules = () => {
        if (!this.state.needToFilter) {
            return;
        }
        const {searchInputValue} = this.state;
        const {selectedPartners, selectedSubscriptionServices, selectedOffers, isOnlyNonDefaultRules} = this.state.filters;

        let filteredBuyoutRules = this.props.buyoutRules;
        filteredBuyoutRules = filterByArrayOfObjects(filteredBuyoutRules, "partnerName", selectedPartners, "name");
        filteredBuyoutRules = filterByArrayOfObjects(filteredBuyoutRules, "externalServiceIds", selectedSubscriptionServices, "externalId");
        filteredBuyoutRules = filterByArrayOfObjects(filteredBuyoutRules, "offerIds", selectedOffers, "id");
        if (isOnlyNonDefaultRules) {
            filteredBuyoutRules = filteredBuyoutRules.filter(rule => !rule.isDefault);
        }
        const beforeSearchInputAmount = filteredBuyoutRules.length;
        filteredBuyoutRules = searchByString(filteredBuyoutRules, ["partnerName", "externalServiceIds", "offerIds"], searchInputValue);
        this.setState({needToFilter: false, filteredBuyoutRules: filteredBuyoutRules, beforeSearchInputAmount: beforeSearchInputAmount});
    };

    getOffers = () => {
        switch (this.state.selectedNavTab) {
            case INACTIVE_OFFERS:
                return this.props.inactiveOffers;
            case DEPRECATED_OFFERS:
                return this.props.deprecatedOffers;
            default:
                return this.props.activeOffers;
        }
    };

    renderTabContent = () => {
        const {isLoading} = this.props;
        const {
            selectedNavTab, selectedOfferIds, selectedOffers, filteredOffers, filters, needToClearSearchInput,
            isModalOpened, modalDataClearTrigger, modalDataClearSelectedPartnersCounter, needToFilter, searchInputValue, beforeSearchInputAmount
        } = this.state;
        switch (this.state.selectedNavTab) {
            case ACTIVE_OFFERS:
            case INACTIVE_OFFERS:
            case DEPRECATED_OFFERS:
                const offers = this.getOffers();
                return <>
                    <OffersCardsGridContent
                        tabs={managerOfferTabs}
                        selectNavTab={this.selectNavTab}
                        selectedNavTab={selectedNavTab}
                        selectedOfferIds={selectedOfferIds}
                        selectedOffers={selectedOffers}
                        onSelectOfferCard={this.toggleOffer}
                        searchInputValue={searchInputValue}
                        beforeSearchInputAmount={beforeSearchInputAmount}
                        filters={filters}
                        resetAllFilters={this.resetAllFilters}
                        toggleCheckboxFilter={this.toggleCheckboxFilter}
                        onSelectFilterValue={this.onSelectFilterValue}
                        isLoading={isLoading}
                        allOffers={offers}
                        filteredOffers={filteredOffers}
                        filterOffers={this.filterOffers}
                        needToFilter={needToFilter}
                        onChangeSearchInput={this.onFooterTextTyping}
                        searchInputAmount={filteredOffers ? filteredOffers.length : 0}
                        isGrayCards={selectedNavTab === INACTIVE_OFFERS || selectedNavTab === DEPRECATED_OFFERS}
                        onClickAdditionalEl={this.editOffers}
                        popupImg={editOfferImg}
                    />
                    {isModalOpened && <OfferMassEditingModal isOpened={isModalOpened} toggle={this.closeModal} offers={offers}
                                           selectedOffers={selectedOffers} onSelectOffer={this.onSelectOffers}
                                           onSave={this.onSave} selectedTab={selectedNavTab}
                                           modalDataClearTrigger={modalDataClearTrigger}
                                           modalDataClearSelectedPartnersCounter={modalDataClearSelectedPartnersCounter}
                                                             onSaveCallback={this.onSaveCallback}
                    /> }

                </>;
            case OFFER_BUYOUT_RULES:
                return <OfferBuyoutRulesContent
                    tabs={managerOfferTabs}
                    selectNavTab={this.selectNavTab}
                    selectedNavTab={selectedNavTab}
                    searchInputValue={searchInputValue}
                    onChangeSearchInput={this.onFooterTextTyping}
                    searchInputAmount={this.state.filteredBuyoutRules?.length}
                    beforeSearchInputAmount={beforeSearchInputAmount}
                    filteredBuyoutRules={this.state.filteredBuyoutRules}
                    filters={this.state.filters}
                    onSelectFilterValue={this.onSelectFilterValue}
                    toggleCheckboxFilter={this.toggleCheckboxFilter}
                    saveOrUpdateBuyoutRule={this.onSaveBuyoutRule}/>;
            default:
                return null;
        }
    };

    render = () => {
        return <BasePage pageName="Офферы" content={this.renderTabContent()}/>
    };
}

const mapStateToProps = (state) => {
    return {
        activeOffers: state.managerOffersReducer.activeOffers,
        inactiveOffers: state.managerOffersReducer.inactiveOffers,
        deprecatedOffers: state.managerOffersReducer.deprecatedOffers,
        isLoading: state.managerOffersReducer.loading,
        partners: state.managerPartnersReducer.partnersFilterData,
        subscriptionServices: state.subscriptionServiceReducer.subscriptionServices,
        buyoutRules: state.offerBuyoutRuleReducer.offerBuyoutRules,
        isBuyoutRulesLoading: state.offerBuyoutRuleReducer.loading,
    }
};

export default connect(mapStateToProps, {
    loadManagerOffers,
    loadActualCurrencyRates,
    loadAllOfferBuyoutRules,
    saveOrUpdateBuyoutRules,
    loadFiltersData,
})(ManagerOffersPage);
