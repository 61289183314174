import {
    OFFER_BUYOUT_RULES_LOADED,
    OFFER_BUYOUT_RULES_LOADING, OFFER_BUYOUT_RULES_SAVED
} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";

export const loadAllOfferBuyoutRules = () => (dispatch, getState) => {
    dispatch({type: OFFER_BUYOUT_RULES_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/offerBuyout', getConfig(getState))
        .then(res =>
            dispatch({
                type: OFFER_BUYOUT_RULES_LOADED,
                payload: prepare(res.data)
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const saveOrUpdateBuyoutRules = (rule, callbackFunction) => (dispatch, getState) => {
    axios
        .put(process.env.REACT_APP_API_URL + '/offerBuyout', rule, getConfig(getState))
        .then(res =>
            dispatch({
                type: OFFER_BUYOUT_RULES_SAVED,
                payload: prepare(res.data)
            })
        )
        .then(() => callbackFunction && callbackFunction())
        .then(() => {
            dispatch(getSuccess({success: "Правило сохранено"}))
        })
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

const prepare = (buyouts) => {
    if (!buyouts) {
        return buyouts;
    }
    buyouts.forEach(buyout => {
        buyout.partnerName = buyout.partnerName ? buyout.partnerName : "ОБЩЕЕ ПРАВИЛО";
        buyout.tableKey = buyout.partnerName + "_" + buyout.buyoutSeconds + "_" + buyout.active + "_" + buyout.reBuyoutEnabled;
        buyout.offerIds.sort((a, b) => a - b);
       buyout.externalServiceIds.sort((a, b) => a - b);
    });
    return buyouts;
};