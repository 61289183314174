import React from "react";
import logo from "../../../resources/images/new-logo.svg";


export default function ChatHeader({name, id, closeChart}) {
    return (
        <div className="contact bar">
            <i className="ti ti-close collap" onClick={closeChart}/>
            <div className="pic"><img src={logo} alt="" className="icon-logo start"/></div>
            <div className="name">
                {name}
            </div>
            <div className="seen">
                {id ? "ID " + id : ""}
            </div>
        </div>
    )
}