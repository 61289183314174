import React from "react";
import {returnErrors} from './errorActions';
import axios from 'axios';
import {isAuthenticated} from "../utils/authUtils"

import {
    AUTHENTICATION_ERROR,
    CHECK_RESTORE_PASSWORD_CODE,
    CHECKING_AUTHENTICATION,
    CONFIRMATION_EMAIL_RESENT,
    LOGIN_AS_PARTNER,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT_FROM_PARTNER,
    LOGOUT_SUCCESS,
    PASSWORD_CHANGED,
    REGISTER_CONFIRMED,
    REGISTER_SUCCESS,
    USER_AUTHENTICATED
} from './actionTypes';
import {pathParameter} from "../utils/urlUtils";
import {getSuccess} from "./successActions";

// Check token & load user
export const loadUser = () => (dispatch, getState) => {
    // User loading
    dispatch({ type: CHECKING_AUTHENTICATION });


    if (!isAuthenticated()) {
        dispatch({
            type: AUTHENTICATION_ERROR
        });
    } else {
        dispatch({
            type: USER_AUTHENTICATED,
        });
    }
};

// Login User
export const login = (user, reCaptchaToken, callbackFunction) => (dispatch, getState) => {
    const config = getConfig(getState);
    config.headers.recaptcha = reCaptchaToken;
    axios
        .post(process.env.REACT_APP_API_URL + '/auth/login', user, config)
        .then(res => {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data
                })
            }
        )
        .then(() => callbackFunction && callbackFunction())
        .catch(err => {
            dispatch(returnErrors(err, LOGIN_FAIL));
        });
};

// Logout User
export const logout = () => (dispatch, getState) =>{
    if (!isAuthenticated()) {
        return;
    }
    const isAdminAsPartner = getState().auth.isAdminAsPartner;
    if (isAdminAsPartner) {
        dispatch({
            type: LOGOUT_FROM_PARTNER,
        });
    } else {
        localStorage.removeItem('user');
        axios
            .get(process.env.REACT_APP_API_URL + '/auth/logout', getConfig(getState))
            .then(res =>
                dispatch({
                    type: LOGOUT_SUCCESS,
                    payload: res.data
                })
            )
            .catch(err => {
                dispatch(returnErrors(err));
            });
    }

};

export const register = (user, onSuccessFunction) => (dispatch, getState) => {

    if (!user) {
        return;
    }
    const referrerId = pathParameter("referrerId");
    if (referrerId && !Number.isNaN(referrerId)) {
        user.referrerId = referrerId;
    }
    user.ref = localStorage.getItem("ref");
    const success = {success: <>Регистрация успешно завершена. <br/>На ваш Email отправлено письмо для подтверждения регистрации</>};
    axios
        .post(process.env.REACT_APP_API_URL + '/auth/register', user, getConfig(getState))
        .then(res =>
            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data
            })
        )
        .then(() => {onSuccessFunction()})
        .then(() => {dispatch(getSuccess(success))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const confirmRegistration = (registrationCode) => (dispatch, getState) => {
    if (!registrationCode) {
        return;
    }
    const success =  {success: <><b>Ваш E-mail успешно подтверждён!</b><br/> Аккаунт отправлен на модерацию. <br/> Пожалуйста, дождитесь ответа менеджера.</>};
    axios
        .get(process.env.REACT_APP_API_URL + '/auth/confirmRegistration?code=' + registrationCode, getConfig(getState))
        .then(res =>
            dispatch({
                type: REGISTER_CONFIRMED,
                payload: res.data
            })
        )
        .then(() => {dispatch(getSuccess(success))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const sendRestorePasswordLink = (email, onSuccessFunction) => (dispatch, getState) => {
    if (!email) {
        return;
    }
    const success = {success: "На ваш Email отправлено письмо с ссылкой для восстановления пароля"};
    axios
        .post(process.env.REACT_APP_API_URL + '/auth/restorePassword?email=' + email, null, getConfig(getState))
        .then(res =>
            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data
            })
        )
        .then(() => {onSuccessFunction()})
        .then(() => {dispatch(getSuccess(success))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const checkRestorePasswordCode = (restorePasswordCode) => (dispatch, getState) => {
    axios
        .post(process.env.REACT_APP_API_URL + '/auth/isRestorePasswordCodeValid', {restorePasswordCode: restorePasswordCode})
        .then(res =>
            dispatch({
                type: CHECK_RESTORE_PASSWORD_CODE,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const changePassword = (body) => (dispatch, getState) => {
    axios
        .put(process.env.REACT_APP_API_URL + '/auth/changePassword', body, getConfig(getState))
        .then(res =>
            dispatch({
                type: PASSWORD_CHANGED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loginAsPartner = () => {
    return {
        type: LOGIN_AS_PARTNER
    };
};

// Logout From Partner
export const logoutFromPartner = () => {
    return {
        type: LOGOUT_FROM_PARTNER
    };
};

// Setup config/headers and token
export const getConfig = getState => {

    // Headers
    return {
        headers: {
            'Content-type': 'application/json',
            'Timezone-Offset-Minutes': -new Date().getTimezoneOffset() //Смещение таймзоны. Для Москвы +240 минут.
        }
    };
};

export const getFormDataConfig = getState => {

    // Headers
    return {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    };
};

export const resendConfirmationEmail = (login, onSuccessFunction) => (dispatch, getState) => {
    if (!login) {
        return;
    }
    const success = {success: "На указанный Email повторно отправлено письмо с ссылкой для подтверждения регистрации"};
    axios
        .get(process.env.REACT_APP_API_URL + '/auth/resendConfirmationEmail?login=' + login,  getConfig(getState))
        .then(res =>
            dispatch({
                type: CONFIRMATION_EMAIL_RESENT,
                payload: res.data
            })
        )
        .then(() => {onSuccessFunction && onSuccessFunction()})
        .then(() => {dispatch(getSuccess(success))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};