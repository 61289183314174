import React from "react";
import IconPopover from "../popovers/IconPopver";

export default function DynamicIcon({dynamicPercentage = 0, isReverseDynamic, name, tooltip}) {
    const percentageString = Math.abs(dynamicPercentage).toFixed(1).toLocaleString("ru-RU") + "%";
    const iconClassName =
        dynamicPercentage < 0
            ? ("ti ti-stats-down " + (isReverseDynamic ? "positive" : "negative"))
            : dynamicPercentage > 0
            ? ("ti ti-stats-up " + (isReverseDynamic ?  "negative" : "positive"))
            : "ti ti-layout-line-solid";
    return <IconPopover displayedContent={tooltip} placement="top" iconClassName={iconClassName}
                        target={"PopoverDynamicIcon-" + name} value={<>&nbsp;{percentageString}</>}/>;
}