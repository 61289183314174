import React from "react";
import ProfileSmallButton from "./buttons/ProfileSmallButton";
import {
    POSTBACK_TYPE_BILLING,
    POSTBACK_TYPE_BUYOUTS,
    POSTBACK_TYPE_SUBSCRIBE,
    POSTBACK_TYPE_UNSUBSCRIBE
} from "../../../utils/constants";
import {fieldErrorValue} from "../../../utils/errorUtils";
import IconPopover from "../../common/popovers/IconPopver";
import ProfileSimpleInput from "./inputs/ProfileSimpleInput";

export default function ProfilePostbackTrafficbackCard({defaultProject, handleTextTyping, onSelectPostbackEventType, fieldErrors}) {

    const {postbackUrl, trafficbackUrl, postbackEventTypes = []} = defaultProject;
    const buttonsDisabled = !postbackUrl || postbackUrl === "";

    return (
        <div className="col-12 col-lg-6 padding5">
            <div className="card" style={{height: "354px"}}>
                <div className="card-header padding10">Глобальные Postback и Trafficback &nbsp;
                    <IconPopover
                        displayedContent="Данные Url будут использоваться для стандартного потока. Рекомендуем указать во избежание потери трафика."
                        placement="top" iconClassName="ti ti-help-alt icn-hlp" target={"PopoverHelpIcon"}/>
                </div>

                <div className="profile-card-data padding15">
                    <ProfileSimpleInput type="text" inputName="trafficbackUrl" label="Trafficback URL" value={trafficbackUrl}
                                        placeholder="Ваша ссылка для обратного приёма неподходящего трафика"
                                        onTextTyping={handleTextTyping} errorMsg={fieldErrorValue(fieldErrors, "defaultProject.trafficbackUrl")}/>
                    <ProfileSimpleInput type="text" inputName="postbackUrl" label="Postback URL" value={postbackUrl}
                                        placeholder="Ваша ссылка для получения нотификаций о транзакциях"
                                        onTextTyping={handleTextTyping} errorMsg={fieldErrorValue(fieldErrors, "defaultProject.postbackUrl")}/>

                    <div className="form-group">
                        <label className="form-control-label">Postback события</label>

                        <div className="input-group">
                            <ProfileSmallButton label="Все" disabled={buttonsDisabled}
                                                selected={postbackEventTypes.length === 0}
                                                onSelect={() => onSelectPostbackEventType()}/>
                            <ProfileSmallButton label="Подписки" disabled={buttonsDisabled}
                                                selected={postbackEventTypes.includes(POSTBACK_TYPE_SUBSCRIBE)}
                                                onSelect={() => onSelectPostbackEventType(POSTBACK_TYPE_SUBSCRIBE)}/>
                            <ProfileSmallButton label="Выкуп" disabled={buttonsDisabled}
                                                selected={postbackEventTypes.includes(POSTBACK_TYPE_BUYOUTS)}
                                                onSelect={() => onSelectPostbackEventType(POSTBACK_TYPE_BUYOUTS)}/>
                            <ProfileSmallButton label="Билинг" disabled={buttonsDisabled}
                                                selected={postbackEventTypes.includes(POSTBACK_TYPE_BILLING)}
                                                onSelect={() => onSelectPostbackEventType(POSTBACK_TYPE_BILLING)}/>
                            <ProfileSmallButton label="Отписки" disabled={buttonsDisabled}
                                                selected={postbackEventTypes.includes(POSTBACK_TYPE_UNSUBSCRIBE)}
                                                onSelect={() => onSelectPostbackEventType(POSTBACK_TYPE_UNSUBSCRIBE)}/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
