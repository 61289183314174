import {
    ACTIVE_ADVERTISING_NETWORKS_LOADED,
    ACTIVE_ADVERTISING_NETWORKS_LOADING, ADVERTISING_NETWORK_BALANCE_LOADED, ADVERTISING_NETWORK_BALANCE_LOADING,
    ALL_ADVERTISING_NETWORKS_LOADED,
    ALL_ADVERTISING_NETWORKS_LOADING,
} from "../../actions/actionTypes";

const initialState = {
    allNetworks: [],
    allNetworksLoading: false,
    activeNetworks: [],
    activeNetworksLoading: false,
    adNetworksDropdownData: [],
    adNetworkBalanceData: null,
    isAdNetworkBalanceDataLoading: false,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case ALL_ADVERTISING_NETWORKS_LOADING:
            return {
                ...state,
                allNetworksLoading: true
            };
        case ALL_ADVERTISING_NETWORKS_LOADED:
            return {
                ...state,
                allNetworks: action.payload,
                allNetworksLoading: false
            };
        case ACTIVE_ADVERTISING_NETWORKS_LOADING:
            return {
                ...state,
                activeNetworksLoading: true
            };
        case ACTIVE_ADVERTISING_NETWORKS_LOADED:
            return {
                ...state,
                activeNetworks: action.payload,
                adNetworksDropdownData: action.payload,
                activeNetworksLoading: false
            };
        case ADVERTISING_NETWORK_BALANCE_LOADING:
            return {
                ...state,
                adNetworkBalanceData: null,
                activeNetworksLoading: true
            };
        case ADVERTISING_NETWORK_BALANCE_LOADED:
            return {
                ...state,
                adNetworkBalanceData: action.payload,
                activeNetworksLoading: false
            };
        default:
            return state;
    }
}