import React from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";

export default function OffersContactsModal({isOpen, onCloseModal}) {

        return (
            <Modal size="sm" isOpen={isOpen} toggle={() => onCloseModal()}>
                <ModalHeader style={{float: "left"}} toggle={() => onCloseModal()}><br/></ModalHeader>
                <ModalBody className="sm-mod">
                    <p className="modalText tal">
                        Для работы с выбранным оффером свяжитесь, пожалуйста, с нашими менеджерами по одному из контактов:
                    </p>
                    <ul className="fdc tac">
                        <li><span className="fa fa-telegram"/><a href="tg://resolve?domain=giftcard4u"
                                                                       className="contacts-a">Роман</a></li>
                        <li><span className="fa fa-telegram"/><a href="tg://resolve?domain=YoYoshka"
                                                                       className="contacts-a">Армен</a></li>
                    </ul>
                </ModalBody>
            </Modal>
        )
}