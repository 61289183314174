import React, {Component} from "react";
import {connect} from "react-redux";
import GroupFilters from "./GroupFilters";
import OffersFilter from "./OffersFilter";
import TableFilterPresets from "./TableFilterPresets";
import {
    MANAGER_ANALYSIS,
    MANAGER_LTV_ANALYSIS,
    MANAGER_MAIN, MANAGER_MONITOR, MANAGER_REFERRER,
    PARTNER_MAIN,
} from "../../../../utils/constants";
import DateRangeFilter from "./DateRangeFilter";
import PeriodButtonsFilter from "./PeriodButtonsFilter";
import OperatorsFilter from "./OperatorsFilter";
import AdminPartnersFilter from "../../../common/filters/AdminPartnersFilter";
import ServiceGroupsFilter from "./ServiceGroupsFilter";
import CountriesFilter from "./CountriesFilter";
import LtvBuyoutRateFilter from "./LtvBuyoutRateFilter";
import LtvFinanceFeeFilter from "./LtvFinanceFeeFilter";
import LtvServiceTypes from "./LtvServiceTypes";
import PayoutTypes from "./PayoutTypes";
import CircleButtonsGroup from "../../../common/buttons/CircleButtonsGroup";


class StatisticFiltersCard extends Component {

    state = {
        chartBtnSelected: false,
        tableBtnSelected: false
    };

    getDateRangeFilter = (className) => {
        const {pageState, selectFromDate, selectToDate, inputStyle} = this.props;
        return <DateRangeFilter className={className} fromDate={pageState.filters.fromDate}
                                toDate={pageState.filters.toDate}
                                selectFromDate={selectFromDate} selectToDate={selectToDate} inputStyle={inputStyle?.root}/>
    };

    getPeriodButtonsFilter = (className) => {
        const {pageState, selectPeriod} = this.props;
        return <PeriodButtonsFilter className={className} selectedPeriod={pageState.filters.period}
                                    setPeriod={selectPeriod} statisticType={pageState.selectedTab}/>;
    };

    getLtvBuyoutRateFilter = () => {
        if (this.props.pageState.selectedTab !== MANAGER_LTV_ANALYSIS) {
            return null;
        }
        const {pageState, handleChangeFilterData} = this.props;
        return <LtvBuyoutRateFilter pageState={pageState}
                                    handleChangeFilterData={handleChangeFilterData} textFieldStyle={this.getTextfieldStyle()}/>
    };

    getGroupsFilter = () => {
        if (this.props.pageState.selectedTab === MANAGER_REFERRER) {
            return null;
        }
        const {pageState, selectGroup, inputStyle} = this.props;
        return <GroupFilters selectedGroups={pageState.filters.groups} selectGroup={selectGroup}
                             statisticType={pageState.selectedTab} inputStyle={inputStyle}
                             toggleGroupsData={this.props.toggleGroupsData}/>
    };

    getCountriesFilter = () => {
        if (this.props.pageState.selectedTab === MANAGER_REFERRER || this.props.pageState.selectedTab === MANAGER_LTV_ANALYSIS) {
            return null;
        }
        const {pageState, handleChangeFilterData, inputStyle} = this.props;
        return <CountriesFilter selectedCountries={pageState.filters.countries}
                                handleChangeFilterData={handleChangeFilterData} inputStyle={inputStyle}/>

    };


    getOperatorsFilter = () => {
        if (this.props.pageState.selectedTab === MANAGER_REFERRER) {
            return null;
        }
        const {pageState, handleChangeFilterData, inputStyle} = this.props;
        return <OperatorsFilter selectedOperators={pageState.filters.operators}
                                handleChangeFilterData={handleChangeFilterData}
                                selectedCountries={pageState.filters.countries} inputStyle={inputStyle}/>
    };

    getOffersFilter = () => {
        if (this.props.pageState.selectedTab === MANAGER_REFERRER) {
            return null;
        }
        const {pageState, handleChangeFilterData, inputStyle} = this.props;
        return <OffersFilter selectedOffers={pageState.filters.offers} selectedOperators={pageState.filters.operators} selectedCountries={pageState.filters.countries}
                             handleChangeFilterData={handleChangeFilterData} inputStyle={inputStyle}/>
    };

    getPartnersFilter = () => {
        const {pageState, handleChangeFilterData, inputStyle, auth} = this.props;
        const isLoggedAsPartner = auth.isPartner || auth.isAdminAsPartner;
        const isVisible = [MANAGER_MAIN, MANAGER_ANALYSIS, MANAGER_LTV_ANALYSIS, MANAGER_MONITOR].includes(pageState.selectedTab) && !isLoggedAsPartner;
        return isVisible && <AdminPartnersFilter selectedPartners={pageState.filters.partners}
                                                 handleChangeFilterData={(value) => handleChangeFilterData("partners", value)}  inputStyle={inputStyle}/>
    };

    getFiltersPresetsFilter = () => {
        const {pageState, selectPreset, deletePreset, saveFiltersPreset, inputStyle} = this.props;
        const isVisible = (pageState.selectedTab === PARTNER_MAIN || pageState.selectedTab === MANAGER_MAIN || pageState.selectedTab === MANAGER_LTV_ANALYSIS)

        const scrollToBottom = () => {
            const el = document.getElementById("nav-filer");
            el.scrollTo(0, 130);
        };

        return isVisible &&
            <div onClick={scrollToBottom}>
            <TableFilterPresets pageState={pageState} selectPreset={selectPreset} saveFiltersPreset={saveFiltersPreset}
                                deletePreset={deletePreset}
                                clearAllFilters={this.props.clearFilters} inputStyle={{...inputStyle, paper: {position: 'relative'}}}/>
            </div>
    };

    getServiceGroupsFilter = () => {
        const {pageState, handleChangeFilterData, auth, inputStyle} = this.props;
        const isLoggedAsPartner = auth.isPartner || auth.isAdminAsPartner;
        const isVisible = [MANAGER_MAIN, MANAGER_MONITOR].includes(pageState.selectedTab) && !isLoggedAsPartner;
        return isVisible && <ServiceGroupsFilter selectedServiceGroups={pageState.filters.serviceGroups}
                                                 handleChangeFilterData={handleChangeFilterData} inputStyle={inputStyle}/>
    };

    getFinanceFeeFilter = () => {
        const {pageState, handleChangeFilterData} = this.props;
        return <LtvFinanceFeeFilter pageState={pageState} handleChangeFilterData={handleChangeFilterData} textFieldStyle={this.getTextfieldStyle()} />
    };

    getLtvServiceTypesFilter = () => {
        const {pageState, handleChangeFilterData, auth, inputStyle} = this.props;
        return <LtvServiceTypes selectedServiceType={pageState.filters.ltvServiceType}
                         handleChangeFilterData={handleChangeFilterData} inputStyle={inputStyle}/>
    };

    getLtvPayoutTypes = () => {
        const {pageState, handleChangeFilterData, auth, inputStyle} = this.props;
        return <PayoutTypes selectedPayoutType={pageState.filters.payoutType}
                            handleChangeFilterData={handleChangeFilterData} inputStyle={inputStyle}/>
    };

    getTextfieldStyle = () => {
        const {inputStyle} = this.props;
        const inputMinWidth = inputStyle?.root?.minWidth ? (inputStyle.root.minWidth + 'px') : '165px';
        return {display: 'flex', paddingTop: '15px', minWidth: inputMinWidth};
    };


    getFilters = () => {
        switch (this.props.pageState.selectedTab) {
            case MANAGER_LTV_ANALYSIS:
                return (
                    <>
                        {this.getDateRangeFilter()}
                        {this.getPeriodButtonsFilter("nowrap-custom")}
                        {this.getGroupsFilter("")}
                        {this.getLtvPayoutTypes()}
                        {this.getPartnersFilter("")}
                        {this.getOffersFilter("")}
                        {this.getOperatorsFilter("")}
                        {this.getLtvBuyoutRateFilter()}
                        {this.getFinanceFeeFilter()}
                        {this.getFiltersPresetsFilter("")}
                        {this.getFilterButtons()}
                    </>
                );
            default:
                return (
                    <>
                        {this.getDateRangeFilter("")}
                        {this.getPeriodButtonsFilter("nowrap-custom")}
                        {this.getGroupsFilter("")}
                        {this.getServiceGroupsFilter("")}
                        {this.getPartnersFilter("")}
                        {this.getCountriesFilter("")}
                        {this.getOperatorsFilter("")}
                        {this.getOffersFilter("")}
                        {this.getFiltersPresetsFilter("")}
                        {this.getFilterButtons()}
                    </>
                );
        }
    };

    getFilterButtons = () => {
       return <CircleButtonsGroup buttons={
            [
                {
                    isSelected: this.props.pageState.tableBtnSelected,
                    title: "Сформировать отчет",
                    iconClassName: "ti ti-check stat-icon",
                    onClick: this.props.createStatisticReport,
                    isLoading: this.props.pageState.tableBtnSelected && this.props.loading,
                },
                {
                    isSelected: this.props.pageState.chartBtnSelected,
                    title: "Построить график",
                    iconClassName: "ti ti-stats-up stat-icon",
                    onClick: this.props.buildChart,
                    isHidden: [MANAGER_REFERRER, MANAGER_LTV_ANALYSIS, MANAGER_MONITOR].includes(this.props.pageState.selectedTab),
                    isLoading: this.props.pageState.chartBtnSelected && this.props.loading,

                },
                {
                    isSelected: false,
                    title: "Очистить",
                    iconClassName: "ti ti-close stat-icon",
                    onClick: () => this.props.clearFilters(true),
                },
            ]
        }/>
    };


    render() {
        return (
            <>
                {
                    this.getFilters()
                }
            </>
        );
    }
}


function mapStateToProps(state) {
    return {
        auth: state.auth,
        filterData : state.filterDataReducer.filterData,
        loading: state.statisticReducer.loading,
    }
}

export default connect(mapStateToProps, {})(StatisticFiltersCard);