import React, {useEffect, useState} from 'react';
import MaterialUIMultiSelect from "../../../../common/selects/MaterialUIMultiSelect";
import {useSelector} from "react-redux";


export default function SmartLinksCategoryFilter({smartLinks, onSelectCategory, selectedCategories, inputStyle}) {

    const {categories} = useSelector(state => state.filterDataReducer.filterData);

    const [dropdownValues, setDropdownValues] = useState([]);

    useEffect(() => {
        const getFilteredCategories = () => {
            let resultCategories = [];
            if (categories && smartLinks) {
                categories.forEach(function (category) {
                    const found = smartLinks.find(function (smartLink) {
                        return smartLink.category.id === category.id;
                    });
                    if (found) {
                        resultCategories.push(category);
                    }
                })
            }
            return resultCategories;
        };

        setDropdownValues(getFilteredCategories());
    }, [smartLinks, categories, selectedCategories]);


    return (
        <MaterialUIMultiSelect styles={inputStyle} dropdownData={dropdownValues} values={selectedCategories}
                               dataField='id' displayedField='name' label='Тематика' placeholder='-'
                               onSelect={onSelectCategory}/>
    )
}


