
export const addOrRemove = (array, item) => {
    if (!array || item === null) {
        return [];
    }
    const index = array.indexOf(item);
    if (index === -1) {
        array.push(item);
    } else {
        array.splice(index, 1);
    }
    return array;
};

export const addOrRemoveAndReturnNewArray = (array, item) => {
    if (!array || item === null) {
        return [];
    }
    array = array.slice(0);
    const index = array.indexOf(item);
    if (index === -1) {
        array.push(item);
    } else {
        array.splice(index, 1);
    }
    return array;
};

export const addOrRemoveObjectAndReturnNewArray = (array, item, itemId) => {
    if (!array || !item || !itemId) {
        return [];
    }
    array = array.slice(0);
    const index = array.findIndex(arrayItem => arrayItem[itemId] === item[itemId]);
    if (index === -1) {
        array.push(item);
    } else {
        array.splice(index, 1);
    }
    return array;
};

export const addIfNotExist = (array, value) => {
    if (!array || !value) {
        return array;
    }
    array = array.slice(0);
    const index = array.indexOf(value);
    if (index === -1) {
        array.push(value);
    }
    return array;
};

export const addObjectIfNotExist = (array, item, fieldName) => {
    if (!array || !item || !fieldName) {
        return [];
    }
    array = array.slice(0);
    const existed = array.find(arrayItem => arrayItem[fieldName] === item[fieldName]);
    if (!existed) {
        array.push(item);
    }
    return array;
};

export const cloneArrayOfObjects = (array) => {
    return array ? array.map(obj => ({...obj})) : array;
};

export const removeIfExist = (array, value) => {
    if (!array || !value) {
        return array;
    }
    const index = array.indexOf(value);
    if (index !== -1) {
        array = array.slice(0);
        array.splice(index, 1);
    }
    return array;
};

export const isEmptyArr = (array) => {
    return !array || array.length === 0;
};