import React, {useEffect, useState} from "react";
import {TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import MaterialUiClearIcon from "../MaterialUiClearIcon";

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 7,
        minWidth: props => props.root && props.root.minWidth ? props.root.minWidth : 220,
        width: '100%',
    },
}));

export default function MaterialUiInput({onTextTyping, value = "", label, placeholder="Введите домен", style={marginTop: '10px'}, styles}) {

    const classes = useStyles(styles);

    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        setSearchValue(value);
    }, [value]);

    const handleTextTyping = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        onTextTyping && onTextTyping(value);
    };

    const clearInputValue = () => {
        const value = "";
        setSearchValue(value);
        onTextTyping && onTextTyping(value);
    };


    return (
            <TextField
                type="text"
                label={label}
                className={classes.textField}
                value={searchValue}
                placeholder={placeholder}
                style={{fontSize: '14px', display: 'flex'}}
                inputProps={{
                    style: {style},
                }}
                InputProps={{
                    classes: classes.input,
                    endAdornment:
                        <div className="material-ui-indicator-container">
                            <span className=" css-1okebmr-indicatorSeparator"/>
                            <div aria-hidden="true"
                                 className=" css-tlfecz-indicatorContainer" style={{cursor: "pointer", opacity: "0.2"}}
                                 onClick={clearInputValue}>
                                <MaterialUiClearIcon/>
                            </div>
                        </div>
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={handleTextTyping}
            />
    )
}