import React from "react";
import SimpleTable from "../../../../common/tables/SimpleTable";
import ImageInput from "../../../../common/inputs/ImageInput";
import {isNumberStringWithTwoDecimalPlaces} from "../../../../../utils/validationUtils";
import MaterialUiSimpleSelect from "../../../../common/selects/MaterialUiSimpleSelect";
import {BIGCLICK, MPAY, UNKNOWN_API} from "../../../../../utils/constants";

export default function AdvertisingNetworksTable({errorFields = [], allNetworks, editableNetwork, setEditableNetwork,
                                                     saveOrUpdateNetwork, clearEditableNetwork, sort, sortableColumn,
                                                     sortableDirection}) {

    const handleChangeEditableNetwork = (value, fieldName) => {
        setEditableNetwork({...editableNetwork, [fieldName]: value, hasChanges: true})
    };

    const handleChangeTransferFee = (value) => {
        if (!isNumberStringWithTwoDecimalPlaces(value)) {
            return;
        }
        handleChangeEditableNetwork(value, "transferFeeOrBonus");
    };


    const tableColumns = () => {
        const columns = [
            {
                name: "Название рекламной сети",
                key: "name",
                getBodyCellValue: network => network.name,
                headerCellStyle: {minWidth: "200px"},
                bodyCellClassName: "tac",
            },
            {
                name: "Тип API",
                key: "type",
                getBodyCellValue: network => network.type,
                headerCellStyle: {minWidth: "90px", width: "90px"},
                bodyCellClassName: "tac",
            },
            {
                name: "Описание",
                key: "description",
                getBodyCellValue: network => network.description,
                headerCellStyle: {minWidth: "300px"},
                bodyCellClassName: "tac",
            },
            {
                name: "Комиссия",
                key: "transferFeeOrBonus",
                getBodyCellValue: network => network.transferFeeOrBonus,
                headerCellStyle: {minWidth: "80px", width: "80px"},
                bodyCellClassName: "tac",
            },
            {
                name: "Активно",
                key: "active",
                getBodyCellValue: network => network.active ? 'Активна' : 'Неактивна',
                headerCellStyle: {minWidth: "80px", width: "80px"},
                bodyCellClassName: "tac",
            },
            {
                name: "Картинка",
                key: "imageId",
                getBodyCellValue: network => network.imageId
                    ? <img src={process.env.REACT_APP_API_URL + "/files/" + network.imageId} alt=""/>
                    : '',
                headerCellStyle: {minWidth: "215px", width: "215px"},
                bodyCellClassName: "tac tacimg",
            },
            {
                name: "",
                key: "actions",
                getBodyCellValue: network => <>
                    <i className="ti ti-pencil td-save" title="Редактировать" onClick={() => setEditableNetwork(network)}
                    />
                </>,
                headerCellStyle: {minWidth: "90px", width: "90px"},
                bodyCellStyle: {textAlign: "left"},
                isSortingDisabled: true,
            },
        ];
        return columns;
    };


    const tableActionRow = () => {
        const actionRows = [
            {
                key: "name",
                getActionCellValue: () =>
                    <input
                        className={errorFields.includes("name") ? "form-control input-error" : "form-control "}
                        value={editableNetwork.name}
                        onChange={(e) => handleChangeEditableNetwork(e.target.value, "name")}/>
            },
            {
                key: "type",
                getActionCellValue: () =>
                    <MaterialUiSimpleSelect
                                      dropdownData={[{value: BIGCLICK}, {value: MPAY}, {value: UNKNOWN_API}]}
                                      selectedValue={{value: editableNetwork.type}}
                                      onSelectValue={(value) => handleChangeEditableNetwork(value.value, "type")}
                                      dataField='value'
                                      displayedField='value'
                                      isClearable={true}
                    />
            },
            {
                key: "description",
                getActionCellValue: () =>
                    <input
                        className={errorFields.includes("description") ? "form-control input-error" : "form-control "}
                        value={editableNetwork.description}
                        onChange={(e) => handleChangeEditableNetwork(e.target.value, "description")}/>
            },
            {
                key: "transferFeeOrBonus",
                getActionCellValue: () =>
                    <input
                        className={errorFields.includes("transferFeeOrBonus") ? "form-control input-error" : "form-control "}
                        value={editableNetwork.transferFeeOrBonus}
                        onChange={(e) => handleChangeTransferFee(e.target.value)}/>
            },
            {
                key: "active",
                getActionCellValue: () =>
                    <MaterialUiSimpleSelect
                                      dropdownData={[{value: "Активна"}, {value: "Не активна"}]}
                                      selectedValue={{value: editableNetwork.active ? "Активна" : "Не активна"}}
                                      onSelectValue={(value) => handleChangeEditableNetwork(value.value === "Активна", "active")}
                                      dataField='value'
                                      displayedField='value'
                                      isClearable={true}
                    />
            },
            {
                key: "imageId",
                getActionCellValue: () =>
                    <ImageInput imgId={editableNetwork.imageId}
                                setImgId={(id) => handleChangeEditableNetwork(id, "imageId")}
                                inputClassName={"form-control"}/>
            },
            {
                key: "action",
                getActionCellValue: () => <>
                    <i className="ti ti-close td-save" onClick={clearEditableNetwork} title='Очистить'/>
                    <i className="ti ti-save td-save" onClick={saveOrUpdateNetwork}
                       title={editableNetwork.id ? 'Сохранить изменения' : 'Добавить сеть'}/>
                </>
            },
        ];
        return actionRows;
    };


    return (
        <>
        <div className="responsive-block statistic_table_wrapper" id="fullWidth" style={{minHeight: '30vh'}}>
        <SimpleTable
            tableClassName="table table-bordered nowrap statistic_table sticky-header sticky-action sticky-footer userselecttext"
            columns={tableColumns()}
            data={allNetworks}
            bodyRowKey="id"
            actionRow={tableActionRow()}
            isSortable
            sortableColumn={sortableColumn} sortableDirection={sortableDirection} onSort={sort}/>
        </div>
        </>
        )
}