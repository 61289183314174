import React, {useEffect} from "react";
import SimpleSortableTable from "../../../../common/tables/SimpleSortableTable";
import {getMoneyString, moneyCellFormat, toDoubleOrNull} from "../../../../../utils/formatUtils";
import {CALCULATED} from "../../../../../utils/constants";
import ActionPopover from "../../../../common/popovers/ActionPopover";
import {deductTimezoneOffset} from "../../../../../utils/dateUtils";
import {isNumberStringWithTwoDecimalPlaces} from "../../../../../utils/validationUtils";

const sumValue = (data, fieldName) => {
    if (!data) {
        return "";
    }
    const result = data.reduce((a, b) => a + b[fieldName], 0);
    return getMoneyString(result);
};



export default function PartnerPaymentsTable(props) {

    useEffect(() => {
        if (!props.tableData) {
            return;
        }
        const dataset = xlsxDataSet();
        const filename = getFilename();
        props.getXlsxReportData({filename:filename, dataset:dataset});
    }, [props.tableData]);

    const formatOrderedPaymentValue = (value, availableBalance) => {
        value = value.replace(/\s/g, "");
        if (!isNumberStringWithTwoDecimalPlaces(value) || toDoubleOrNull(value) > availableBalance) {
            return value.substring(0, value.length - 1);
        }
        return value === "" ? "" : getMoneyString(value);
    };

    const columns = [
        {
            name: "period",
            label: "Период",
            footerValue: () => "Итого:",
            headerStyle: {minWidth: "200px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "center"}
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "Период",
            formatXlsxBodyCell: value => value ? value : "",
        },
        {
            name: "paymentTypeValue",
            label: "Тип выплаты",
            footerValue: () => "-",
            headerStyle: {textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "center", fontWeight: 600}
            },
            footerStyle: {textAlign: "center"},
            formatXlsxBodyCell: value => value ? value : "",
            xlsxLabel: "Тип выплаты",
        },
        {
            name: "incomePaid",
            label: "Сумма к выплате",
            footerValue: (data) => sumValue(data, "incomePaid"),
            headerStyle: {textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "right"}
            },
            formatBodyCell: (value, data) => getMoneyString(data.incomePaid),
            footerStyle: {minWidth: "100px", textAlign: "right"},
            xlsxLabel: "Сумма к выплате",
            xlsxNumFormat: (value) => moneyCellFormat(value),
        },
        {
            name: "periodBalance",
            label: "Остаток",
            footerValue: (data) => "-",
            headerStyle: {textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "right"}
            },
            formatBodyCell: (value, data) => getMoneyString(data.periodBalance),
            footerStyle: {minWidth: "100px", textAlign: "right"},
            xlsxLabel: "Остаток",
            xlsxNumFormat: (value) => moneyCellFormat(value),
        },
        {
            name: "date",
            label: "Дата выплаты",
            footerValue: () => "-",
            headerStyle: {minWidth: "120px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "center"}
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "Дата выплаты",
        },
        {
            name: "paymentStatus",
            label: "Статус",
            footerValue: () => "-",
            headerStyle: {minWidth: "130px", textAlign: "center"},
            bodyStyle: (data) => {
                return {
                    textAlign: "center", fontWeight: 600,
                    color: data.paymentStatusColor
                }
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "Статус",
        },
        {
            name: "paymentDataValue",
            label: "Реквизиты",
            footerValue: () => "-",
            headerStyle: {minWidth: "200px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "center"}
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "Реквизиты",
            formatXlsxBodyCell: value => value ? value : ""
        },
        {
            name: "comment",
            label: "Комментарий",
            footerValue: () => "-",
            headerStyle: {minWidth: "130px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "center"}
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "Комментарий",
            formatXlsxBodyCell: value => value ? value : ""
        },
        {
            name: "actions",
            isSortingDisabled: true,
            label: "",
            footerValue: () => "",
            headerStyle: {width: "100px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "right"}
            },
            formatBodyCell: (value, data) => {
                return <>
                    {
                        (data.status === CALCULATED && data.type === 'ORDER') &&
                        <div style={{display: "inline-flex"}}>
                            <ActionPopover
                                button={
                                    <i className="ti ti-pencil" style={{cursor: "pointer", fontSize: "14px"}}/>
                                }
                                tooltip='Изменить сумму запроса'
                                popoverLocation='right'
                                label={'Доступно: ' + (getMoneyString(data.availableBalance)) + 'руб.'}
                                hasInput
                                inputLabel='Сумма запроса'
                                inputDefaultValue={data.incomePaid ? getMoneyString(data.incomePaid).toLocaleString("ru-RU") : ""}
                                inputValueFormatter={(value) => formatOrderedPaymentValue(value, data.availableBalance)}
                                handleSubmit={(state) => props.updatePayment(data.id, state.inputValue)}
                            />
                            <ActionPopover
                                button={
                                    <i className="ti ti-close" style={{cursor: "pointer", fontSize: "14px"}}/>
                                }
                                tooltip='Отменить заказ'
                                popoverLocation='right'
                                label='Отменить заказ?'
                                handleSubmit={() => props.cancelOrderedPayment(data)}
                            />
                        </div>
                    }
                </>
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "",
            formatXlsxBodyCell: () => "",
        }
    ];

    const xlsxColumns = () => {
        let xlsxColumns = columns.map(column => {
            return {title: column.xlsxLabel, width: {wch: (column.xlsxLabel.length + 5)}, height: {wpx: 14}}
        });
        xlsxColumns[0].width = {wch: 30};
        return xlsxColumns;
    };

    const xlsxData = () => {
        const tableData = props.tableData;
        return tableData.map((row) => {
            const xlsxRow = [];
            columns.forEach((column, colIdx) => {
                const horizontalAlignment = (colIdx === 0) ? "center" : "right";
                xlsxRow.push({
                    value: column.formatXlsxBodyCell ? column.formatXlsxBodyCell(row[column.name], row) : row[column.name],
                    style: {
                        font: {sz: "11"},
                        alignment: {horizontal: horizontalAlignment},
                        numFmt: column.xlsxNumFormat && column.xlsxNumFormat(row[column.name])
                    }
                });
            });
            return xlsxRow;
        })
    };

    const xlsxDataSet = () => {
        return [{columns: xlsxColumns(), data: xlsxData()}];
    };

    const getFilename = () => {
        const date = deductTimezoneOffset(new Date());
        return "am-payments-" + date.getFullYear() + date.getMonth() + date.getDate() + "-" + date.getHours() + date.getMinutes();
    };


    return (
        <>
            <div className="responsive-block statistic_table_wrapper" id="fullWidth">
                <SimpleSortableTable columns={columns} renderedData={props.tableData} allData={props.tableData}
                                     sortableColumn={props.sortableColumn}
                                     sortableDirection={props.sortableDirection} onSort={props.sort}/>
            </div>
            <br/>
        </>
    )
}