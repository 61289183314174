import {useEffect, useState} from "react";
import {DARK_THEME, DARK_THEME_CLASS_NAME, LIGHT_THEME} from "../utils/constants";

const getTheme = () => {
    return document.body.classList.contains(DARK_THEME_CLASS_NAME) ? DARK_THEME : LIGHT_THEME;
};

const useThemeChange = () => {

    const [theme, setTHeme] = useState(getTheme());

    useEffect(() => {

        const handleClick = () => {
            setTHeme(getTheme());
        };
        window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
    }, [theme]); // Empty array ensures that effect is only run on mount and unmount

    return theme;
};

export default useThemeChange;