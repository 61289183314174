import React from "react";
import PropTypes from "prop-types";
import DynamicIcon from "../../../common/iconElements/DynamicIcon";

DashboardTotalSumBox.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    dynamicPercentage: PropTypes.number,
    isReverseDynamic: PropTypes.bool,
    lineColor: PropTypes.string,
    onCounterClick: PropTypes.func,
    disabled: PropTypes.bool,
    iconTooltip: PropTypes.string,
};

DashboardTotalSumBox.defaultProps = {
    value: 0,
    dynamicPercentage: 0,
};

export default function DashboardTotalSumBox(props) {
    const {name, value, dynamicPercentage, isReverseDynamic, lineColor="rgb(30, 100, 242)", onCounterClick, disabled, iconTooltip} = props;
    return (
            <div className={"progress-box progress-2" + (disabled ? " fade-5" : "")}>
                <h4 className="por-title" style={{borderBottom: "2px solid " + lineColor, marginTop: "1.33rem"}} onClick={onCounterClick}>
                    <DynamicIcon dynamicPercentage={dynamicPercentage} isReverseDynamic={isReverseDynamic} name={name} tooltip={iconTooltip}/>
                    &nbsp;{name}</h4>
                <div className="por-txt" onClick={onCounterClick}>{value}</div>

        </div>
    )

};