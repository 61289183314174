import {pathParameter} from "./urlUtils";
import {ROLE_ADMIN, ROLE_PARTNER} from "./constants";


export const isAuthenticated = () => {
    return currentUserId() !== null;
};

export const isPartner = () => {
    if (isAuthenticated && localStorage.getItem('user') !== null) {
        const user = JSON.parse(localStorage.getItem('user'));
        return user.roles !== null && user.roles.includes(ROLE_PARTNER);
    }
    return false;
};

export const currentUserId = () => {
    if (localStorage.getItem('user') === null) {
        return null;
    }
    return JSON.parse(localStorage.getItem('user')).id;
};

export const currentUserRoles = () => {
    if (localStorage.getItem('user') === null) {
        return null;
    }
    return JSON.parse(localStorage.getItem('user')).roles;
};

export const currentUserLogin = () => {
    if (localStorage.getItem('user') === null) {
        return null;
    }
    return JSON.parse(localStorage.getItem('user')).login;
};

export const getPartnerId = () => {
    var partnerId = null;
    if (isPartner()) {
        partnerId = currentUserId();
    } else {
        partnerId = pathParameter('partnerId');
    }
    return partnerId;
};


export const isAdmin = () => {
    if (isAuthenticated && localStorage.getItem('user') !== null) {
        const user = JSON.parse(localStorage.getItem('user'));
        return user.roles !== null && user.roles.includes(ROLE_ADMIN)
    }
    return false;
};


export const isAdminAsPartner = () => {
    return isAdmin() && pathParameter('partnerId') !== null;
};