import React, {useState} from "react";
import PropTypes from "prop-types";
import image_not_available from "../../../resources/images/image-not-available.png";
import {Popover, PopoverBody} from "reactstrap";
import ImgPopover from "./IconPopver";
import {isMobileScreen} from "../../../utils/displayUtils";


ImgPopover.propTypes = {
    target: PropTypes.string,
    iconClassName: PropTypes.string, /*Имя класса иконки*/
    iconColor: PropTypes.string, /*Цвет иконки*/
    iconSize: PropTypes.string, /*Размер иконки*/
    displayedContent: PropTypes.any, /*Отображаемый контент*/
    placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'center']), /*Расположение*/

};

ImgPopover.propTypes = {
    target: PropTypes.string,
    imgUrl: PropTypes.string,
    imgClassName: PropTypes.string,
    placement: PropTypes.string,

};

export default function ImagePopover(props) {
    const {target, imgUrl, imgClassName, placement} = props;
    const [isOpen, setIsOpen] = useState(false);

    function toggle() {
        setIsOpen(!isOpen);
    }

    function close() {
        setIsOpen(false);
    }

    const isMobile = isMobileScreen();

    return (
        <>
            <img id={target} src={imgUrl ? imgUrl : image_not_available} alt="" className={imgClassName}
                 onMouseEnter={() => {if (!isMobile) {toggle();}}}
                 onClick={() => {if (isMobile) {toggle();}}} onMouseLeave={() => {close();}}/>
            <Popover isOpen={isOpen} target={target}  placement={placement} >
                <PopoverBody><img src={imgUrl} alt="" /></PopoverBody>
            </Popover>
        </>
    );
}