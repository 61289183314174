import React from "react";
import {IconButton, Popover} from "@material-ui/core";
import {BANK_TRANSFER, CAPITALIST, CONTRACT, USDT, YANDEX_MONEY} from "../../../../utils/constants";
import ProfileBankTransferPaymentForm from "./ProfileBankTransferPaymentForm";
import ProfileBankAgreementPaymentForm from "./ProfileBankAgreementPaymentForm";
import ProfileWalletPaymentForm from "./ProfileWalletPaymentForm";
import Done from '@material-ui/icons/DoneOutlined';
import Cancel from '@material-ui/icons/ClearOutlined';
import ProfilePaymentSelect from "../inputs/ProfilePaymentSelect";
import {getPaymentDetailsTypes} from "../../../../utils/paymentUtils";

export default function ProfilePaymentDetailsPopover({button, disabled, label, tooltip, paymentDetails, fieldErrors = [],
                                                      onChangeData, onOpenCallback, onSave, existedPreset}) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        onOpenCallback && onOpenCallback();
    };

    const handleSave = () => {
        handleClose();
        onSave && onSave();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTextTyping = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        onChangeData(name, value);
    };

    const getPaymentDataForm = () => {
        const paymentType = paymentDetails.paymentType;
        if (paymentType) {
            switch (paymentType) {
                case BANK_TRANSFER:
                    return <ProfileBankTransferPaymentForm paymentDetails={paymentDetails} onTextTyping={handleTextTyping}/>;
                case CONTRACT:
                    return <ProfileBankAgreementPaymentForm paymentDetails={paymentDetails} onTextTyping={handleTextTyping}/>;
                case CAPITALIST:
                case USDT:
                case YANDEX_MONEY:
                    return <ProfileWalletPaymentForm inputName="wallet" paymentDetails={paymentDetails} onTextTyping={handleTextTyping}
                                                     fieldErrors={fieldErrors} disabled={disabled}/>;
                default:
                    return null;
            }
        }

    };

    return (
        <>
            <div aria-describedby={id} onClick={handleClick} title={tooltip}>
                {button}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <div style={{padding: "10px", textAlign: "center"}}>{label}</div>
                <div style={{padding: "20px"}}>
                    <ProfilePaymentSelect paymentTypes={getPaymentDetailsTypes()} selectedType={paymentDetails.paymentType}
                                          onSelectPaymentType={onChangeData} disabled={existedPreset}/>
                    {getPaymentDataForm()}
                    <div className="text-right">
                        <IconButton disableFocusRipple size="medium" onClick={handleSave} variant="contained"
                                    title="Сохранить">
                            <Done fontSize="medium"/>
                        </IconButton>
                        <IconButton disableFocusRipple size="medium" onClick={handleClose} variant="contained"
                                    title="Отмена">
                            <Cancel fontSize="medium"/>
                        </IconButton>
                    </div>
                </div>
            </Popover>
        </>
    )
}