import React, {Fragment, useEffect, useState} from "react";
import DashboardBannerItem from "./DashboardBannerItem";
import useScreenSize from "../../../../hooks/useScreenSize";
import {copyToClipboard} from "../../../../utils/copyToClipboardUtils";
import {connect} from "react-redux";
import {loadAllBanners} from "../../../../actions/bannerActions";
import {sort} from "../../../../utils/sortUtils";

function DashboardBannersCard({allBanners, loadAllBanners}) {

    const screenSize = useScreenSize();
    const [copiedPromoCodeBannerId, setCopiedPromoCodeBannerId] = useState(null);

    const copyPromoCode = (banner) => {
        setCopiedPromoCodeBannerId(banner.id);
        copyToClipboard(banner.promoCode);
    };

    useEffect(() => {
        loadAllBanners();
    }, []);

    return (
        <div className="advert plate">
            <div className="ad-list">
                {allBanners?.map(banner =>
                    <Fragment key={banner.id}>
                        <DashboardBannerItem banner={banner} copyPromoCode={copyPromoCode} copiedPromoCodeBannerId={copiedPromoCodeBannerId}/>
                    </Fragment>
                )}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoading: state.bannerReducer.allBannersLoading,
        allBanners: sort(state.bannerReducer.allBanners, "weight", -1),
    }
}

export default connect(mapStateToProps, {
    loadAllBanners,
})(DashboardBannersCard);