import {ADD_RETENTION_EVENT, REMOVE_RETENTION_EVENT} from "./actionTypes";

export const addRetentionEvent = (event) => (dispatch, getState) => {
    dispatch({
        type: ADD_RETENTION_EVENT,
        payload: event
    });
};

export const executeRetentionEvent = (callback) => (dispatch, getState) => {
    getState().retentionEventsReducer.retentionEvent && getState().retentionEventsReducer.retentionEvent(callback);
    dispatch({
        type: REMOVE_RETENTION_EVENT,
    });
};
