import React, {useState} from "react";
import {connect} from "react-redux";
import {loadPartnerCaps} from "../../../actions/capActions";
import {Popover} from "@material-ui/core";

function CapButton(props) {

    const [isOpen, setIsOpen] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClose = () =>  {
        setAnchorEl(null);
        setIsOpen(false);
    };

    const handleClick = (event) => {
        !isOpen && props.loadPartnerCaps();
        setAnchorEl(isOpen ? null : event.currentTarget);
        setIsOpen(!isOpen);
    };

    const getColor = (remainInPercent) => {
        return remainInPercent < 30
            ? "low"
            : remainInPercent < 70
                ? "mid"
                : "many";
    };

    return (
        <>
            <div className="cap" onClick={handleClick}>КАПА</div>
            <Popover
                id={id}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <div className="cap-card">
                    {!props.isCapLoading && props.partnerCaps &&
                    props.partnerCaps.map(capItem =>
                        <div key={capItem.capName} className="cap-item">
                            <span className="cap-name">{capItem.capName + ":"}</span>
                            <span>{(capItem.spentInValue ? capItem.spentInValue : 0)  + "/" + capItem.dailyLimit}</span>
                            <span className={"cap-percent " + getColor((capItem.remainInPercent || capItem.remainInPercent === 0) ? capItem.remainInPercent : 100)}>
                                {((capItem.remainInPercent || capItem.remainInPercent === 0) ? capItem.remainInPercent : 100) + "%"}
                            </span>
                        </div>
                    )
                    }
                </div>

            </Popover>
        </>
    )
}
const mapStateToProps = state => ({
    isCapLoading: state.capReducer.loading,
    partnerCaps: state.capReducer.partnerCaps,
});

export default connect(mapStateToProps, {loadPartnerCaps})(CapButton);
