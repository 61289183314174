import React, {useEffect, useState} from "react";
import {
    POSTBACK_TYPE_BILLING,
    POSTBACK_TYPE_BUYOUTS,
    POSTBACK_TYPE_SUBSCRIBE,
    POSTBACK_TYPE_UNSUBSCRIBE
} from "../../../utils/constants";
import PostbackEventTypeBtn from "./PostbackEventTypeBtn";
import {fieldErrorValue} from "../../../utils/errorUtils";
import ValidInput from "../inputs/ValidInput";
import {isEmptyUrl} from "../../../utils/urlUtils";
import ProfileSimpleInput from "../../pages/profile/inputs/ProfileSimpleInput";

const postbackEventTypes =
    [
        {name: null, label: "Все"},
        {name: POSTBACK_TYPE_SUBSCRIBE, label: "Подписки"},
        {name: POSTBACK_TYPE_BUYOUTS, label: "Выкуп"},
        {name: POSTBACK_TYPE_BILLING, label: "Биллинг"},
        {name: POSTBACK_TYPE_UNSUBSCRIBE, label: "Отписки"},
    ];

export default function ProjectCreationForm({project, onSave, fieldErrors}) {

    const [state, setState] = useState({
        projectId: null,
        name: "",
        trafficbackUrl: "",
        postbackUrl: "",
        postbackEventTypes: [],
        isDefaultProject: false
    });
    const [isProjectloaded, setProjectloaded] = useState(false);

    useEffect(() => {
        if (project && project.name && !isProjectloaded) {
            setState({
                projectId: project.id,
                name: project.name ? project.name : "",
                trafficbackUrl: project.trafficbackUrl ? project.trafficbackUrl : "",
                postbackUrl: project.postbackUrl ? project.postbackUrl : "",
                postbackEventTypes: project.postbackEventTypes && project.postbackEventTypes.length !== 4
                    ? project.postbackEventTypes
                    : [],
                isDefaultProject: project.sourceId === 0
            });
            setProjectloaded(true);
        }
    }, [project, isProjectloaded]);

    const handleTextTyping = (fieldName, value) => {
        setState(
            {...state, [fieldName]: value}
        );
    };

    const onSelectPostBackEvent = (eventType) => {
        let postbackEventTypes = state.postbackEventTypes;
        if (!eventType) {
            postbackEventTypes = [];
        } else {
            const index = postbackEventTypes.indexOf(eventType);
            if (index === -1) {
                postbackEventTypes.push(eventType);
            } else {
                postbackEventTypes.splice(index, 1);
            }
        }
        if (postbackEventTypes.length === 4) {
            postbackEventTypes = [];
        }
        setState({...state, postbackEventTypes: postbackEventTypes});
    };

    const isDefaultProject = () => {
        return project && project.sourceId === 0;
    };

    const saveProject = () => {
        const {projectId, name, trafficbackUrl, postbackUrl, postbackEventTypes, isDefaultProject} = state;
        const project = {
            projectId: projectId,
            name: name,
            trafficbackUrl: isEmptyUrl(trafficbackUrl) ? null : trafficbackUrl,
            postbackUrl: isEmptyUrl(postbackUrl) ? null : postbackUrl,
            postbackEventTypes: postbackEventTypes && postbackEventTypes.length > 0
                ? postbackEventTypes
                : [POSTBACK_TYPE_SUBSCRIBE, POSTBACK_TYPE_BUYOUTS, POSTBACK_TYPE_BILLING, POSTBACK_TYPE_UNSUBSCRIBE],
            isDefaultProject: isDefaultProject
        };
        onSave(project)
    };

    return (
        <>
            <div className="form-group mr-2" id="notific2">
                <div className="">
                    <div className="rw-multiselect rw-widget">
                        <span className="rw-sr"/>
                        <div className="rw-widget-input rw-widget-picker rw-widget-container">
                            <div>
                                <ValidInput name="name" value={state.name} placeholder="Добавьте название" size="24"
                                            onTextTyping={handleTextTyping} errorMsg={fieldErrorValue(fieldErrors, "name")}
                                disabled={isDefaultProject()}/>
                            </div>
                            <span className="rw-select"><button aria-hidden="true" type="button" aria-disabled="false"
                                                                className="rw-btn rw-btn-select"/></span>
                        </div>
                        <div className="rw-popup-container rw-popup-transition-exited">
                            <div className="rw-popup-transition">
                                <div className="rw-popup"/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ProfileSimpleInput type="text" inputName="trafficbackUrl" label="Trafficback URL" value={state.trafficbackUrl} labelClassName="float-left"
                                placeholder="Cсылка, на которую будет возвращаться трафик."
                                onTextTyping={(e) => handleTextTyping(e.target.name, e.target.value)} errorMsg={fieldErrorValue(fieldErrors, "trafficbackUrl")}/>
            <ProfileSimpleInput type="text" inputName="postbackUrl" label="Postback URL" value={state.postbackUrl} labelClassName="float-left"
                                placeholder="Введите url для приема get параметров о событиях в партнерской программе."
                                onTextTyping={(e) => handleTextTyping(e.target.name, e.target.value)} errorMsg={fieldErrorValue(fieldErrors, "postbackUrl")}/>
            <div className="form-group">
                <label className="float-left">События для Postback</label>
                <br/>
                <div className="btn-group" role="group" aria-label="First group"
                     style={{padding: "0px 15px 0px 0px"}}>
                    {
                        postbackEventTypes.map(eventType =>
                        <PostbackEventTypeBtn key={eventType.label} name={eventType.label} isDisabled={!state.postbackUrl}
                                              onSelectEventType={() => onSelectPostBackEvent(eventType.name)}
                                              isSelected={state.postbackEventTypes.includes(eventType.name) || (state.postbackEventTypes.length === 0 && eventType.label === "Все") }/>)
                    }
                </div>
            </div>
            <div className="text-right">
                <button className="btn btn-outline-info" onClick={() => saveProject()}>Сохранить
                </button>
            </div>
        </>

    )
}