import React from "react";
import {borderColors, getLineColor, getLineLabel} from "../../../utils/chartUtils";
import PropTypes from "prop-types";
import {isAdminAsPartner, isPartner} from "../../../utils/authUtils";

ChartLegend.propTypes = {
    type: PropTypes.oneOf(["line", "doughnut"]),
    lines: PropTypes.arrayOf(PropTypes.any),
    displayedLines:PropTypes.arrayOf(PropTypes.any),
    onClickReduxActionType:PropTypes.string,
};

ChartLegend.defaultProps = {
    type: "line"
};

export default function ChartLegend(props) {
    const {type, lines, displayedLines, toggleChartLegend} = props;
    const isLoggedAsPartner = isPartner() || isAdminAsPartner();

    const lineLegend = () =>
        <div className='po-chart-legends row'>
            <ul className="po-chart-legends multiple-rows">
                {
                    lines.map((line, idx) =>
                        <li className={displayedLines.includes(line) ? null : "disabled"} key={line}
                            onClick={() => toggleChartLegend(line)}>
                            <span style={{backgroundColor: getLineColor(line, idx)}}/>
                            {getLineLabel(line, isLoggedAsPartner, idx)}
                        </li>)
                }
            </ul>
        </div>;

    const doughnutLegend = () =>
        <div className='po-chart-legends row'>
            <ul>
                {
                    lines.map((line, idx) =>
                        <li className={displayedLines.includes(line) ? null : "disabled"} key={line}
                            onClick={() => toggleChartLegend(line)}>
                            <span style={{backgroundColor: borderColors[idx]}}/>
                            {line}
                        </li>)
                }
            </ul>
        </div>;

    return (
        <div className='po-chart-legends row'>
                {
                   type === "line" ? lineLegend() : doughnutLegend()
                }
        </div>
    )
}