import {SUBSCRIPTION_SERVICES_LOADED, SUBSCRIPTION_SERVICES_LOADING} from "../../actions/actionTypes";

const initialState = {
    subscriptionServices: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SUBSCRIPTION_SERVICES_LOADING:
            return {
                ...state,
                loading: true
            };
        case SUBSCRIPTION_SERVICES_LOADED:
            return {
                ...state,
                subscriptionServices: action.payload,
                loading: false
            };
        default:
            return state;
    }
}
