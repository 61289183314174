import React from "react";
import PropTypes from "prop-types";


SimpleSortableTableBody.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.any,
        bodyStyle: PropTypes.func,
        formatBodyCell: PropTypes.func,
        isEditable: PropTypes.func,
    })),
};

export default function SimpleSortableTableBody({columns = [], data = [], handleEditCell = () => {}, bodyRowKey}) {

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.target.blur();
        }
    };

    return (
        <tbody className="statistic_body">
        {
            data && data.map((row, rowIdx) =>
            <tr key={"row_" + (bodyRowKey ? row[bodyRowKey] : rowIdx)}>
                {columns.map((column) =>
                    <td key={"row_" + rowIdx + "_column_" + column.label} style={column.bodyStyle(row)} contentEditable={column.isEditable && column.isEditable(row) && 'true'}
                         suppressContentEditableWarning={true} onInput={(e) =>  handleEditCell && handleEditCell(e.target.innerHTML, row, column)}
                        onKeyDown={(e) => handleKeyDown(e)}>
                    {column.formatBodyCell ? column.formatBodyCell(row[column.name], row) : row[column.name]}
                    </td>)}
            </tr>
        )}
        </tbody>
    );
}