import {
    ADD_MESSAGE_TO_CHAT,
    CHAT_CREATED,
    CHAT_LOADED, CHAT_MESSAGE_TYPING,
    CHATS_HISTORY_LOADED, CHATS_MESSAGES_READ,
    CHATS_NUMBER_OF_UNREAD_MESSAGES_LOADED, TOGGLE_OPEN_CHAT,
} from "../../actions/actionTypes";


const initialState = {
    isChatOpened: false,
    chat: null,
    messages: [],
    numberOfUnreadMessages: 0,
    loading: false,
    text: "",
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case CHAT_LOADED:
            return {
                ...state,
                chat: action.payload,
                loading: false
            };
        case CHAT_CREATED:
            return {
                ...state,
                chat: action.payload,
                loading: false
            };
        case CHATS_NUMBER_OF_UNREAD_MESSAGES_LOADED:
            return {
                ...state,
                numberOfUnreadMessages: action.payload,
                loading: false
            };
        case CHATS_HISTORY_LOADED:
            return {
                ...state,
                messages: action.payload,
                loading: false
            };
        case CHATS_MESSAGES_READ:
            return {
                ...state,
                messages: action.payload,
                numberOfUnreadMessages: 0,
                loading: false
            };
        case CHAT_MESSAGE_TYPING:
            return {
                ...state,
                text: action.payload,
            };
        case TOGGLE_OPEN_CHAT:
            return {
                ...state,
                isChatOpened: action.payload,
            };
        case ADD_MESSAGE_TO_CHAT:
            return {
                ...state,
                messages: action.payload.messages,
                numberOfUnreadMessages: action.payload.numberOfUnreadMessages,
            };
        default:
            return state;

    }
}

