import React from "react";
import {fieldErrorValue} from "../../../../utils/errorUtils";
import TextField from "@material-ui/core/TextField/TextField";

export default function ProfileWalletPaymentForm({paymentDetails={}, onTextTyping, fieldErrors, disabled, inputName}) {

    const style = {paddingRight: '10px', paddingBottom: '10px', width: '100%'};

    const errorMsg = fieldErrorValue(fieldErrors, "paymentData.webmoneyWallet");
    const value = paymentDetails[inputName] ? paymentDetails[inputName] : '';
    return (
        <div  style={{maxWidth: '300px'}}>
            <TextField label="Номер кошелька" name={inputName}  onChange={onTextTyping} InputLabelProps={{shrink: true}}
                       value={value} style={style} disabled={disabled} error={errorMsg} helperText={errorMsg && errorMsg}/>
        </div>
    )
}