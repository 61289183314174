import React from "react";
import {ASC} from "../../../../utils/constants";

export default function PartnersTableHeader({columns=[], sortingColumn, sortingDirection=ASC, sort}) {

    const onSort = (column) => {
        if (column.sortingDisabled) {
            return;
        }
        sort(column.name);
    };

    return (
        <thead className="thead-light">
        <tr>
            {columns.map((column) => <th key={column.name} className={"left_position "
            + (!column.sortingDisabled && getSortingType(column.name, sortingColumn, sortingDirection))}
                                        style={{width: column.width, minWidth: column.minWidth}} onClick={() => onSort(column)}>{column.label}</th>)}
        </tr>
        </thead>
    );
}

const getSortingType = (column, sortingColumn, direction) => {
    if (sortingColumn && sortingColumn === column) {
        return direction === ASC ? "sorting_asc" : "sorting_desc"
    }
    return "sorting";
};