import React, {useRef} from "react";
import {LinkCopyPopup} from "../../../common/linkCopyPopup/LinkCopyPopup";
import {replaceDomain} from "../../../../utils/urlUtils";

export default function OfferItemLink({link, isLinksLoading, selectedDomain}) {

   const resultLink = (!isLinksLoading && link) ? replaceDomain(link, selectedDomain?.cname) : '';
    const childRef = useRef();

    return (
        <>
        <span className="link">
                                <label htmlFor="offer-link" className="input-label">Ссылка на оффер</label>
            {<input readOnly={true} type="text"
                                       placeholder={isLinksLoading ? "Загрузка...." : "ссылка появится после нажатия на 'Получить ссылку'"}
                                       className="linkToCopy copyClick form-control form-control-sm"
                                       id="offer-link"
                                       value={resultLink}
                                       onClick={() => childRef.current.handleClickCopy(link)}/>}
                            </span>
            <LinkCopyPopup ref={childRef}/>
            </>
)
}