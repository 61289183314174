import React, {useEffect, useState} from "react";
import {
    NONE,
    PARTNER_PAYMENT_DETAILS,
} from "../../../../utils/constants";
import {connect} from "react-redux";
import {deleteSelectedPreset, loadUserPresets, savePreset} from "../../../../actions/presetActions";
import {getPartnerId, isAdmin} from "../../../../utils/authUtils";
import {presetsAccessType} from "../../../../utils/presetsUtils";
import ActionPopover from "../../../common/popovers/ActionPopover";
import {getPaymentDetailsString} from "../../../../utils/paymentUtils";
import SimpleTable from "../../../common/tables/SimpleTable";
import ProfilePaymentDetailsPopover from "./ProfilePaymentDetailsPopover";
import ProfileSmallButton from "../buttons/ProfileSmallButton";
import {getRusDateHourString} from "../../../../utils/dateUtils";
import ProfileSimpleInput from "../inputs/ProfileSimpleInput";


const emptyPaymentPreset = { data: {paymentType: NONE} };

function ProfilePaymentDetailsCard({fieldErrors, disabled, loadUserPresets, savePreset, deleteSelectedPreset, partnerPaymentDetailsPresets,
                                       financeFee, defaultFinanceFee, onTextTyping}) {

    useEffect(() => {
        loadUserPresets(PARTNER_PAYMENT_DETAILS, presetsAccessType(), getPartnerId());
    }, []);

    useEffect(() => {
        setIsPaymentDataEditingAvailable(!partnerPaymentDetailsPresets || partnerPaymentDetailsPresets.length === 0 || isAdmin());
    }, [partnerPaymentDetailsPresets]);

    const [editablePaymentPreset, setEditablePaymentPreset] = useState({...emptyPaymentPreset});
    const [isPaymentDataEditingAvailable, setIsPaymentDataEditingAvailable] = useState(false);

    const handleChangeData = (fieldName, value) => {
        const paymentDetails = {...editablePaymentPreset.data, [fieldName]: value};
        setEditablePaymentPreset({...editablePaymentPreset, data: paymentDetails})
    };

    const handleSavePreset = () => {
        const preset = editablePaymentPreset;
        const userId = preset.userId ? preset.userId : getPartnerId();
        preset.userId = userId;
        if (!preset.id) {
            preset.label = preset.data.paymentType + '_' + getRusDateHourString(new Date());
            preset.type = PARTNER_PAYMENT_DETAILS;
            preset.accessType = "PARTNER";
        }
        savePreset(preset, () => loadUserPresets(PARTNER_PAYMENT_DETAILS, preset.accessType, userId), true);
    };

    const makeDefault = (preset) => {
        const userId = preset.userId ? preset.userId : getPartnerId();
        preset.userId = userId;
        preset.defaultPreset = true;
        savePreset(preset, () => loadUserPresets(PARTNER_PAYMENT_DETAILS, preset.accessType, userId), true);
    };

    const handleDeletePreset = (preset) => {
        const userId = preset.userId ? preset.userId : getPartnerId();
        deleteSelectedPreset(preset, () => loadUserPresets(PARTNER_PAYMENT_DETAILS, preset.accessType, userId), true)
    };


    const tableColumns = () => {
        return [
            {
                name: "Реквизиты",
                key: "data",
                getBodyCellValue: item => getPaymentDetailsString(item["data"]),
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "left"},
            },
            {
                name: "",
                key: "actions",
                getBodyCellValue: item => <>
                    <ActionPopover
                        button={<i className={item.defaultPreset ? "fa fa-star" : "fa fa-star-o"} style={{cursor: "pointer", fontSize: "18px"}}/>}
                        tooltip="Реквизиты по умолчанию"
                        popoverLocation='right'
                        label="Сделать реквизитами по умолчанию?"
                        handleSubmit={() => makeDefault(item)}
                        disabled={item.defaultPreset}/>
                    {isPaymentDataEditingAvailable &&
                    <ProfilePaymentDetailsPopover button={<i className="ti ti-pencil" style={{cursor: "pointer", fontSize: "18px"}}/>}
                                                  label="Реквизиты" tooltip="Редактировать" paymentDetails={editablePaymentPreset.data}
                                                  onOpenCallback={() => setEditablePaymentPreset(item)}
                                                  onChangeData={handleChangeData} fieldErrors={fieldErrors} disabled={disabled}
                                                  onSave={handleSavePreset} existedPreset={Boolean(editablePaymentPreset.id)}/>}
                    {isPaymentDataEditingAvailable && !item.defaultPreset &&
                    <ActionPopover
                        button={<i className="ti ti-trash" style={{cursor: "pointer", fontSize: "18px"}}/>}
                        tooltip="Удалить"
                        popoverLocation='right'
                        label="Удалить ?"
                        handleSubmit={() => handleDeletePreset(item)}/>}
                </>,
                headerCellStyle: {minWidth: "100px", width: "100px"},
                bodyCellStyle: {textAlign: "left", display: "flex"},
                isSortingDisabled: true,
            },
        ]
    };

    return (
        <div className="col-12 padding5">
            <div className="card">
                <div className="card-header padding10">Платежные данные
                    { isPaymentDataEditingAvailable && <span style={{position: "absolute", right: "25px"}}>
                         <ProfilePaymentDetailsPopover button={<ProfileSmallButton label="Добавить"/>}
                                                       label="Реквизиты" tooltip="Редактировать" paymentDetails={editablePaymentPreset.data}
                                                       onOpenCallback={() => setEditablePaymentPreset({...emptyPaymentPreset})}
                                                       onChangeData={handleChangeData} fieldErrors={fieldErrors} disabled={disabled}
                                                       onSave={handleSavePreset}
                         /></span>
                    }
                </div>
                    <div className="profile-card-data padding15">
                        {partnerPaymentDetailsPresets && partnerPaymentDetailsPresets.length > 0 &&
                        <SimpleTable tableClassName="table table-bordered nowrap statistic_table sticky-header sticky-action sticky-footer userselecttext"
                                     columns={tableColumns()} data={partnerPaymentDetailsPresets} bodyRowKey="id"/>}
                        <br/>
                        {isAdmin()
                        && <div className="profile-card-data">
                                <ProfileSimpleInput inputName="financeFee" label="Фин. комиссия, %"
                                                    value={financeFee}
                                                    placeholder={defaultFinanceFee} type="text"
                                                    onTextTyping={onTextTyping}/>
                        </div>}
                    </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    partnerPaymentDetailsPresets: state.preset.partnerPaymentDetailsPresets,
});

export default connect(mapStateToProps, {loadUserPresets, savePreset, deleteSelectedPreset})(ProfilePaymentDetailsCard);
