import React from "react";
import menuImg from "../../../resources/images/menu.png";


export default function NavigationMenuMobilePopupButton({onClick}) {

    return (
        <div className="mobile-menu-icon" onClick={onClick}>
            <img src={menuImg} alt="" onClick={onClick}/>
        </div>
    )
}