import React, {useEffect, useState} from "react";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {isMobileScreen} from "../../../../utils/displayUtils";
import IosCheckbox from "../../../common/checkboxes/IosCheckbox";
import IpRangesXlsxButton from "./IpRangesXlsxButton";
import IpRangeUploadXlsxButton from "./IpRangeUploadXlsxButton";
import {isAdminAsPartner, isPartner} from "../../../../utils/authUtils";
import useScreenSize from "../../../../hooks/useScreenSize";

export default function IpRangesFiltersCard({
                                                allRanges, selectedOperator, selectedCountry, isCIDRChecked,
                                                onSelectOperator, onSelectCountry, toggleCIDRChecked, uploadIpRanges, templateData
                                            }) {
    useScreenSize();

    const mobileScreen = isMobileScreen();
    const [countries, setCountries] = useState([]);
    const [filteredOperators, setFilteredOperators] = useState([]);

    useEffect(() => {
        if (!allRanges || allRanges.length === 0) {
            setCountries([]);
            setFilteredOperators([]);
            return;
        }
        const countries = [...new Map(allRanges.map(range => [range.countryName, {
            id: range.countryId,
            name: range.countryName
        }])).values()];
        setCountries(countries);
        let filteredOperators = selectedCountry
            ? allRanges.filter(range => range.countryId === selectedCountry.id).map(range => {
                return {id: range.operatorId, name: range.operatorName}
            })
            : [];
        filteredOperators = [...new Map(filteredOperators.map(operator => [operator.name, operator])).values()];
        setFilteredOperators(filteredOperators);

    }, [allRanges, selectedCountry]);

    return (
        <div className="form-row col-lg-12">
            <div className="form-group col-lg-3 col-md-6 col-sm-12 col-xs-8">
                <MaterialUISelect styles={{root: {minWidth: mobileScreen ? 220 : null, padding: 10}}}
                                  dropdownData={countries} selectedValue={selectedCountry} dataField='id'
                                  displayedField='name' label='Страны' placeholder='-'
                                  onSelectCallbackFunction={onSelectCountry}/>
            </div>
            <div className="form-group col-lg-3 col-md-6 col-sm-12 col-xs-8">
                <MaterialUISelect styles={{root: {minWidth: mobileScreen ? 220 : null, padding: 10}}}
                                  dropdownData={filteredOperators} selectedValue={selectedOperator}
                                  dataField='id'
                                  displayedField='name' label='Операторы' placeholder='-'
                                  onSelectCallbackFunction={onSelectOperator}/>
            </div>
            <div className="form-group col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <div className="checkbox-group">
                    <div className="custom-mselect form-select margin-left5">
                        <IosCheckbox label='CIDR' isChecked={isCIDRChecked}
                                     onClickFunction={toggleCIDRChecked}/>
                    </div>
                </div>
                <div className="circle-group">
                    <IpRangesXlsxButton ranges={allRanges} iconClassName="ti ti-cloud-down" title="Скачать весь диапазон IP"
                                        filenamePrefix="ip-ranges-all-"/>
                    {!isPartner() && !isAdminAsPartner() &&
                    <>
                        <IpRangeUploadXlsxButton uploadIpRanges={uploadIpRanges}/>
                        <IpRangesXlsxButton ranges={templateData}
                                            title="Скачать шаблон"
                                            iconClassName="ti ti-list-ol"
                                            filenamePrefix="ip-ranges-template-"/>
                    </>
                    }
                </div>
            </div>
        </div>
    )
}