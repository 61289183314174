import {
    SERVICE_GROUPS_LOADING,
    SERVICE_GROUPS_LOADED
} from '../../actions/actionTypes';

const initialState = {
    serviceGroups: [],
    loading: false
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case SERVICE_GROUPS_LOADING:
            return {
                ...state,
                loading: true
            };
        case SERVICE_GROUPS_LOADED:
            return {
                ...state,
                serviceGroups: action.payload,
                loading: true
            };
        default:
            return state;

    }
}
