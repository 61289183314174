import {
    FILTERS_LOADED,
    FILTERS_LOADING,
} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {PARTNER_GROUPS} from "../utils/constants";
import {getPartnerId} from "../utils/authUtils";

export const loadFiltersData = (types = [], callbackFunction) => (dispatch, getState) => {
    if (!types || types.length === 0) {
        return;
    }
    dispatch({type: FILTERS_LOADING});

    const typesParam = types.join(",");
    const partnerId = getPartnerId();
    const partnerIdParam = partnerId ? ('&partnerId=' + partnerId) : '';
    axios
        .get(process.env.REACT_APP_API_URL + '/filterData?types=' + typesParam + partnerIdParam, getConfig(getState))
        .then(res => {
                dispatch({
                    type: FILTERS_LOADED,
                    payload: formatResponseData(res.data, types)
                })
            }
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};




const formatResponseData = (response, types) => {
    let result = response;
    if (types.includes(PARTNER_GROUPS)) {
        const partnerGroups = formatFilterData(response?.partnerGroups);
        result = {...result, partnerGroups : partnerGroups}
    }
    return result;
}

const formatFilterData = (partners) => {
    const result = [];
    if (!partners) {
        return result;
    }
    partners.forEach(group => {
        result.push({
            partnerType: group.partnerType,
            partnerTypeId: group.partnerTypeId,
            id: group.partnerType,
            key: group.partnerTypeId + group.partnerType,
            options: group.partners.map(partner => {
                return {...partner, key: partner.id + "_" + partner.partnerTypeId}
            })
        });
    });
    return result;
};