import {
    POSTBACK_TEST_SENT, POSTBACK_TEST_SEND,
} from "../../actions/actionTypes";

const initialState = {
    sending: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case POSTBACK_TEST_SEND:
            return {
                sending: true
            };
        case POSTBACK_TEST_SENT:
            return {
                sending: false
            };
        default:
            return state;
    }
}