import React from 'react';
import PropTypes from "prop-types";


LeftSideBarLink.propTypes = {
    name: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    img: PropTypes.string,
    isMenuOpen: PropTypes.bool,
};

export default function LeftSideBarLink(props) {

    const {href, img, name, pathName, hideOnMobile, onClick, isMenuOpen} = props;

    const getActive = () => {
        return (pathName === window.location.pathname) ? " active" : "";
    };

    const hideOnMobileDevice = () => {
        return hideOnMobile ? " mobile-no" : "";
    };

    return (
        <div className={"nav-item" + hideOnMobileDevice() + getActive()} onClick={onClick && onClick}>
            <a id="leftSideNavBarIcon" className="nav-icon" href={href ? href : null}>
                <img src={img} alt=""/>
            </a>
            <a id="leftSideNavBarTxt" href={href ? href : null}>
            <div className={"nav-text" + (isMenuOpen ? "" : " close-icon")}>{name}</div>
            </a>
        </div>
    )
}