import React from "react";
import RegistrationIcon from '@material-ui/icons/HowToReg';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import InfoIcon from '@material-ui/icons/InfoRounded';
import ActionMenuIconWrapper from "./ActionMenuIconWrapper";
import {LOGIN_FORM, REGISTRATION_FORM} from "../../../../../utils/constants";
import {useHistory} from 'react-router-dom';


export default function ActionMenu({openPopup}) {

    const history = useHistory();

    return <div className="MordaMenu">
        <div className="user-block">
            <ActionMenuIconWrapper onClick={() => openPopup(REGISTRATION_FORM)} className="reg" text="Регистрация">
                <RegistrationIcon className="material-icons"/>
            </ActionMenuIconWrapper>
            <ActionMenuIconWrapper onClick={() => openPopup(LOGIN_FORM)} className="auth" text="Авторизация">
                <FingerprintIcon className="material-icons"/>
            </ActionMenuIconWrapper>
            <ActionMenuIconWrapper onClick={() => history.push("/info/about")} className="auth" text="О компании">
                <InfoIcon className="material-icons"/>
            </ActionMenuIconWrapper>
        </div>
    </div>
}