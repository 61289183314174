import React, {useEffect, useState} from "react";
import DatePicker from "../../../common/pickers/DatePicker";
import MaterialUiInput from "../../../common/inputs/MaterialUiInput";
import CircleButtonsGroup from "../../../common/buttons/CircleButtonsGroup";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {connect} from "react-redux";
import {loadLogs, loadLogTypes, resetLogs} from "../../../../actions/logsActions";
import Spinner from "../../../common/Spinner";
import {emptyString} from "../../../../utils/stringUtils";
import {LOG_FIRST, LOG_LAST} from "../../../../utils/constants";

const outputOrderDropdown = [{value: LOG_FIRST, label: "Начало файла"}, {value: LOG_LAST, label: "Конец файла"}];

function LogsContent({logsData, loadLogs, loading, resetLogs, loadLogTypes, logTypes}) {

    const DEFAULT_ROW_LIMIT = "20";
    const [searchString, setSearchString] = useState("");
    const [logTypesDropdown, setLogTypesDropdown] = useState(null);
    const [logType, setLogType] = useState(null);
    const [outputOrder, setOutputOrder] = useState(outputOrderDropdown[1]);
    const [searchDate, setSearchDate] = useState(new Date());
    const [rowLimit, setRowLimit] = useState(DEFAULT_ROW_LIMIT);

    useEffect(() => {
        loadLogTypes();
    }, []);

    useEffect(() => {
        if (!logTypes) {
            return;
        }
        const logTypesDropdown = logTypes?.map(type => { return {value: type}});
        setLogTypesDropdown(logTypesDropdown);
        logTypesDropdown && setLogType(logTypesDropdown[0]);
    }, [logTypes]);

    const searchLogs = () => {
        if (loading) {
            return;
        }
        loadLogs({searchString: searchString, logType: logType, outputOrder: outputOrder, searchDate: searchDate, rowLimit: rowLimit});
    };

    const resetSearchData = () => {
        setSearchString("");
        setLogType(logTypesDropdown && logTypesDropdown[0]);
        setOutputOrder(outputOrderDropdown[0]);
        setSearchDate(new Date());
        setRowLimit(DEFAULT_ROW_LIMIT);
        resetLogs();
    };

    const renderLogs = () => {
        return (
            <div className="col-12">
                {loading && <Spinner/>}
                {!loading &&
                (!emptyString(logsData)
                        ? <div className="input-group">
                            <div className="api-wrap">
                            <textarea name="ip" id="" rows="15" value={logsData} style={{height: "70vh", fontSize: "13px"}}
                                   onChange={() => {
                                   }} className="log-textarea" readOnly={false}/>
                            </div>
                          </div>
                        : <div/>
                )
                }
            </div>
        )
    };

    return <div id="custom-nav-home" role="tabpanel"
                aria-labelledby="custom-nav-home-tab" style={{height: '85vh'}}>
        <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-12">
                <MaterialUISelect
                    label="Тип"
                    dropdownData={logTypesDropdown}
                    selectedValue={logType}
                    onSelectCallbackFunction={setLogType}
                    dataField='value'
                    displayedField='value'
                    isClearable={true}
                    styles={{root: {minWidth: "150px"}, input: {height: "30px"}}}
                />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
                <DatePicker date={searchDate} onSelectDate={setSearchDate}/>
            </div>
            <div className="col-lg-1 col-md-2 col-sm-12">
                <MaterialUiInput placeholder="-" label="Строк" value={rowLimit} onTextTyping={setRowLimit}
                                 styles={{root: {minWidth: 80}}}/>
            </div>
            <div className="col-lg-2 col-md-10 col-sm-12">
                <MaterialUISelect
                    label="Порядок"
                    dropdownData={[...outputOrderDropdown]}
                    selectedValue={outputOrder}
                    onSelectCallbackFunction={setOutputOrder}
                    dataField='value'
                    displayedField='label'
                    isClearable={true}
                    styles={{root: {minWidth: "150px"}, input: {height: "30px"}}}
                />
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12" title="Если необходимо произвести поиск по нескольким совпадениям, то их необходимо разделить символом '+'. Пример: UNI3+Finish+videomir">
                <MaterialUiInput placeholder="-" label="Строка поиска" value={searchString} onTextTyping={setSearchString}
                                 styles={{root: {minWidth: 150}}}/>
            </div>
        </div>
        <CircleButtonsGroup buttons={
            [
                {
                    isSelected: false,
                    title: "Найти",
                    iconClassName: "ti ti-search stat-icon",
                    onClick: () => searchLogs()
                },
                {
                    isSelected: false,
                    title: "Очистить",
                    iconClassName: "ti ti-close stat-icon",
                    onClick: () => resetSearchData()
                }
            ]
        }/>
        {renderLogs()}
    </div>

}

const mapStateToProps = (state) => {
    return {
        loading: state.logsReducer.loading,
        logsData: state.logsReducer.logsData,
        logTypes: state.logsReducer.logTypes,
    }
};

export default connect(mapStateToProps, {
    loadLogs,
    resetLogs,
    loadLogTypes
})(LogsContent);