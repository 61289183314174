import React from "react";

import logo from "../../../../../resources/images/main/new-logo-w.png";
import affimob from "../../../../../resources/images/main/affimob.svg";

export default function MainPageLogo({className = "logo", href}) {

    return (
        <a href={href}>
            <div className={className}>
                <img src={logo} alt="" className="logo-icon"/>
                <img src={affimob} alt="" className="logo-text"/>
            </div>
        </a>
    )
}