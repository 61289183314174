import React, {useEffect, useState} from 'react';
import MaterialUIMultiSelect from "../../../../common/selects/MaterialUIMultiSelect";
import {activityStatuses} from "../../../../../utils/offerUtils";
import {isMobileScreen} from "../../../../../utils/displayUtils";

const statuses = [
    {
        status: 'NEW', name: activityStatuses['NEW'].name,
        icon: <i className="fa fa-dot-circle-o" style={{"color": activityStatuses['NEW'].color, "fontSize": "20px"}}/>,
        tooltip: activityStatuses['NEW'].tooltip
    },
    {
        status: 'ACTIVE', name: activityStatuses['ACTIVE'].name,
        icon: <i className="fa fa-dot-circle-o"
                 style={{"color": activityStatuses['ACTIVE'].color, "fontSize": "20px"}}/>,
        tooltip: activityStatuses['ACTIVE'].tooltip
    },
    {
        status: 'PAUSED', name: activityStatuses['PAUSED'].name,
        icon: <i className="fa fa-dot-circle-o"
                 style={{"color": activityStatuses['PAUSED'].color, "fontSize": "20px"}}/>,
        tooltip: activityStatuses['PAUSED'].tooltip
    },
    {
        status: 'STOPPED', name: activityStatuses['STOPPED'].name,
        icon: <i className="fa fa-dot-circle-o"
                 style={{"color": activityStatuses['STOPPED'].color, "fontSize": "20px"}}/>,
        tooltip: activityStatuses['STOPPED'].tooltip
    },
];

export default function OffersStatusFilter({offers, selectedStatuses, onSelectStatus, inputStyle}) {
    const [dropdownValues, setDropdownValues] = useState([]);

    useEffect(() => {
        const getFilteredStatuses = () => {
            let resultCategories = [];
            if (statuses && offers) {
                statuses.forEach(function (status) {
                    const found = offers.find(function (offer) {
                        return offer.activityStatus === status.status;
                    });
                    if (found) {
                        resultCategories.push(status);
                    }
                })
            }
            return resultCategories;
        };
        setDropdownValues(getFilteredStatuses());
    }, [offers, selectedStatuses]);

    return (
        <MaterialUIMultiSelect styles={inputStyle} dropdownData={dropdownValues} values={selectedStatuses}
                               dataField='status' displayedField='name' iconField='icon'
                               label='Статус' placeholder='-'
                               tooltipField='tooltip'
                               maxChipTxtWidth={isMobileScreen() ? null : 100}
                               onSelect={onSelectStatus}/>
    )
}


