import React, {Component} from "react";
import {connect} from "react-redux";
import {loadBlackList} from "../../../../actions/blackListActions";
import BlackListTable from "./BlackListTable";
import BlackListActionButtons from "./BlackListActionButtons";
import {ASC, DESC} from "../../../../utils/constants";
import {searchByString} from "../../../../utils/searchUtils";
import {sort} from "../../../../utils/sortUtils";

const NUMBER_OF_ROWS_TO_RENDER = 50;

class BlackListContent extends Component {

    state = {
      renderedData: [],
        filteredData: [],
        filterData: {},
        sortableColumn: "date",
        sortableDirection: DESC,
        rowIdx: NUMBER_OF_ROWS_TO_RENDER,
    };

    componentDidMount() {
        this.props.loadBlackList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {renderedData, filteredData} = this.state;
        if (prevProps.blackList === null && prevProps.blackList !== this.props.blackList ||
            this.state.sortableColumn !== prevState.sortableColumn || this.state.sortableDirection !== prevState.sortableDirection
            || this.state.filterData !== prevState.filterData
            || (!this.props.isLoading && prevProps.isLoading) || this.state.rowIdx !== prevState.rowIdx){
            filteredData = this.getFilteredBlackList(this.props.blackList);
            filteredData = this.getSortedBlackList(filteredData);
            renderedData = this.getLazyLoadData(filteredData);
            this.setState({...this.state, renderedData: renderedData, filteredData: filteredData});
        }
    }

    getFilteredBlackList = (blackList) => {
        let {filterData} = this.state;
        let filteredData = blackList;
        Object.keys(filterData).forEach(key => {
            filteredData = searchByString(filteredData, [key], filterData[key]);
        });
        return filteredData;
    };

    getSortedBlackList = (blackList) => {
        const {sortableColumn, sortableDirection} = this.state;
        const directionNumb = sortableDirection === DESC ? -1 : sortableDirection === ASC ? 1 : 0;
        return sort(blackList, sortableColumn, directionNumb);
    };

    getLazyLoadData = (data) => {
        return data.slice(0, this.state.rowIdx);
    };

    changeFilterData = (value, fieldName) => {
        this.setState({...this.state, filterData: {...this.state.filterData, [fieldName]: value}, rowIdx: NUMBER_OF_ROWS_TO_RENDER})
    };

    setSorting = (column, direction) => {
        this.setState({...this.state, sortableColumn: column, sortableDirection: direction});
    };

    handleScroll = (e) => {
        const srcElement = e.target;
        if (srcElement.clientHeight + srcElement.scrollTop < (srcElement.scrollHeight - 205)) {
            return;
        }
        this.setState({rowIdx: this.state.rowIdx + NUMBER_OF_ROWS_TO_RENDER})
    };


    render() {
        const {filterData, renderedData, filteredData, sortableColumn, sortableDirection} = this.state;
        return <>
               <BlackListTable isLoading={this.props.isLoading} tableData={renderedData} filterData={filterData}
                               changeFilterData={this.changeFilterData} setSorting={this.setSorting}
                               sortableColumn={sortableColumn} sortableDirection={sortableDirection}
                               onScroll = {this.handleScroll}
               />
               <BlackListActionButtons allData={this.props.blackList} filteredData={filteredData}/>
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        blackList: state.blackListReducer.blackList,
        isLoading: state.blackListReducer.loading,
    }
};

export default connect(mapStateToProps, {
    loadBlackList,
})(BlackListContent);