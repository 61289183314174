import React, {Fragment, useRef} from "react";

export default function NewsCoverPreview(props) {

    const {coverImageId, uploadCoverImage} = props;
    const inputFileRef = useRef();

    const onSelectFile = () => {
        inputFileRef.current.click();
    };

    const onChangeFile = (e) => {
        uploadCoverImage(e.target.files[0]);
        e.target.value = "";
    };

    return (
        <Fragment>
            <label htmlFor="exampleMessage">Обложка</label>
            <div className="row">
                { coverImageId
                &&
                <div className={"prevBlock"} style={{cursor: "initial"}}>
                    <img src={process.env.REACT_APP_API_URL + "/files/" + coverImageId} alt=""/>
                </div>
                }
                <div className="prevBlock add" title="Загрузить новую обложку" onClick={onSelectFile}>
                    <i className="ti ti-export"/>
                    <input type="file" accept="image/*" style={{display: "none"}} ref={inputFileRef} onChange={onChangeFile} onSelect={onChangeFile}/>
                </div>
            </div>
        </Fragment>
    )
}