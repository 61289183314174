import React from "react";
import {connect, useSelector} from "react-redux";
import {
    loadAllOperators,
    syncOperatorsWithGateconfig
} from "../../../../actions/operatorsActions";
import CircleButton from "../../../common/buttons/CircleButton";

function RefreshOperatorsComponent({syncOperatorsWithGateconfig, loadAllOperators}) {

    const operatorsSyncInProgress =  useSelector(state => state.operatorsReducer.operatorsSyncInProgress);

    const syncDataWithGateConf = () => {
        if (operatorsSyncInProgress) {
            return;
        }
        syncOperatorsWithGateconfig(() => loadAllOperators());
    };


    return <div style={{fontSize: "12px", fontWeight: "600", paddingBottom: "15px", float: "right"}} className='row'>
        <div style={{paddingRight: '10px', paddingTop: "6px"}}>Синхронизация с GATECONFIG</div>
        <CircleButton iconClassName='ti ti-reload'
                      isLoading={operatorsSyncInProgress}
                      onClick={syncDataWithGateConf}
                      title='Синхронизировать данные по операторам и странам с сервером GATECONFIG'/>
    </div>
}

export default connect(null,
    {
        syncOperatorsWithGateconfig,
        loadAllOperators
    })(RefreshOperatorsComponent);