import {
    ACTUAL_CURRENCY_RATES_LOADING, ACTUAL_CURRENCY_RATES_LOADED
} from "../../actions/actionTypes";

const initialState = {
    actualCurrencyRates: null,
    loading: false,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case ACTUAL_CURRENCY_RATES_LOADING:
            return {
                ...state,
                loading: true
            };
        case ACTUAL_CURRENCY_RATES_LOADED:
            return {
                ...state,
                actualCurrencyRates: action.payload,
                loading: false
            };
        default:
            return state;

    }
}