import React from "react";

export default function DashboardOffersHeader({isChecked, dynamic, onClickCheckbox, onSelectAll}) {

    const dynamicClassNameColor =
        dynamic.amount > 0
            ? ("green")
            : dynamic.amount < 0
            ? ("red")
            : "";

    return (
        <div className="dynamica-all" id="dynamica">
                    <span className="text-dyn" onClick={onSelectAll} style={{cursor: "pointer"}}>
                         <span className="dyn-title">Динамика офферов</span>
                            <span className="gray">вчера/сегодня</span>
                    </span>
            <div className="dyn-block"  onClick={onClickCheckbox}>
                <div className={"today-absolute " + dynamicClassNameColor}>{(dynamic.amount > 0 ? "+" : "") + dynamic.amount}</div>
                <span>|</span>
                <div className={"today-percent " + dynamicClassNameColor}>{(dynamic.amount > 0 ? "+" : "") + dynamic.percent}%</div>
            </div>

        </div>
    )
}
