import {
    IP_RANGES_LOADED,
    IP_RANGES_LOADING, IP_RANGES_TEMPLATE_DADA_LOADED, IP_RANGES_TEMPLATE_DADA_LOADING, IP_RANGES_UPLOADED
} from "./actionTypes";
import axios from "axios";
import {getConfig, getFormDataConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";


export const loadIpRanges = () => (dispatch, getState) => {
    dispatch({type: IP_RANGES_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/ipRanges' , getConfig(getState))
        .then(res =>
            dispatch({
                type: IP_RANGES_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadTemplateData = () => (dispatch, getState) => {
    dispatch({type: IP_RANGES_TEMPLATE_DADA_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/ipRanges/template' , getConfig(getState))
        .then(res =>
            dispatch({
                type: IP_RANGES_TEMPLATE_DADA_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const uploadIpRanges = (file) =>  (dispatch, getState) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    let response;
    axios
        .post(process.env.REACT_APP_API_URL + '/ipRanges/file', formData , getFormDataConfig(getState))
        .then(res => {
                response = res.data;
                dispatch({
                    type: IP_RANGES_UPLOADED,
                    payload: response
                })
            }
        )
        .then(() => {dispatch(getSuccess({success: "Список диапазонов обновлен"}))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};