import React, {Fragment} from "react";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {isMobileScreen} from "../../../../utils/displayUtils";

export default function NewsCategorySelect({allCategories, category, onSelectCategory, error}) {
    const isMobile = isMobileScreen();

    const styles = {
        root: {
            padding: 0,
            minWidth: isMobile ? 220 : 130
        }
    };


    return (
        <Fragment>
            <label htmlFor="tematika">Тематика</label>
            <MaterialUISelect
                styles={styles}
                dropdownData={allCategories}
                selectedValue={category}
                displayedField='label'
                dataField='value'
                placeholder='Выберите категорию'
                onSelectCallbackFunction={onSelectCategory}
                isSearchable={!isMobile}/>
            {error && <div style={{fontSize: "12px", color: "red"}}>{error.error}</div>}
        </Fragment>
    )
}