import React, { useEffect, useState } from "react";
import useResizeObserver from "../../../hooks/useResizeObserver";
import {
    isMobileHorizontalScreen,
    isMobileScreen,
    isMobileVerticalScreen,
} from "../../../utils/displayUtils";
import { useDispatch, useSelector } from "react-redux";
import {
    TOGGLE_OPEN_FILTER_BLOCK_BY_BUTTON,
    TOGGLE_OPEN_FILTER_BLOCK_BY_MOUSE,
} from "../../../actions/actionTypes";
import { getFromLocalStorage } from "../../../utils/localStorageUtils";
import { LOCAL_STORAGE_IS_FILTERS_OPEN } from "../../../utils/constants";

export default function FiltersCard({ content }) {
    const isFilterOpenByMouseMove = useSelector(
        (state) => state.navigationReducer.isFilterBlockOpenByMouse
    );
    const isFilterOpenByBtn = useSelector(
        (state) => state.navigationReducer.isFilterBlockOpenByBtn
    );

    const isOpen = isFilterOpenByMouseMove || isFilterOpenByBtn;
    const dispatch = useDispatch();

    const [timeoutState, setTimeoutState] = useState({
        timeout: 0,
    });

    useEffect(() => {
        const isFiltersOpen = (isMobileVerticalScreen() || isMobileHorizontalScreen())
            ? false
            : getFromLocalStorage(LOCAL_STORAGE_IS_FILTERS_OPEN)
                ? getFromLocalStorage(LOCAL_STORAGE_IS_FILTERS_OPEN).value
                : true;
        dispatch({ type: TOGGLE_OPEN_FILTER_BLOCK_BY_BUTTON, payload: isFiltersOpen });
    }, []);

    const observer = useResizeObserver(document.getElementById("filters"));
    const inputMinWidth = observer && observer.width && observer.width - 30;
    const inputStyle = {
        root: { minWidth: inputMinWidth, flexGrow: "0", padding: "0px" },
        chip: { maxWidth: isMobileScreen() ? null : "160px" },
        input: { height: 0 },
        paper: {position: "relative", maxHeight: 250},
    };
    const inputStyleLandscape = {
        root: { minWidth: '30vw', width: 'auto', flexGrow: "1", padding: "0px"},
        input: { height: 0 },
        paper: {maxHeight: 150},
    };

    const openByMouse = (e) => {
        if (isOpen) {
            return;
        }
        dispatch({ type: TOGGLE_OPEN_FILTER_BLOCK_BY_MOUSE, payload: true });
    };

    const resetTimer = () => {
        if (timeoutState.timeout) {
            clearTimeout(timeoutState.timeout);
        }
    };

    const closeWithTimer = () => {
        if (!isOpen) {
            return;
        }
        if (timeoutState.timeout) {
            clearTimeout(timeoutState.timeout);
        }
        setTimeoutState({
            timeout: setTimeout(() => {
                if (isFilterOpenByMouseMove) {
                    dispatch({ type: TOGGLE_OPEN_FILTER_BLOCK_BY_MOUSE, payload: false });
                }
            }, 3000),
        });
    };

    const getFiltersClass = () => {
        if (!isOpen) {
            return "filters";
        }
        return "filters open" + (isMobileHorizontalScreen() ? " filter-popup" : "");
    };

    const getInputProps = () => {
        return  {
            inputStyle: (isMobileHorizontalScreen() ? inputStyleLandscape : inputStyle),
            itemMinWidth: inputMinWidth,
            }
    };

    const getPopupCloseBtn = () => {
        return isMobileHorizontalScreen() &&
            <button className="closeButton" onClick={() => dispatch({ type: TOGGLE_OPEN_FILTER_BLOCK_BY_BUTTON, payload: !isOpen })}>
                 <i className="ti ti-close"/>
             </button>
    };

    return (
        <>
            <div
                className={getFiltersClass()}
                id="filters"
                onMouseLeave={closeWithTimer}
                onMouseMove={resetTimer}
            >
                <div className="nav-filter" id="nav-filer">
                    {React.cloneElement(content, getInputProps())}
                    {getPopupCloseBtn()}

                </div>
            </div>
            <div className="empty-filters-block" onMouseMove={openByMouse} />
        </>
    );

}
