import {useEffect, useState} from "react";

const useScreenSize = () => {

    function getSize() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    const [screenSize, setScreenSize] = useState(getSize());

    useEffect(() => {

        function handleResize() {
            setScreenSize(getSize());
        }

        if (!screenSize.width) {
            handleResize();
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [screenSize]); // Empty array ensures that effect is only run on mount and unmount

    return screenSize;
};

export default useScreenSize;