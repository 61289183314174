import React, {useRef} from "react";
import ProfileSimpleInput from "./inputs/ProfileSimpleInput";
import {LinkCopyPopup} from "../../common/linkCopyPopup/LinkCopyPopup";
import {isPartner} from "../../../utils/authUtils";

export default function ProfileReferralProgramCard({referrerLink = "", referrerPercent = "0", onTextTyping}) {
    const isReferrerPercentIncorrect = referrerPercent && Number.parseInt(referrerPercent) && (Number.parseInt(referrerPercent) > 100 || Number.parseInt(referrerPercent) < 0);
    const childRef = useRef();

    return (
        <div className="col-12 col-lg-6 padding5">
            <div className="card">
                <div className="card-header padding10">Реферальная программа</div>
                <div className="profile-card-data padding15">
                    <div id="for-user">
                        <ProfileSimpleInput inputName="referrerLink" label="Реферальная ссылка" value={referrerLink}
                                             type="text" onTextTyping={onTextTyping}/>
                        {<ProfileSimpleInput inputName="referrerPercent" label="Процент реферального вознаграждения"
                                            value={referrerPercent} type="text"
                                            placeholder="Процент реферального вознаграждения"
                                            isIncorrect={isReferrerPercentIncorrect} onTextTyping={onTextTyping}
                                                          disabled={isPartner()}/>}
                        <LinkCopyPopup ref={childRef}/>
                    </div>
                </div>
            </div>

        </div>
    )
}