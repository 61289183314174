import {
STATISTIC_TABLE_ACTIVE_OFFERS_LOADING, STATISTIC_TABLE_ACTIVE_OFFERS_LOADED
} from '../../actions/actionTypes';

const initialState = {
    offers: [],
    loading: false
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case STATISTIC_TABLE_ACTIVE_OFFERS_LOADING:
            return {
                ...state,
                loading: true
            };
        case STATISTIC_TABLE_ACTIVE_OFFERS_LOADED:
            return {
                ...state,
                offers: action.payload,
                loading: true
            };
        default:
            return state;

    }
}
