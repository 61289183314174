import React, {Fragment, useEffect, useState} from "react";
import NotificationPopup from "../common/NotificationPopup";
import {connect} from "react-redux";
import {clearErrors} from "../../actions/errorActions";
import {logout} from "../../actions/authActions";
import {clearSuccess} from "../../actions/successActions";
import {clearWarnings} from "../../actions/warningActions";
import {URL_ROOT} from "../../properties";
import {useHistory} from 'react-router-dom';

function NotificationWrapper(props) {
    const {error, success, warning, children} = props;

    const [errorState, setErrorState] = useState({
        isOpened: false,
        title: null,
        details: null,
    });

    const [successState, setSuccessState] = useState({
        isOpened: false,
        details: null,
    });

    const [warningState, setWarningState] = useState({
        isOpened: false,
        details: null,
    });

    const history = useHistory();

    useEffect(() => {
        if (!error.data || !error.status || error.data.fieldErrors) {
            return;
        }
        let errorTitle = null;
        switch (error.status) {
            case 401:
                props.logout();
                history.push(URL_ROOT);
                errorTitle = 'ВЫ НЕ АВТОРИЗОВАНЫ';
                break;
            case 403:
                history.push(URL_ROOT);
                errorTitle = 'В ДОСТУПЕ ОТКАЗАНО';
                break;
            case 500:
                errorTitle = 'ОШИБКА СЕРВЕРА';
                break;
            default:
                errorTitle = 'ОШИБКА';
        }
        setErrorState({
            isOpened: true,
            title: errorTitle,
            details: error.data.details,
        });
    }, [error]);

    useEffect(() => {
        if (!success.successMsg) {
            return;
        }
        setSuccessState({
            isOpened: true,
            details: success.successMsg,
            semiSuccess: success.semiSuccess,
        });
    }, [success]);

    useEffect(() => {
        if (!warning.message) {
            return;
        }
        setWarningState({
            isOpened: true,
            details: warning.message,
        });
    }, [warning]);

    const handleCloseErrorModal = e => {
        setErrorState({...errorState, isOpened: false});
        props.clearErrors();
    };

    const handleCloseSuccessModal = e => {
        setSuccessState({...successState, isOpened: false});
        props.clearSuccess();
    };

    const handleCloseWarningModal = e => {
        setWarningState({...warningState, isOpened: false});
        props.clearWarnings();
        if (props.warning.onCancel) {
            props.warning.onCancel();
        }
    };

    const handleSubmitWarningModal = e => {
        setWarningState({...warningState, isOpened: false});
        props.clearWarnings();
        if (props.warning.onSubmit) {
            props.warning.onSubmit();
        }
    };

    return (
        <Fragment>
            {children}
            <NotificationPopup
                onClose={handleCloseErrorModal}
                variant="error"
                message={<div align="center">{errorState.title} <br/><span style={{whiteSpace: "break-spaces"}}>{errorState.details}</span></div>}
                open={errorState.isOpened}
            />
            <NotificationPopup
                onClose={handleCloseSuccessModal}
                variant={successState.semiSuccess ? "warning" : "success"}
                message={<div align="center" style={{maxHeight:"80vh", maxWidth: "80vw", overflowY: "auto"}}>{successState.details}</div>}
                open={successState.isOpened}
            />
            <NotificationPopup
                onClose={handleCloseWarningModal}
                onSubmit={handleSubmitWarningModal}
                variant="info"
                message={<div align="center">{warningState.details}</div>}
                open={warningState.isOpened}
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    error: state.error,
    success: state.success,
    warning: state.warning,
});

export default connect(mapStateToProps, {clearErrors, logout, clearSuccess, clearWarnings,})(NotificationWrapper);