import React, {useRef} from "react";
import PropTypes from "prop-types";
import {LinkCopyPopup} from "../../../common/linkCopyPopup/LinkCopyPopup";
import SimpleTable from "../../../common/tables/SimpleTable";

ProjectsTable.propTypes = {
    projects: PropTypes.array,
    onDeleteProject: PropTypes.func,
    onEditProject: PropTypes.func,
};


export default function ProjectsTable({projects=[], defaultProject, onDeleteProject, onEditProject}) {

    const childRef = useRef();
    const defaultPoctbackUrl = defaultProject && defaultProject.postbackUrl;
    const defaultTrafficbackUrl = defaultProject && defaultProject.trafficbackUrl;

    const tableColumns = () => {
        return [
            {
                name: "Название потока",
                key: "name",
                getBodyCellValue: project => project.sourceId + " | " + project.name,
                headerCellStyle: {minWidth: "120px"}
            },
            {
                name: "Trafficback ссылка",
                key: "trafficback",
                getBodyCellValue: project => <span className="link" onClick={() => childRef.current.handleClickCopy(project.trafficbackUrl)}>
                        <input readOnly={true}  type="text" placeholder={defaultTrafficbackUrl && "Используется ссылка 'Потока по умолчанию'"}
                               className="linkToCopy copyClick form-control form-control-sm" style={{minWidth: "150px"}}
                               value={project.trafficbackUrl ? project.trafficbackUrl : ""}/></span>
            },
            {
                name: "Postback ссылка",
                key: "postback",
                getBodyCellValue: project => <span className="link" onClick={() => childRef.current.handleClickCopy(project.postbackUrl)}>
                        <input readOnly={true} type="text" placeholder={defaultPoctbackUrl && "Используется ссылка 'Потока по умолчанию'"}
                               className="linkToCopy copyClick form-control form-control-sm" style={{minWidth: "150px"}}
                               value={project.postbackUrl ? project.postbackUrl : ""} /> </span>,
            },
            {
                name: "",
                key: "buttons",
                getBodyCellValue: project => <>
                    <i className="ti ti-pencil" style={{"color": "#414455"}} title="Редактировать"
                       onClick={() => onEditProject(project)}/>
                    {project.sourceId !== 0 && <i className="ti ti-close" style={{"color": "#414455"}} title="Удалить"
                                                  onClick={() => onDeleteProject(project)}/>}
                </>,
                headerCellStyle: {minWidth: "60px", width: "60px"}
            },
        ]
    };

    return (
        <div>
            <SimpleTable columns={tableColumns()} data={projects} bodyRowKey="id"/>
            <LinkCopyPopup ref={childRef}/>
        </div>
    )
}
