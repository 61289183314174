import {CLEAR_SUCCESS, GET_SUCCESS, GET_SEMI_SUCCESS} from "./actionTypes";

export const getSuccess = (response) => {
    return {
        type: GET_SUCCESS,
        payload: response.success
    };
};

export const getSemiSuccess = (response) => {
    return {
        type: GET_SEMI_SUCCESS,
        payload: response.success
    };
};


export const clearSuccess = () => {
    return {
        type: CLEAR_SUCCESS
    };
};