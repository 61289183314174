import React, {useEffect, useRef, useState} from "react";
import ChatStartComponent from "./ChatStartComponent";
import ChatHeader from "./ChatHeader";
import ChatBody from "./ChatBody";
import ChatFooter from "./ChatFooter";
import SockJsClient from 'react-stomp';
import {getAuthToken, isAdminAsPartner, isPartner} from "../../../utils/authUtils";
import {emptyString, removeHtmlTags, replaceBrTags} from "../../../utils/stringUtils";
import {connect} from "react-redux";
import {
    addMessageChatToChat, createChat,
    loadChat, loadNumberOfUnreadMessages,
    onChatMessageTyping,
    readMessages,
    toggleOpenChat
} from "../../../actions/chatActions";

function ChatCard(props) {
    const {chat} = props;
    const socketRef = useRef();

    const [chatState, setChatState] = useState({
        isDialogStarted: false,
        isConnected: false,
    });

    useEffect(() => {
        if (isPartner() || isAdminAsPartner()) {
            props.loadChat();
            props.loadNumberOfUnreadMessages();
        }
    }, []);

    useEffect(() => {
        setChatState({...chatState, chat: chat, isDialogStarted: chat && chat.id});
    }, [chat]);

    const startDialog = () => {
        props.createChat();
        setChatState({
            ...chatState,
            isDialogStarted: true
        })
    };

    const sendMessage = () => {
        // chatMessageText = emptyString(chatMessageText) ? chatMessageText : chatMessageText.replace(/<div>(.*?)<\/div>/g, "\n$1");
        let chatMessageText = props.chatMessageText;
        chatMessageText = replaceBrTags(chatMessageText);
        chatMessageText = removeHtmlTags(chatMessageText);
        if (emptyString(chatMessageText)) {
            return;
        }
        const msg = {
            text: chatMessageText,
            userId: chat.owner.id,
            userRole: chat.owner.roles,
            status: "SENT_BY_PARTNER"
        };
        console.log("chat", chat)
        chat && socketRef.current.sendMessage('/ws/chat/' + chat.id, JSON.stringify(msg));
        props.onChatMessageTyping("");
    };

    const readMessages = () => {
        let messages = props.chatMessages;
        if (!messages) {
            return;
        }
        messages = messages.filter(message => message.status === "SENT_BY_MANAGER");
        props.readMessages(messages);
    };


    return (
        <div className="chat-box" style={props.isOpened ? {right: "0px", transition: 'right 0.3s linear'}
            : {right: "-100%", transition: 'right 0.3s linear'}}>
            <div className="center">
                <div className="chat" onClick={readMessages}>
                    {!chatState.isDialogStarted && <ChatStartComponent startDialog={startDialog}/>}
                    <ChatHeader closeChart={() => props.toggleOpenChat(false)} name={chat ? chat.owner.name : ""}
                                id={chat && chat.owner.dominicPartnerId}/>
                    <ChatBody messages={props.chatMessages} isOpened={props.isOpened}/>
                    <ChatFooter messages={props.chatMessages} sendMessage={sendMessage}
                                onChatMessageTyping={props.onChatMessageTyping}
                                chatMessageText={props.chatMessageText}/>
                    {chat && <SockJsClient url={process.env.REACT_APP_WEB_SOCKET_URL + '/chat'}
                                           topics={['/topics/messages/' + chat.id]}
                                           onMessage={props.addMessageChatToChat}
                                           onConnect={() => {
                                               console.log("connected");
                                               setChatState({...chatState, isConnected: true})
                                           }}
                                           onDisconnect={() => {
                                               console.log("disconnected");
                                               setChatState({...chatState, isConnected: false})
                                           }}
                                           ref={socketRef}/>}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isOpened: state.chatReducer.isChatOpened,
    chatMessages: state.chatReducer.messages,
    chatMessageText: state.chatReducer.text,
    chat: state.chatReducer.chat,
});

export default connect(mapStateToProps, {
    onChatMessageTyping, toggleOpenChat, addMessageChatToChat, readMessages, loadChat,
    createChat, loadNumberOfUnreadMessages
})(ChatCard);
