import React, {useEffect, useState} from "react";
import {availableBillingTypesData, getBillingTypeName, getOfferNameWithId} from "../../../../utils/offerUtils";
import SimpleTable from "../../../common/tables/SimpleTable";
import OfferEditInput from "./OfferEditInput";
import {connect} from "react-redux";
import {
    activate,
    deactivate,
    loadAllManagerOffers,
    loadChildren, loadOffer,
    removeChild
} from "../../../../actions/managerOfferActions";
import OfferEditChildrenPopover from "./OfferEditChildrenPopover";
import ActionPopover from "../../../common/popovers/ActionPopover";
import {NEW_OFFER_IDS} from "../../../../utils/offerUtils";
import {OFFER_STATUS_ACTIVE, OFFER_STATUS_INACTIVE} from "../../../../utils/constants";

function OfferEditMainDataTable({offer, children, getTestUrl, onCopyLink, allOffers, removeChild, excludeChildFromClone,
                                    addChildToClone, loadChildren, loadAllManagerOffers, activate, deactivate, loadOffer}) {

    const [availableBillingTypesForAdding, setAvailableBillingTypesForAdding] = useState([]);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (!offer || !children) {
            return;
        }

        const availableBillingTypesForAdding = availableBillingTypesData.map(item => item.type).filter(type => !offer.parentId && type !== offer.billingType
            && (children.length === 0 || !children?.some(child => child.billingType === type)));
        setAvailableBillingTypesForAdding(availableBillingTypesForAdding);


    }, [allOffers, offer, children]);

    useEffect(() => {
        if (!offer || !children) {
            return;
        }
        const parentOffer = {
            id: offer.offerId,
            offerName: offer.name,
            billingType: offer.billingType,
            externalServiceId: offer.subscriptionService.externalId,
            operatorId: offer.operatorId,
            operatorType: offer.operatorType,
            isParent: true,
            status: offer.status,
        };
        setTableData([parentOffer, ...children]);
    }, [offer, children]);


    const activateOrDeactivate = (offerItem) => {
        if (!offerItem) {
            return;
        }
        if (offerItem.status === OFFER_STATUS_ACTIVE) {
            deactivate(offerItem.id, false, () => afterActivateCallback())
        } else {
            activate(offerItem.id, false, () => afterActivateCallback())
        }
    };

    const afterActivateCallback = () => {
        loadOffer(offer.offerId);
        loadChildren(offer.offerId);
    };

    const tableColumns = () => {
        return [
            {
                name: "Название",
                key: "offerId",
                getBodyCellValue: offerItem => <a href={"/offers/edit/" + offerItem.id}>{getOfferNameWithId(offerItem.id, offerItem.offerName)}</a>,
                headerCellStyle: {minWidth: "220px", width: "220px"},
                bodyCellStyle: {textAlign: "left"},
            },
            {
                name: "Тип",
                key: "billingType",
                getBodyCellValue: offerItem => getBillingTypeName(offerItem.billingType, true),
                headerCellStyle: {minWidth: "80px", width: "80px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "Тестовая ссылка",
                key: "testUrl",
                getBodyCellValue: offerItem => <span className="link"
                                                 onClick={() => onCopyLink(getTestUrl(offerItem.id))}>
                                    <OfferEditInput value={getTestUrl(offerItem.id)} disabled
                                                    inputStyle={{marginTop: "5px"}}/>
                                </span>,
                headerCellStyle: {minWidth: "200px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: availableBillingTypesForAdding?.length > 0
                    && <OfferEditChildrenPopover
                        button={<i className="ti ti-plus td-save" title="Добавить"/>}
                        allOffers={allOffers}
                        parentOffer={offer}
                        addChildToClone={addChildToClone}
                    />,
                key: "actions",
                getBodyCellValue: (offerItem) => <div className="row" id={offerItem.status}>
                    {
                        [OFFER_STATUS_ACTIVE, OFFER_STATUS_INACTIVE].includes(offerItem.status) &&
                        <ActionPopover
                            button={
                                <i className={"ti ti-plug td-save" + (offerItem.status === OFFER_STATUS_ACTIVE ? " active-icon" : "")}/>
                            }
                            tooltip={offerItem.status === OFFER_STATUS_ACTIVE ? 'Отключить оффер' : 'Подключить оффер'}
                            popoverLocation='top'
                            label={offerItem.status === OFFER_STATUS_ACTIVE ? 'Отключить оффер?' : 'Подключить оффер?'}
                            handleSubmit={() => activateOrDeactivate(offerItem)}
                        />
                    }
                    {!offerItem.isParent && !NEW_OFFER_IDS.includes(offerItem.id)
                    && <ActionPopover
                        button={
                            <i className="ti ti-unlink td-save"/>
                        }
                        tooltip='Отвязать дочерний оффер'
                        popoverLocation='top'
                        label='Отвязать дочерний оффер?'
                        handleSubmit={() => removeChild(offerItem.id, () => {
                            loadChildren(offer.offerId);
                            loadAllManagerOffers();
                        })}
                    />}
                    {!offerItem.isParent && NEW_OFFER_IDS.includes(offerItem.id) &&
                        <ActionPopover
                            button={
                                <i className="ti ti-close td-save"/>
                            }
                            tooltip='Отвязать дочерний оффер'
                            popoverLocation='top'
                            label='Отвязать дочерний оффер?'
                            handleSubmit={() => excludeChildFromClone(offerItem.id)}
                        />
                    }
                </div>,
                headerCellStyle: {minWidth: "90px", width: "90px"},
                bodyCellStyle: {textAlign: "left"},
                isSortingDisabled: true,
            },
        ];
    };

    return <div className='card'>
                <div style={{width: "100%", padding: "5px"}}>
                    <SimpleTable
                        tableClassName="table table-bordered nowrap statistic_table sticky-header sticky-action sticky-footer userselecttext"
                        columns={tableColumns()}
                        data={tableData} bodyRowKey="id"/>
                </div>
            </div>
}

const mapStateToProps = (state) => {
    return {
        allOffers: state.managerOffersReducer.allOffers,
    }
};

export default connect(mapStateToProps, {
    loadAllManagerOffers,
    loadChildren,
    removeChild,
    activate,
    deactivate,
    loadOffer

})(OfferEditMainDataTable);