import React, {useRef} from "react";
import {LinkCopyPopup} from "../../common/linkCopyPopup/LinkCopyPopup";

export default function ReferralLinkCard({referralUrl}) {
    const childRef = useRef();

    return (
        <>
            <div style={{margin: '10px'}}>
                <p style={{fontSize: "16px", color: "#878787"}}>Реферальная ссылка</p>
                <span className="link" onClick={() => childRef.current.handleClickCopy(referralUrl)}>
                        <input readOnly={true} type="text"
                               className="linkToCopy copyClick form-control form-control-sm" style={{marginBottom: "10px"}}
                               value={referralUrl}/>
                </span>
            </div>
            <LinkCopyPopup ref={childRef}/>
        </>
    )
}