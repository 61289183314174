import {
    MANAGER_ACTIVE_OFFERS_LOADED, MANAGER_ALL_OFFERS_LOADED, MANAGER_ALL_OFFERS_LOADING,
    MANAGER_DEPRECATED_OFFERS_LOADED,
    MANAGER_INACTIVE_OFFERS_LOADED,
    MANAGER_OFFER_ACTIVATED, MANAGER_OFFER_CHILD_ADDED, MANAGER_OFFER_CHILD_REMOVED, MANAGER_OFFER_CHILDREN_LOADED,
    MANAGER_OFFER_DEACTIVATED,
    MANAGER_OFFER_DELETED,
    MANAGER_OFFER_DEPRECATED, MANAGER_OFFER_SAVED, MANAGER_OFFER_SAVING,
    MANAGER_OFFERS_LOADING,
    MANAGER_OFFERS_MASS_UPDATED,
    OFFER_CARD_DATA_LOADED,
    MANAGER_OFFER_CHANGE_VIEW,
} from "./actionTypes";
import axios from "axios";
import {getConfig, getFormDataConfig} from "./authActions";
import {returnAllErrorsAsPopup, returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";
import React from "react";

const offerTypeActionMapping = {
    ACTIVE_OFFERS: MANAGER_ACTIVE_OFFERS_LOADED,
    INACTIVE_OFFERS: MANAGER_INACTIVE_OFFERS_LOADED,
    DEPRECATED_OFFERS: MANAGER_DEPRECATED_OFFERS_LOADED,
};

const offerTypeParamMapping = {
    ACTIVE_OFFERS: "ACTIVE",
    INACTIVE_OFFERS: "INACTIVE",
    DEPRECATED_OFFERS: "DEPRECATED",
};

export const loadManagerOffers = (type, callbackFunction) => (dispatch, getState) => {
    if (!type || !offerTypeActionMapping[type]) {
        return;
    }
    dispatch({type: MANAGER_OFFERS_LOADING});

    const sorting = '&sortBy=id&orderDirection=desc';

    axios
        .get(process.env.REACT_APP_API_URL + '/offers/manager?offersFilterType=' + offerTypeParamMapping[type] + sorting,
            getConfig(getState))
        .then(res =>
            dispatch({
                type: offerTypeActionMapping[type],
                payload: res.data
            })
        )
        .then(() => callbackFunction && callbackFunction())
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadAllManagerOffers = (billingType, callbackFunction) => (dispatch, getState) => {
    dispatch({type: MANAGER_ALL_OFFERS_LOADING});

    axios
        .get(process.env.REACT_APP_API_URL + '/offers' + (billingType ? ("?billingType=" + billingType) : ""),

            getConfig(getState))
        .then(res =>
            dispatch({
                type: MANAGER_ALL_OFFERS_LOADED,
                payload: res.data
            })
        )
        .then(() => callbackFunction && callbackFunction())
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadOffer = (id) => (dispatch, getState) => {
    if (!id) {
        return;
    }
    axios
        .get(process.env.REACT_APP_API_URL + '/offers/' + id,
            getConfig(getState))
        .then(res =>
            dispatch({
                type: OFFER_CARD_DATA_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const massUpdate = (body, callbackFunction) => (dispatch, getState) => {
    let resp = {success: "Офферы сохранены"};
    axios
        .put(process.env.REACT_APP_API_URL + '/offers/massUpdate', body, getConfig(getState))
        .then(res => {
            resp = res.data?.success === null || res.data?.success === ""
            ? resp
            : {...resp, success:  <div dangerouslySetInnerHTML={{__html: res.data?.success}} />};
                dispatch({
                    type: MANAGER_OFFERS_MASS_UPDATED,
                    payload: res.data
                })
            }
        )
        .then(() => callbackFunction && callbackFunction())
        .then(() => {dispatch(getSuccess(resp))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const activate = (offerId, isActivateChildren, callbackFunction) => (dispatch, getState) => {
    const successObj = {success: "Оффер включен"};
    axios
        .put(process.env.REACT_APP_API_URL + '/offers/' + offerId + '/activate?isActivateChildren=' + (isActivateChildren ? 'true' : 'false'), null, getConfig(getState))
        .then(res =>
            dispatch({
                type: MANAGER_OFFER_ACTIVATED,
                payload: res.data
            })
        )
        .then(() => callbackFunction && callbackFunction())
        .then(() => {dispatch(getSuccess(successObj))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const deactivate = (offerId, isDeactivateChildren, callbackFunction) => (dispatch, getState) => {
    const successObj = {success: "Оффер отключен"};
    axios
        .put(process.env.REACT_APP_API_URL + '/offers/' + offerId + '/deactivate?isDeactivateChildren=' + (isDeactivateChildren ? 'true' : 'false'), null, getConfig(getState))
        .then(res =>
            dispatch({
                type: MANAGER_OFFER_DEACTIVATED,
                payload: res.data
            })
        )
        .then(() => callbackFunction && callbackFunction())
        .then(() => {dispatch(getSuccess(successObj))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const deprecate = (offerId, deprecateDate, callbackFunction) => (dispatch, getState) => {
    const successObj = {success: "Оффер назначен устаревшим с даты "+ deprecateDate};
    axios
        .put(process.env.REACT_APP_API_URL + '/offers/' + offerId + '/deprecate?deprecatedDate=' + (deprecateDate ? deprecateDate : ""), null, getConfig(getState))
        .then(res =>
            dispatch({
                type: MANAGER_OFFER_DEPRECATED,
                payload: res.data
            })
        )
        .then(() => callbackFunction && callbackFunction())
        .then(() => {dispatch(getSuccess(successObj))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const deleteOffer = (offerId, callbackFunction) => (dispatch, getState) => {
    const successObj = {success: "Оффер удален"};
    axios
        .put(process.env.REACT_APP_API_URL + '/offers/' + offerId + '/delete', null, getConfig(getState))
        .then(res =>
            dispatch({
                type: MANAGER_OFFER_DELETED,
                payload: res.data
            })
        )
        .then(() => callbackFunction && callbackFunction())
        .then(() => {dispatch(getSuccess(successObj))})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const saveOffer = (body, offerImg, callbackFunction) => (dispatch, getState) => {
    dispatch({type: MANAGER_OFFER_SAVING});
    const successObj = {success: "Оффер сохранен"};
    const formData = new FormData();
    Object.keys(body).forEach((key) => {
        formData.append(key, body[key])
    });
    let offer;
    axios
        .post(process.env.REACT_APP_API_URL + '/offers', formData, getFormDataConfig(getState))
        .then(res => {
                offer = res.data;
                dispatch({
                    type: MANAGER_OFFER_SAVED,
                    payload: offer
                })
        }
        )
        .then(() => callbackFunction && callbackFunction(offer))
        .then(() => {dispatch(getSuccess(successObj))})
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
        });
};

export const addChild = (body, callbackFunction) => (dispatch, getState) => {
    axios
        .post(process.env.REACT_APP_API_URL + '/offers/addChild', body, getConfig(getState))
        .then(res => {
                dispatch({
                    type: MANAGER_OFFER_CHILD_ADDED,
                    payload: res.data
                })
            }
        )
        .then(() => callbackFunction && callbackFunction())
        .then(() => {dispatch(getSuccess( {success: "Оффер првязан"}))})
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
        });
};

export const removeChild = (id, callbackFunction) => (dispatch, getState) => {
    axios
        .post(process.env.REACT_APP_API_URL + '/offers/removeChild/' + id, null, getConfig(getState))
        .then(res => {
                dispatch({
                    type: MANAGER_OFFER_CHILD_REMOVED,
                    payload: res.data
                })
            }
        )
        .then(() => callbackFunction && callbackFunction())
        .then(() => {dispatch(getSuccess( {success: "Оффер отвязан"}))})
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
        });
};

export const loadChildren = (id) => (dispatch, getState) => {
    if (!id) {
        return;
    }
    axios
        .get(process.env.REACT_APP_API_URL + '/offers/' + id + '/children',
            getConfig(getState))
        .then(res =>
            dispatch({
                type: MANAGER_OFFER_CHILDREN_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};
