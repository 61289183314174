import React, {useEffect, useRef} from "react";
import CentralBlockItem from "./CentralBlockItem";
import useElementSize from "../../../../../hooks/useElementSize";
import {isMobileScreen} from "../../../../../utils/displayUtils";

export default function CentralBlock({hideScrollIcons}) {
    const ref = useRef();
    useElementSize(ref);
    const clientHeight = document.body.clientHeight;
    const mobileScreen = isMobileScreen();
    useEffect(() => {
        if (mobileScreen) {
            const clientHeight = document.body.clientHeight;
            const oNasEl = document.getElementsByClassName("o-nas")[0];
            const height = oNasEl && (clientHeight - oNasEl.getBoundingClientRect().bottom + 3) + "px !important";
            Array.from(document.getElementsByClassName("content-block")).forEach(el => el.style.cssText = "height: " + height);
        }
    }, [clientHeight, mobileScreen]);


    return (
        <div className="central-block" ref={ref} onScroll={hideScrollIcons}>
            <div className="with-labels">
                <CentralBlockItem mainClassName="content-block dark first" imgClassName="img-block af" textBlockClassName="text-block"
                                  text="Регулярно проводим A/А/B тестирование ЛП - как собственных, так и
                        доступных на рынке в данный момент. На основе тестирования предлагаем своим партнерам самые
                        конвертные решения!"/>
                <CentralBlockItem mainClassName="content-block white" imgClassName="img-block fi" textBlockClassName="text-block"
                                  text="У нас прямые договоры с операторами и агрегаторами. Меньше посредников, меньше удержек,
                                  больше конечная прибыль!"/>
                <CentralBlockItem mainClassName="content-block dark" imgClassName="img-block mm" textBlockClassName="text-block"
                                  text="Вам больше не нужны трекеры. Сэкономьте тонну денег за TDS. В нашей
                        платформе вы сможете с лёгкостью сделать детальный анализ своего трафика и оптимизировать свои
                        кампании!"/>
                <CentralBlockItem mainClassName="content-block white last" imgClassName="img-block ob" textBlockClassName="text-block"
                                  text="Для вашего удобства у нас несколько вариантов выплат : по запросу,
                        еженедельно, 2 раза в неделю.
                        Необходимо пополнение напрямую в рекламную сетку? Мы это умеем и ещё дадим бонус при пополнении!"/>
            </div>

        </div>
    )
}