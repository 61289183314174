import {CAP_NAMES_LOADED, CAP_NAMES_LOADING, CAP_NAMES_RELOADED, CAP_NAMES_RELOADING} from "../../actions/actionTypes";

const initialState = {
    capNames: null,
    loading: false,
    reloading: false,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case CAP_NAMES_LOADING:
            return {
                ...state,
                loading: false
            };
        case CAP_NAMES_LOADED:
            return {
                ...state,
                capNames: action.payload,
                loading: false
            };
        case CAP_NAMES_RELOADING:
            return {
                ...state,
                reloading: true
            };
        case CAP_NAMES_RELOADED:
            return {
                ...state,
                reloading: true
            };
        default:
            return state;
    }
}