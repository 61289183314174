import React from 'react';
import ChipButton from "../../../common/buttons/ChipButton";


export default function PartnerPageFilters(props) {
    const {isOnlyActive, toggleIsOnlyActive, isOnlyFavorite, toggleIsOnlyFavorite, isOnlyCanOrderPayment, toggleIsOnlyCanOrderPayment,
        registerStatuses, toggleRegisterStatus} = props;

    return (
        <div>

                        <ChipButton icon={<i className="fa fa-rocket"/>} title="Есть трафик за последние 3 дня"
                                    label="Активные" selected={isOnlyActive} onClick={toggleIsOnlyActive}/>
                        <ChipButton icon={<i className="fa fa-star"/>}
                                    label="Избранные" selected={isOnlyFavorite} onClick={toggleIsOnlyFavorite}/>
                        <ChipButton icon={<i className="ti ti-wallet" style={{color: "#f15a24", fontWeight: "600"}}/>}
                                    label="Заказ выплат активен" selected={isOnlyCanOrderPayment} onClick={toggleIsOnlyCanOrderPayment}/>
                        <br/>

                        <ChipButton icon={<i className="fa fa-user-plus" />}
                                    label="Новый" selected={registerStatuses.includes("NEW")} onClick={() => toggleRegisterStatus("NEW")}/>
                        <ChipButton icon={<i className="fa fa-envelope-open" />}
                                    label="E-mail подтверждён" selected={registerStatuses.includes("EMAIL_CONFIRMED")}
                                    onClick={() => toggleRegisterStatus("EMAIL_CONFIRMED")}/>
                        <ChipButton icon={<i className="fa fa-user"/>}
                                    label="Зарегистрирован" selected={registerStatuses.includes("REGISTERED")}
                                    onClick={() => toggleRegisterStatus("REGISTERED")}/>
                        <ChipButton icon={<i className="fa fa-user-times"/>}
                                    label="Отклонён" selected={registerStatuses.includes("DECLINED")}
                                    onClick={() => toggleRegisterStatus("DECLINED")}/>
        </div>
    );
}
