import React from "react";
import {getOfferNameWithId} from "../../../../utils/offerUtils";

export default function DashboardOfferItem({isChecked, onClickCheckbox, offer}) {

    const dynamicClassNameColor =
        offer.growthPercentage > 0
            ? ("green")
            : offer.growthPercentage < 0
            ? ("red")
            : "";

    return (
        <div className={isChecked ? "offer-item active" : "offer-item"} onClick={onClickCheckbox}>
            <div className="offer-item-text">
                <span className="check"></span>
                {getOfferNameWithId(offer.id, offer.offerName)}
            </div>
            <div className="dyn-block">
                <div className={"today-absolute " + dynamicClassNameColor}>{offer.currentDayLeads - offer.prevDayLeads}</div>
                <span>|</span>
                <div className={"today-percent " + dynamicClassNameColor}>{offer.growthPercentage}%</div>
            </div>

        </div>
    );
}
