import React from "react";
import {Popover} from "@material-ui/core";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import TextField from "@material-ui/core/TextField";
import CircleButtonsGroup from "../../../common/buttons/CircleButtonsGroup";

export default function ProfileAdNetworkPopover({button, label, tooltip, adNetworks, partnerAdNetworkData, onChangeData, onOpenCallback, onSave}) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        onOpenCallback && onOpenCallback();
    };

    const handleSave = () => {
        handleClose();
        onSave && onSave();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div aria-describedby={id} onClick={handleClick} title={tooltip}>
                {button}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <div style={{padding: "10px", textAlign: "center"}}>{label}</div>
                <div style={{padding: "20px"}}>
                    <MaterialUISelect label="Рекламная сеть" dropdownData={adNetworks} dataField="id"
                                      displayedField="name" isSearchable
                                      selectedValue={partnerAdNetworkData && partnerAdNetworkData.adNetwork}
                                      onSelectCallbackFunction={(value) => onChangeData("adNetwork", value)}
                                      isDisabled={!adNetworks || adNetworks.length === 0}
                                      styles={{
                                          root: {minWidth: "220px !important"},
                                          input: {height: "30px !important"}
                                      }}/>
                    <TextField label="Email в рекламной сети"
                               name="adNetworkEmail"
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={(e) => onChangeData("adNetworkEmail", e.target.value)}
                               value={partnerAdNetworkData && partnerAdNetworkData.adNetworkEmail}
                               style={{marginTop: '15px', minWidth: '100%'}}
                    />
                    <br/>
                    <CircleButtonsGroup buttons={
                        [
                            {
                                isSelected: false,
                                title: "Сохранить",
                                iconClassName: "ti ti-check stat-icon",
                                onClick: handleSave
                            },
                            {
                                isSelected: false,
                                title: "Отмена",
                                iconClassName: "ti ti-close stat-icon",
                                onClick: handleClose
                            },
                        ]
                    }/>
                </div>
                </Popover>
                </>
                )
}
