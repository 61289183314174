export const emptyString = (string) => {
    return !string || string === "";
};

export const notEmptyString = (string) => {
    return string && string !== "";
};

export const removeHtmlTags = (msg) => {
    return emptyString(msg) ? msg : msg.replace(/(<([^>]+)>)/ig, "");
};

export const replaceBrTags = (msg) => {
    return emptyString(msg) ? msg : msg.replace(/<br\s*[\/]?>/gi, "\n");
};