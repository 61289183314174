import {CAP_LOADED, CAP_LOADING} from "../../actions/actionTypes";

const initialState = {
    partnerCaps: null,
    loading: false,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case CAP_LOADING:
            return {
                ...state,
                loading: true
            };
        case CAP_LOADED:
            return {
                ...state,
                partnerCaps: action.payload,
                loading: false
            };
        default:
            return state;
    }
}