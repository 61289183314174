import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function ExportToXlsx(props) {
    const {button, dataSet, name, listName="Основной отчёт"} = props;
    return (
        <ExcelFile element={button} filename={name}>
            <ExcelSheet dataSet={dataSet}  name={listName}/>
        </ExcelFile>
    );
}