import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {loadPartnerProjectsWithNew, saveProject} from "../../../../actions/projectsActions";
import LinksCreationTable from "./LinksCreationTable";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import ProjectCreationCollapse from "../../../common/projectCreation/ProjectCreationCollapse";
import SmartLinksCreationTable from "./SmartLinksCreationTable";
import PageContent from "../../../common/page/PageContent";
import OfferLinkInfo from "../OfferLinkInfo";
import {PARTNER_MACROS} from "../../../../utils/constants";
import {loadUserPresets} from "../../../../actions/presetActions";
import MacrosCollapse from "../macros/MacrosCollapse";
import {presetsAccessType} from "../../../../utils/presetsUtils";
import PostbackTestModal from "../../projects/modal/PostbackTestModal";
import {sendPostbackTest} from "../../../../actions/postbackTestActions";
import {loadPromotionLinks} from "../../../../actions/partnerOfferActions";
import {billingTypesData} from "../../../../utils/offerUtils";


class LinkCreationComponent extends Component {
    static propTypes = {
        selectedOffers: PropTypes.array,
        returnToPrevComponent: PropTypes.func,
        selectedProject: PropTypes.any,
        onSelectProject: PropTypes.func,
        onExcludeOffer: PropTypes.func,
        onShowEditSmartLinkComponent: PropTypes.func,
        createdSmartLinksByProjectIdMap: PropTypes.any,
        smartLinksTemplates: PropTypes.any,
        deleteSmartLink: PropTypes.func,
        createLinks: PropTypes.func,
        shouldScrollToLink: PropTypes.bool,
        shouldScrollToSmartLink: PropTypes.bool,
        macros: PropTypes.any,
        selectedBillingType: PropTypes.any,
        onSelectBillingType: PropTypes.func,
    };

    static defaultProps = {
        selectedOffers: []
    };


    state = {
        isSmartLinksCreated: false,
        isFormExpanded: false,
        width: 0,
        smartLinksTemplates: [],
        needToSetDefaultProject: true,
        selectedDomain: null,
        isOpenedModal: false,
        billingTypes: [],
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.setState({width: this.container.offsetWidth});
        this.props.loadPartnerProjectsWithNew();
        const accessType = presetsAccessType();
        this.props.loadUserPresets(PARTNER_MACROS, accessType, null, () => console.log("macros_loaded"));
        if (!this.props.selectedProject || this.props.selectedProject.id === -1) {
            this.props.onSelectProject(this.props.projects[1])
        }
        if (this.props.domains) {
            const defaultDomain = this.props.domains.find(domain => domain.defaultDomain);
            this.selectDomain(defaultDomain);
        }
        const offerIds = this.props.selectedOffers.map(offer => offer.offerId);
        this.props.loadPromotionLinks(offerIds);
        window.scrollTo({top: 0});
        let billingTypes = this.props.selectedOffers?.flatMap(offer => offer.offerIncomes.map(income => income.billingType));
        billingTypes = billingTypes ? [...new Set(billingTypes)] : [];
        const billingTypeDropdown = billingTypesData.filter(dataItem => billingTypes.includes(dataItem.type));
        this.setState({billingTypes: billingTypeDropdown});
        this.props.onSelectBillingType(billingTypeDropdown[0]);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {projects, selectedProject, onSelectProject} = this.props;
        if (this.state.needToSetDefaultProject && projects && projects.length > 0 && !selectedProject) {
            onSelectProject(projects[1]);
            this.setState({needToSetDefaultProject: false})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = (e) => {
        if (this.container.offsetWidth !== this.state.width) {
            this.setState({width: this.container.offsetWidth})
        }
    };


    hideForm = () => {
        this.selectProject(null);
    };

    selectProject = (project) => {
        const isExpanded = project && project.id === -1;
        this.props.onSelectProject(project);
        this.setState({isFormExpanded: isExpanded});
    };

    selectDomain = (domain) => {
        this.setState({selectedDomain: domain})
    };

    openModal = () => {
        this.setState({
            isOpenedModal: true,
        })
    };

    handleCloseModal = () => {
        this.setState({
            isOpenedModal: false,
            selectedOffer: null
        });
        this.props.onSelectBillingType(null);
        this.selectProject(null);
    };

    onSaveProject = (project) => {
        this.props.saveProject(project, () => null);
    };

    onSendPostback = (postbackTest, handlePostbackTestResult) => {
        this.props.sendPostbackTest(postbackTest, handlePostbackTestResult);
    };

    render() {

        const {projects, selectedProject, selectedOffers, onExcludeOffer, createdSmartLinksByProjectIdMap, domains, smartLinksTemplates, onSelectBillingType, selectedBillingType} = this.props;
        const {selectedDomain, billingTypes} = this.state;
        const createdSmartLinks = selectedProject && createdSmartLinksByProjectIdMap[selectedProject.id];
        const hasSelectedOffers = hasOffers(selectedOffers);

        const domainsFilter = domains && domains.length > 0
             && <MaterialUISelect
                 styles={{root: {minWidth: 150}}}
                 dropdownData={domains}
                 displayedFields={['id', 'cname']} dataField='id'
                 placeholder="Домен"
                 selectedValue={selectedDomain}
                 onSelectCallbackFunction={(domain) => this.selectDomain(domain)}
                 isSearchable={true}/>;

        const projectsFilter = hasSelectedOffers
            && <MaterialUISelect
                styles={{root: {minWidth: 150}}}
                dropdownData={projects}
                displayedFields={['sourceId', 'name']} dataField='id'
                placeholder="Поток"
                selectedValue={selectedProject}
                onSelectCallbackFunction={(project) => this.selectProject(project)}
                isSearchable={true}/>;

        const billingTypeFilter = hasSelectedOffers
            && <MaterialUISelect
                styles={{root: {minWidth: 150}}}
                dropdownData={billingTypes}
                displayedField='fullName' dataField='type'
                placeholder="Тип выплаты"
                selectedValue={selectedBillingType}
                onSelectCallbackFunction={(billingType) => onSelectBillingType(billingType)}
                isSearchable={true}/>;


        const smartlinkBtnLabel = smartLinksTemplates && smartLinksTemplates.length === 1 ? 'Создать смартлинк' : 'Создать смартлинки';
        const offersBtnLabel = "Получить ссылк" + (selectedOffers?.length === 1 ? "у" : "и");

        const returnBtn =
                <button className="circle-btn" title="Назад" onClick={this.props.returnToPrevComponent}>
                    <i className="ti ti-back-left"/>
                </button>;

        const smartLinksTable = (createdSmartLinks && createdSmartLinks.length !== 0)
            && <SmartLinksCreationTable smartLinks={createdSmartLinks} onDeleteSmartLink={this.props.deleteSmartLink}
                                        onShowEditSmartLinkComponent={this.props.onShowEditSmartLinkComponent}
                                        selectedDomain={this.state.selectedDomain}
                                        shouldScrollToSmartLink={this.props.shouldScrollToSmartLink}/>;
        const simpleLinksTable = <LinksCreationTable groupName="Офферы" offers={selectedOffers}
                                                     selectedProject={selectedProject}
                                                     excludeOffer={onExcludeOffer}
                                                     selectedDomain={this.state.selectedDomain}
                                                     shouldScrollToLink={this.props.shouldScrollToLink}
                                                     promotionLinks={this.props.promotionLinks}/>;


        const desktopForm =
            <div className="row">
                <hr/>
                <div style={{display: "-webkit-inline-box"}} className="row col-lg-11 col-md-10 col-sm-10 col-xs-12 col">
                    <div style={{paddingLeft: "10px"}}>
                        {domainsFilter}
                    </div>
                    <div style={{paddingLeft: "10px"}}>
                        {projectsFilter}
                    </div>
                    <div style={{paddingLeft: "10px"}}>
                        {billingTypeFilter}
                    </div>
                    {!this.state.isFormExpanded
                        && <div className="select-potok" style={{maxHeight: '40px'}}>
                        <button className="btn btn-outline-primary btn-margin select-potok"
                                onClick={() => this.props.createLinks(true)}>{offersBtnLabel}
                        </button>
                        <button className="btn btn-outline-success btn-margin select-potok"
                                    onClick={() => this.props.createSmartLinks(smartLinksTemplates)}>{smartlinkBtnLabel}
                            </button>
                            <button className="btn btn-outline-warning btn-margin select-potok"
                                onClick={() => this.openModal()}>Проверить Postback
                            </button>
                        </div>
                    }
                </div>
                <PostbackTestModal isOpened={this.state.isOpenedModal}
                                   isSending={this.props.isPostbackTestSending}
                                   offers={selectedOffers}
                                   projects={projects}
                                   selectedProject={selectedProject}
                                   onCloseModal={this.handleCloseModal}
                                   onSendPostback={this.onSendPostback}
                />
                <div className="col-lg-1 col-md-2 col-sm-2 col-xs-12 right-buttons no_left_right_padding">
                    {returnBtn}
                </div>
            </div>;

        const mobileForm =
            <div className="row">
                <hr/>
                <div className="row col-sm-12 col-12 right-buttons no_left_right_padding">
                    {returnBtn}
                </div>
                <div className="col-sm-12 col-12" style={{paddingTop: "10px"}}>
                    {domainsFilter}
                </div>
                <div className="col-sm-12 col-12" style={{paddingTop: "10px"}}>
                    {projectsFilter}
                </div>
                <div className="col-sm-12 col-12"  style={{paddingLeft: "10px"}}>
                    {billingTypeFilter}
                </div>
                {!this.state.isFormExpanded
                    && <div className="row col-12 buttons-block btn-block">
                        <button className="btn btn-outline-primary" style={{minWidth: '100%'}}
                                onClick={() => this.props.createLinks(true)}>{offersBtnLabel}
                        </button>
                        <button className="btn btn-outline-success" style={{minWidth: '100%'}}
                                onClick={() => this.props.createSmartLinks(smartLinksTemplates)}>{smartlinkBtnLabel}</button>
                        <button className="btn btn-outline-warning" style={{minWidth: '100%'}}
                            onClick={() => this.openModal()}>Тест Postback
                        </button>
                    </div>}
                <PostbackTestModal isOpened={this.state.isOpenedModal}
                                   isSending={this.props.isPostbackTestSending}
                                   offers={selectedOffers}
                                   projects={projects}
                                   selectedProject={selectedProject}
                                   onCloseModal={this.handleCloseModal}
                                   onSaveProject={this.onSaveProject}
                />

            </div>;

        return (
                <PageContent
                    contentEl={
                        <div className="background-white" style={{paddingTop: "10px"}}>
                            {this.state.width > 659 ? desktopForm : mobileForm}
                            <ProjectCreationCollapse isExpanded={this.state.isFormExpanded}
                                                     onHideForm={() => this.hideForm()}
                                                     onSelectCreatedProject={this.selectProject}/>

                            <div className="padding-5-10">
                                <MacrosCollapse macrosPreset={this.props.macrosPreset} macros={this.props.macros}/>
                            </div>
                            {smartLinksTable}
                            {simpleLinksTable}
                            <OfferLinkInfo/>
                            <div ref={el => (this.container = el)}/>
                        </div>
                    }
                >
                </PageContent>
        );
    }
};

function hasOffers(selectedOffers) {
    return selectedOffers && selectedOffers.length > 0;
}

function mapStateToProps(state) {
    return {
        isPostbackTestSending: state.postbackTestReducer.sending,
        projects: state.projects.projects,
        domains: state.partnerDomainsReducer.partnerDomains,
        macrosPreset: state.preset.macrosPreset,
        promotionLinks: state.partnerOffers.promotionLinks,
        isPromotionLinksLoading: state.partnerOffers.isPromotionLinksLoading,
    }
}

export default connect(mapStateToProps, {
    loadPartnerProjectsWithNew, loadUserPresets, saveProject, sendPostbackTest, loadPromotionLinks
})(LinkCreationComponent);