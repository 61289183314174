import React, {useEffect, useState} from "react";
import ExportToXlsx from "../../../common/xlsx/ExportToXlsx";
import CircleButton from "../../../common/buttons/CircleButton";

const columns = [
    {title: "Страна", width: {wch: 25}, height: {wpx: 14}},
    {title: "Оператор", width: {wch: 25}, height: {wpx: 14}},
    {title: "Диапазон", width: {wch: 100}, height: {wpx: 14}},
];

export default function IpRangesXlsxButton({ranges, title, iconClassName, filenamePrefix}) {

    const [dataSet, setDataSet] = useState([{columns: columns, data: []}]);
    const [fileName, setFileName] = useState("");

    useEffect(() => {
        if (!ranges || ranges.length == null) {
            return;
        }
        const xlsxData = ranges.map((row) => {
            return [
                {value: row.countryName, style: {font: {sz: "11", bold: false}, alignment: {horizontal: "left"}}},
                {value: row.operatorName, style: {font: {sz: "11", bold: false}, alignment: {horizontal: "left"}}},
                {value: row.range, style: {font: {sz: "11", bold: false}, alignment: {horizontal: "left"}}},
            ];
        });
        setDataSet([{columns: columns, data: xlsxData}]);
        const date = new Date();
        const fileName = filenamePrefix + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + "-" + date.getHours() + date.getMinutes();
        setFileName(fileName);
    }, [ranges]);

    return (
        <ExportToXlsx button={<CircleButton iconClassName={iconClassName} title={title}/>}
                      dataSet={dataSet} name={fileName} listName="Диапазоны IP"/>
    )
}