import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField/TextField";
import MaterialUISelect from "../../../../common/selects/MaterialUISelect";

export default function ManagerPaymentPopoverPartnerInput({partnerName, partnersDropdownData, selectedPartner, onSelectPartner}) {

    const [isNewPayment, setIsNewPayment] = useState(true);

    useEffect(() => {
        if (partnerName) {
            setIsNewPayment(false);
        }
    }, []);

    return (
        isNewPayment

            ? <MaterialUISelect label="Партнёр" dropdownData={partnersDropdownData} dataField="partnerName"
                                displayedField="partnerName" isSearchable
                                selectedValue={selectedPartner}
                                onSelectCallbackFunction={onSelectPartner}
                                styles={{
                                    root: {minWidth: "150px !important", padding: "0 0 15px 0"},
                                    input: {height: "30px !important"},
                                }}
            />
            : <TextField label="Партнёр"
                         name="type"
                         value={partnerName}
                         disabled
                         InputLabelProps={{shrink: true}}
                         style={{width: '100%', paddingBottom: '15px'}}/>
    )

}