import React from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import {makeStyles} from "@material-ui/styles";


const useStyles = makeStyles({
    input: {
        paddingTop: 7
    }
});

export default function DateTimePicker(props) {
    const {selectedDate, handleDateChange} = props;
     const classes = useStyles();

    return (
        <KeyboardDateTimePicker
            variant="inline"
            ampm={false}
            value={selectedDate ? selectedDate : null}
            onChange={handleDateChange}
            onError={console.log}
            format="dd.MM.yyyy HH:mm"
            invalidDateMessage="Дата некорректна"
            minutesStep={1}
            InputProps={{ className: classes.input }}
        />
    )
}
