import React from "react";

export default function FiltersCard(props) {

    return (
        <>
            {props.filters}
            {props.buttons}
        </>
    )
}
