import {CLEAR_WARNING_MESSAGE, SHOW_WARNING_MESSAGE} from "./actionTypes";

export const clearWarnings = () => {
    return {
        type: CLEAR_WARNING_MESSAGE
    };
};

export const showWarning = (msg, onSubmit, onCancel) => {
    return {
        type: SHOW_WARNING_MESSAGE,
        payload: {message: msg, onSubmit: onSubmit, onCancel: onCancel}
    };
};
