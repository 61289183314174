import React from "react";
import SimpleTable from "../../../common/tables/SimpleTable";
import useResizeObserver from "../../../../hooks/useResizeObserver";


export default function CapNameTable({capNames, editableCapNameItem, setEditableCapNameItem, clearEditableCapNameItem,
                                         handleTyping, saveCapNameItem, deleteCapNameItem}) {

    const tableColumns = () => {
        return [
            {
                name: "Название капы",
                key: "name",
                getBodyCellValue: item => item["name"],
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "left"},
            },
            {
                name: "Название оффера в трекере",
                key: "binomOfferName",
                getBodyCellValue: item => item["binomOfferName"],
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "left"},
            },
            {
                name: "",
                key: "actions",
                getBodyCellValue: (capNameItem) => <>
                    <i className="ti ti-pencil td-save" title="Редактировать"
                       onClick={() =>  setEditableCapNameItem(capNameItem)}
                    />
                    <i className="ti ti-close td-save" onClick={() => deleteCapNameItem(capNameItem)}
                          title="Удалить"/>
                </>,
                bodyCellStyle: {textAlign: "left", minWidth: "105px", width: "105px", paddingRight: "15px"},
                isSortingDisabled: true,
            },
        ]
    };

    const tableActionRow = () => {
        return [
            {
                key: "name",
                getActionCellValue: () => <input
                    name="name"
                    className="form-control"
                    value = {editableCapNameItem ? editableCapNameItem["name"] : ""}
                    onChange={handleTyping}/>
            },
            {
                key: "binomOfferName",
                getActionCellValue: () => <input
                    name="binomOfferName"
                    className = "form-control"
                    value = {editableCapNameItem ? editableCapNameItem["binomOfferName"] : ""}
                    onChange={handleTyping}/>
            },
            {
                key: "action",
                getActionCellValue: () => <>
                    <i className="ti ti-close td-save" onClick={clearEditableCapNameItem} title='Очистить'/>
                    <i className="ti ti-save td-save" onClick={saveCapNameItem}
                       title = {'Сохранить'}/>
                </>
            },

        ]
    };

    const height = useResizeObserver(document.getElementById("scrollable")).height - 50;

    return (
        <div className="responsive-block statistic_table_wrapper" id="fullWidth" onScroll={() =>{}} style={{height: height + "px"}}>
            <SimpleTable tableClassName="table table-bordered nowrap statistic_table sticky-header sticky-action sticky-footer userselecttext" columns={tableColumns()}
                         data={capNames} bodyRowKey="id" actionRow={tableActionRow()}/>
        </div>
    )
};