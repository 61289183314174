import React from "react";
import PopupInput from "./PopupInput";
import {fieldErrorValue} from "../../../../../utils/errorUtils";

export default function PopupRestorePasswordForm({onTextTyping, sendRestorePasswordLink, values, fieldErrors}) {

    return (
        <form id="fpass-form" style={{display: "block"}}>
            <PopupInput type="email" name="email" placeholder="E-mail" onTextTyping={onTextTyping} value={values["email"]}
                        errorMsg={fieldErrorValue(fieldErrors, "email")}/>
            <a href="#" className="login-btn" id="getpass-btn" onClick={sendRestorePasswordLink}>Восстановить пароль</a>
        </form>
    )
}