import React from 'react';
import {Line} from 'react-chartjs-2';
import PropTypes from "prop-types";
import {getDataset} from "../../../utils/chartUtils";
import Spinner from "../Spinner";
import {isMobileScreen} from "../../../utils/displayUtils";
import useThemeChange from "../../../hooks/useThemeChange";
import {DARK_THEME} from "../../../utils/constants";

LineChart.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string.isRequired), /*x-axe values*/
    chartData: PropTypes.arrayOf(PropTypes.shape({
        lineName: PropTypes.string.isRequired,
        lineData: PropTypes.arrayOf(PropTypes.number)
    })),
    displayedLines: PropTypes.arrayOf(PropTypes.string.isRequired),
    legendClickReduxActionType: PropTypes.string,
    isLoading: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number,
    isLoggedAsPartner:  PropTypes.bool,
};

export default function LineChart(props) {

    const themeName = useThemeChange();

    const chartOptions = {
        legend: {
            display: false,
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            backgroundColor: '#6c757d',
            titleFontFamily: '"Rubik", sans-serif',
            titleFontSize: 14,
            bodySpacing: 10,
            bodyFontFamily: '"Rubik", sans-serif',
            bodyFontSize: 12,
            displayColors: true,
            position: "nearest",
            callbacks: {
                title: (items, data) => data.labels[items[0].index],
                label: (item, data) => " " + data.datasets[item.datasetIndex].label + ": " + data.datasets[item.datasetIndex].data[item.index].toLocaleString("ru-RU"),
            }
        },
        hover: {
            mode: 'index',
            intersect: false,
            fontColor: "green",
        },
        elements: {
            point: {
                radius: 1,
                pointStyle: 'circle',
                hoverRadius: 3,
                hoverBorderWidth: 3
            },
            line: {
                borderWidth: 3,
                data: [1]
            }
        },
        scales: {
            yAxes: getYAxes(props),
            xAxes:[themeName === DARK_THEME && {
                gridLines:{
                    color:"rgba(97,104,105,0.4)",
                    zeroLineColor:"rgba(97,104,105,0.4)",
                }
            }],
        },
        responsive: true,
        maintainAspectRatio: false
    };
    const height = isMobileScreen() ? window.screen.height * 0.25 : props.height ? props.height : 270;
    const isLoading = props.isLoading;
    return (
         isLoading ? <Spinner/> :
        <Line data={getChartData(props)} options={chartOptions} height={height} width={props.width}/>
    );
};

function getChartData(props) {
    const displayedLines = props.displayedLines ? props.displayedLines : [];
    return {
        labels: props.labels ? props.labels : [],
        datasets: getDatasets(props.chartData, displayedLines, props.isLoggedAsPartner, props.showGroupLineChart)
    }
}

function getDatasets(chartData, displayedLines, isLoggedAsPartner, showGroupLineChart) {
    let datasets = [];
    chartData.forEach(function (data, idx) {
        let dataset = getDataset(data.lineName, data.lineData, idx, isLoggedAsPartner, showGroupLineChart);
        dataset.hidden = !displayedLines.includes(data.lineName);
        dataset.fill = false;
        dataset.lineTension = 0;
        datasets.push(dataset);
    });
    return datasets;
}

function getYAxes(props) {
    let yAxes = [];

    if (props.showGroupLineChart){
        yAxes.push({
            id: "default",
            display: true,
            ticks: {beginAtZero: true,},
            });
    } else {
        props.chartData.forEach(function (data) {
            const yAxe = getYAxe(data);
            yAxes.push(yAxe);
        });
    }
    return yAxes;
}

function getYAxe(chartData) {
    const maxValue = Math.max(...chartData.lineData);
    return {
        id: chartData.lineName,
        display: false,
        ticks: {
            beginAtZero: true,
            userCallback: function (value) {
                getValueOfY(value, maxValue)

            }
        }
    }
}

function getValueOfY(value, maxValue) {
    if (Math.floor(value) === value) {
        if (maxValue === 0) {
            return value;
        }
        return (value / maxValue * 100).toFixed(0);
    }
}
