import React, {useEffect, useState} from "react";

import SimpleTable from "../../../common/tables/SimpleTable";
import Spinner from "../../../common/Spinner";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import useThemeChange from "../../../../hooks/useThemeChange";
import {LIGHT_THEME} from "../../../../utils/constants";
import useResizeObserver from "../../../../hooks/useResizeObserver";


export default function BlackListTable({isLoading, tableData = [], filterData = {}, changeFilterData, sortableColumn,
                                           sortableDirection, setSorting, onScroll}) {

    const [filterDataState, setFilterDataState] = useState({});
    const [timeoutState, setTimeoutState] = useState({
        timeout: 0
    });

    const dropDownData = [
        {value: "Да", label: "Да"},
        {value: "Нет", label: "Нет"},
    ];

   const themeName = useThemeChange();

    const dropDownStyles = {
        root: {
            padding: "0",
            minWidth: 150,
            underlineBorderBottom: '0px solid rgba(0,0,0,0) !important',
        },
        input: {
            backgroundColor: themeName === LIGHT_THEME ? "white" : "#1c1c1e",
            border: themeName === LIGHT_THEME ? "1px solid #ced4da" : "1px solid #000",
            borderRadius: ".25rem",
            fontSize: '12px !important',
            padding: "0 0 0 5px",
            minHeight: "30px",
            height: "30px"
        },
        placeholder: {
            fontSize: "12px !important",
            padding: "0 0 3px 5px",
        }
    };

    useEffect(() => {
        setFilterDataState(filterData);
    }, []);

    const onChangeFilterValue = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFilterDataState({...filterDataState, [name]: value});
        if (timeoutState.timeout) {
            clearTimeout(timeoutState.timeout);
        }
        setTimeoutState({
            timeout: setTimeout(() => {
                changeFilterData(value, name)
            }, 300)
        });
    };

    const onSelectDropdownValue = (name, data) => {
        setFilterDataState({...filterDataState, [name]: data});
        changeFilterData(data ? data.value : "", name)
    };

    const tableColumns = () => {
        return [
            {
                name: "Дата добавления",
                key: "date",
                getBodyCellValue: item => item["date"],
                headerCellStyle: {minWidth: "150px", width: "150px"}
            },
            {
                name: "Домен",
                key: "domain",
                getBodyCellValue: item => item["Домен"],
                headerCellStyle: {minWidth: "150px"}
            },
            {
                name: "ИД",
                key: "siteId",
                getBodyCellValue: item => item["siteId"],
                headerCellStyle: {width: "150px"}
            },
            {
                name: "Нарушение",
                key: "violation",
                getBodyCellValue: item => item["violation"],
                headerCellStyle: {width: "150px"},
            },
            {
                name: "Можно преленд",
                key: "isPreLandAllowed",
                getBodyCellValue: item => item["isPreLandAllowed"],
                headerCellStyle: {width: "15px"},
            },
            {
                name: "Рекл.сетка",
                key: "adNetwork",
                getBodyCellValue: item => item["adNetwork"],
                headerCellStyle: {minWidth: "150px"},
            },
        ]
    };

    const tableActionRow = () => {
        return [
            {
                key: "date",
                getActionCellValue: () => <input
                    name="date"
                    className="form-control"
                    value={filterDataState["date"] ? filterDataState["date"] : ""}
                    onChange={onChangeFilterValue}/>
            },
            {
                key: "domain",
                getActionCellValue: () => <input
                    name="domain"
                    className="form-control"
                    value={filterDataState["domain"] ? filterDataState["domain"] : ""}
                    onChange={onChangeFilterValue}/>
            },
            {
                key: "siteId",
                getActionCellValue: () => <input
                    type="text"
                    name="siteId"
                    className="form-control"
                    value={filterDataState["siteId"] ? filterDataState["siteId"] : ""}
                    onChange={onChangeFilterValue}/>
            },
            {
                key: "violation",
                getActionCellValue: () => <input
                    name="violation"
                    className="form-control"
                    value={filterDataState["violation"] ? filterDataState["violation"] : ""}
                    onChange={onChangeFilterValue}/>
            },
            {
                key: "isPreLandAllowed",
                getActionCellValue: () =>
                    <MaterialUISelect styles={dropDownStyles}
                                      dropdownData={dropDownData}
                                      selectedValue={filterDataState["isPreLandAllowed"]}
                                      onSelectCallbackFunction={(data) => onSelectDropdownValue("isPreLandAllowed", data)}
                                      isSearchable
                                      isClearable={true}
                                      maxChipTxtWidth={220}
                    />
            },
            {
                key: "adNetwork",
                getActionCellValue: () => <input
                    name="adNetwork"
                    className="form-control"
                    value={filterDataState["adNetwork"] ? filterDataState["adNetwork"] : ""}
                    onChange={onChangeFilterValue}/>
            },
        ]
    };

    const height = useResizeObserver(document.getElementById("scrollable")).height - 50;

    return isLoading
        ? <Spinner/>
        : tableData &&
        <div className="responsive-block statistic_table_wrapper" id="fullWidth" onScroll={onScroll} style={{height: height + "px"}}>
            <SimpleTable
                tableClassName="table table-bordered nowrap statistic_table sticky-header sticky-action sticky-footer userselecttext"
                columns={tableColumns()}
                data={tableData} bodyRowKey="id" actionRow={tableActionRow()} isSortable
                sortableColumn={sortableColumn} sortableDirection={sortableDirection} onSort={setSorting}/>
        </div>

}