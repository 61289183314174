import React, {Fragment} from "react";

export default function PopupInput({type, name, placeholder, value, errorMsg, onTextTyping, autoFocus=false, autoComplete="off"}) {

    return (
        <Fragment>
            <input autoFocus={autoFocus} autoComplete={autoComplete} type={type} name={name} placeholder={placeholder} onChange={onTextTyping} value={value}/>
            {errorMsg && <span className="login-error is-visible">{errorMsg}</span>}
        </Fragment>
    )
}